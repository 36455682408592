import React, { useState, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, AddressBlock } from 'app/_shared'

import { ButtonBar, BookingThrobber } from '../_shared'
import { useBooking, BookingSteps } from '../BookingContext'
import Summary from './Summary'
import Section from './Section'
import styles from './index.module.scss'

export default () => {
  const bottomRef = useRef();

  const { orderState, activeStep, actions: { confirmBooking }} = useBooking();
  const { isSubmitting, setSubmitting, values } = useFormikContext();

  // orderState can become unrenderable if we're transitioning away from the CONFIRM step,
  // for example, when the the selected time in the SCHEDULE step is no longer avaialble.
  // In this case, this component is only rendered for the transition animation out. Therefore
  // we'll continue rendering the last renderable orderState.
  const [order, setOrder] = useState(orderState);
  useEffect(() => {
    activeStep === BookingSteps.CONFIRM && setOrder(orderState);
  }, [orderState, activeStep]);

  const [atBottom, setAtBottom] = useState();

  const submit = async () => {
    setSubmitting(true);
    await confirmBooking(values);
    setSubmitting(false);
  }

  const toBottom = () => bottomRef.current.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    const snapScroll = () => bottomRef.current && setAtBottom(bottomRef.current.getBoundingClientRect().top < window.innerHeight);
    window.addEventListener('scroll', snapScroll, true);
    return () => window.removeEventListener('scroll', snapScroll, true)
  }, []);

  return (
    <div className={styles.confirm}>
      <h2>Please review and confirm your order.</h2>

      <Section editable step={BookingSteps.ADDRESS}>
        <AddressBlock className={styles.addressStep} address={order.address} />
      </Section>
      
      <Summary order={order} />
      
      <div ref={bottomRef} />
      <ButtonBar>
        { atBottom ? (
          <ActionLink type="button" appearance="button" disabled={isSubmitting} className={styles.submit} onClick={submit}>
            Place your order
            <FontAwesomeIcon icon={faArrowRight} />
          </ActionLink>
        ) : (
          <ActionLink type="button" appearance="button" disabled={isSubmitting} className={styles.scroll} onClick={toBottom}>
            <FontAwesomeIcon icon={faArrowDown} />
            Scroll down to confirm
          </ActionLink>
        )}
        <BookingThrobber in={isSubmitting} />
      </ButtonBar>
    </div>
  )
}
