import React from 'react'
import { useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, Throbber } from 'app/_shared'
import { useRoute } from 'app/layout'

import ChangeSummary from './ChangeSummary'
import SummaryConfirm from '../Confirm/SummaryConfirm'
import styles from './ConfirmStep.module.scss'

export default ({ orderData, isCancel, back, showError }) => {
  const { layoutNav: { path }} = useRoute();
  const { isSubmitting, values } = useFormikContext();
  const { 
    info: { id, promoCode, promoCodeValid }, 
    schedule: { cancellationPolicyDesc }, 
    originalSchedule, confirm, originalConfirm 
  } = orderData;

  const errors = [
    ...(originalConfirm ? originalConfirm.errors : []),
    ...(confirm ? confirm.errors : [])
  ];

  const newConfirm = { ...confirm, ...values.confirm };
  const reschedulePath = path({ modal: ['reschedule', id].join('_') });

  return (
    <div className={styles.confirm}>
      <ChangeSummary 
        originalTime={originalSchedule && originalSchedule.selectedTime} 
        newTime={!isCancel && values.schedule.selectedTime} 
      />

      <SummaryConfirm
        error={showError && errors[0]}
        confirmData={originalConfirm || newConfirm}
        newConfirmData={!isCancel && originalConfirm ? newConfirm : undefined}
        isCancelled={!!originalConfirm}
        cancellationPolicyText={cancellationPolicyDesc}
        promoCode={!isCancel && promoCode}
        promoCodeValid={promoCodeValid}
        hidePaymentAndTerms={isCancel}
      />
      <div className={styles.buttons} data-cancellation={isCancel}>
        { !isCancel && 
          <ActionLink 
            type="button" 
            appearance="plain button"
            disabled={isSubmitting} 
            className={styles.back} 
            onClick={back}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </ActionLink>
        }
        <ActionLink 
          type="submit" 
          appearance={'button'} 
          disabled={isSubmitting} 
          className={styles.next}
        >
          Confirm { !isCancel ? 'reschedule' : 'cancellation' } 
        </ActionLink>
        { isCancel && (confirm ? (
          <ActionLink appearance="link" to={reschedulePath} className={styles.reschedule}>
            Reschedule instead
          </ActionLink>
        ) : (
          <span>
            This order can be cancelled but cannot be rescheduled. 
            Please <ActionLink appearance="link" to="mailto:help@real.vision">contact us</ActionLink> if you require a reschedule.
          </span>
        ))}
        <Throbber active={isSubmitting} />
      </div>
    </div>
  )
}
