import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faTimes, faShareAlt, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import { AddressBlock, SizeSensor, InlineScroll, useFlags, ActionLink } from 'app/_shared'
import { useActiveUser } from 'app/user'
import { useListingData, useRoute } from 'app/layout'

import Stats from '../_shared/Stats'
import Status from '../_shared/Status'
import Agent from '../_shared/Agent'
import AgentModal from '../_shared/AgentModal'
import { useListing } from '../ListingContext'

import AccountMessage from './AccountMessage'
import { useShare } from '../Share'
import ControllerButton from './ControllerButton'
import styles from './index.module.scss'

export default ({ className, onResize, wideLayout }) => {
  const activeUser = useActiveUser();
  const { controller, tourName, layoutNav: { path }} = useRoute();
  const { my, explore } = useListingData();
  const { listing } = useListing();
  const { unbranded, minimalNav } = useFlags();
  const openShare = useShare();

  const tourNames = ({ my, explore }[controller] || {}).tourNames || [];
  const index = tourNames.indexOf(tourName);
  const prevTourName = index === -1 ? undefined : tourNames[index - 1];
  const nextTourName = index === -1 ? undefined : tourNames[index + 1];

  return (
    <div className={`${styles.header} ${wideLayout ? styles.wide : ''} ${className || ''}`}>
      <div className={styles.nav}>
        { !unbranded && !minimalNav &&
          <>
            <ControllerButton className={styles.controller} />
            <ControllerButton className={`${styles.controller} ${styles.mobile}`} mobile />
          </>
        }
        { !unbranded && listing && listing.stats && (!listing.status || listing.status.fullyDelivered) && (listing.offline ? (
            <ActionLink appearance="disabled pill" className={styles.share}>
              <FontAwesomeIcon icon={faEyeSlash} /> Offline
            </ActionLink>
          ) : (
            <ActionLink appearance="pill" onClick={() => openShare('facebook')} className={styles.share}>
              <FontAwesomeIcon icon={faShareAlt} /> Share
            </ActionLink>
          ))
        }
        { !unbranded && !minimalNav && controller && 
          <>
            <ActionLink 
              className={styles.prev} 
              data-disabled={!prevTourName || undefined}
              to={path({ tourName: prevTourName })}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </ActionLink>
            <ActionLink 
              className={styles.next} 
              data-disabled={!nextTourName || undefined} 
              to={path({ tourName: nextTourName })}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </ActionLink>
            <ActionLink 
              className={styles.close}
              to={path({ tourName: '' })}
            >
              <FontAwesomeIcon icon={faTimes} />
            </ActionLink>
          </>
        }
      </div>

      <div className={styles.account}>
        { controller === 'my' && listing && !listing.permissions.includes('order') && (activeUser || (!listing.photos && !listing.floorplan && !listing.tourReady)) &&
          <AccountMessage />
        }
      </div>

      { !unbranded && (!listing || (listing.agents && listing.agents.length)) > 0 && 
        <InlineScroll className={styles.agents}>
          { listing ? listing.agents.map((agent, i) => (
            <AgentModal key={i} agent={agent} render={open => (
              <Agent className={styles.agent} agent={agent} onClick={open} />
            )} />
          )) : (
            <Agent className={styles.agent} />
          )}
        </InlineScroll>
      }

      <AddressBlock className={styles.address} address={listing && {...listing.address, street: listing.address.street}} />
      
      { listing && listing.status && !listing.status.fullyDelivered ? (
        <Status className={styles.status} events={listing.status.orderEvents} />
      ) : listing && listing.stats && (
        <Stats className={styles.stats} stats={listing.stats} />
      )}
      { wideLayout &&
        <SizeSensor className={styles.spacer} onResize={onResize} />
      }
    </div>
  )
}