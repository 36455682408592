import React from 'react'

import Preparation from './Preparation'
import Reschedule from './Reschedule'
import Survey from './Survey'
import Results from './Results'

export default ({ event, expand, globalPermission, className }) => {
  const thisUserFeedback = event.feedbacks && event.feedbacks.find(t => t.isCurrentUser);
  return (
    <>
      { (!event.started || globalPermission) &&
        <Reschedule 
          admin={event.started} 
          orderId={event.orderId}
        />
      }
      { globalPermission && event.feedbacks.length > 0 &&
        <Results results={event.feedbacks} />
      }
      { expand && (
        !event.ended ? (
          <Preparation 
            key={event.orderId} 
            className={className}
            orderId={event.orderId} 
            lastGuideDownload={thisUserFeedback && thisUserFeedback.guideDownloaded}
          />
        ) : (
          <Survey 
            key={event.orderId} 
            className={className} 
            orderId={event.orderId} 
            photographerName={event.photographerName}
            submission={thisUserFeedback}
          />
        )
      )}
    </>
  )
}