import React from 'react'
import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointUp, faArrowRight, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

import { Register, SignIn, Reset } from 'app/user'
import { Modal, TabBox, MorphBox, ActionLink, Emoji, useFlags } from 'app/_shared'

import AnnualSwitch from './AnnualSwitch'
import Price from './Price'
import Terms from './Terms'
import Payment from './Payment'
import { pricing } from './index'

import styles from './Checkout.module.scss'

export default ({ activeTab, setActiveTab, close, annual, setAnnual, selectedPlan }) => {
  const { parsedQuery } = useFlags();
  const isCanada = parsedQuery['country'] === 'CA';
  
  const initialAmount = (pricing[selectedPlan][annual]?.[3] * (annual ? 12 : 1));
  const tax = isCanada ? Math.round(initialAmount * 100 * 0.13) / 100 : 0;
  const currency = isCanada ? 'cad' : 'usd';

  return (
    <Modal open={!!activeTab} close={close}>
      <TabBox activeTab={activeTab} center className={clsx(styles.box, styles[activeTab])}>

        <Register tabkey="REGISTER" openSignIn={() => setActiveTab("SIGN_IN")} finish={() => setActiveTab('TERMS')} />

        <SignIn tabkey="SIGN_IN" openRegister={() => setActiveTab("REGISTER")} openReset={() => setActiveTab('RESET')} finish={() => setActiveTab('TERMS')} mode="app" />

        <Reset tabkey="RESET" openSignIn={() => setActiveTab("SIGN_IN")} />

        <div className={clsx(styles.tab, styles.terms)} tabkey="TERMS">
          <h2>You've selected the <b>{selectedPlan}</b> subscription</h2>
          <Price className={styles.price} plan={selectedPlan} annual={annual} showSaveMessage />
          <div className={styles.summary}>
            <AnnualSwitch annual={annual} setAnnual={setAnnual} className={styles.annual} />
            <MorphBox className={styles.save} collapseDirection="height">
              { !annual && selectedPlan && 
                <div className={styles.message}>
                  Save <b>${ (pricing[selectedPlan][false][3] - pricing[selectedPlan][true][3]) * 12 }+</b> per year <FontAwesomeIcon icon={faHandPointUp} />  with Annual Billing
                </div>
              }
            </MorphBox>
            <Terms plan={selectedPlan} annual={annual} className={styles.definitions} />
            <div className={styles.review}>
              Please review all applicable terms in our <ActionLink appearance="link" to="/legal/subscription" target="_blank">
                Subscription Agreement <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </ActionLink>
            </div>
            <ActionLink appearance="button" className={styles.next} onClick={() => setActiveTab('PAYMENT')}>
              Accept &amp; continue <FontAwesomeIcon icon={faArrowRight} />
            </ActionLink>
          </div>
        </div>

        <div className={clsx(styles.tab, styles.payment)} tabkey="PAYMENT">
          <h2>Submit Initial Payment</h2>
          <div className={styles.content}>
            <div className={styles.lines}>
              <div className={styles.line}>
                <span>Initial Charge</span>
                <span>${initialAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              </div>
              { !!tax &&
                <div className={styles.line}>
                  <span>HST</span>
                  <span>${tax.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                </div>
              }
              
              <div className={clsx(styles.line, styles.total)}>
                <span>Total Due</span>
                <span>${(initialAmount + tax).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              </div>
              <p>We will email you a paid invoice for your records after payment is complete.</p>
            </div>
            <Payment amount={initialAmount + tax} currency={currency} show={activeTab === 'PAYMENT'} onSuccess={() => setActiveTab('SUCCESS')} />
          </div>
        </div>

        <div className={clsx(styles.tab, styles.success)} tabkey="SUCCESS">
          <h2><Emoji symbol="🎉" /> Congratulations!</h2>
          <div className={styles.content}>
            <p>
              You're signed up for the <b>{selectedPlan}</b> subscription.
            </p>
            <p>
              Our Client Success team will reach out to you soon to help make the most out of your subscription.
            </p>
            <p>
              If you have a listing coming up, now's a good time to get started:
            </p>
            <p className={styles.order}>
              <ActionLink className={styles.button} to="/order" appearance="button">submit an order</ActionLink>
            </p>
          </div>
        </div>

        <div className={clsx(styles.tab, styles.contact)} tabkey="CONTACT">
          <h2>Contact us</h2>
          <div className={styles.content}>
            Let's find the perfect Realvision subscription to provide your listings maximum value. Contact us now:
            <dl>
              <dt>Email:</dt>
              <dd><ActionLink to="mailto:help@real.vision" appearance="link">help@real.vision</ActionLink></dd>
              <dt>Phone and SMS:</dt>
              <dd><ActionLink to="sms:+12024104389" appearance="link">202-410-4389</ActionLink></dd>
            </dl>
          </div>
        </div>
      </TabBox>
    </Modal>
  )
}
