import { createRef, useState, useEffect, useRef } from 'react'

export default ({ rootMargin, threshold, onUpdate, render, count = 1 }) => {
  const [isIntersecting, setIsIntersecting] = useState(new Array(count).fill(null).map(() => false));
  const ref = useRef(new Array(count).fill(null).map(() => createRef()));

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      setIsIntersecting(prevIsIntersecting => {
        const newIsIntersecting = [ ...prevIsIntersecting ];
        entries.forEach(t => {
          const index = ref.current.findIndex(s => s.current === t.target);
          newIsIntersecting[index] = t.isIntersecting;
        })
        return newIsIntersecting;
      })
    }, { rootMargin, threshold });

    ref.current.forEach(t => observer.observe(t.current));
    return () => observer.disconnect();
  }, [ rootMargin, threshold ]);

  useEffect(() => {
    onUpdate && onUpdate(count === 1 ? isIntersecting[0] : isIntersecting);
  }, [count, isIntersecting, onUpdate]);

  return render({ 
    ref: count === 1 ? ref.current[0] : ref.current,
    isIntersecting: count === 1 ? isIntersecting[0] : isIntersecting 
  })
}