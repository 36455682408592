import React from 'react'

import List from '../List'
import Intro from './Intro'
import Footer from './Footer'
import { useRoute } from '../RouteContext'
import { useListingData } from '../ListingDataContext'

export default ({ className }) => {
  const { contact, filter } = useRoute();
  const { explore, cache } = useListingData();

  return (
    <List 
      className={className}
      allListings={explore.tourNames && explore.tourNames.map(t => cache[t])}
      pagination={explore.pagination}
      header={contact || filter ? undefined : <Intro />}
      footer={contact || filter ? undefined : <Footer />}
    />
  )
}