import React, { useEffect, useState } from 'react'

import { useFlags } from 'app/_shared'

import LogoLink from './LogoLink'
import AppLinks from './AppLinks'
import PageLinks from './PageLinks'
import ActionLinks from './ActionLinks'
import MenuLink from './MenuLink'

import styles from './Navbar.module.scss'

const isAtTop = () => typeof window === 'undefined' || window.scrollY < window.innerHeight * 0.2;

export default ({ mode, path, className }) => {
  const { unbranded, tourOnly, minimalNav, embedded } = useFlags();
  const [atTop, setAtTop] = useState(isAtTop());

  useEffect(() => {
    const onScroll = () => setAtTop(isAtTop());
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (unbranded || tourOnly) ? null : (
    <nav className={`${styles.navbar} ${className || ''}`} 
      data-mode={mode} 
      data-top={atTop ? '' : undefined} 
      data-path={path}
    >
      <div className={styles.inner}>
        <LogoLink className={styles.logo}/>
        { mode === 'app' && !minimalNav &&
          <AppLinks className={styles.app} />
        }
        { (mode !== 'app' || !minimalNav) &&
          <>
            { !embedded &&
              <PageLinks className={styles.pages} mode={mode} />
            }
            <ActionLinks className={styles.action} mode={mode} />
            <MenuLink className={styles.menu} mode={mode} />
          </>
        }
      </div>
    </nav>
  );
}
