import { EventHandler } from './'

// The exported class here normalizes touch and click events into common handlers.
// Some handlers are specific to either touch or mouse (such as pinch and hover, respective), 
// while others are common to both but with idiosyncracies (especially multiple touch)
// normalized into a common interface.

const MOUSE_DRAG_THRESHOLD = 3;
const TOUCH_DRAG_THRESHOLD = 10;
const DEFAULT_DECELERATION = 0.0025; // pixels/ms/ms

// Return the position of the specified event relative to the center of the container.
// NOTE: positive y is down.
const getContainerCoordinates = (container, { clientX, clientY }) => {
  var rect = container.getBoundingClientRect();
  return {
    x: clientX - rect.left - rect.width / 2,
    y: clientY - rect.top - rect.height / 2
  }
}

const setupMouse = (container, events, momentum, setDragging) => {  
  const onMouseDown = e => {
    e.preventDefault();
    let x = e.clientX;
    let y = e.clientY;
    let clickCancelled = false;
    events.trigger('start', { originalEvent: e });
    setDragging(true);
    momentum.start({ x, y, time: e.timeStamp });
    
    const onMouseMove = f => {
      f.preventDefault();
      const delta = { dx: f.clientX - x, dy: f.clientY - y };
      events.trigger('drag', { 
        originalEvent: f,
        clickCancelled,
        ...delta
      });
      momentum.add({ x: f.clientX, y: f.clientY, time: f.timeStamp });
      x = f.clientX;
      y = f.clientY;
    }

    const onMouseUp = (f) => {
      events.trigger('end', { originalEvent: f });
      setDragging(false);
      momentum.trigger(f.timeStamp);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mouseleave', onMouseUp);
    }
    
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mouseleave', onMouseUp);

    const clickCanceller = f => {
      if (Math.abs(e.clientX - f.clientX) > MOUSE_DRAG_THRESHOLD || Math.abs(e.clientY - f.clientY) > MOUSE_DRAG_THRESHOLD) {
        clickCancelled = true;
        document.removeEventListener('mouseup', triggerClick);
        document.removeEventListener('mousemove', clickCanceller);
        container.addEventListener('click', clickPreventer);
      }
    }

    const clickPreventer = f => {
      f.preventDefault();
      f.stopPropagation();
      container.removeEventListener('click', clickPreventer);
    }

    const triggerClick = f => {
      document.removeEventListener('mouseup', triggerClick);
      document.removeEventListener('mousemove', clickCanceller);
      events.trigger('click', {
        originalEvent: f,
        ...getContainerCoordinates(container, f)
      });
    }
    
    document.addEventListener('mousemove', clickCanceller);
    document.addEventListener('mouseup', triggerClick);
  };

  const onHoverMove = e => {
    events.trigger('hover', {
      originalEvent: e,
      ...getContainerCoordinates(container, e)
    });
  };
  
  container.addEventListener('mousedown', onMouseDown);
  container.addEventListener('mousemove', onHoverMove);

  return () => {  
    container.removeEventListener('mousedown', onMouseDown);
    container.removeEventListener('mousemove', onHoverMove);
  }
}

const setupTouch = (container, events, momentum, setDragging) => {
  const getTouchesAverage = touches => {
    let sum = [ 0, 0 ];
    for (let i = 0; i < touches.length; i++) {
      sum = [ sum[0] + touches[i].clientX, sum[1] + touches[i].clientY ];
    }
    let average = [ sum[0] / touches.length, sum[1] / touches.length ];
    let spread = null;
    if (touches.length > 1) {
      let sumSquareDistance = 0;
      for (let i = 0; i < touches.length; i++) {
        sumSquareDistance += Math.pow(touches[i].clientX - average[0], 2) + Math.pow(touches[i].clientY - average[1], 2);
      }
      spread = Math.sqrt(sumSquareDistance / touches.length);
    }
    return [ ...average, spread ];
  }

  const getContainerTouches = touches => Array.from(touches).filter(t => container.contains(t.target));

	const onInitialTouch = e => {
    // NOTE: since all React synthetic events are handled at the root, this also prevents
    // any of container's children's related synthetic events from firing.
    // https://gideonpyzer.dev/blog/2018/12/29/event-propagation-react-synthetic-events-vs-native-events/ 
    e.preventDefault();
    e.stopPropagation();

    let [x, y, r] = getTouchesAverage(e.targetTouches);
    let clickCancelled = false;
    events.trigger('start', { originalEvent: e });
    setDragging(true);
    momentum.start({ x, y, time: e.timeStamp });

    const onFinalTouchEnd = (f) => {
      if (getContainerTouches(f.touches).length === 0) {
        events.trigger('end', { originalEvent: f });
        setDragging(false);
        momentum.trigger(f.timeStamp);
        container.removeEventListener('touchend', onFinalTouchEnd);
        container.addEventListener('touchstart', onInitialTouch);
      }
    }
    container.removeEventListener('touchstart', onInitialTouch);
    container.addEventListener('touchend', onFinalTouchEnd);

    const onTouchMove = f => {
      let [oldX, oldY, oldR] = [x, y, r];
      const containerTouches = getContainerTouches(f.touches);
      [x, y, r] = getTouchesAverage(containerTouches);
      let dr = 1;
      let containerCoordinates = getContainerCoordinates(container, { clientX: x, clientY: y })
      if (containerTouches.length > 1) {
        dr = r / oldR;
        events.trigger('pinch',{
          originalEvent: f,
          ...containerCoordinates,
          dr
        });
      }
      const delta = { dx: x - oldX, dy: y - oldY, dr };
      events.trigger('drag', {
        originalEvent: f,
        ...containerCoordinates,
        ...delta,
        clickCancelled
      });
      momentum.add({ x, y, time: f.timeStamp });
    }

    const onAdditionalTouch = f => {
      [x, y, r] = getTouchesAverage(getContainerTouches(f.touches));
    }
    
    const onTouchEnd = (f) => {
      const containerTouches = getContainerTouches(f.touches);
      if (containerTouches.length === 0) {
        container.removeEventListener('touchmove', onTouchMove);
        container.removeEventListener('touchend', onTouchEnd);
        container.removeEventListener('touchstart', onAdditionalTouch);
      }
      else {
        [x, y, r] = getTouchesAverage(containerTouches);
        if (containerTouches.length === 1) {
          events.trigger('pinchend', {
            originalEvent: f
          });
        }
      }
    }

    container.addEventListener('touchstart', onAdditionalTouch);
    container.addEventListener('touchmove', onTouchMove);
    container.addEventListener('touchend', onTouchEnd);
  
    const clickCanceller = f => {
      const containerTouches = getContainerTouches(f.touches);
      if (containerTouches.length > 1 || 
          Math.abs(e.targetTouches[0].clientX - containerTouches[0].clientX) > TOUCH_DRAG_THRESHOLD || 
          Math.abs(e.targetTouches[0].clientY - containerTouches[0].clientY) > TOUCH_DRAG_THRESHOLD) {
        clickCancelled = true;
        container.removeEventListener('touchend', triggerClick);
        container.removeEventListener('touchstart', clickCanceller);
        container.removeEventListener('touchmove', clickCanceller);
      }
    }

    const triggerClick = f => {
      container.removeEventListener('touchend', triggerClick);
      container.removeEventListener('touchstart', clickCanceller);
      container.removeEventListener('touchmove', clickCanceller);
      // NOTE: since preventDefault and stopPropagation were called on the initial touch event, there
      // is no default handling by the browser of click events on a tap. Therefore we trigger it
      // manually here.
      f.target.click && f.target.click();
      events.trigger('click', {
        originalEvent: f,
        ...getContainerCoordinates(container, f.changedTouches[0])
      });
    }

    container.addEventListener('touchmove', clickCanceller);
    container.addEventListener('touchstart', clickCanceller);
    container.addEventListener('touchend', triggerClick);
	}

  container.addEventListener('touchstart', onInitialTouch);
  return () => container.removeEventListener('touchstart', onInitialTouch);
}

const setupWheel = (container, events) => {
  let block;
  const wheel = e => {
    e.preventDefault();
    if (!block) {
      // NOTE: on a Mac two events trigger at once; we debounce this.
      block = setTimeout(() => block = undefined, 100);
      events.trigger('wheel', {
        originalEvent: e,
        scrollX: Math.sign(e.deltaX),
        scrollY: Math.sign(e.deltaY),
        ...getContainerCoordinates(container, e)
      });
    }
  }
  container.addEventListener('wheel', wheel);

  return () => {
    container.removeEventListener('wheel', wheel);
  }
}

const setupMomentum = events => {
  const points = [];
  let running, deceleration;

  return {
    start: ({ x, y, time }) => {
      // NOTE: starting a new momentum will immediately kill any existing
      // momentum, *without* triggering the momentumend event.
      running = false;
      points.length = 0;
      points.push({ x, y, time });
    },
    add: ({ x, y, time }) => {
      points.push({ x, y, time });
    },
    trigger: (timeStamp) => {
      running = true;
      deceleration = DEFAULT_DECELERATION;
      const last = points[points.length - 1];
      const first = points.find(t => t.time >= timeStamp - 200);
      points.length = 0;

      // NOTE: capping momentum speed if there are insufficient sample points,
      // or points with insufficient duration in between.
      if (first && first !== last && last.time - first.time > 10) {
        const delta = { 
          x: last.x - first.x, 
          y: last.y - first.y,
          time: last.time - first.time 
        };

        const d = Math.sqrt(delta.x ** 2 + delta.y ** 2);
        const xComponent = delta.x / d;
        const yComponent = delta.y / d;

        let speed = d / delta.time;
        let time = last.time;

        const frame = (newTime) => {
          if (running) {
            const deltaTime = newTime - time;
            time = newTime;

            const newSpeed = Math.max(0, speed - deceleration * deltaTime);
            const distance = (speed + newSpeed) * deltaTime / 2;
            speed = newSpeed;

            events.trigger('momentum', { 
              dx: distance * xComponent, 
              dy: distance * yComponent
            });
            if (speed > 0) {
              window.requestAnimationFrame(frame);
            }
            else {
              running = false;
              events.trigger('momentumend');
            }
          }
        }
        window.requestAnimationFrame(frame);
      }
      else {
        running = false;
        events.trigger('momentumend');
      }
    },
    kill: () => {
      const wasRunning = running;
      running = false;
      wasRunning && events.trigger('momentumend', { kill: true });
    },
    applyResistance: factor => deceleration = DEFAULT_DECELERATION * factor
  };
}

export default class {
  constructor(container) {
    const events = new EventHandler();
    const momentum = setupMomentum(events);
    this.isDragging = false;
    const setDragging = dragging => this.isDragging = dragging;

    const destroyMouse = setupMouse(container, events, momentum, setDragging);
    const destroyTouch = setupTouch(container, events, momentum, setDragging);
    const destroyWheel = setupWheel(container, events);

    this.addEventListener = events.addEventListener;
    this.removeEventListener = events.removeEventListener;
    this.killMomentum = momentum.kill;
    this.resistMomentum = momentum.applyResistance;
    this.block = node => {
      if (node && container.contains(node)) {
        node.addEventListener('mousedown', e => e.stopPropagation());
        node.addEventListener('touchstart', e => e.stopPropagation());
        node.addEventListener('wheel', e => e.stopPropagation());
      }
    };

    this.destroy = () => {
      destroyMouse();
      destroyTouch();
      destroyWheel();
      momentum.kill();
      return undefined;
    }
  }
}