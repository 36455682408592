import React from 'react'
import clsx from 'clsx'
import Switch from 'react-switch'

import styles from './AnnualSwitch.module.scss'

export default ({ className, annual, setAnnual }) => {
  const size = 25;

  return (
    <label className={clsx(styles.container, className)}>
      <span>Monthly Billing</span>
      <Switch
        onChange={() => setAnnual(annual => !annual)}
        checked={annual}
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        offColor='#4CAF50'
        onColor='#4CAF50'
        width={size * 1.75}
        height={size}
        handleDiameter={size - 6}
        uncheckedIcon={false}
        checkedIcon={false}
        className={styles.switch}
      />
      <span>Annual Billing</span>
    </label>
  )
}