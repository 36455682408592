import React from 'react'
import Switch from 'react-switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons'

import styles from './Photo.module.scss'

export default ({ photo, style, onToggle, DragHandle}) => (
  <div className={styles.photo} data-hidden={photo.hidden} style={style}>
    <img src={photo.url} alt="" />
    <div className={styles.filename}>
      {photo.name}
    </div>
    <div className={styles.overlay}>
      <div className={styles.visibility}>
        <span>Visible</span>
        <Switch
          onChange={onToggle}
          checked={!photo.hidden}
          className={styles.switch}
        />
      </div>
      { DragHandle && 
        <DragHandle render={() => (
          <div className={styles.move}>
            <FontAwesomeIcon icon={faArrowsAlt} /> Drag
          </div>
        )} />
      }
      { /* this mask prevents the overlay's elements from being blindly activated on the same touch event that activates the hover state */ }
      <div className={styles.mask}></div>
    </div>
  </div>
)