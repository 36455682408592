import React, { useLayoutEffect, useRef, useMemo } from 'react'

import { TabBox } from 'app/_shared'

import { useMetadata } from '../_shared/MetadataContext'
import Tabs from './Tabs'
import styles from './Preview.module.scss'

const previewTabs = Tabs.reduce((all, tab) => {
  if (!tab.Preview) {
    return all;
  }
  else if (tab.data.subTabs) {
    return [
      ...all,
      ...tab.data.subTabs.map((subTab, i) => ({
        key: tab.data.id + '|' + subTab,
        component: tab.Preview[i]
      }))
    ]
  }
  else {
    return [
      ...all,
      { 
        key: tab.data.id,
        component: tab.Preview
      }
    ]
  }
}, []);

export default ({ activeTab, activeSubTab, startingScene, className = '' }) => {
  const ref = useRef();

  const metadataTags = useMetadata();
  const metadata = useMemo(() => ({
    title: metadataTags?.find(t => t.tag === 'title')?.innerText,
    description: metadataTags?.find(t => t.attributes?.name === 'description')?.attributes.content,
    image: metadataTags?.find(t => t.attributes?.name === 'image')?.attributes.content.replace(/(^.*(\?|&)size=)((.+?)(&|$))(.*)/, '$1382x200$5$6')
  }), [metadataTags]);

  const activeKey = activeTab + (activeSubTab ? '|' + activeSubTab : '');
  const is360 = activeKey === 'facebook|360 Photo';
 
  useLayoutEffect(() => {
    const container = ref.current;
    let i = 0;
    let handle;

    const rotate = () => {
      if (i++ > 10) {
        end();
        return;
      }

      // NOTE: TabBox is sensitive to rerendering; when a rerendering happens during a transition, the transition
      // will end prematurely. Therefore we manipulate the DOM directly here it here to avoid rerenders.
      const randomX = 0.6 - Math.random();
      const randomY = 0.5 - Math.random();

      container.style.setProperty('--rotateX', randomX * 50 + 'deg');
      container.style.setProperty('--rotateY', randomY * 60 + 'deg');
      container.style.setProperty('--shadowX', -randomY * 3 + 'em');
    };

    const end = () => {
      clearInterval(handle);
      container.style.removeProperty('--rotateX');
      container.style.removeProperty('--rotateY');
      container.style.removeProperty('--shadowX');
    }

    if (is360) {
      rotate();
      handle = setInterval(rotate, 1500);
      return end;
    }
  }, [is360]);

  return (
    <div ref={ref} className={`${styles.container} ${!previewTabs.find(t => t.key === activeKey) ? styles.inactive : ''} ${className}`} >
      <div className={styles.device}>
        <TabBox activeTab={activeKey}>
          { previewTabs.map(t => (
            <t.component tabkey={t.key} key={t.key} startingScene={startingScene} {...metadata} />
          ))}
        </TabBox>
      </div>
    </div>
  )
}