import React, { useState, useRef, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, useHint, InlineScroll } from 'app/_shared'

import { useTour } from './TourContext'
import styles from './ThumbnailStrip.module.scss'

export default ({ photos, className }) => {
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState();
  const { tourConfig, tour } = useTour();
  const { dismiss } = useHint();
  const source = useRef();

  useEffect(() => {
    if (tour) {
      const moveHandler = ({ data }) => data.source !== source && setActiveThumbnailIndex(undefined);
      tour.addEventListener('move', moveHandler);
      return () => tour.removeEventListener('move', moveHandler);
    }
  }, [tour]);
  
  const onThumbnailSelect = useCallback(index => {
    const photo = photos[index];
    tour.jumpTo({
      source,
      pan: (tourConfig.scenesByCode[photo.sceneCode]?.yaw ?? 0) - photo.yaw,
      tilt: photo.pitch,
      sceneCode: photo.sceneCode
    });
    setActiveThumbnailIndex(index);
    dismiss('tourThumbnail');
  }, [photos, tour, tourConfig, dismiss]);

  return (
    <InlineScroll 
      className={`${styles.thumbs} ${className || ''}`}
      fixedChildren={({ scroll, atMin, atMax, blockMotion }) => (
        <>
          <ActionLink 
            appearance={`white pill`}
            className={`${styles.arrow} ${styles.left}`} 
            onClick={() => scroll(-1)}
            disabled={atMin}
            ref={blockMotion}
          >
            <FontAwesomeIcon icon={faAngleLeft} fixedWidth />
          </ActionLink>
          <ActionLink 
            appearance={`white pill`}
            className={`${styles.arrow} ${styles.right}`} 
            onClick={() => scroll(1)}
            disabled={atMax}
            ref={blockMotion}
          >
            <FontAwesomeIcon icon={faAngleRight} fixedWidth />
          </ActionLink>
        </>
      )}
    >
      { ({ centerOn }) => photos.map((t, i) => (
        <ActionLink 
          key={t.code}
          onClick={e => {
            centerOn(e.currentTarget);
            onThumbnailSelect(i);
          }}
          className={`${styles.item} ${activeThumbnailIndex === i ? styles.active : ''}`}
          style={{backgroundImage: `url('${t.url}')`}}
        >
          { t.caption && 
            <div className={styles.caption}>
              { t.caption }
            </div>
          }
        </ActionLink>
      ))}
    </InlineScroll>
  )
}
