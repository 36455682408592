import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { ActionLink } from 'app/_shared'
import { useUserModal } from 'app/user'

import styles from './MenuLink.module.scss'

export default ({ className, mode }) => {
  const { openMenu } = useUserModal();
  return (
    <ActionLink className={`${styles.menu} ${className} ${styles[mode]}`} onClick={openMenu}>
      <FontAwesomeIcon icon={faBars} />
      <span>more</span>
    </ActionLink>
  )
}
