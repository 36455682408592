import React from 'react'

import { ActionLink } from 'app/_shared'
import { Submit } from './_shared'
import { BookingSteps } from './BookingContext'
import styles from './Progress.module.scss'

export default ({ activeStep }) => (
  <div className={styles.progress}>
    { Object.keys(BookingSteps).filter(t => t !== 'COMPLETE').map(t => (
      <Submit 
        key={BookingSteps[t]} 
        targetStep={BookingSteps[t]} 
        render={(form) => (
          <ActionLink className={styles.step} data-active={activeStep === BookingSteps[t] ? '' : undefined} onClick={form.submitForm}>
            {t}
          </ActionLink>
        )}
      />
    ))}
  </div>
)