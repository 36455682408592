import { authFetch } from 'app/_shared'

const backendEndpoint = process.env.BACKEND_API_ENDPOINT;

export async function getMetadata(tourName) {
  let url = `${backendEndpoint}/listing/metadata/${tourName}`;
  let response = await authFetch(url, { method: 'GET' });
  let contents = await response.text();

  let parser = new DOMParser();
  let doc = parser.parseFromString(contents,"text/html");
  let headNode = doc.getElementsByTagName("head")[0];

  return Array.from(headNode.children).map(t => ({
    tag: t.tagName.toLowerCase(),
    innerText: t.innerText,
    attributes: Array.from(t.attributes).reduce((all, current) => ({
      ...all,
      [current.name]: current.value
    }), {})
  }));
}

export async function downloadZip(tourName, sizes) {
  let url = `${backendEndpoint}/listing/zip/${tourName}?sizes=${sizes}`;
  let response = await authFetch(url, {
    method: 'POST'
  });
  let zipPackage = await response.json();
  window.location.href = zipPackage;
}

export async function getTourConfig(tourName, version, includeHidden) {
  let url = `${backendEndpoint}/tour/${tourName}?v=${version}`;
  if (includeHidden) {
    url += `&includeHidden=true`;
  }
  let response = await fetch(url, { method: 'GET' });
  if (response.ok) {
    let config = await response.json();
    config.scenesByCode = config.scenes.reduce((all, scene) => {
      scene.height = scene.height || config.height || 130;

      // TODO: get rid of this mechanism for positioning scenes not in the config; simply filter out rings not in the same chunk.
      if (config.minZ == null || scene.z < config.minZ) {
        config.minZ = scene.z;
      }
      return {
        ...all,
        [scene.code]: scene
      };
    }, {});

    return config;
  }
  else {
    throw new Error("Unable to load tour");
  }
}
