import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'

import { TabBox, InlineScroll, ActionLink } from 'app/_shared'

import Order from './Order'
import styles from './index.module.scss'

export default ({ orders, updateOrder }) => {
  const hasOrders = Array.isArray(orders) && !!orders[0];
  const [activeOrder, setActiveOrder] = useState(hasOrders && orders[0].info.orderCode);

  useEffect(() => {
    if (hasOrders && !activeOrder) {
      setActiveOrder(orders[0].info.orderCode);
    }
  }, [activeOrder, hasOrders, orders])

  const orderStatus = hasOrders && orders.reduce((all, order) => {
    let photographer = order.schedule.photographers.find(t => t.id === order.schedule.selectedPhotographerId);
    let duration = photographer ? photographer.appointmentDuration : 0;
    let appointmentComplete = moment(order.schedule.selectedTime.utc).add(duration, 'seconds').isBefore(moment());

    all[order.info.id] = 
      order.info.cancelledDate ? "Cancelled" 
      : order.info.deliveredDate ? "Delivered" 
      : appointmentComplete ? "In production"
      : "Confirmed" 
    
    return all;        
  }, {});

  return (
    <div className={styles.orders}>
      { hasOrders &&
        <div className={styles.tabs}>
          <InlineScroll className={styles.scroller}>
            {({ centerOn }) => orders.map(t => (
              <ActionLink 
                key={t.info.orderCode} 
                className={styles.tab} 
                onClick={(e) => {
                  centerOn(e.currentTarget);
                  setActiveOrder(t.info.orderCode);
                }}
                data-active={t.info.orderCode === activeOrder}
              >
                <span className={styles.title}>Order</span>
                <span className={styles.code}>{t.info.orderCode}</span>
                <span className={styles.status}>{orderStatus[t.info.id]}</span>
              </ActionLink>
            ))}
          </InlineScroll>
        </div>
      }
      { hasOrders &&
        <TabBox activeTab={activeOrder} className={styles.content}>
          { orders.map(t => (
            <Order 
              order={t} 
              key={t.info.orderCode} 
              tabkey={t.info.orderCode}
              status={orderStatus[t.info.id]}
              updateOrder={updateOrder}
            />
          ))}
        </TabBox>
      }
    </div>
  )
}
