import React from 'react'
import { ActionLink } from 'app/_shared'

import { Submit } from '../_shared'
import { BookingSteps } from '../BookingContext'
import styles from './Section.module.scss'

export default ({ step, children, editable }) => (
  <section className={styles.section}>
    <h3>
      { Object.keys(BookingSteps).find(t => BookingSteps[t] === step) }
      { editable &&
        <Submit targetStep={step} render={form => (
          <ActionLink className={styles.change} onClick={form.submitForm}>change</ActionLink>
        )} />
      }
    </h3>
    { children }
  </section>
)