import React from 'react'
import { useFormikContext } from 'formik'

import { ActionLink } from 'app/_shared'

import { ButtonBar, RegistrationReminder, ProductItem, OwnershipInput, UnavailableMessage } from './_shared'

import { useBooking, BookingSteps } from './BookingContext'
import styles from './Product.module.scss'

export default () => {
  const { orderState: { info: { ownerEmail, id }, address: { userAddress }, product: { products }}} = useBooking();
  const { values: { product }, isValid } = useFormikContext();

  return (
    <div className={styles.product}>
      { !products || products.length === 0 ? (
        <UnavailableMessage address={userAddress} />
      ) : (
        <>
          <RegistrationReminder className={styles.register} render={({ activeUser, openSignIn, openVerify, openRegister }) => activeUser ? activeUser.isVerified ? null : (
            <>
              <p>You're signed in but your email is not verified.</p>
              <p><ActionLink appearance="link" onClick={openVerify}>verify your email</ActionLink> to receive the best pricing customized for you, and to access additional account features.</p>
            </>
          ) : (
            <>
              <p>You're not currently signed in.</p>
              <p>
                <ActionLink onClick={openRegister} appearance="link">Create an account</ActionLink> 
                {' or '}
                <ActionLink appearance="link" onClick={openSignIn}>sign in</ActionLink> to save your listings, access more features, and receive the best pricing.
              </p>
            </>
          )} />

          { typeof ownerEmail === 'string' &&
            <OwnershipInput className={styles.ownership} orderId={id} ownerEmail={ownerEmail} />
          }
          { product &&
            <ProductItem 
              product={products[0]}
              instruction={true}

              name="product.products[0].quantity"
              value={product && product.products[0].quantity}
              highlight={!isValid}
            />
          }
        </>
      )}
      <ButtonBar lastStep={BookingSteps.ADDRESS} nextStep={BookingSteps.SCHEDULE} />
    </div>
  )
}
