import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt, faThLarge, faImage, faUserCog } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, InlineScroll, useFlags, useUnitPreference } from 'app/_shared'

import { useListing, Section, ListingLink } from '../ListingContext'
import { usePhotos } from '../PhotosSection/PhotosContext'
import SelectorItem from './SelectorItem'
import styles from './index.module.scss'

export default ({ className, children }) => {
  const { listing, activeSection, activeSubSection, enabledSections, photosGalleryAdmin } = useListing();
  const { visiblePhotos } = usePhotos(); 
  const { unbranded } = useFlags();
  const { unitPreference, toggleUnitPreference } = useUnitPreference();
  const listingStatus = listing.status;
  const floorPlanPdfUrl = listing.floorplan?.[`${unbranded ? 'un' : ''}brandedPdfUrl`]?.[unitPreference];
  const hasVideo = listing.photos && listing.photos.some(t => !t.hidden && t.externalLink);

  return (
    <InlineScroll className={`${styles.selector} ${className || ''}`}> 
      { ({ centerOn }) => (
        <>
          { enabledSections.includes(Section.INFO) &&
            <SelectorItem 
              to={{ section: Section.INFO }}
              label={`Info${unbranded ? '' : ' & Map'}`}
              active={activeSection === Section.INFO} 
              centerOn={centerOn}
            />
          }
          { enabledSections.includes(Section.TOUR) &&
            <SelectorItem
              to={{ section: Section.TOUR }}
              label="3D Tour" 
              active={activeSection === Section.TOUR} 
              centerOn={centerOn} 
              admin={listingStatus && !listingStatus.tourDelivered}
            />
          }
          { enabledSections.includes(Section.PHOTOS) &&
            <SelectorItem
              to={{ section: Section.PHOTOS }}
              label={`${hasVideo ? 'Video & ' : ''}Photos`}
              active={activeSection === Section.PHOTOS} 
              centerOn={centerOn}
              admin={(listingStatus && !listingStatus.photosDelivered) || (!listing.photos.some(t => !t.hidden) && photosGalleryAdmin)}
            >
              <div className={styles.toggle}>
                <ListingLink 
                  to={{ section: Section.PHOTOS, subSection: '' }}
                  className={activeSection === Section.PHOTOS && !activeSubSection ? styles.active : undefined}
                >
                  <FontAwesomeIcon icon={faThLarge} />
                </ListingLink>
                <ListingLink
                  to={{ section: Section.PHOTOS, subSection: activeSubSection || visiblePhotos?.[0]?.code }}
                  className={activeSection === Section.PHOTOS && activeSubSection ? styles.active : undefined}
                >
                  <FontAwesomeIcon icon={faImage} />
                </ListingLink>
              </div>
            </SelectorItem>
          }
          { enabledSections.includes(Section.FLOORPLAN) &&
            <SelectorItem 
              to={{ section: Section.FLOORPLAN }}
              label="Floor Plan" 
              active={activeSection === Section.FLOORPLAN} 
              centerOn={centerOn}
              admin={listingStatus && !listingStatus.floorPlanDelivered}
            >
              <div className={styles.toggle}>
                <ActionLink
                  className={unitPreference === "imperial" ? styles.active : undefined} 
                  onClick={unitPreference === "imperial" ? undefined : toggleUnitPreference}
                >
                  SF
                </ActionLink>
                <ActionLink 
                  className={unitPreference === "metric" ? styles.active : undefined} 
                  onClick={unitPreference === "metric" ? undefined : toggleUnitPreference}
                >
                  m²
                </ActionLink>
              </div>
              <ActionLink className={styles.link} to={floorPlanPdfUrl}>
                <FontAwesomeIcon icon={faCloudDownloadAlt} /> PDF
              </ActionLink>
            </SelectorItem>
          }
          { enabledSections.includes(Section.DASHBOARD) &&
            <SelectorItem
              to={{ section: Section.DASHBOARD }}
              label={
                <>
                  <FontAwesomeIcon icon={faUserCog} /> Dashboard
                </>
              }
              active={activeSection === Section.DASHBOARD} 
              centerOn={centerOn}
            />
          }
          {children}
        </>
      )}
    </InlineScroll>
  )
}