import React, { useRef, useLayoutEffect } from 'react'
import { ErrorMessage, getIn, useFormikContext } from 'formik'
import { ActionLink, useForwardedRef } from '.'
import styles from './FieldSet.module.scss'

const updateTextAreaSize = el => {
  el.style.height = null;
  el.style.height = Math.ceil(el.scrollHeight + el.getBoundingClientRect().height - el.clientHeight + 1) + 'px';
}

export default React.forwardRef(({ name, label, component, children, style, admin, autoResize, after, className = '', ...rest }, forwardedRef) => {
  const formik = useFormikContext();
  const ref = useForwardedRef(forwardedRef);
  const fileDummy = useRef();
  const Component = component || 'input';

  const handleFileChange = (file) => {
    if (file) {
      fileDummy.current.innerHTML= file.name;
      const reader = new FileReader();
      reader.onload = () => {
        formik.setFieldValue(name, {
          name: file.name,
          type: file.type,
          data: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
    else {
      fileDummy.current.innerHTML= '';
      formik.setFieldValue(name, null);
    }

  };

  const onFileDrop = (e) => {
    e.preventDefault();
    handleFileChange(e.dataTransfer.files[0]);
  }

  const controlled = name && formik;
  const value = rest.type === 'file' || !controlled ? rest.value : getIn(formik.values, name);
  const needResizeTextArea = autoResize && Component === 'textarea';

  // NOTE: useLayoutEffect instead of useEffect so that the text area can be the correct size
  // when first mounted, and so correctly measured by, e.g., MorphBox.
  useLayoutEffect(() => {
    needResizeTextArea && updateTextAreaSize(ref.current);
  }, [value, needResizeTextArea, controlled, ref]);

  return (
    <label className={`${styles.field} ${admin ? styles.admin : ''} ${className}`} style={style} data-name={name} data-select={component === "select" ? "" : undefined}>
      <Component 
        {...rest}
        name={name}
        onInput={controlled || !needResizeTextArea ? rest.onInput : e => {
          updateTextAreaSize(e.target);
          rest.onInput && rest.onInput(e);
        }}
        onChange={controlled ? e => {
          rest.type === 'file' ? handleFileChange(e.currentTarget.files[0]) : formik.handleChange(e);
          rest.onChange && rest.onChange(e);
        } : needResizeTextArea ? e => {
          updateTextAreaSize(e.target);
          rest.onChange && rest.onChange(e);
        } : rest.onChange}
        onBlur={controlled ? e => {
          formik.handleBlur(e); 
          rest.onBlur && rest.onBlur(e);
        } : rest.onBlur}
        value={value}
        checked={controlled && rest.type === "checkbox" ? !!getIn(formik.values, name) : undefined}
        disabled={controlled && formik.isSubmitting}
        ref={ref}
        data-error={controlled && getIn(formik.touched, name) && !!getIn(formik.errors, name) ? "" : undefined}
        data-empty={controlled && component === "select" && !getIn(formik.values, name) ? "" : undefined}
      >
        { children }
      </Component>
      { rest.type === 'file' &&
        <ActionLink 
          ref={fileDummy} 
          className={styles.dummy}
          onDragOver={e => e.preventDefault()}
          onDrop={onFileDrop}
        />
      }
      <span className={styles.label}>{label}</span>
      { controlled && 
        <span className={styles.error}>
          <ErrorMessage name={name} />
        </span>
      }
      { after }
    </label>
  )
})
