import React from 'react'
import { ActionLink, Emoji } from 'app/_shared'
import styles from './Message.module.scss'

export default () => (
  <div className={styles.container}>
    <h2>
      <Emoji symbol="🏆" /> Ready to impress your clients and elevate your listings?
    </h2>
    <p className={styles.order}>
      <ActionLink appearance="button" to="/order">List with Realvision</ActionLink>
    </p>
  </div>
)