import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { FieldSet, ActionLink } from 'app/_shared'

import Error from './Error'
import { register } from '../service'
import styles from './UserContext.module.scss'

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid').required('Required'),
  phone: Yup.string().required('Required'),
  password: Yup.string().required('Required').min(6, 'Must be at least 6 characters'),
  confirmPassword: Yup.string().required('Must match above').oneOf([Yup.ref('password')], 'Must match above'),
  terms: Yup.bool().test('consent', 'Required', v => v)
});

export default ({ openSignIn, finish }) => {
  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <h1>Register a new account</h1>
        <div className={styles.subtext}>
          <p>Aleady have an account?</p>
          <ActionLink appearance="arrow" onClick={openSignIn}>Sign in</ActionLink>
        </div>
      </div>
      <Formik 
        initialValues={{ firstName: '', lastName: '', email: '', phone: '', password: '', confirmPassword: '', terms: false }}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            actions.setStatus({ error: undefined });
            await register(values);
            finish();
          }
          catch ({ fieldErrors, statusError, message }) {
            actions.setSubmitting(false);
            fieldErrors && actions.setErrors(fieldErrors);
            (statusError || message) && actions.setStatus({ error: statusError || message });
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FieldSet name="firstName" label="First name" />
            <FieldSet name="lastName" label="Last name" />
            <FieldSet type="email" name="email" label="Email address" />
            <FieldSet type="tel" name="phone" label="Phone number" />
            <FieldSet type="password" name="password" label="Password" />
            <FieldSet type="password" name="confirmPassword" label="Confirm password" />
            <FieldSet type="checkbox" name="terms" label={(
              <>
                I agree with Realvision's <ActionLink appearance="link" target="_blank" to="/legal/terms">
                  terms of use
                </ActionLink> and <ActionLink appearance="link" target="_blank" to="/legal/privacy">
                  privacy policy
                </ActionLink>.
              </>
            )} />
            <Error />
            <ActionLink type="submit" appearance="button" disabled={isSubmitting}>Submit</ActionLink>
          </Form>
        )}
      </Formik>
    </div>
  )
}
