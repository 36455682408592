import React from 'react'
import { ActionLink, Emoji } from 'app/_shared'
import { useUserModal, useActiveUser } from 'app/user'
import styles from './Message.module.scss'

export default ({ isFooter, className }) => {
  const userVerified = useActiveUser()?.isVerified;
  const { openVerify } = useUserModal();

  return (
    <div className={`${styles.container} ${isFooter ? styles.footer : ''} ${className || ''}`}>
      { !isFooter && 
        <h1>
          <Emoji symbol="😃"/> Welcome to your Realvision account!
        </h1>
      }
      <p className={styles.order}>
        <ActionLink appearance="button" to="/order">Create a new listing</ActionLink>
      </p>
      { userVerified ? (
        <p className={styles.note}>
          To claim your past listings that don't appear here, please contact us at <ActionLink target="_blank" to="mailto:help@real.vision">help@real.vision</ActionLink>.
        </p>
      ) : (
        <p className={styles.note}>
          To claim your past listings, please <ActionLink appearance="link" onClick={openVerify}>verify your account</ActionLink>.
        </p>
      )}
    </div>
  )
}
