import React, { useEffect, useRef } from 'react'

import { ActionLink } from 'app/_shared'
import { PromoCodeInput, Error } from '../_shared/'

import SummaryFinance from './SummaryFinance'
import styles from './SummaryConfirm.module.scss'

export default ({ orderId, confirmData, newConfirmData, isCancelled, promoCode, promoCodeValid, cancellationPolicyText, hidePaymentAndTerms, error }) => {
  const errorRef = useRef();
  const hasError = !!error;
  useEffect(() => { hasError && errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}, [hasError]);

  return (
    <div className={styles.confirmStep} ref={hasError ? errorRef : undefined}>
      <Error className={styles.error}>{error}</Error>
      
      <SummaryFinance 
        className={styles.breakdown} 
        orderId={orderId}
        confirmData={confirmData}
        newConfirmData={newConfirmData}
        isCancelled={isCancelled}
      />
      
      <PromoCodeInput 
        className={styles.promo} 
        promoCode={promoCode}
        promoCodeValid={promoCodeValid}
      />

      { cancellationPolicyText &&
        <div className={styles.cancellation}>
          <h4>Cancellation policy:</h4>
          <p>{cancellationPolicyText}</p>
        </div>
      }

      { !hidePaymentAndTerms &&
        <>
          <div className={styles.payment}>
            <h4>You will be invoiced upon delivery of your order.</h4>
            <p>
              Your order total may be adjusted upon delivery according to actual captured area.
            </p>
          </div>
          <p>
            By placing your order, you agree to Realvision&apos;s <ActionLink appearance="link" target="_blank" to="/legal/terms">
              terms of use
            </ActionLink> and <ActionLink appearance="link" target="_blank" to="/legal/privacy">
              privacy policy
            </ActionLink>.
          </p>
        </> 
      }
    </div>
  )
}
