import React, { useContext, useEffect, useState } from 'react'

import { useFlags } from 'app/_shared'
import { useRoute, useListingData } from 'app/layout'

import { getTourConfig } from '../../service'
import { useListing, Section } from '../ListingContext'

const TourContext = React.createContext();

export default ({ children }) => {
  const { layoutNav: { go: layoutGo }, tourName, startingView } = useRoute();
  const { addFloors } = useListingData();
  const { listing } = useListing();
  
  const { debugMode } = useFlags();
  const [tourLoaded, setTourLoaded] = useState();

  const [tour, setTour] = useState();
  const [tourConfig, setTourConfig] = useState();

  const tourNameToLoad = listing && listing.tourReady ? tourName : undefined;
  const tourConfigVersion = tourNameToLoad && listing.tourConfigVersion;
  useEffect(() => {
    let abort = false;
    setTourLoaded(false);
    if (tourNameToLoad) {
      getTourConfig(tourNameToLoad, tourConfigVersion, debugMode).then(config => {
        if (!abort) {
          if (startingView) {
            const sceneCode = startingView.substring(5);
            const angle = parseInt(startingView.substring(0, 5));
            const pan = Math.floor(angle / 180);
            const tilt = angle % 180 - 90;
            if (!isNaN(pan) && !isNaN(tilt) && config.scenesByCode[sceneCode]) {
              config.starting = { sceneCode, pan, tilt };
              layoutGo({ section: Section.TOUR, replace: true });
            }
            else {
              layoutGo({ d1: '', replace: true });
            }
          }
          setTourConfig(config);
        }
      });
    }
    return () => {
      setTourConfig(undefined);
      abort = true;
    }
  }, [tourNameToLoad, startingView, tourConfigVersion, debugMode, layoutGo]);

  // NOTE: the loaded tourConfig may contain floors from other tours not included in those generated 
  // by the listing API, because the tourConfig API carries over floors from copied photos. This can
  // be improved. For now, if an extra floor is detected, it will be added to the listing data.
  useEffect(() => {
    if (tourNameToLoad && tourConfig && listing) {
      const existingFloors = listing.floorplan?.floors ?? [];
      const newFloors = tourConfig.floors.filter(t => 
        t.tourId !== listing.tourId && !existingFloors.some(s => s.id === t.floorId)
      );
      if (newFloors.length > 0) {
        addFloors(tourNameToLoad, newFloors.map(t => ({
          id: t.floorId,
          name: t.name,
          transform: {
            tx: t.transform.Tx,
            ty: t.transform.Ty,
            rz: t.transform.Rz
          },
          svgUrl: t.svgUrl,
          imageUrl: t.imageUrl
        })))
      }
    }
  }, [tourNameToLoad, tourConfig, addFloors, listing])


  return (
    <TourContext.Provider value={{ tourConfig, tour, setTour, tourLoaded, setTourLoaded }}>
      {children}
    </TourContext.Provider>
  );
}

export const useTour = () => useContext(TourContext);