import React, { useContext, useEffect, useReducer, useRef } from 'react'

import * as service from '../service'

export const BookingSteps = {
  ADDRESS: 'address', // for convenience the constant values match the property names in the order state from the server
  PRODUCT: 'product',
  SCHEDULE: 'schedule',
  CONTACT: 'contact',
  CONFIRM: 'confirm',
  COMPLETE: 'complete'
}

const BookingContext = React.createContext({});

function firstInvalidStep(orderState, upTo) {
  for (let key in BookingSteps) {
    if (BookingSteps[key] === BookingSteps.CONFIRM) {
      return BookingSteps.CONFIRM;
    }
    else if (!orderState[BookingSteps[key]].isValid || BookingSteps[key] === upTo) {
      return BookingSteps[key];
    }
  }
  return BookingSteps.CONFIRM;
}

function merge(newState, currentState) {
  return {
    address: newState.address || currentState.address,
    product: newState.product || currentState.product,
    schedule: newState.schedule || currentState.schedule,
    contact: newState.contact || currentState.contact,
    confirm: newState.confirm || currentState.confirm,
    info: newState.info || currentState.info
  }
}

function reducer(state, { type, orderState, targetStep, formValues }) {
  switch (type) {
    case 'initialize':
      return {
        ...state,
        activeStep: firstInvalidStep(orderState, BookingSteps.ADDRESS),
        suppressError: true,
        orderState
      };
    case 'refresh':
      return {
        ...state,
        activeStep: firstInvalidStep(orderState, targetStep || state.activeStep),
        orderState: merge(orderState, state.orderState)
      }
    case 'submit':
      const newStep = firstInvalidStep(orderState, targetStep || state.activeStep);
      return {
        ...state,
        activeStep: newStep,
        orderState: merge(orderState, state.orderState),
        suppressError: newStep === targetStep || orderState.info.id !== formValues.info.id
      }
    case 'confirm':
      return {
        ...state,
        activeStep: orderState.info.confirmedDate ? BookingSteps.COMPLETE : firstInvalidStep(orderState),
        suppressError: false,
        orderState
      };
    case 'reset':
      return {
        ...state,
        activeStep: null,
        suppressError: false,
        orderState: {}
      };
    default :
      return state;
  }
}

export default ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    activeStep: null,
    suppressError: false,
    orderState: {},
    actions: {
      refresh: (orderState, targetStep) => {
        dispatch({ type: 'refresh', orderState, targetStep });
      },
      submit: async (formValues, targetStep) => {
        const activeStep = activeStepRef.current;
        const orderState = await service.submit(formValues.info, formValues[activeStep], activeStep, targetStep);
        dispatch({ type: 'submit', orderState, targetStep, formValues });
      },
      confirmBooking: async (formValues) => {
        let orderState = await service.confirmBooking(formValues);
        dispatch({ type: 'confirm', orderState });
      },
      reset: () => {
        dispatch({ type: 'reset' });
        service.initializeOrder().then(orderState => dispatch({ type: 'initialize', orderState }));
      }
    }
  });

  const activeStepRef = useRef();
  activeStepRef.current = state.activeStep;

  useEffect(() => {
    let abort = false;
    service.initializeOrder().then(orderState => !abort && dispatch({ type: 'initialize', orderState }));
    return () => abort = true;
  }, []);


  return (
    <BookingContext.Provider value={state}>
      {children}
    </BookingContext.Provider>
  )
}

export const useBooking = () => useContext(BookingContext);
