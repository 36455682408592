import React from 'react'
import InlineScroll from './InlineScroll'
import styles from './AddressBlock.module.scss'

// TODO: make scroller optional via allowWrap prop
export default ({ className, address, streetOnly, wrap }) => {
  const Component = wrap ? 'div' : InlineScroll;
  return (
    <div className={`${styles.block} ${!address ? styles.skeleton : ''} ${className || ''}`}>
      <Component className={styles.address}>
        { address && address.unit &&
          <span className={styles.unit}>
            {address.unit}
          </span>
        }
        <span className={styles.street}>
          {address && address.street}
        </span>
      </Component>
      { !streetOnly && 
        <div className={styles.neighborhood}>
          {address && address.neighborhood}
        </div>
      }
    </div>
  )
}