import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { TabBox, ActionLink } from 'app/_shared'

import * as service from '../../service'

import ResetEmail from './Email'
import ResetCode from './Code'
import ResetPassword from './Password'
import ResetComplete from './Complete'
import styles from '../UserContext.module.scss'

const resetSteps = ['EMAIL', 'CODE', 'PASSWORD', 'COMPLETE'];

// TODO: this can just go in the service.
const getSubmitValues = (activeStep, { email, code, password }) => {
  let submitValues = { email };
  resetSteps.indexOf(activeStep) >= resetSteps.indexOf('CODE') && (submitValues = { ...submitValues, code });
  resetSteps.indexOf(activeStep) >= resetSteps.indexOf('PASSWORD') && (submitValues = { ...submitValues, password });
  return submitValues;
}

const getValidationSchema = (activeStep) => {
  let schema = { 
    email: Yup.string().email('Invalid').required('Required') 
  };
  resetSteps.indexOf(activeStep) >= resetSteps.indexOf('CODE') && (schema = { 
    ...schema, 
    code: Yup.string().required('Required'),
  });
  resetSteps.indexOf(activeStep) >= resetSteps.indexOf('PASSWORD') && (schema = { 
    ...schema,
    password: Yup.string().required('Required').min(6, 'Must be at least 6 characters'),
    confirmPassword: Yup.string().required('Must match above').oneOf([Yup.ref('password')], 'Must match above') 
  });

  return Yup.object().shape(schema);
}

export default ({ openSignIn }) => {
  const [activeStep, setActiveStep] = useState('EMAIL');

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <h1>Reset your password</h1>
        <div className={styles.subtext}>
        <ActionLink appearance="arrow" onClick={openSignIn}>
          Back to sign in
        </ActionLink>
        </div>
      </div>
      <Formik 
        initialValues={{ email: '', code: '', password: '', confirmPassword: '' }}
        validationSchema={getValidationSchema(activeStep)}
        onSubmit={async (values, actions) => {
          try {
            actions.setStatus({ error: undefined });
            await service.reset(getSubmitValues(activeStep, values));
            setActiveStep(resetSteps[resetSteps.indexOf(activeStep) + 1]);
          }
          catch({ fieldErrors, statusError, message }) {
            actions.setSubmitting(false);
            fieldErrors && actions.setErrors(fieldErrors);
            (statusError || message) && actions.setStatus({ error: statusError || message });
          }
        }}
      >
        <TabBox activeTab={activeStep} center>
          <ResetEmail tabkey="EMAIL" />
          <ResetCode tabkey="CODE" />
          <ResetPassword tabkey="PASSWORD" />
          <ResetComplete tabkey="COMPLETE" openSignIn={openSignIn} />
        </TabBox>
      </Formik>
    </div>
  )
}
