import React from 'react'
import moment from 'moment-timezone'

import styles from './DateBox.module.scss'

export default ({ datetime }) => {
  const date = moment(datetime.localDate);
  const time = moment(datetime.localTime,'H:m');

  return (
    <div className={styles.datetime}>
      <div className={styles.box}>
        <div className={styles.month}>{date.format('MMM YYYY')}</div>
        <div className={styles.date}>{date.format('D')}</div>
        <div className={styles.day}>{date.format('ddd')}</div>
      </div>
      <div className={styles.time}>{time.format('h:mm a')}</div>
      <div className={styles.timezone}>{datetime.timeZoneName}</div>
    </div>
  )
}