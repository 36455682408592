import React from 'react'

import { AddressBlock } from 'app/_shared'
import { StaticMap } from 'app/map'

import Stats from './_shared/Stats'
import Status from './_shared/Status'
import Agent from './_shared/Agent'
import Photo from './PhotosSection/Photo'
import styles from './Tile.module.scss'

export default ({ listing, divRef }) => (
  <div className={styles.tile} ref={divRef}>
    <div className={styles.gallery}>
      { !listing ? (
        <div className={styles.image}></div>
      ) : listing.photos && listing.photos.filter(t => !t.hidden).length > 0 ? (
        <Photo className={styles.image} url={listing && listing.photos.find(t => !t.hidden).url} cover />
      ) : (
        <StaticMap className={styles.map} lat={listing.location.lat} lng={listing.location.lng} showMarker/>
      )}
    </div>
    <div className={styles.info}>
      { (!listing || (listing.agents && listing.agents.length > 0)) &&
        <div className={styles.agents}>
          { listing ? listing.agents.map((agent, i) => (
            <Agent key={i} className={styles.agent} agent={agent} />
          )) : (
            <Agent className={styles.agent} />
          )}
        </div>
      }
      <AddressBlock address={listing && listing.address} className={styles.address} wrap />
    </div>
    { !listing ? (
      <div className={styles.stats}></div>
    ) : listing.status && !listing.status.fullyDelivered ? (
      <Status className={styles.status} events={listing.status.orderEvents} />
    ) : listing.stats && (
      <Stats className={styles.stats} stats={listing.stats} />
    )}
  </div>
)