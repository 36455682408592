import React, { useEffect, useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'

import { Throbber, wrapAdminElement, useAdminElement } from 'app/_shared'

import Slot from './Slot'
import styles from './TimeSlots.module.scss'

export default function TimeSlots({ className, isDateSelected, selectedTime, selectSlot, timeSlots, photographers, selectedPhotographer, originalSelectedTime, originalTravelCost, isAvailable, scrollBehavior }) {
  // NOTE: in some race conditions, schedule may be undefined momentarily and so its properties
  // can't be extracted directly.
  const { values: { schedule, info } } = useFormikContext();
  const adminEnabled = useAdminElement();

  const travelCostsByZone = useMemo(() => photographers.reduce((map, t) => {
    t.zones.forEach(z => map[z.id] = z.travelCost);
    return map;
  }, {}), [photographers]);

  const getTravelCost = useCallback(slot => {
    const slotZoneIds = Object.keys(slot.zones);
    const availableSlotZoneIds = slotZoneIds.filter(s => slot.zones[s]);
    let travelCost = 0;
    const useSelectedPhotographer = selectedPhotographer && (schedule?.ignoreAvailability || isAvailable(slot));
    if (useSelectedPhotographer) {
      // TODO: let the user know if a cheaper travel charge is available by removing the photographer selection.
      travelCost = selectedPhotographer.zones.find(t => slotZoneIds.some(z => z === t.id + ""))?.travelCost ?? Math.min(...selectedPhotographer.zones.map(z => z.travelCost));
    }
    else {
      travelCost = !availableSlotZoneIds.length ? 0 : Math.min(...availableSlotZoneIds.map(z => travelCostsByZone[z]));
    }

    return travelCost;
  }, [isAvailable, selectedPhotographer, travelCostsByZone, schedule]);

  // NOTE: this effect centers the selected time, but only when time slots are reloaded or a time selection changes.
  // It also tries to maintain the time-of-day (in local time) selection when time slots are reloaded by,
  // for example, choosing another date. This also applies to selecting another photographer.
  const selectedTimeUtc = selectedTime && selectedTime.utc;
  const selectedTimeLocaleTime = selectedTime && selectedTime.localTime;
  useEffect(() => {
    if (timeSlots) {
      let match = timeSlots.find(t => t.startTime.utc === selectedTimeUtc || t.startTime.localTime === selectedTimeLocaleTime);
      if (!match) {
        selectSlot(null, null, false, false);
      }
      else {
        const travelCost = getTravelCost(match);
        const unavailable = !isAvailable(match);
        if (match.startTime.utc !== selectedTimeUtc
            || travelCost !== schedule?.travelCost
            || unavailable !== schedule?.unavailableTimeSelected) {
          selectSlot(match.startTime, travelCost, false, unavailable);
        }
      }
    }
  }, [timeSlots, selectedTimeUtc, selectedTimeLocaleTime, getTravelCost, selectSlot, selectedPhotographer, isAvailable, schedule]);

  const scrollTo = useCallback(selection => {
    const parent = selection.parentNode.parentNode.parentNode;
    const scrollTop = selection.offsetTop - parent.offsetHeight / 2 + selection.offsetHeight / 2;
    parent.scroll({ top: scrollTop, behavior: scrollBehavior.current });
  }, [scrollBehavior]);

  const slotsToShow = timeSlots?.filter(s => selectedPhotographer || !s.noAvailability);

  return (
    <div className={className}>
      {!isDateSelected ? (
        <div>
          Select a date to see available time slots.
        </div>
      ) : !slotsToShow ? (
        <Throbber active />
      ) : (slotsToShow.length === 0 || ((!info.isAdmin || !adminEnabled) && slotsToShow.every(x => x.noAvailability))) ? (
        <div>
          No times available. Try another date{info.isAdmin && timeSlots.length > slotsToShow && wrapAdminElement(
            <span className={styles.admin}>, or select a photographer to see unavailable blocks</span>
          )}.
        </div>
      ) : slotsToShow.map((slot) => {
        const travelCost = getTravelCost(slot);
        const relativeTravelCost = travelCost - (originalTravelCost || 0);
        const isSelected = slot.startTime.utc === selectedTimeUtc;
        const slotAvailable = isAvailable(slot);

        return (
          <React.Fragment key={slot.startTime.utc}>
            {wrapAdminElement((
              <Slot {...{
                slot,
                photographers,
                selectedPhotographer,
                slotAvailable,
                travelCost,
                relativeTravelCost,
                isSelected,
                originalSelectedTime,
                originalTravelCost,
                scrollTo,
                selectSlot
              }}/>
            ), !info.isAdmin || !slot.noAvailability)}
          </React.Fragment>
        )
      })}
    </div>
  )
}
