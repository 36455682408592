import React from 'react'

import { ClickToCopy, ActionLink } from 'app/_shared'
import { useShare, useListing, Section, ListingLink } from 'app/listing'

import MockSelector from './MockSelector'
import RevisionMenu from './RevisionMenu'
import DownloadPhotos from './DownloadPhotos'
import EmailLink from './EmailLink'
import styles from './index.module.scss'

export default ({ className }) => {
  const openShare = useShare();
  const { listing } = useListing();

  return (
    <>
      <h3>What next?</h3>
      <div className={`${styles.delivered} ${className || ''}`}>
        <section>
          <h4>1. <em>Inspect and finalize</em> your listing package.</h4>
          <p>
            Use the listing header tabs to inspect each of your Realvision package components.
          </p>
          <MockSelector className={styles.mock} />
          <hr />
          <p>
            We can perform the following revisions to perfect your package:
          </p>
          <RevisionMenu className={styles.menu} listing={listing} />
          <p className={styles.cta}>
            Email us at <EmailLink /> with revision requests.
          </p>
        </section>

        <section className={styles.mls}>
          <h4>2. <em>Publish your listing to MLS</em> or your brokerage system.</h4>

          <h5>Download all your listing photos in web/MLS size.</h5>
          <DownloadPhotos className={styles.action} tourName={listing.tourName} size="web" />
          <ul>
            <li>
              Unzip the downloaded file on your computer.
            </li>
            <li>
              Upload your desired photos onto your MLS listing.
            </li>
            <li>
              Download individual photos from the <ListingLink className={styles.tab} to={{ section: Section.PHOTOS, subSection: "carousel" }}>photos</ListingLink> tab.
            </li>
          </ul>
          <hr />

          <h5>Enter the following web address into your MLS listing's <em>virtual tour</em> field.</h5>
          <div className={styles.action}>
            <ClickToCopy value={`${window.location.origin}/${listing.tourName}?o=u`}>
              Unbranded virtual tour link
            </ClickToCopy>
          </div>
          <ul>
            <li>
              Click the address above to copy it onto your clipboard.
            </li>
            <li>
              Paste this value into your MLS listing's <em>virtual tour</em> input field.
            </li>
          </ul>
        </section>

        <section>
          <h4>3. <em>Share and market</em> your listing.</h4>

          <h5>Share with your network.</h5>
            <div className={styles.action}>
              <ActionLink appearance="pill" onClick={() => openShare('link')}>
                Open sharing center
              </ActionLink>
            </div>

          <hr />

          <h5>Create print media.</h5>
          <p>Download full resolution photos and use any design/print service to create brochures, feature sheets, postcards, and other print materials.</p>
          <DownloadPhotos className={styles.action} tourName={listing.tourName} size="print" />

          <hr />

          <h5>Embed on your own website.</h5>
          <p>Copy and customize the following code into your personal website's content management system to embed an interactive experience directly on your site.</p>
          <pre className={styles.action}>
            {`<iframe src="${window.location.origin}/${listing.tourName}" width="100%" height="720px" frameborder="0"></iframe>`}
          </pre>
        </section>
        <h3>Need a hand?</h3>
        <p>
          Contact us at <EmailLink /> and we'll be happy to help!
        </p>
      </div>
    </>
  )
}