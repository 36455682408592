import React, { useState, useEffect, useRef } from 'react'

import { ActionLink, TabBox, wrapAdminElement } from 'app/_shared'

import InnerForm from '../_shared/InnerForm'
import * as service from '../../service'
import styles from './AdjustmentRemove.module.scss'

export default ({ orderId, adjustmentId, className }) => {
  const [armed, setArmed] = useState(false);
  const confirmRef = useRef();
  
  useEffect(() => {
    if (armed) {
      const disarmClick = e => !confirmRef.current.contains(e.target) && setArmed(false);
      const disarmEscape = e => e.key === 'Escape' && setArmed(false);
      document.addEventListener('click', disarmClick, false);
      document.addEventListener('keydown', disarmEscape, false);
      return () => {
        document.removeEventListener('click', disarmClick, false);
        document.removeEventListener('keydown', disarmEscape, false);
      }
    }
  }, [armed]);

  return wrapAdminElement(
    <InnerForm
      initialValues={{ orderId, adjustmentId }}
      submitEndpoint={service.deleteAdjustment}
    >
      {({ isSubmitting, submitForm }) => (
        <TabBox className={className} activeTab={armed ? "confirm" : "remove"} center>
          <ActionLink
            tabkey="remove"
            disabled={isSubmitting} 
            className={styles.button} 
            appearance="admin button"
            onClick={() => setArmed(true)}
          >
            Remove
          </ActionLink>
          <ActionLink
            tabkey="confirm"
            disabled={isSubmitting} 
            className={styles.button} 
            appearance="admin button"
            onClick={armed ? submitForm : undefined}
            ref={confirmRef}
          >
            Sure?
          </ActionLink>
        </TabBox>
      )}
    </InnerForm>
  )
}