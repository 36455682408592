import React, { useEffect } from 'react'

import { TabBox } from 'app/_shared'
import { useListingData } from 'app/layout'
import { useListing } from 'app/listing'

import DashboardContextProvider, { useDashboard } from './DashboardContext'
import Photos from './Photos'
import ChangeModal from './ChangeModal'
import Selector from './Selector'
import Timeline from './Timeline'
import styles from './index.module.scss'
import Orders from './Orders'

const Dashboard = ({ className }) => {
  const { orders, refetchOrders } = useDashboard();
  const { listing, activeSubSection, dashboardActive, listingNav } = useListing();
  const { refreshActiveListing, updateListingPhotos } = useListingData();
  const photosEditEnabled = listing && listing.photos && listing.permissions.includes('photos');

  const enabledSubSections = [''];
  orders && enabledSubSections.push('orders');
  photosEditEnabled && enabledSubSections.push('photos');
  const needRedirect = dashboardActive && !enabledSubSections.includes(activeSubSection);

  useEffect(() => {
    needRedirect && listingNav({ subSection: '', replace: true }, true);
  }, [listingNav, needRedirect]);

  return (
    <div className={`${styles.container} ${className || ''}`}>
      <Selector enabledSubSections={enabledSubSections} />
      <TabBox activeTab={dashboardActive ? activeSubSection: undefined} className={styles.tabs}>
        <Timeline
          tabkey={''}
          listing={listing}
        />
        { orders &&
          <Orders 
            tabkey="orders"
            orders={orders}
          />
        }
        { photosEditEnabled &&
          <Photos 
            tabkey="photos"
            photos={listing.photos}
            updateListingPhotos={updateListingPhotos}
          />
        }
      </TabBox>
      
      <ChangeModal
        onSuccess={() => {
          refreshActiveListing();
          refetchOrders();
        }}
      />
    </div>
  )
}

export default ({ className }) => (
  <DashboardContextProvider>
    <Dashboard className={className} />
  </DashboardContextProvider> 
)