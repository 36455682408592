import React, { useCallback, useMemo, useContext, useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import cookie from 'cookie'
import { navigate } from 'gatsby'
import query from 'query-string'

import { TabBox, Modal, ActionLink } from 'app/_shared'
import safeLocalStorage from 'app/_shared/safeLocalStorage'

import { verifySsoToken } from '../service'
import SignIn from './SignIn'
import Register from './Register'
import Reset from './Reset'
import Verify from './Verify'
import Menu from './Menu'
import styles from './UserContext.module.scss'

const UserModalContext = React.createContext({});
const ActiveUserContext = React.createContext();
const CookieAccessContext = React.createContext();

const getActiveUserFromCookie = oldUser => {
  const parsedCookie = typeof document !== 'undefined' && cookie.parse(document.cookie);
  const userToken = parsedCookie && (parsedCookie['Authorization'] || parsedCookie['Authorization-Legacy'])
  const tokenClaims = userToken && jwtDecode(userToken);

  // NOTE: tokens representing anonymous users will yield a null activeUser
  // NOTE: legacy tokens have PascalCase properties which (for now) need to be read here,
  // since legacy tokens are not upgraded to session tokens until an API call is made; PascalCase
  // properties can then be removed.
  const newUser = (tokenClaims && (tokenClaims.email ?? tokenClaims.Email)) ? {
    firstName: tokenClaims.firstName ?? tokenClaims.FirstName,
    lastName: tokenClaims.lastName ?? tokenClaims.LastName,
    email: tokenClaims.email ?? tokenClaims.Email,
    isVerified: tokenClaims.isVerified ?? tokenClaims.IsVerified === 'True'
  } : null;

  // If new and old user have the same property values then avoid unnecessary state changes
  // by maintaining the existing activeUser state object.
  return Object.keys({...oldUser, ...newUser}).every(t => oldUser?.[t] === newUser?.[t]) ? oldUser : newUser;
}

export default ({ mode, location, children }) => {

  const [hasCookieAccess, setHasCookieAccess] = useState(!document.hasStorageAccess || undefined);
  useEffect(() => {
    if (!hasCookieAccess) {
      document.hasStorageAccess().then(hasAccess => {
        if (hasAccess) {
          setHasCookieAccess(true);
        }
      });
    }
  }, [hasCookieAccess]);

  const requestCookieAccess = async () => {
    if (!hasCookieAccess) {
      document.requestStorageAccess().then(
        () => { setHasCookieAccess(true) },
        () => { setHasCookieAccess(false) }
      );
    }
  }

  const { authOrigin, authToken, ...otherQuery } = query.parse(location.search);
  const newSearch = query.stringify(otherQuery);
  useEffect(() => {
    if (authOrigin && authToken) {
      if (authToken) {
        verifySsoToken(authToken, authOrigin).then(() => {
          navigate(location.pathname + (newSearch && ('?' + newSearch)), { replace: true });
        });
      }
      else {
        const messageHandler = async e => {
          if (e.data.action === 'authenticate' && e.origin === authOrigin && e.data.token) {
            await verifySsoToken(e.data.token, e.origin);
            window.removeEventListener('message', messageHandler)
          }
        }

        window.addEventListener('message', messageHandler);
        window.parent.postMessage('ready', authOrigin);

        return () => window.removeEventListener('message', messageHandler);
      }
    }
  }, [authOrigin, authToken, newSearch, location.pathname]);

  const [activeUser, setActiveUser] = useState(getActiveUserFromCookie);
  useEffect(() => {
    if (typeof window !== undefined) {
      // Upon a trigger from a successful authFetch, update activeUser from the
      // authorization cookie, and also trigger a message to other tabs/windows
      // by setting a localStorage value to do the same via a storage event.
      const onAuthUpdate = () => {
        setActiveUser(getActiveUserFromCookie);
        safeLocalStorage.setItem('authUpdate', '');
        safeLocalStorage.removeItem('authUpdate');
      }

      // Upon receiving a trigger from another tab or window via temporarily setting
      // the localStorage, update activeUser from the authorization cookie.
      const onStorage = e => e.key === 'authUpdate' && setActiveUser(getActiveUserFromCookie);

      window.addEventListener('authUpdate', onAuthUpdate);
      window.addEventListener('storage', onStorage);

      return () => {
        window.removeEventListener('authUpdate', onAuthUpdate);
        window.removeEventListener('storage', onStorage);
      }
    }
  }, []);

  const [activeTab, setActiveTab] = useState();
  const close = useCallback(() => setActiveTab(undefined), []);

  const hasActiveUser = !!activeUser;
  const actions = useMemo(() => ({
    openSignIn: () => setActiveTab(hasActiveUser ? 'MENU' : 'SIGN_IN'),
    openRegister: () => setActiveTab(hasActiveUser ? 'MENU' : 'REGISTER'),
    openMenu: () => setActiveTab('MENU'),
    openVerify: () => setActiveTab('VERIFY'),
  }), [hasActiveUser]);

  return (authOrigin && authToken) ? null : (
    <ActiveUserContext.Provider value={activeUser}>
      <UserModalContext.Provider value={actions}>
        <CookieAccessContext.Provider value={{ hasCookieAccess, requestCookieAccess }}>
          { children }
          <Modal open={!!activeTab} close={close} className={`${styles.modal} ${styles[activeTab]}`}>
            <ActionLink className={styles.close} onClick={close}>
              &times;
            </ActionLink>
            <TabBox activeTab={activeTab} center>
              <Menu tabkey="MENU" mode={mode} close={close} openSignIn={actions.openSignIn} />
              <SignIn tabkey="SIGN_IN" mode={mode} openRegister={actions.openRegister} openReset={() => setActiveTab('RESET')} finish={close} />
              <Reset tabkey="RESET" openSignIn={actions.openSignIn} />
              <Register tabkey="REGISTER" openSignIn={actions.openSignIn} finish={() => setActiveTab('VERIFY')} />
              <Verify tabkey="VERIFY" close={close} />
            </TabBox>
          </Modal>
        </CookieAccessContext.Provider>
      </UserModalContext.Provider>
    </ActiveUserContext.Provider>
  )
}

export const useUserModal = () => useContext(UserModalContext);
export const useActiveUser = () => useContext(ActiveUserContext);
export const useCookieAccess = () => useContext(CookieAccessContext);
