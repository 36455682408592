import React, { useLayoutEffect, useRef, useState, useEffect, useCallback } from 'react'
import styles from './MorphBox.module.scss'
import { forceReflow, useForwardedRef } from '.';

// NOTE: the collapseDirection prop controls how the box vanishes and appears when transitioning to and from
// having no children: shrink and grow the width only, height only, or both (defaults to 'height').
// getCollapsedSize will return an object that explicitly sets the specified dimension(s) (width, height, 
// or both) to 0, and sets the unspecified directions to either undefined (will not animate), or the current
// value of the node optionally passed in.
const getCollapsedSize = (direction, node) => {
  const box = node && getSize(node);
  return {
    width: direction === 'width' || direction === 'both' ? 0 : (box?.width), 
    height: !direction || direction === 'height' || direction === 'both' ? 0 : (box?.height)
  };
}
const getSize = node => {
  const width = parseFloat(getComputedStyle(node).width);
  const height = parseFloat(getComputedStyle(node).height);
  return { width, height };
}

export const MorphBox = React.forwardRef(({ 
  className, id, children, morphOnMount, morphSameId, collapseDirection, center, onComplete,
}, forwardedRef) => {
  const outerRef = useForwardedRef(forwardedRef);
  const innerRef = useRef();
  const lastSizeRef = useRef(morphOnMount ? getCollapsedSize(collapseDirection) : undefined);

  // NOTE: lastChildren is the last truthy children until the last transition finishes.
  // Update last children here only when children is truthy.
  const lastChildrenRef = useRef();
  const lastChildren = lastChildrenRef.current;
  const hasLastChildren = lastChildren && React.Children.toArray(lastChildren).length > 0;
  const hasChildren = React.Children.toArray(children).length > 0;
  if (hasChildren) {
    lastChildrenRef.current = children;
  }

  // NOTE: lastId is the id which was passed in together with the last truthy children.
  // NOTE: When the id changes, lastChildren is rendered as inert to fade out.
  // When id doesn't change (including staying at undefined), the children are simply replaced in place.
  const lastIdRef = useRef();
  const lastId = lastIdRef.current;
  if (lastId !== id && hasChildren) {
    lastIdRef.current = id;
  }
  const renderLast = (lastId !== id || !hasChildren) && hasLastChildren;

  // Measure and record the current size triggerd by events that would alter the size.
  // NOTE: if outer div has no children (neither children nor lastChildren are rendered), then record
  // the collapsed size instead (width or height will record as undefined rather than 0).
  const measureSize = useCallback(() => {
    lastSizeRef.current = outerRef.current.children.length === 0
      ? getCollapsedSize(collapseDirection) 
      : getSize(outerRef.current);
  }, [collapseDirection, outerRef]);

  const forceUpdate = useState()[1];
  useEffect(() => {
    const outerDiv = outerRef.current;
    const end = ({ target }) => {
      measureSize(); // This fires for both events on the outerDiv, and its children
      if (target === outerDiv) { // Do the following only on events fired by the outerDiv.
        outerDiv.style.height = null;
        outerDiv.style.width = null;
        lastChildrenRef.current = undefined;
        onComplete && onComplete();
        forceUpdate({}); // Force an update to remove the outgoing lastChildren from being rendered.
      }
    }
    outerDiv.addEventListener('transitionend', end);
    return () => outerDiv.removeEventListener('transitionend', end);
  }, [outerRef, forceUpdate, measureSize, onComplete]);

  useLayoutEffect(() => {
    // The logic in here is a bit intricate.
    // Since we're inside a useLayoutEffect, at this point, the React elements have been generated, DOM tree updated,
    // but not yet painted to browser. If there is no explicit size set for the outer div, then it is now the same
    // size as the inner div.
    const outerDiv = outerRef.current;
    const lastSize = lastSizeRef.current; // Get the last measured size of the outer div prior to this render.
    measureSize(); // Measure again immediately.
    const targetSize = innerRef.current ? getSize(innerRef.current) // The current size of the inner div, and the desired size of the outer div.
      : getCollapsedSize(collapseDirection, outerDiv); // if there is no inner div, then prepare to collapse the outer div
    const lastTargetSize = {
      width: parseFloat(outerDiv.style.width),
      height: parseFloat(outerDiv.style.height)
    }; // The previously set target size of the outer div.

    // NOTE: unless morphSameId is true, morph only happens if children change from/to falsy, or if the id changes, 
    // or we're in the middle of a transition already.
    // NOTE: even after transition end, that the measured outer div size (depending on exact timing of measurement) 
    // might be different from the inner size by a rounding error. Therefore there is a tolerance of 1px here.
    if (lastSize && // lastSize is falsy only on first mount, and only if morphOnMount is falsy.
      (morphSameId || hasLastChildren !== hasChildren || lastId !== id || lastTargetSize.width >= 0 || lastTargetSize.height >= 0) &&
      (Math.abs(lastSize.height - targetSize.height) > 1 || Math.abs(lastSize.width - targetSize.width) > 1)
    ) {
      if (isNaN(lastTargetSize.height) || isNaN(lastTargetSize.width)) {
        // If outer div currently doesn't have a target size set, then set it to its last measured size.
        // Otherwise leave it at the existing target size.
        outerDiv.style.width = (lastTargetSize.width || lastSize.width) + 'px';
        outerDiv.style.height = (lastTargetSize.height || lastSize.height) + 'px';
      }
      // Force a reflow (while updating the outer div size measurement at the same time).
      measureSize();
      // Set the target outer div size, and listen for transition end to cleanup.
      outerDiv.style.width = targetSize.width + 'px';
      outerDiv.style.height = targetSize.height + 'px';
    }
    else {
      measureSize();
    }
  });

  // If both the current and last id are numbers, then the children will transition with a moving effect
  // to the left or right, depending on how the numbers compare. Otherwise, transition will be a simple fade.
  // TODO: allow a prop to specify whether transition should be vertical
  const direction = !isNaN(lastId) && !isNaN(id) && (lastId < id ? 'left' : lastId > id ? 'right' : 'stay');

  useLayoutEffect(() => {
    if (renderLast && innerRef.current) {
      innerRef.current.style.transition = 'none';
      innerRef.current.classList.add(styles[direction]);
      forceReflow(innerRef.current);
      innerRef.current.style.transition = null;
      innerRef.current.classList.remove(styles[direction]);
    }
  });

  return (
    <div ref={outerRef} className={`${styles.outer} ${center ? styles.center : ''} ${className || ''}`}>
      { renderLast &&
        <div className={`${styles.inner} ${direction ? styles[direction] : ''}`} inert={1} key={lastId}>
          {lastChildren}
        </div>
      }
      { children &&
        <div ref={innerRef} className={styles.inner} key={children ? id : undefined}>
          {children}
        </div>
      }
    </div>
  );
})

export const TabBox = React.forwardRef(({ activeTab, center, children, className }, ref) => {
  const childrenArray = React.Children.toArray(children);
  const activeIndex = childrenArray.findIndex(t => t.props.tabkey === activeTab);

  return (
    <MorphBox className={className} center={center} id={activeIndex === -1 ? undefined : activeIndex} ref={ref}>
      { childrenArray[activeIndex] }
    </MorphBox>
  )
})