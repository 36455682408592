import React from 'react'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { Emoji } from 'app/_shared'

import { reactions } from './Survey'
import styles from './Results.module.scss'

export default ({ results, className }) => {
  return (
    <div className={`${styles.results} ${className || ''}`}>
      { results.map((t, i) => (
        <div key={i} className={styles.result}>
          <div className={styles.name}>
            {t.userName}
          </div>
          <div className={styles.email}>
            {t.userEmail}
          </div>
          { t.guideDownloaded && 
            <div className={styles.guide}>
              <FontAwesomeIcon icon={faCheckCircle} />
              Guide last downloaded <span>{moment(t.guideDownloaded).format('ll [at] h:mm a')}</span>.
            </div>
          }
          { t.rating &&
            <div className={styles.rating}>
              {reactions.map((s, i) => (
                <Emoji key={i} symbol={s} className={i + 1 === t.rating ? styles.active : undefined} />
              ))}
            </div>
          }
          { (t.rating || t.comment) &&
            <div className={styles.modified}>
              {moment(t.lastUpdated).format('ll [at] h:mm a')}
            </div>
          }
          { t.comment &&
            <div className={styles.comments}>
              { t.comment && t.comment.split('\n').map((s, i) => (
                <p key={i}>{s}</p>
              ))}
            </div>
          }
        </div>
      ))}
    </div>
  )
}