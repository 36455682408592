import React, { useState, useContext } from 'react'
import Switch from 'react-switch'
import safeLocalStorage from 'app/_shared/safeLocalStorage'
import styles from './UnitPreference.module.scss'

const UnitPreferenceContext = React.createContext({});

export const UnitPreferenceContextProvider = ({ children }) => {
  const [state, setState] = useState({
    unitPreference: (safeLocalStorage.getItem('unitPreference')) || 'imperial',
    toggleUnitPreference: () => {
      setState(prevState => {
        const unitPreference = prevState.unitPreference === 'imperial' ? 'metric' : 'imperial';
        safeLocalStorage.setItem('unitPreference', unitPreference);
        return { ...prevState, unitPreference };
      });
    }
  });

  return (
    <UnitPreferenceContext.Provider value={state}>
      {children}
    </UnitPreferenceContext.Provider>
  ) 
}

export const useUnitPreference = () => useContext(UnitPreferenceContext);

export const UnitPreferenceToggle = ({ className, size, ...rest }) => {
  const { unitPreference, toggleUnitPreference } = useUnitPreference();
  return (
    <Switch 
      onChange={toggleUnitPreference} 
      checked={unitPreference !== 'imperial'}
      uncheckedIcon={<div className={styles.label}>ft</div>}
      checkedIcon={<div className={styles.label}>m</div>}
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      offColor="#B0BEC5"
      onColor="#B0BEC5"
      offHandleColor="#FFF"
      onHandleColor="#FFF"
      width={size * 2}
      height={size}
      handleDiameter={size - 4}
      className={`${styles.switch} ${className || ''}`}
      {...rest} 
    />
  )
}