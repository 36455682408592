import React, { useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, useFlags } from 'app/_shared'
import { Booking, AddressInputContext, useAddressInput } from 'app/booking'
import { Listing } from 'app/listing'

import ListingData from './ListingDataContext'
import RouteContext, { useRoute } from './RouteContext'
import Explore from './Explore'
import MyListings from './MyListings'
import Account from './Account'
import styles from './index.module.scss'
import clsx from 'clsx'

const routeMap = {
  'order': Booking,
  'explore': Explore,
  'my': MyListings,
  'account': Account
}

const ListingLayout = () => {
  const { bookingStep } = useAddressInput();
  const { controller, tourName, fullPath } = useRoute();
  const mainRef = useRef();

  useEffect(() => {
    mainRef.current.scroll({ top: 0, behavior: 'smooth' });
  }, [bookingStep]);

  const { unbranded, embedded, tourOnly } = useFlags();
  const ControllerComponent = routeMap[controller];

  return (
    <main 
      className={styles.main} 
      data-booking-step={bookingStep} 
      data-listing-active={!!tourName} 
      ref={mainRef}
    >
      { !!ControllerComponent &&
        <ControllerComponent className={clsx(styles.controller, styles[controller])} />
      }
      { controller !== 'account' && (
        <>
          <Listing className={styles.content} />
          { embedded && unbranded && !tourOnly && (
            <ActionLink className={styles.expand} to={fullPath} target="_blank">
              <FontAwesomeIcon icon={faExpandArrowsAlt} />
            </ActionLink>
          )}
        </>
      )}
    </main>
  );
}

export default () => (
  <RouteContext>
    <AddressInputContext>
      <ListingData>
        <ListingLayout />
      </ListingData>
    </AddressInputContext>
  </RouteContext>
)