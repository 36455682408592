import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, Logo, Emoji } from 'app/_shared'

import { useBooking } from './BookingContext'
import styles from './Complete.module.scss'

const icons = ["🙂","✌","️❤️","️🏁","👍","👌","💪","📷"];

export default () => {
  const { orderState: { info: { orderCode, tourName }, contact: { billingContact: { email }}}, actions: { reset }} = useBooking();

  return (
    <div className={styles.complete}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <h1>
        <Emoji symbol={icons[Math.floor(Math.random() * icons.length)]} /> Thanks for your order!
      </h1>
      <p className={styles.id}>
        Your order ID is <em>{orderCode}</em>.
      </p>
      <p className={styles.email}>
        Confirmation sent to <em>{email}</em>.
      </p>
      <div className={styles.links}>
        <ActionLink appearance="button" to={`/my/${tourName}`}>
          Prepare for your appointment <FontAwesomeIcon icon={faArrowRight} />
        </ActionLink>
        <ActionLink appearance="link" onClick={reset}>
          Order another
        </ActionLink>
      </div>
    </div>
  );
}