import { DateTime } from 'luxon'
import authFetch from './authFetch'

let start;
let index = 0;
export default async function recordEvent(type, attributes) {
  const now = new Date();
  // NOTE: time and index need to be generated immediately without potentially needing to await visitId.
  const body = {
    ...attributes,
    time: start ? now - start : 0,
    index: index++,
    type
  };
  if (!start) {
    start = now;
    body.clientDate = DateTime.fromJSDate(start).toISO(); // ISO formatted local date with offset
  }
  trySend(body);
};

let visitId, initialEvent;
const queue = [];
const url = `${process.env.AUTH_ENDPOINT}/event`;
const options = {
  method: 'POST',
  headers: {'Content-Type': 'application/json'},
  keepalive: true // This option instructs the browser to allow the request to outlive the page
};
// visitId is obtained from the response of the first successful call to /event.
// Calls to recordEvent are queued to delay execution until the visitId is obtained.
// visitId is not generated on the front end, since some front end user agents (e.g. google bot)
// have deterministic random number generators and will generate duplicate uuids. See "Known issues" at:
// https://www.npmjs.com/package/uuid
// Therefore we delegate the responsibility of ensuring uniqueness to the back end.
const trySend = (body) => {
  // NOTE: logic is similar to fetchAfterInitial, except no return value is needed.
  if (visitId) {
    authFetch(url, {...options, body: JSON.stringify({...body, visitId})});
  }
  else if (initialEvent) {
    // If no visitId is assigned from an initial event yet and an initial event is
    // in progress, queue this event up.
    queue.push(body);
  }
  else {
    // If no initialEvent is in progress, then this event will become the initialEvent
    // and be submitted without a visitId.
    initialEvent = authFetch(url, {...options, body: JSON.stringify(body)});

    // Handler for successful initialEvent
    const onSuccess = response => {
      response.json().then(data => {
        if (data?.visitId) {
          visitId = data.visitId;
          queue.forEach(t => fetch(url, {...options, body: JSON.stringify({...t, visitId})}));
        }
        else {
          onError();
        }
      })
    };

    // Hanlder for unsuccessful initialEvent, or if visitId cannot be obtained from the resopnse.
    const onError = () => {
      initialEvent = undefined;
      const next = queue.shift();
      next && trySend(next);
    };

    // NOTE: catch follows then so that if onSuccess throws an error (e.g. there is no json data
    // in the response), onError will be called.
    initialEvent.then(onSuccess).catch(onError);
  }
}
