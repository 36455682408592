import React, { useState } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircleDot, faCircleRight } from '@fortawesome/free-solid-svg-icons'

import { ActionLink } from 'app/_shared'
import { useActiveUser } from 'app/user'

import AnnualSwitch from './AnnualSwitch'
import Form from './Checkout'
import Price from './Price'

import styles from './index.module.scss'

// TODO: fetch pricing table from server
export const pricing = {
  undefined: { false: [299, 349, 10] },
  'AGENT': { false: [284, 332, 9.5, 284, 5], true: [269, 314, 9, 269, 10] },
  'TEAM': { false: [269, 314, 9, 1345, 10], true: [254, 297, 8.5, 1270, 15] }
}

export default ({ className }) => {
  const activeUser = useActiveUser();
  const [annual, setAnnual] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [selectedPlan, setSelectedPlan] = useState();

  const close = () => setActiveTab();

  const selectPlan = plan => {
    setSelectedPlan(plan);
    if (!plan) {
      setActiveTab();
    }
    else if (activeUser) {
      setActiveTab('TERMS');
    }
    else {
      setActiveTab('REGISTER');
    }
  };

  return (
    <div className={clsx(styles.page, className)}>
      <AnnualSwitch className={styles.annual} annual={annual} setAnnual={setAnnual} />
      <div className={styles.plans}>
        <div className={styles.package}>
          All listing packages include <b>unlimited professional photos</b>, <b>floor plan with dimensions</b>, <br/> and <b>3D tour inside and out</b>, all from one single appointment.
        </div>
        <div className={styles.title}>
          Pay as you go
        </div>
        <div className={styles.subtitle}>
          For the casual agent with occasional listings
        </div>
        <Price className={styles.price} />
        <div className={styles.cta}>
          <ActionLink appearance="button" className={styles.button} to="/order">Order now</ActionLink>
        </div>
        <div className={styles.terms}>
          <h3>&nbsp;</h3>
          <ul>
            <li><FontAwesomeIcon icon={faCircleDot} /> Payment due immediately after each delivery</li>
            <li><FontAwesomeIcon icon={faCircleRight} /> Upgrade to a subscription and receive a discount credit for your previous month's orders</li>
          </ul>
        </div>

        <div className={styles.title}>
          Agent
        </div>
        <div className={styles.subtitle}>
          Start accelerating your business if you have listings monthly
        </div>
        <Price className={styles.price} plan='AGENT' annual={annual} />
        <div className={styles.cta}>
          <ActionLink appearance="button" className={styles.button} onClick={() => selectPlan('AGENT')}>Sign up</ActionLink>
        </div>

        <div className={styles.title}>
          Team
        </div>
        <div className={styles.subtitle}>
          Build marketing consistency as you grow your listing volume
        </div>
        <Price className={styles.price} plan='TEAM' annual={annual} />
        <div className={styles.cta}>
          <ActionLink appearance="button" className={styles.button} onClick={() => selectPlan('TEAM')}>Sign up</ActionLink>
        </div>

        <div className={styles.title}>
          Beyond...
        </div>
        <div className={styles.subtitle}>
          Maximize value for offices and brokerages of all sizes
        </div>
        <div className={styles.contact}>
          Contact us to learn how to save up to <b>30%</b>
        </div>
        <div className={styles.cta}>
          <ActionLink appearance="button" className={styles.button} onClick={() => setActiveTab('CONTACT')}>Contact us</ActionLink>
        </div>
        <div className={styles.perks}>
          <h3>Exclusive benefits for all subscriptions:</h3>
          <ul>
            <li><FontAwesomeIcon icon={faCheckCircle} /> All subscription fees redeemable toward service usage at the discounted rate, up to 90 days after the end of corresponding billing period</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> Rate discount applicable for all additional usage, billed at the end of each month</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> Same day appointment booking reserved exclusively for subscribers (subject to photographer availability)</li>
            { false && (
              <>
                <li><FontAwesomeIcon icon={faCheckCircle} /> Free expedited photo delivery on every listing</li>
                <li><FontAwesomeIcon icon={faCheckCircle} /> Blue sky photo edits upon request (where allowed by local listing rules)</li>
                <li><FontAwesomeIcon icon={faCheckCircle} /> Virtual staging of empty rooms for up to 2 photos per listing</li>
              </>
            )}
          </ul>
        </div>
      </div>
      
      <Form activeTab={activeTab} setActiveTab={setActiveTab} close={close} annual={annual} setAnnual={setAnnual} selectedPlan={selectedPlan} />
    </div>
  )
}
