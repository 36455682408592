import React, { useEffect, useMemo, useRef } from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import { ListingLayout } from './layout'
import { UserContextProvider } from './user'
import { Navbar } from './nav'
import { DevSticker, UpdateDetector, FlagContextProvider, HintContextProvider, UnitPreferenceContextProvider, AdminElementContextProvider, recordEvent } from 'app/_shared'

export default ({ path, location, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  // Record navigation events
  // NOTE: useMemo is used instead of useEffect so the events are registered
  // BEFORE rendering child components which may introduce delays.
  const isInitial = useRef(true);
  useMemo(() => {
    if (isInitial.current) {
      isInitial.current = false;
      recordEvent('load', {
        version: '2024-01-14', // TODO: auto-generate at every deployment
        url: document.location.href,
        visibilityState: document.visibilityState,
        hasFocus: document.hasFocus(),
        language: navigator.language,
        languages: navigator.languages,
        referrer: document.referrer || undefined, // NOTE: falsey referrer value to be omitted
        ancestorOrigins: [...(window.location.ancestorOrigins ?? [])],
        ancestorUrls: (() => {
          // Traverse up the window chain and record each window's href if it's readable.
          // This will give us full urls for same-origin windows, and 'unknown' for cross-origin
          const refs = [];
          let curr = window;
          while (curr !== curr.parent) {
            let href = 'unknown';
            try {
              href = curr.parent.location.href;
            }
            catch {}
            refs.push(href);
            curr = curr.parent;
          }
          return refs;
        })()
      });
    }
    else {
      recordEvent('navigate', {url: location.href});
    }
  }, [location.href]);

  // record page lifecycle events
  useEffect(() => {
    const onLifecycle = e => recordEvent(e.type, {
      visibilityState: document.visibilityState,
      hasFocus: document.hasFocus()
    });
    // NOTE: When closing a page (by closing a tab or closing the browser, or navigating to another site),
    // a pagehide and hide events do not seem to be logged reliably. This could be because the pagehide
    // and visibilitychange events in the browser don't get fire reliably (despite specs), or they get fired
    // but the fetch to the back end get abandoned despite the keepalive option being set to true.
    const onPagehide = e => recordEvent('pagehide', {persisted: e.persisted});

    window?.addEventListener('focus', onLifecycle);
    window?.addEventListener('blur', onLifecycle);
    document?.addEventListener('visibilitychange', onLifecycle);
    window?.addEventListener('pagehide', onPagehide);

    return () => {
      window?.removeEventListener('focus', onLifecycle);
      window?.removeEventListener('blur', onLifecycle);
      document?.removeEventListener('visibilitychange', onLifecycle);
      window?.removeEventListener('pagehide', onPagehide);
    }
  }, []);

  const mode = path === '/*' ? 'app' : 'page';
  return (
    <AdminElementContextProvider>
      <UnitPreferenceContextProvider>
        <HintContextProvider>
          <FlagContextProvider mode={mode} location={location}>
            <UserContextProvider mode={mode} location={location}>
              <Helmet>
                <title>{data.site.siteMetadata.title}</title>
                <meta name="description" content={data.site.siteMetadata.description} />
                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
                <link rel="canonical" href={`https://real.vision`} />
                <html lang="en" />
              </Helmet>
              <Navbar mode={mode} path={path} />
              { mode === 'page' ? children : (
                // TODO: wrap ListingLayout in react-loadable (https://github.com/jamiebuilds/react-loadable)
                <ListingLayout />
              )}
              { process.env.CURRENT_ENV === 'DEV' &&
                <DevSticker />
              }
              <UpdateDetector />
            </UserContextProvider>
          </FlagContextProvider>
        </HintContextProvider>
      </UnitPreferenceContextProvider>
    </AdminElementContextProvider>
  )
}
