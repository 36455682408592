import React from 'react'

import { ActionLink, FieldSet, wrapAdminElement, MorphBox } from 'app/_shared'

import InnerForm from '../_shared/InnerForm'
import * as service from '../../service'
import styles from './PhotographerInstructions.module.scss'

export default ({ className, orderId, photographerInstructions }) => {
  return wrapAdminElement(
    <InnerForm
      initialValues={{ orderId, photographerInstructions: photographerInstructions || '' }}
      submitEndpoint={service.updatePhotographerInstructions}
    >
      {({ values, isSubmitting, submitForm, setFieldValue }) => (
        <div className={`${styles.container} ${className || ''}`}>
          <h4>Admin Instructions to Photographer</h4>
          <FieldSet admin component="textarea" autoResize name="photographerInstructions" disabled={isSubmitting}/>
          <MorphBox className={styles.buttons}>
            { values.photographerInstructions !== (photographerInstructions || '') &&
              <div className={styles.inner}>
                <ActionLink 
                  type="button" 
                  appearance="plain button" 
                  disabled={isSubmitting}
                  onClick={() => setFieldValue('photographerInstructions', photographerInstructions || '')}
                >
                  Discard changes
                </ActionLink>
                <ActionLink appearance="admin button" onClick={submitForm} disabled={isSubmitting}>
                  Save
                </ActionLink>
              </div>
            }
          </MorphBox>
        </div>
      )}
    </InnerForm>
  )
}