import React, { useState, useContext, useEffect } from 'react'
import Switch from 'react-switch'
import safeLocalStorage from 'app/_shared/safeLocalStorage'

const AdminElementHandlersContext = React.createContext({});
const AdminElementContext = React.createContext({});

export const AdminElementContextProvider = ({ children }) => {
  const [enabled, setEnabled] = useState(); // NOTE: initializes to undefined.
  const [handlers, setHandlers] = useState({
    init: () => {
      setEnabled(safeLocalStorage.getItem('adminElementEnabled') !== 'false');
      setHandlers({
        init: undefined,
        toggle: () => {
          setEnabled(prev => {
            safeLocalStorage.setItem('adminElementEnabled', !prev);
            return !prev;
          });
        }
      })
    }
  })

  return (
    <AdminElementHandlersContext.Provider value={handlers}>
      <AdminElementContext.Provider value={enabled}>
        {children}
      </AdminElementContext.Provider>
    </AdminElementHandlersContext.Provider>
  ) 
}

export const useAdminElement = () => useContext(AdminElementContext);

// NOTE: admin-only elements are wrapped in this component. Children are rendered
// only if admin elements are globally enabled.
export const AdminElement = ({ children }) => {
  const enabled = useAdminElement();
  const { init } = useContext(AdminElementHandlersContext);
  // NOTE: this enables the toggle switch only when an admin-only element is first encountered,
  // which prevents the switch from being shown to non-admin users.
  useEffect(() => init && init(), [init]);
  return !!enabled && children;
}

// Some elements are conditionally admin-only. This utility function provides a shortcut
// for conditionally wrapping an element in AdminElement.
export const wrapAdminElement = (element, bypass) => bypass ? element : (
  <AdminElement>
    {element}
  </AdminElement>
)

// Injects a switch for globally toggling whether to show admin elements. Switch only shows
// if the presence of any AdminElement components activated it.
export const AdminElementToggle = ({ size, className, render, ...rest }) => {
  const enabled = useAdminElement();
  const { toggle } = useContext(AdminElementHandlersContext);
  size = size || 25;

  return !!toggle && render(
    <Switch 
      onChange={toggle} 
      checked={enabled}
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      width={size * 2}
      height={size}
      handleDiameter={size - 4}
      className={className}
      {...rest} 
    />
  )
}