import React from 'react'

import { useListing } from '../ListingContext'
import AgentCard from '../_shared/AgentCard'

import styles from './Agents.module.scss'

export default ({ className }) => {
  const { listing: { agents }} = useListing();

  return agents.length > 0 && (
    <div className={`${styles.agents} ${className || ''}`}>
      <h3>Presented by</h3>
      <div className={styles.list}>
        {agents.map((t, i) => (
          <AgentCard key={i} agent={t} className={styles.agent} />
        ))}
      </div>
    </div>
  )
}