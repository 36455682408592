import React from 'react'

import { useListing, Section } from '../ListingContext'
import HeroBackground from './HeroBackground'
import LinkBar from './LinkBar'
import styles from './Hero.module.scss'

export default ({ className }) => {
  let { listing: { address }, activeSection } = useListing();
  return (
    <div className={`${styles.hero} ${activeSection === Section.INFO ? '' : styles.hidden} ${className || ''}`}>
      <HeroBackground className={styles.background} visible={activeSection === Section.INFO} />
      <div className={styles.address}>
        <div className={styles.street}>
          { address.unit &&
            <span className={styles.unit}>{address.unit}</span>
          }
          {address.street}
        </div>
        <div className={styles.neighborhood}>{address.neighborhood}</div>
      </div>
      <LinkBar className={styles.links} />
    </div>
  )
}