import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import styles from './Avatar.module.scss'

const defaultColor = '#455A64';

const getColorFromHash = (hash) => {
  if (!hash) {
    return defaultColor;
  }

  let code = 0;
  let hashString = hash.toString();
  for (var i = 0; i < hashString.length; i++) {
    code = hashString.charCodeAt(i) + ((code << 5) - code);
  }

  return hashColors[code % hashColors.length];
}

export default ({ className, user, empty, children, ...rest }) => {
  const userAvatarUrl = user && user.avatarUrl;
  return (
    <div 
      className={`${styles.avatar} ${className || ''}`} 
      style={userAvatarUrl ? {backgroundImage: `url(${user.avatarUrl})`} : undefined} 
      {...rest}
    >
      { !userAvatarUrl && !empty && (children || (
        <FontAwesomeIcon 
          icon={user ? (user.icon ?? faUser) : faUsers} 
          className={styles.placeholder} 
          style={{ color: user ? getColorFromHash(user.id) : defaultColor }} 
        />
      ))}
    </div>
  )
}

const hashColors = [
    "#f0ffff",
    "#f5f5dc",
    "#000000",
    "#0000ff",
    "#a52a2a",
    "#00ffff",
    "#ff00ff",
    "#ffd700",
    "#008000",
    "#4b0082",
    "#f0e68c",
    "#add8e6",
    "#e0ffff",
    "#90ee90",
    "#d3d3d3",
    "#ffb6c1",
    "#ffffe0",
    "#00ff00",
    "#ff00ff",
    "#800000",
    "#000080",
    "#808000",
    "#ffa500",
    "#ffc0cb",
    "#800080",
    "#800080",
    "#ff0000",
    "#c0c0c0",
    "#ffffff",
    "#ffff00"
];