import React from 'react'
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faExclamation, faTimes } from '@fortawesome/free-solid-svg-icons'

import { MorphBox, useAdminElement, ActionLink } from 'app/_shared'
import { formatTravelCost } from '../../service'

import styles from './Slot.module.scss'

export default function Slot({ className, slot, selectSlot, photographers, selectedPhotographer, originalSelectedTime, relativeTravelCost, originalTravelCost, isSelected, scrollTo, travelCost, slotAvailable }) {
  const { values: { schedule } } = useFormikContext();
  const adminElementEnabled = useAdminElement();

  return (
    <ActionLink
      className={clsx(className, styles.slot, {
        [styles.noAvailability]: slot.noAvailability ,
        [styles.notAvailable]: !slotAvailable ,
        [styles.adminView]: adminElementEnabled ,
        [styles.allowNoAvailability]: schedule?.ignoreAvailability
      })}
      onClick={() => selectSlot(slot.startTime, travelCost, true, !slotAvailable)}
      data-selected={isSelected}
      ref={e => e && isSelected && scrollTo(e)}
    >
      {DateTime.fromFormat(slot.startTime.localTime, "H:m").toFormat('h:mm a')}
      <span className={styles.travel}>{formatTravelCost(relativeTravelCost, true)}</span>
      {!slotAvailable &&
        <span className={styles.reset}>
          {!slot.noAvailability ?
            <span title="Selected Photographer Not Available "><FontAwesomeIcon icon={faUser} /> <FontAwesomeIcon icon={faExclamation} /></span>
            :
            <span title="No Photographer Available"><FontAwesomeIcon icon={faUser} /> <FontAwesomeIcon icon={faTimes} /></span>
          }
        </span>
      }
      <MorphBox className={styles.details}>
        {isSelected && (originalSelectedTime || {}).utc === slot.startTime.utc &&
          <div className={styles.note}>
            This is the currently confirmed appointment.
          </div>
        }
        {isSelected && !slotAvailable && selectedPhotographer && photographers.length > 1 && (
          <div className={styles.note}>
            {selectedPhotographer.name} is not available for this time, but we'll assign the best available alternative.
          </div>
        )}
        {isSelected && relativeTravelCost !== 0 && (originalTravelCost ? (
          <div className={styles.note}>
            {!slotAvailable ? 'Also, due' : 'Due'} to changes in availability, the travel fee
            will {relativeTravelCost > 0 ? "increase" : "decrease"} by {formatTravelCost(Math.abs(relativeTravelCost))}.
          </div>
        ) : (
          <div className={styles.note}>
            This appointment is {!slotAvailable ? 'also ' : ' '}
            outside {selectedPhotographer && slotAvailable && photographers.length > 1
              ? `${selectedPhotographer.name}'s`
              : `our${photographers.length > 1 ? ' available photographers\'' : ''}`
            } normal radius,
            so a {formatTravelCost(relativeTravelCost)} travel fee applies.
          </div>
        ))}
      </MorphBox>
    </ActionLink>);
}
