import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import ActionLink from './ActionLink'

import styles from './MockDevice.module.scss'

export default ({ children, className, mobile, tablet, portrait, landscape, onClick, style, to }) => {
  const Screen = to ? ActionLink : 'div';
  return (
    <div 
      className={[
        styles.device,
        mobile ? styles.mobile : '',
        tablet ? styles.tablet : '',
        portrait ? styles.portrait : '',
        landscape ? styles.landscape : '',
        className || ''
      ].join(' ')}
      style={style}
    >
      <Screen className={styles.screen} to={to} onClick={onClick}>
        { children }
        {(to || onClick) && 
          <div className={styles.overlay}>
            <FontAwesomeIcon icon={faSearch} />
          </div>
        }
      </Screen>
    </div>
  )
}