import React from 'react'
import { Field, Form, useFormikContext } from 'formik'
import { FieldSet, ActionLink } from 'app/_shared'
import Error from '../Error'

export default () => {
  const { isSubmitting } = useFormikContext();
  return (
    <Form>
      <p>Please enter and confirm a new password for your account.</p>
      <Field type="hidden" name="email" />
      <Field type="hidden" name="code" />
      <FieldSet type="password" name="password" label="Password" />
      <FieldSet type="password" name="confirmPassword" label="Confirm password" />
      <Error />
      <ActionLink type="submit" appearance="button" disabled={isSubmitting}>Set new password</ActionLink>
    </Form>
  )
}
