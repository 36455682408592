import React, { useCallback, useEffect, useContext, useState, useMemo } from 'react'
import { useAdminElement } from 'app/_shared'

import { useRoute } from 'app/layout'
import { useListing, Section } from '../ListingContext'
import { useTour } from '../TourSection/TourContext'

const PhotosContext = React.createContext();

const useActivePhoto = (visiblePhotos) => {
  // TODO: remove dependency on useRoute so a listing can be standalone.
  const { layoutNav: { go: layoutGo }} = useRoute();
  const { activeSection, activeSubSection } = useListing();

  const pathIndex = activeSection === Section.PHOTOS ?
    visiblePhotos?.findIndex(t => t.code === activeSubSection) : undefined;
  const [index, setIndex] = useState(pathIndex);
  useEffect(() => setIndex(pathIndex), [pathIndex]);

  const setActiveIndex = useCallback((index, stable) => {
    const code = visiblePhotos?.[index]?.code;
    setIndex(code ? index : undefined);
    if (stable) {
      layoutGo({ section: Section.PHOTOS, subSection: code ?? '' });
    }
  }, [layoutGo, visiblePhotos]); // NOTE: layoutGo reference never changes.

  // NOTE: Index is only valid in the transient state when we want to give context consumers
  // access to the currently displayed photo index, but not ready to commit to set the path yet.
  // Prefer index over pathIndex only when both it and pathIndex are valid (i.e., carousel is fully open).
  const activeIndex = pathIndex > -1 && index > -1 ? index : pathIndex;
  const pathInvalid = activeSection === Section.PHOTOS && !!activeSubSection && !!visiblePhotos && !(pathIndex > -1);
  useEffect(() => {
    pathInvalid && layoutGo({ section: Section.PHOTOS, subSection: '', replace: true });
  }, [pathInvalid, layoutGo]);
  const transient = pathIndex !== activeIndex;
  return [activeIndex, transient, setActiveIndex];
}

export default function PhotosContextProvider({ children }) {
  const { listing, photosGalleryAdmin } = useListing();
  const { tourConfig } = useTour();

  const adminElementEnabled = useAdminElement();
  const allPhotos = listing?.photos;
  const visiblePhotos = useMemo(() => {
    return photosGalleryAdmin && adminElementEnabled ? allPhotos : allPhotos?.filter(t => !t.hidden);
  }, [allPhotos, photosGalleryAdmin, adminElementEnabled]);

  const [activeIndex, transient, setActiveIndex] = useActivePhoto(visiblePhotos);

  const setNearest = useCallback(({ x, y, floorId }) => {
    // TODO: take the field of view into consideration as well when multiple photos from the same scene
    // face different directions.
    const closestPhoto = visiblePhotos.reduce((winner, photo, index) => {
      const scene = tourConfig?.scenesByCode[photo.sceneCode];
      if (scene && scene.floor === floorId) {
        let distance = (scene.x * 10 - x) ** 2 + (scene.y * 10 - y) ** 2;
        if (!winner || distance < winner.distance) {
          return { index, distance }
        }
      }
      return winner;
    }, null);
    if (closestPhoto) {
      setActiveIndex(closestPhoto.index, true);
    }
  }, [visiblePhotos, tourConfig, setActiveIndex]);

  return (
    <PhotosContext.Provider value={{ 
      activeIndex, 
      transient, 
      setActiveIndex, 
      visiblePhotos, 
      setNearest
    }}>
      { children }
    </PhotosContext.Provider>
  )
}

export const usePhotos = () => useContext(PhotosContext);