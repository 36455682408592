import React from 'react'

import { ActionLink } from 'app/_shared'
import { useListing } from 'app/listing'

import styles from './EmailLink.module.scss'

export default ({ className, children }) => {
  const { listing } = useListing();
  let address = listing.address.street;
  if (listing.address.unit) {
    address = `Unit ${listing.address.unit} · ${address}`; 
  }
  const body = `Please help me with the following revisions for ${address}:\n...\n\n${window.location.origin}/my/${listing.tourName}`;
  return (
    <ActionLink 
      className={`${styles.email} ${className || ''}`} 
      appearance="link"
      to={`mailto:help@real.vision?subject=${encodeURIComponent(`Revisions for ${address}`)}&body=${encodeURIComponent(body)}`}
      target="_blank"
    >
      { children ?? "help@real.vision" }
    </ActionLink>
  )
}