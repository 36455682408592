import React, { useState, useContext } from 'react'
import { BookingSteps } from './BookingContext'

const AddressInputContext = React.createContext({});

export default ({ children }) => {
  const [bookingStep, setBookingStep] = useState();
  const [locationFromBooking, setLocationFromBooking] = useState();
  const [map, setMap] = useState();

  return (
    <AddressInputContext.Provider value={{ 
      map, 
      setMap,
      bookingStep,
      setBookingStep,
      mapInputMode: bookingStep === BookingSteps.ADDRESS,
      setLocationFromBooking,
      locationFromBooking: bookingStep ? locationFromBooking : undefined
    }}>
      { children }
    </AddressInputContext.Provider>
  )
}

export const useAddressInput = () => useContext(AddressInputContext);