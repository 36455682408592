import React from 'react'
import { ActionLink } from 'app/_shared'
import styles from './PageLinks.module.scss'

export default ({ className, mode }) => (
  <div className={`${styles.pages} ${className} ${styles[mode]}`}>
    <ActionLink to="/product">Product</ActionLink>
    <ActionLink to="/pricing">Pricing</ActionLink>
    <ActionLink to="/showcase">Showcase</ActionLink>
  </div>
)