import { useEffect, useRef } from 'react'

export default function (ref) {
  const targetRef = useRef();

  useEffect(() => {
    if (typeof ref === 'function') {
      ref(targetRef.current);
    }
    else if (ref) {
      ref.current = targetRef.current;
    }
  }, [ref]);

  return targetRef;
}