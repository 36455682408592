import React from 'react'
import { Formik, useFormikContext } from 'formik'
import { useBooking } from '../BookingContext'
import { useDashboard } from 'app/dashboard'

// This component is a form that is meant to be optionally placed inside the booking flow form.
// After submission to the server, the server should return a whole updated order object.
// When placed inside the booking flow, after submission, the order is refreshed.
// A optional callback can also be triggered with the returned order object after submission.
//
// NOTE: since HTML <form> elements cannot be nested (forms have built-in behavior like submit when
// pressing enter on an input, which would become undefined when nested), the InnerForm cannot have
// its own formik <Form> component, and therefore certain behaviors (such as form reset, enableReinitialize)
// may not work as expected. Use extra care to ensure fields work as expected.
export default ({ initialValues, validationSchema, submitEndpoint, onSubmit, children }) => {
  const outerFormik = useFormikContext();
  const booking = useBooking();
  const dashboard = useDashboard();
  const submit = async (values, innerFormik) => {
    outerFormik && outerFormik.setSubmitting(true);
    let newState = await submitEndpoint(values, booking.activeStep);
    // Parent component can optionally pass in callback to be invoked with the new order state.
    onSubmit && onSubmit(newState, innerFormik);
    
    // Refresh the order in the booking and dashboard context, if context exists.
    booking.actions && booking.actions.refresh(newState);
    dashboard.updateOrder && dashboard.updateOrder(newState);
    outerFormik && outerFormik.setSubmitting(false);
  }

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      { formik => children({ 
        ...formik, 
        isSubmitting: formik.isSubmitting || (outerFormik && outerFormik.isSubmitting),
        isInnerFormSubmitting: formik.isSubmitting,
        isOuterFormSubmitting: outerFormik && outerFormik.isSubmitting,
        // NOTE: since InnerForm will not have an HTML <form> element, pressing enter on an input
        // will potentially trigger submission on the outer form. Therefore text inputs should manually 
        // have a onKeyPress handler set to the below to submit the inner form as desired and prevent
        // propogating a submission to the outer form.
        submitOnEnter: e => {
          if (e.which === 13) { 
            e.preventDefault();
            formik.submitForm();
          } 
        }
      })}
    </Formik>
  )
}
