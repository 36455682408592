import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import ViewerMotion from './ViewerMotion'
import { useUnitPreference, useHint } from 'app/_shared'

import { useListing, Section } from '../ListingContext'
import { useTour } from '../TourSection/TourContext'
import { usePhotos } from '../PhotosSection/PhotosContext'
import ViewerFloor from './ViewerFloor'
import styles from './Viewer.module.scss'

const viewBoxPadding = 0.05;
const spacing = 0.1;

// BBox doesn't work properly if SVG is not visible first the first time
// (assumed by the SVG parent's size being 0). Wait until it intiially shows before
// rendering the ViewerFloor components.
const useSvgRef = () => {
  const [visible, setVisible] = useState();
  const svgRef = useRef();

  useEffect(() => {
    const svgParent = svgRef.current.parentNode;
    if (!svgParent.offsetWidth || !svgParent.offsetHeight) {
      const sizeSensor = new ResizeObserver(() => {
        if (svgParent.offsetWidth && svgParent.offsetHeight) {
          setVisible(true);
          sizeSensor.disconnect();
        }
      });
      sizeSensor.observe(svgParent);
    }
    else {
      setVisible(true);
    }
  }, []);
  return { visible, svgRef }
}

// Calculate the position of each floor, depending on the BBox of each floor SVG as each loads,
// and whether we're stacking the floors or displaying them side by side.
const useGeometry = (floors, stacked, selectedFloorId) => {
  // As the SVG of each floor loads inside ViewerFloor, record its boundary box.
  const [floorBox, setFloorBox] = useState({});
  const onFloorLoad = useCallback((floorId, bbox) => {
    setFloorBox(floorBox => {
      return {
        ...floorBox,
        [floorId]: bbox ? {
          x: bbox.x + bbox.width / 2,
          y: bbox.y + bbox.height / 2,
          w: bbox.width,
          h: bbox.height
        } : undefined
      };
    });
  }, []);

  // Each time the floor boundary boxes, stacked flag, and some other related parameters change,
  // recalculate the horizontal offset to apply to each floor svg.
  const lastFloorOffset = useRef();
  const floorOffsets = useMemo(() => {
    let position = 0;
    let newOffsets = floors.reduce((offsets, floor) => {
      let box = floorBox[floor.id];
      let w = box ? box.w : 0;
      let x = box ? box.x : 0;

      position += w * spacing;
      const offset = stacked ? 0 : position - (x - w / 2);
      position += w * (1 + spacing);

      return {
        ...offsets,
        [floor.id]: offset
      }
    }, {});

    // Keep the target active floor offset the same, and offset every other floor by the same amount.
    let globalOffset = ((lastFloorOffset.current?.[selectedFloorId]) || 0) - newOffsets[selectedFloorId];
    return Object.keys(newOffsets).reduce((a, b) => ({
      ...a,
      [b]: newOffsets[b] + globalOffset
    }), {});
  }, [floors, stacked, floorBox, selectedFloorId]);
  lastFloorOffset.current = floorOffsets;

  return { onFloorLoad, floorOffsets, floorBox }
}

// Set up motion handler on the svg element, and update its event handlers as depencencies change.
// Handles drag, pinch, wheel.
// NOTE: We use a special ViewerMotion, which is an abstraction above GestureHandler, to take care of all 
// necessary transforms between user input in the screen space and viewbox coordinates in the SVG space.
const useMotion = (stacked, floorBox, floorOffsets, selectedFloorId, setSelectedFloorId, svgRef) => {
  const [scale, setScale] = useState(1);
  const [motion, setMotion] = useState();

  useEffect(() => {
    const motion = new ViewerMotion(svgRef.current);
    const scaleHandler = ({ data: { scale }}) => setScale(scale);
    motion.addEventListener('scale', scaleHandler);
    setMotion(motion);
    
    return () => {
      motion.removeEventListener('scale', scaleHandler);
      motion.destroy();
      setMotion(undefined);
    }
  }, [svgRef]);

  // Center on the new selected floor only if different from when drag started.
  useEffect(() => {
    if (motion) {
      let changed = true;
      const startHandler = () => changed = false;
      const endHandler = () => changed && motion.transitionToBounds();
      motion.addEventListener('start', startHandler);
      motion.addEventListener('end', endHandler);
      return () => {
        motion.removeEventListener('start', startHandler);
        motion.removeEventListener('end', endHandler);
      }
    }
  }, [motion, selectedFloorId]);

  // On every manual or momentum move, only when in unstacked mode, find the floor closest to viewBox center.        
  useEffect(() => {
    if (motion && !stacked) {
      const moveHandler = ({ data: { x, y }}) => {
        const closestFloorId = Object.keys(floorBox).reduce((winner, floorId) => {
          const box = floorBox[floorId];
          const offset = floorOffsets[floorId];
          const distance = (box.x + offset - x) ** 2 + (box.y - y) ** 2;
          return (!winner || distance < winner.distance) ? { floorId, distance } : winner;
        }, null).floorId;
    
        if (closestFloorId !== selectedFloorId) {
          setSelectedFloorId(closestFloorId)
        }
      };
      motion.addEventListener('move', moveHandler);
      return () => motion.removeEventListener('move', moveHandler);        
    }
  }, [motion, stacked, floorBox, floorOffsets, selectedFloorId, setSelectedFloorId]);

  return { scale, motion };
}

// Convert the scale into a grid with a pretty scale.
const usePrettyScale = (scale) => {
  const { unitPreference } = useUnitPreference();

  let mmPerUnit = unitPreference === 'metric' ? 1000 : 304.8;
  let idealUnitsPerGrid = 80 / scale / mmPerUnit;

  let orderOfMagnitude = Math.log10(idealUnitsPerGrid);
  let whole = Math.floor(orderOfMagnitude);
  let part = orderOfMagnitude - whole;
  
  let unitsPerGrid = Math.pow(10, whole) * (part > 0.67 ? 5 : part > 0.33 ? 2.5 : 1);
  let gridWidth = unitsPerGrid * mmPerUnit * scale;

  return { unitsPerGrid, gridWidth, unit: unitPreference === 'metric' ? 'm' : 'ft' };
}

// Set up interfacing with the tour, including move and rotate events, and clicking on the floor plan.
const useTourEvents = (motion, selectedFloorId, setSelectedFloorId, floorOffsets, interactive, svgRef) => {
  const { tour, tourConfig } = useTour();
  const { dismiss } = useHint();
  const { activeSection } = useListing();
  const { visiblePhotos, activeIndex, setNearest } = usePhotos();

  // 5. Add a listener to keep track the floor id and x,y coordinate inside the 3D tour.
  const [tourPosition, setTourPosition] = useState();
  const photoDriven = activeSection === Section.PHOTOS;
  const activePhoto = visiblePhotos?.[activeIndex];
  const photoSceneCode = activePhoto?.sceneCode;
  const photoYaw = activePhoto?.yaw;

  useEffect(() => {
    const fromScene = (code, photoYaw) => {
      const scene = code && tourConfig.scenesByCode[code];
      if (scene?.floor) {
        setSelectedFloorId(scene.floor)
      }
      return {
        photoDriven,
        ...(scene && {
          floorId: scene.floor,
          x: scene.x * 10,
          y: scene.y * 10,
          yaw: typeof photoYaw === 'number' ? (scene.yaw - photoYaw) : undefined,
          photoDriven
        })
      };
    }

    if (tour && tourConfig) {
      if (!photoDriven) {
        const moveHandler = ({ data }) => {
          setTourPosition(fromScene(data.targetScene));
        }
        const dismissHandler = ({ data }) => {
          if (data.source === svgRef) {
            dismiss('tourFloorPlanClick');
          }
        }
        tour.addEventListener('move', moveHandler);
        tour.addEventListener('move', dismissHandler);
        return () => {
          tour.removeEventListener('move', moveHandler);
          tour.removeEventListener('move', dismissHandler);
        }
      }
      else {
        setTourPosition(fromScene(photoSceneCode, photoYaw));
      }
    }
  }, [tour, tourConfig, photoDriven, photoSceneCode, photoYaw, setSelectedFloorId, dismiss, svgRef]);
  
  useEffect(() => {
    if (motion) {
      const clickHandler = async({ data: { x, y }}) => {
        const target = {
          floorId: selectedFloorId,
          x: x - floorOffsets[selectedFloorId],
          y: -y
        };
        if (tour) {
          if (!photoDriven) {
            const targetScene = await tour.jumpTo({ ...target, noJump: !interactive, source: svgRef });
            if (targetScene && !interactive) {
              // TODO: set state to render popup at the location of the found scene with
              // button to navigate to tour section, while calling tourActions.flyIn at the
              // found location.
              console.log(targetScene);
            }
          }
          else {
            setNearest(target);
          }
        }
      };
      motion.addEventListener('click', clickHandler);
      return () => motion.removeEventListener('click', clickHandler);
    }
  }, [motion, selectedFloorId, floorOffsets, tour, photoDriven, setNearest, interactive, svgRef]);

  return { tourPosition };
}

// Recenter the floor plan on the current active floor, in a variety of different updates situations.
const useSetBounds = (motion, selectedFloorId, tourPosition, interactive, floorBox, floorOffsets) => {
  const box = floorBox[selectedFloorId];
  useEffect(() => {
    if (box && motion) {
      let newBox = { ...box };
      if (selectedFloorId === tourPosition?.floorId && interactive) {
        const minX = Math.min(box.x - box.w / 2, tourPosition.x);
        const maxX = Math.max(box.x + box.w / 2, tourPosition.x);
        const minY = Math.min(box.y - box.h / 2, -tourPosition.y);
        const maxY = Math.max(box.y + box.h / 2, -tourPosition.y);
        newBox = {
          x: (maxX + minX) / 2,
          y: (maxY + minY) / 2,
          w: maxX - minX,
          h: maxY - minY
        }
      }

      newBox.w *= 1 + 2 * viewBoxPadding;
      newBox.h *= 1 + 2 * viewBoxPadding;

      newBox.x += floorOffsets[selectedFloorId];
      motion.setBounds(newBox);
    }
  }, [motion, selectedFloorId, tourPosition, box, interactive, floorOffsets]);

  useEffect(() => {
    if (motion && !motion.isDragging()) {
      // NOTE: a slower transition is triggered for changes in box size (i.e., due to tour position)
      motion.transitionToBounds(0.8);
    }
  }, [motion, tourPosition, box]);

  useEffect(() => {
    if (motion && !motion.isDragging()) {
      motion.transitionToBounds();
    }
  }, [motion, selectedFloorId, interactive, floorOffsets]);
}

export default ({ floors, selectedFloorId, setSelectedFloorId, floating, stacked, interactive, className }) => {
  const { visible, svgRef } = useSvgRef();
  const { onFloorLoad, floorOffsets, floorBox } = useGeometry(floors, stacked, selectedFloorId);
  const { scale, motion } = useMotion(stacked, floorBox, floorOffsets, selectedFloorId, setSelectedFloorId, svgRef);
  const { unitsPerGrid, gridWidth, unit } = usePrettyScale(scale);
  const { tourPosition } = useTourEvents(motion, selectedFloorId, setSelectedFloorId, floorOffsets, interactive, svgRef);
  useSetBounds(motion, selectedFloorId, tourPosition, interactive, floorBox, floorOffsets);
  
  return (
    <div className={`${styles.viewer} ${floating ? styles.floating : ''} ${className || ''}`}>
      <div className={styles.scale} style={{ width: `${gridWidth}px` }}>
        {unitsPerGrid} {unit}
      </div>
      <svg 
        preserveAspectRatio="xMidYMid meet"
        ref={svgRef}
        data-stacked={stacked}
        style={{ backgroundSize: `${gridWidth}px ${gridWidth}px`}}
      >
        { visible && floors.map(t => (
          <ViewerFloor
            key={t.id}
            floor={t}
            selected={t.id === selectedFloorId}
            tourPosition={tourPosition}
            onLoad={onFloorLoad} // NOTE: this prop must stay the same reference to prevent an infinite effect loop
            offset={floorOffsets[t.id]}
            scale={scale}
            interactive={interactive}
            markerSize={floating ? 15 : 25}
          />
        ))}
      </svg>
    </div>
  )
}
