import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, useFlags, useHint } from 'app/_shared'

import { useTour } from './TourContext'
import { useListing } from '../ListingContext'

export default ({ thumbnails }) => {
  const { initialAutoplay } = useFlags();
  const { dismiss } = useHint();
  const { tour, tourConfig } = useTour();
  const { activeSection } = useListing();

  const [autoplay, setAutoplay] = useState();
  const [playing, setPlaying] = useState(initialAutoplay);

  // this is to bypass the dependency on changes in thumbnails for Autoplay, which is
  // initialized only once for each tour and ignores subsequent changes to thumbnails.
  const thumbnailsRef = useRef();
  thumbnailsRef.current = thumbnails; 

  useEffect(() => {
    if (tour && tourConfig) {
      const AutoPlay = require('./core/AutoPlay.js').default;
      const autoplay = new AutoPlay({ tour, tourConfig, thumbnails: thumbnailsRef.current });
      const inputHandler = ({ data }) => data.source !== autoplay && setPlaying(false);
      tour.addEventListener('move', inputHandler);
      tour.addEventListener('rotate', inputHandler);
      setAutoplay(autoplay);

      return () => {
        tour.removeEventListener('move', inputHandler);
        tour.removeEventListener('rotate', inputHandler);
        autoplay.destroy();
        setPlaying(false);
        setAutoplay(undefined);
      };
    }
  }, [tour, tourConfig]);

  // Turn off autoplay when window loses visibility
  useEffect(() => {
    const disableAutoplay = () => setPlaying(false);
    document.addEventListener("visibilitychange", disableAutoplay);
    return () => document.addEventListener("visibilitychange", disableAutoplay);
  }, []);

  // Turn off autoplay every time section changes
  useEffect(() => setPlaying(false), [activeSection]);

  // When autoplay first loads, and when playing changes, imperatively start or stop the autoplay.
  useEffect(() => {
    if (autoplay) {
      if (playing) {
        autoplay.enable();
        dismiss("tourAutoplay");
      }
      else {
        autoplay.disable();
      }
    }
  }, [autoplay, playing, dismiss]);

  return (
    <ActionLink appearance="white pill" onClick={() => setPlaying(enabled => !enabled)}>
      <FontAwesomeIcon icon={ playing ? faPause: faPlay } fixedWidth />
    </ActionLink>
  );
}
