import React from 'react'
import styles from './Stats.module.scss'
import { useUnitPreference } from 'app/_shared'

const areaToString = (areaPrimary, areaSecondary, unitPreference) => {
  const areaNumber = sqFt => unitPreference === 'metric' ? Math.round(sqFt * 0.92903) / 10 : Math.round(sqFt);
  let areaString = areaNumber(areaPrimary);
  if (areaSecondary) {
    areaString += " + " + areaNumber(areaSecondary);
  }
  return areaString + (unitPreference === 'metric' ? ' m²' : ' SF');
}

export default ({ stats, className }) => {
  const { unitPreference } = useUnitPreference();

  return (
    <div className={`${styles.stats} ${className || ''}`}>
      { !!stats.bedroomsPrimary && 
        <div className={styles.stat}>
          {stats.bedroomsPrimary}
          {stats.bedroomsSecondary ? ` + ${stats.bedroomsSecondary}` : ""} BED
        </div>
      }
      { !!stats.bathroomsPrimary &&
        <div className={styles.stat}>
          {stats.bathroomsPrimary}
          {stats.bathroomsSecondary ? ` + ${stats.bathroomsSecondary}` : ""} BATH
        </div>
      }
      { !!stats.areaPrimary &&
        <div className={styles.stat}>
          ~ {areaToString(stats.areaPrimary, stats.areaSecondary, unitPreference)}
        </div>
      }
    </div>
  )
}