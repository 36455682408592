import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons'

import { Avatar, ActionLink } from 'app/_shared'

import styles from './AgentCard.module.scss'

export default ({ agent, className }) => {

  return (
    <div className={`${styles.card} ${className || ''}`}>
      <div className={styles.person}>
        <Avatar user={agent} className={styles.avatar}/>
        <div className={styles.name}>{agent.name}</div>
        <div className={styles.title}>{agent.title}</div>
        <div className={styles.company}>{agent.company}</div>
      </div>
      <div className={styles.contact}>
        { agent.phone && 
          <ActionLink target="_blank" to={`tel:${agent.phone}`} className={styles.phone}>
            <FontAwesomeIcon icon={faPhone} fixedWidth /> {agent.phone}
          </ActionLink>
        }
        { agent.email &&
          <ActionLink target="_blank" to={`mailto:${agent.email}`} className={styles.email}>
            <FontAwesomeIcon icon={faEnvelope} fixedWidth /> {agent.email}
          </ActionLink>
        }
        { agent.website &&
          // NOTE: the "to" property prepends an http:// if a protocol is not already present, while
          // the text removes the protocol and any trailing slash
          <ActionLink target="_blank" to={(agent.website.match(/(^\w+:|^)\/\//) ? '' : 'http://') + agent.website} className={styles.website}>
            <FontAwesomeIcon icon={faLink} fixedWidth /> {agent.website.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '')}
          </ActionLink>
        }
      </div>
    </div>
  )
}