import React from 'react'
import { Field, Form, useFormikContext } from 'formik'
import { FieldSet, ActionLink } from 'app/_shared'
import Error from '../Error'

export default () => {
  const { values, isSubmitting } = useFormikContext();
  return (
    <Form>
      <p>We've sent a code to <em>{values.email}</em>. Please enter the code here.</p>
      <Field type="hidden" name="email" />
      <FieldSet name="code" label="Verfication code"  />
      <Error />
      <ActionLink type="submit" appearance="button" disabled={isSubmitting}>Confirm code</ActionLink>
    </Form>
  )
}
