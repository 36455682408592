export default class {
  constructor() {
    this._eventRegister = {};
  }

  addEventListener = (type, listener) => {
    if (!this._eventRegister[type]) {
      this._eventRegister[type] = [];
    }
    // NOTE: repeat adds of the same handler is ignored.
    if (!this._eventRegister[type].includes(listener)) {
      this._eventRegister[type].push(listener);
    }
  };

  removeEventListener = (type, listener) => {
    if (this._eventRegister[type]) {
      const index = this._eventRegister[type].indexOf(listener);
      if (index > -1) {
        this._eventRegister[type].splice(index, 1);
      }
    }
  };

  trigger = (type, data) => {
    (this._eventRegister[type] || []).forEach(handler => {
      handler({ type, data });
    });
  }
}