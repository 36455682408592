import React, { useState, useEffect, useContext } from 'react'
import { navigate } from "@reach/router"
import query from 'query-string'

const FlagContext = React.createContext({});

export const FlagContextProvider = ({ location, mode, children }) => {
  const parsedQuery = query.parse(location.search);
  const embedded = typeof window !== 'undefined' && window !== window.parent;
  const isStandealone = mode === 'app' && !["explore", "my", "order"].includes(location.pathname.split('/')[1]);
  const [state, setState] = useState({
    fullPath: location.pathname + location.search,
    embedded,
    tourOnly: parsedQuery.touronly === null || parsedQuery.touronly?.toLowerCase() === 'true',
    enableJsApi: embedded && (parsedQuery.enablejsapi === null || parsedQuery.enablejsapi?.toLowerCase() === 'true'),
    minimalNav: isStandealone,
    unbranded: parsedQuery.o ? parsedQuery.o.includes('u') : false,
    initialAutoplay: parsedQuery.o ? parsedQuery.o.includes('w') : false,
    // disableAutoplay: parsedQuery.o ? parsedQuery.o.includes('a') : false,
    disableAutoplay: parsedQuery.o ? !parsedQuery.o.includes('a1') : true,
    debugMode: parsedQuery.o ? parsedQuery.o.includes('f') : false,
    touchInput: false,
    mouseInput: false,
    isMac: typeof navigator !== 'undefined' && navigator.platform.startsWith('Mac'),
    parsedQuery
  });

  const needsCleanup = !embedded && parsedQuery.enablejsapi !== undefined;
  useEffect(() => {
    if (needsCleanup) {
      const { enablejsapi, ...newQuery } = parsedQuery;
      const newSearch = query.stringify(newQuery);
      navigate(location.pathname + (newSearch && ('?' + newSearch)), { replace: true });
    }
  }, [location.pathname, parsedQuery, needsCleanup])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('touchstart', function touch() {
        setState(prevState => ({ ...prevState, touchInput: true }));
        window.removeEventListener('touchstart', touch, false);
      }, false);
      window.addEventListener('mouseover', function hover() {
        setState(prevState => ({ ...prevState, mouseInput: true }));
        window.removeEventListener('mouseover', hover, false);
      }, false);
    }
  }, []);

  useEffect(() => {
    if (!state.embedded && state.minimalNav && !isStandealone) {
      setState(prevState => ({ ...prevState, minimalNav: false }));
    }
  }, [state.embedded, state.minimalNav, isStandealone]);

  return (
    <FlagContext.Provider value={state}>
      {children}
    </FlagContext.Provider>
  )
}

// a React hook for making the context value directly available to a function component.
// NOTE: when state changes, consumers of this hook will rerender unless the function returns
// with useMemo. See https://github.com/facebook/react/issues/15156#issuecomment-474590693
export const useFlags = () => useContext(FlagContext);
