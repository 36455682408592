import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import { Avatar, ActionLink } from 'app/_shared'

import styles from './Agent.module.scss'

export default ({ className, agent, onClick }) => {
  return (
    <ActionLink 
      className={[
        styles.agent, 
        !agent ? styles.skeleton : '', 
        onClick && agent ? styles.link : '', 
        className || ''
      ].join(' ')}
      onClick={onClick}
    >
      <Avatar user={agent} empty={!agent} className={`${styles.avatar}`} />
      <div className={styles.name}>{agent && agent.name} {agent && onClick && <FontAwesomeIcon icon={faExternalLinkAlt} />}</div>
      <div className={styles.company}>{agent && agent.company}</div>
    </ActionLink>
  )
}