import React from 'react'
import { ActionLink, Emoji } from 'app/_shared'
import { useUserModal, useActiveUser } from 'app/user'
import styles from './AccountMessage.module.scss'

export default ({ className }) => {
  const { openSignIn, openRegister, openVerify } = useUserModal();
  const activeUser = useActiveUser();

  return (
    <div className={`${styles.account} ${className || ''}`}>
      { !activeUser ? (
        <>
          <p>
            <Emoji symbol="💡" /> Is this your listing?
          </p>
          <p> 
            <ActionLink onClick={openSignIn}>
              Sign in
            </ActionLink> or <ActionLink onClick={openRegister}>
              Register
            </ActionLink> to manage all your listings.
          </p>
        </>
      ) : !activeUser.isVerified ? (
        <>
          <p>
            <Emoji symbol="💡" /> Is this your listing?
          </p>
          <p> 
            <ActionLink onClick={openVerify}>
              Verify your account
            </ActionLink> to claim your past listings.
          </p>
        </>
      ) : (
        <>
          <Emoji symbol="💡" /> Your account does not own this listing.
        </>
      )}
    </div>
  )
}
