import React from 'react'
import * as Yup from 'yup'

import { ActionLink, FieldGroup, FieldSet, wrapAdminElement } from 'app/_shared'

import InnerForm from '../_shared/InnerForm'
import * as service from '../../service'
import styles from './AdjustmentCreate.module.scss'

export default ({ className, orderId }) => wrapAdminElement(
  <InnerForm
    initialValues={{ orderId, description: '', amount: '' }}
    validationSchema={Yup.object().shape({
      description: Yup.string().required('Required'),
      amount: Yup.number().typeError('Invalid').required('Required')
    })}
    submitEndpoint={service.createAdjustment}
    onSubmit={(_, { resetForm }) => resetForm()}
  >
    {({ isSubmitting, submitForm, submitOnEnter }) => (
      <div className={`${styles.line} ${styles.input} ${className || ''}`}>
        <FieldGroup collapse>
          <FieldSet 
            disabled={isSubmitting}
            name="description" 
            label="New adjustment" 
            admin
            required
            onKeyPress={submitOnEnter}
          />
          <FieldSet 
            disabled={isSubmitting} 
            name="amount" 
            type="number" 
            label="Amount" 
            admin
            required
            style={{ width: '10em'}} 
            onKeyPress={submitOnEnter}
          />
        </FieldGroup>
        <ActionLink 
          disabled={isSubmitting} 
          className={styles.button} 
          appearance="admin button"
          onClick={submitForm}
        >
          Create
        </ActionLink>
      </div>
    )}
  </InnerForm>
)