import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'

import { ActionLink, PhotoGrid, MorphBox } from 'app/_shared'
import safeLocalStorage from 'app/_shared/safeLocalStorage'
import { useListing } from 'app/listing'
import { updatePhotos } from '../../service'

import Photo from './Photo'

import styles from './index.module.scss'

const DragHandle = SortableHandle(({ render }) => render());

const SortablePhoto = SortableElement(({ photo, onToggle }) => (
  <Photo photo={photo} onToggle={onToggle} DragHandle={DragHandle} style={photo.grid} />
));

const SortablePhotoList = SortableContainer(({ photos, render, className }) => (
  <PhotoGrid photos={photos} className={className} maxRowHeight={200} render={render} />
));

const store = (tourName, photos) => safeLocalStorage.setItem(`photoEditState_${tourName}`, JSON.stringify(photos.map(t => ({ code: t.code, hidden: t.hidden }))));
const removeStored = (tourName, photos) => {
  safeLocalStorage.removeItem(`photoEditState_${tourName}`);
  return photos.map(t => ({ ...t }));
}
const getStored = (tourName, photos) => {
  const stored = JSON.parse(safeLocalStorage.getItem(`photoEditState_${tourName}`));
  if (Array.isArray(stored) && stored.length === photos.length && photos.every(t => stored.find(s => s.code === t.code))) {
    return stored.map(t => ({...photos.find(s => s.code === t.code), hidden: t.hidden }));
  }
  else {
    return removeStored(tourName, photos);
  }
}

export default ({ updateListingPhotos }) => {
  const { listing: { tourName, photos }} = useListing();
  const [photoState, setPhotoState] = useState();
  const [saving, setSaving] = useState();

  useEffect(() => setPhotoState(getStored(tourName, photos)), [tourName, photos]);

  const hasChanges = useMemo(() => (Array.isArray(photoState) &&
    (photoState.length !== photos.length || photoState.some((t, i) => photos[i].code !== t.code || photos[i].hidden !== t.hidden))
  ), [photoState, photos]);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setPhotoState(state => {
      const newState = arrayMove(state, oldIndex, newIndex); // NOTE: arrayMove creates a new array
      store(tourName, newState);
      // write new state to storage
      return newState;
    });
  }, [tourName]);

  const updateVisibility = useCallback((index, visible) => {
    setPhotoState(state => {
      const newState = [...state];
      newState[index].hidden = !visible;
      store(tourName, newState);
      return newState;
    });
  }, [tourName]);

  const discardChanges = useCallback(() => setPhotoState(removeStored(tourName, photos)), [photos, tourName]);

  const saveChanges = useCallback(hasChanges && !saving ? async () => {
    setSaving(true);
    let newPhotos = await updatePhotos(tourName, getStored(tourName, photos));
    updateListingPhotos(tourName, newPhotos);
    setSaving(false);
  } : undefined, [photos, tourName, hasChanges]);

  return (
    <div className={styles.admin}>
      { photoState && 
        <SortablePhotoList 
          photos={photoState} 
          className={styles.photos} 
          axis="xy" 
          useDragHandle={true} 
          onSortEnd={onSortEnd} 
          render={layout => layout.map((t, i) => (
            <SortablePhoto key={t.code} index={i} photo={t} onToggle={visible => updateVisibility(i, visible)} />
          ))}
        />
      }
      <MorphBox className={styles.save}>
        { (hasChanges || saving) &&
          <div className={styles.inner}>
            <ActionLink appearance="plain button" onClick={discardChanges} disabled={saving}>
              Discard changes
            </ActionLink>
            <ActionLink appearance="button" onClick={saveChanges} disabled={saving}>
              Save
            </ActionLink>
          </div>
        }
      </MorphBox>
    </div>
  )
}
