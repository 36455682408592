import React from 'react'

import { ActionLink } from 'app/_shared'
import clock from './UnavailableMessageClock.jpg'

import styles from './UnavailableMessage.module.scss'

export default ({ address }) => {
  let to = "mailto:help@real.vision"
    + `?subject=${encodeURIComponent(`Schedule for ${address}`)}`
    + `&body=${encodeURIComponent(`Please help me schedule an appointment for ${address}.`)}`;
  return (
    <div className={styles.unavailable}>
      <img src={clock} alt="" />
      <h2>Oops, our bad!</h2>
      <p>
        Real-time scheduling is not available{ address && (
          <>
            {' for '} <strong>{address}</strong>
          </>
        )}.
      </p>
      <p>To order for a different location, please go back and update the address.</p>
      <p>Otherwise, please contact us at <ActionLink appearance="link" to={to}>help@real.vision</ActionLink> and we'll try our best to accommodate.</p>
      <p>Thank you!</p>
    </div>
  )
}