export default async (url, options, maxAttempts = 1) => {
  // NOTE: this option enables the Authorization cross-domain cookie value
  // to be included in the request
  options.credentials = 'include';

  // NOTE: In case the endpoint times out (504 response) or has a transient error,
  // we give an option here to retry up to maxAttempts without interruption to the user.
  // Back end logic should be idempotent as much as possible to accommodate retries
  // with minimal side effects.
  let response = null;
  let attempts = 0;
  do {
    attempts++;
    response = null;
    try {
      response = await fetchAfterInitial(url, options);
    }
    catch (error) {}
  } while (attempts < maxAttempts && (!response || !response.ok))

  // Dispatch an event to trigger UserContext to update its activeUser state.
  // NOTE: since authFetch exists outside the scope of react components and has no direct
  // access to the UserContext hooks, this is the most ergonomic way to trigger an update.
  window.dispatchEvent(new CustomEvent('authUpdate'));
  return response;
}

// NOTE: fetches are queued up until one initial call is completed successfully.
// Otherwise, if multiple calls are sent out when the application initially has no valid
// session token (in the Authorization cookie) then multiple sessions will be created
// in the back end. After one successful call, the existance of a valid session is guaranteed,
// and so subsequent parallel calls are allowed.
let initialFetchSuccessful, initialFetch;
const queue = [];
const fetchAfterInitial = (url, options) => {
  // NOTE: Every path below returns a promise which resolves to fetching the url and options passed in.
  if (initialFetchSuccessful) {
    return fetch(url, options);
  }
  else if (initialFetch) {
    // If an initialFetch is in progress, don't call fetch yet; return a promise whose resolve function
    // can be triggered later with a fetch to this url and options.
    return new Promise(resolve => queue.push({url, options, resolve}));
  }
  else {
    // If no initialFetch is in progress, then this request will become the initialFetch.
    initialFetch = fetch(url, options);

    // Handler for a successful initialFetch
    const onSuccess = () => {
      initialFetchSuccessful = true;
      queue.forEach(t => t.resolve(fetch(t.url, t.options)));
    }

    // Handler for a failed initialFetch:
    const onError = () => {
      // set the stage for the next call to fetchAfterInitial to become the initialFetch
      initialFetch = undefined;
      const next = queue.shift();
      // If there's anything in the queue, call fetchAfterInitial for the next item in queue.
      next?.resolve(fetchAfterInitial(next.url, next.options))
    }

    // Run error handler if either the fetch throws, or the response is not an OK status code.
    initialFetch.then(response => response.ok ? onSuccess() : onError(), onError);
    return initialFetch;
  }
}
