import React, { useState, useCallback, useEffect, useContext } from 'react'

import { Modal, TabBox } from 'app/_shared'
import { useRoute } from 'app/layout'
import { useListing } from '../ListingContext'
import { useTour } from '../TourSection/TourContext'

import Tabs from './Tabs'
import TabSelector from './TabSelector'
import styles from './index.module.scss'
import Preview from './Preview'

const ShareContext = React.createContext();

export default ({ children }) => {
  const { modal, layoutNav: { go }} = useRoute();
  const setActiveTab = useCallback((tab) => go({ modal: tab ? `share_${tab}` : '' }), [go]);
  const close = useCallback(() => setActiveTab(), [setActiveTab]);

  const activeTab = modal?.startsWith('share_') && modal.split('_')[1];
  const [activeSubTab, setActiveSubTab] = useState(Tabs.reduce((all, current) => ({ ...all, [current.data.id]: current.data.subTabs?.[0] }), {}));
  
  const { listing } = useListing();
  const tourUrl = listing && `${window.location.origin}/${listing.tourName}`;

  const { tourConfig } = useTour();
  const [startingScene, setStartingScene] = useState();
  useEffect(() => {
    if (tourConfig) {
      const scene = tourConfig.scenesByCode[tourConfig.starting.sceneCode] ?? {
        code: tourConfig.starting.sceneCode
      };
      setStartingScene({
        ...scene,
        yaw: (scene.yaw ?? 0) - (tourConfig.starting.pan ?? 0)
      });
      return () => setStartingScene(undefined);
    }
  }, [tourConfig]);

  return (
    <ShareContext.Provider value={setActiveTab}>
      { children }
      <Modal open={!!activeTab && listing && !listing.offline} close={close} className={styles.modal}>
        <TabSelector setActiveTab={setActiveTab} activeTab={activeTab} className={styles.header} />
        <div className={styles.body}>
          <Preview 
            className={styles.preview} 
            activeTab={activeTab} 
            activeSubTab={activeSubTab[activeTab]} 
            startingScene={startingScene}
          />
          <TabBox className={styles.tabs} activeTab={activeTab} center>
            { Tabs.map(T => (
              <T 
                key={T.data.id}
                tabkey={T.data.id}
                activeSubTab={activeSubTab[T.data.id]}
                setActiveSubTab={subTab => setActiveSubTab(current => ({ ...current, [T.data.id]: subTab }))}
                tourUrl={tourUrl}
                startingScene={startingScene}
                divProps={{
                  className: `${styles.tab} ${!T.Preview ? styles.hidePreview : ''}`,
                  style: { '--brand-color': T.data.color, '--brand-background': T.data.background }
                }}
              />
            ))}
          </TabBox>
        </div>
      </Modal>
    </ShareContext.Provider>
  )
}

export const useShare = () => useContext(ShareContext);