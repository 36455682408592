import React from 'react'

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="160" height="35" viewBox="0 0 160 35">
    <g fill="none" transform="translate(0 .156)">
      <g transform="translate(62.09)">
        <path fill="#E2231A" d="M33.933994,12.7890388 C33.926591,12.7618149 33.9201433,12.7336358 33.9127403,12.7064119 C28.077994,13.3356657 24.8087403,13.9902328 23.8592478,15.4304716 L18.6489791,23.6848 C18.1582328,24.4554269 17.7372179,24.8580537 17.3520239,24.8580537 C16.9847403,24.8580537 16.5467701,24.4730985 16.0729791,23.6848 L9.38545672,12.7064119 L12.0646209,12.7064119 L17.4048,21.5835463 L22.7970388,12.7064119 L33.9127403,12.7064119 C31.8769194,5.43381493 25.1948896,0.0824119403 17.2815761,0.0824119403 C7.75751642,0.0824119403 0.00778507463,7.83094925 0.00778507463,17.355009 C0.00778507463,26.878591 7.75751642,34.6268896 17.2815761,34.6268896 C26.8049194,34.6268896 34.5532179,26.878591 34.5532179,17.355009 C34.5532179,15.8032478 34.3201433,14.2230687 33.933994,12.7890388"/>
      </g>
      <path fill="currentColor" d="M2.08288955,19.2367522 L2.08288955,14.7899463 L8.91130746,14.7899463 C10.8021731,14.7899463 11.7483224,15.5431403 11.7483224,17.0316179 C11.7483224,17.7666627 11.4500537,18.3448119 10.853994,18.7295284 C10.3298149,19.0796179 9.68169552,19.2367522 8.91130746,19.2367522 L2.08288955,19.2367522 Z M12.9920239,19.6403343 C13.6224716,18.9224836 13.9367403,18.0467821 13.9367403,17.0490507 C13.9367403,15.5775284 13.3941731,14.4572896 12.3085612,13.7050507 C11.3633672,13.0385433 10.1191881,12.7063642 8.57936716,12.7063642 L2.3880597e-05,12.7063642 L2.3880597e-05,24.6655284 L2.08288955,24.6655284 L2.08288955,21.3040955 L7.8089791,21.3040955 L11.1525015,24.6655284 L14.2342925,24.6655284 L10.6099343,21.058603 C11.6079045,20.7959164 12.4126806,20.3235582 12.9920239,19.6403343 L12.9920239,19.6403343 Z"/>
      <polygon fill="currentColor" points="17.761 24.666 28.092 24.666 28.092 22.582 19.845 22.582 19.845 19.64 27.549 19.64 27.549 17.557 19.845 17.557 19.845 14.79 28.092 14.79 28.092 12.706 17.761 12.706"/>
      <path fill="currentColor" d="M37.2241433,19.2374687 L39.3232478,15.7886328 L41.4063522,19.2374687 L37.2241433,19.2374687 Z M40.3221731,13.0566925 C39.9902328,12.7949612 39.6738149,12.6719761 39.3760239,12.6719761 C39.0963821,12.6719761 38.7809194,12.7949612 38.4308299,13.0750806 C38.1328,13.3024239 37.9228896,13.5646328 37.7485612,13.8447522 L31.2520836,24.6473791 L33.9312478,24.6473791 L35.9663522,21.3040955 L42.6500537,21.3040955 L44.6636657,24.6473791 L47.3433075,24.6657672 L41.0044418,13.8447522 C40.8289194,13.5469612 40.6013373,13.2840358 40.3221731,13.0566925 L40.3221731,13.0566925 Z"/>
      <polygon fill="currentColor" points="52.155 12.706 50.072 12.706 50.072 24.666 60.385 24.666 60.385 22.582 52.155 22.582"/>
      <polygon fill="currentColor" points="99.81 24.665 101.893 24.665 101.893 12.706 99.81 12.706"/>
      <path fill="currentColor" d="M117.990328,18.2218985 C117.220418,17.5570627 116.255881,17.2239284 115.101254,17.2239284 L109.464239,17.2239284 C109.060179,17.2239284 108.728716,17.1195701 108.448836,16.9087045 C108.167284,16.6983164 108.026866,16.4005254 108.026866,16.015809 C108.026866,15.6485254 108.167284,15.3509731 108.448836,15.123391 C108.728716,14.8950925 109.060179,14.7907343 109.464239,14.7907343 L118.480597,14.7907343 L118.480597,12.7064358 L109.464239,12.7064358 C108.42997,12.7064358 107.554507,12.9870328 106.854328,13.5644657 C106.101851,14.1774806 105.732657,15.0001672 105.732657,15.9981373 C105.732657,17.0137791 106.101851,17.8371821 106.854328,18.449003 C107.554507,19.027391 108.42997,19.3079881 109.464239,19.3079881 L115.101254,19.3079881 C115.678687,19.3079881 116.169194,19.4300179 116.572299,19.6745552 C117.06209,19.9725851 117.307104,20.3759284 117.307104,20.9187343 C117.307104,21.4608239 117.079045,21.8816 116.588299,22.1965851 C116.169194,22.4595104 115.678687,22.5817791 115.101254,22.5817791 L105.978388,22.5817791 L105.978388,24.6651224 L115.101254,24.6651224 C116.255881,24.6651224 117.220418,24.3498985 117.990328,23.7022567 C118.795104,23.0199881 119.198687,22.1084657 119.198687,20.9705552 C119.198687,19.8328836 118.795104,18.9223164 117.990328,18.2218985"/>
      <polygon fill="currentColor" points="123.002 24.665 125.086 24.665 125.086 12.706 123.002 12.706"/>
      <path fill="currentColor" d="M141.251773 21.5136955C140.516251 22.2315463 139.570579 22.5818746 138.414997 22.5818746L135.071236 22.5818746C133.933564 22.5818746 132.988848 22.2315463 132.235415 21.5136955 131.499893 20.7960836 131.132609 19.867606 131.132609 18.7294567 131.132609 17.5738746 131.499893 16.6291582 132.253325 15.893397 132.988848 15.158591 133.933564 14.7901134 135.071236 14.7901134L138.414997 14.7901134C139.570579 14.7901134 140.516251 15.158591 141.270639 15.893397 142.004251 16.6291582 142.372728 17.5738746 142.372728 18.7294567 142.372728 19.867606 142.004251 20.7960836 141.251773 21.5136955M138.414997 12.7062925L135.071236 12.7062925C133.302639 12.7062925 131.868131 13.2493373 130.728549 14.3175164 129.609504 15.3854567 129.047116 16.8034866 129.047116 18.5544119 129.047116 20.3234866 129.62646 21.7763821 130.763176 22.9312478 131.902042 24.0875463 133.337266 24.6656955 135.071236 24.6656955L138.414997 24.6656955C140.164967 24.6656955 141.602101 24.0875463 142.740012 22.9312478 143.878639 21.7763821 144.438161 20.3234866 144.438161 18.5544119 144.438161 16.8034866 143.878639 15.3854567 142.757922 14.3175164 141.636967 13.2493373 140.183833 12.7062925 138.414997 12.7062925M157.817146 12.7063881L157.817146 21.0058507 150.147654 13.3201194C149.743594 12.9167761 149.341445 12.7063881 148.956251 12.7063881 148.67637 12.7063881 148.465504 12.8114627 148.309087 13.0225672 148.151713 13.214806 148.081266 13.4595821 148.081266 13.7401791L148.081266 24.6655522 150.147654 24.6655522 150.147654 16.3658507 157.817146 24.0697313C158.289027 24.5420896 158.726042 24.7878209 159.112191 24.7878209 159.636848 24.7878209 159.899534 24.4019104 159.899534 23.6322388L159.899534 12.7063881 157.817146 12.7063881z"/>
    </g>
  </svg>
)

export default Logo
