import React, { useRef } from 'react'

import { useFlags } from 'app/_shared'

import { useListing, Section } from '../ListingContext'
import Hero from './Hero'
import Intro from './Intro'
import Photos from './Photos'
import Media from './Media'
import Agents from './Agents'
import LinkBar from './LinkBar'
import MapContainer from './MapContainer'
import styles from './index.module.scss'

export default ({ wideLayout, className }) => {
  const { listing, activeSection, showSelector, enabledSections } = useListing();
  const { unbranded } = useFlags();
  const ref = useRef();

  return (
    <div 
      className={[
        styles.container,
        wideLayout ? styles.wide : '',
        listing ? styles.listing : '',
        (activeSection !== Section.INFO && showSelector) ? styles.hidden : '',
        unbranded ? styles.unbranded : '',
        className || ''
      ].join(' ')} 
    >
      { listing && showSelector && (
        <div className={styles.info}>
          <div ref={ref} />
          <Hero className={styles.hero} />
          <Intro className={styles.intro} />
          { enabledSections.includes(Section.PHOTOS) && listing.photos.some(t => !t.hidden) && 
            <Photos className={styles.photos} />
          }
          { (enabledSections.includes(Section.TOUR) || enabledSections.includes(Section.FLOORPLAN)) &&
            <Media className={styles.media} />
          }
          { !unbranded && 
            <Agents className={styles.agents} />
          }
          <LinkBar className={styles.footer} topRef={ref} />
        </div>
      )}
      { !unbranded &&
        <MapContainer wideLayout={wideLayout} className={styles.map} />
      }
    </div>
  )
}