import React from 'react'
import DOMPurify from 'dompurify'

import { ActionLink, useFlags, useUnitPreference, UnitPreferenceToggle, MorphBox, IntersectionSensor } from 'app/_shared'

import { useListing, Section, ListingLink } from '../ListingContext'
import AgentModal from '../_shared/AgentModal'
import ListingVideo from '../_shared/ListingVideo'
import styles from './Intro.module.scss'

const addSeparators = (input, final) => {
  let output = [];
  
  input.forEach((t, i) => {
    output.push(t);

    if (i < input.length - 1 && input.length > 2) {
      output.push(",");
    }

    if (i === input.length - 2) {
      output.push(final || "and");
    }
  });

  return output;
}

const pluralize = (word, suffix, ...numbers) => {
  numbers = numbers.filter(t => t > 0);
  return `${numbers.join(' + ')} ${word}${numbers.reduce((a,b) => a + b, 0) === 1 ? '' : suffix}`
}

const dimensionText = (mm, imperial) => {
  if (imperial) {
    const feet = Math.floor(Math.round(mm / 25.4) / 12);
    const inches = Math.round(mm / 25.4) % 12;
    return `${feet > 0 ? feet + '\'' : ''}${inches > 0 ? inches + '"' : ''}`;
  }
  else {
    return `${Math.round(mm / 100) / 10}m`;
  }
}

export default ({ className }) => {
  const { listing: { address, stats, agents, floorplan, description, photos }, enabledSections, activeSection } = useListing();
  const { unbranded } = useFlags();
  const { unitPreference } = useUnitPreference();
  const video = photos && photos.find(t => !t.hidden && t.externalLink);
  let tokens = [];

  const sanitizedDescription = description && DOMPurify.sanitize(description, {FORBID_TAGS: ['iframe']});

  if (agents.length > 0 && !unbranded) {
    tokens.push(...addSeparators(agents.map((t, i) => (
      <AgentModal key={i} agent={t} render={open => (
        <ActionLink className={styles.agent} onClick={open}>
          {t.name}
        </ActionLink>
      )} />
    ))));
    tokens.push(agents.length === 1 ? "presents" : "present");
  }
  else {
    tokens.push("Presenting");
  }

  if (address.unit) {
    tokens.push(<i className={styles.address}>Unit {address.unit}</i>);
    tokens.push("at");
  }

  tokens.push(<i className={styles.address}>{address.street}</i>);

  const rooms = [];

  if (stats.bedroomsPrimary) {
    rooms.push(<i>{pluralize("bedroom", "s", stats.bedroomsPrimary, stats.bedroomsSecondary)}</i>);
  }

  if (stats.bathroomsPrimary) {
    rooms.push(<i>{pluralize("bathroom", "s", stats.bathroomsPrimary, stats.bathroomsSecondary)}</i>);
  }

  if (rooms.length > 0) {
    tokens.push(",", "featuring", ...addSeparators(rooms));
  }

  if (floorplan && floorplan.area && floorplan.area.length > 0) {
    tokens.push(",",  "totaling", ...addSeparators(floorplan.area.map(t => (
      <i>
        {unitPreference === 'metric' ? Math.round(t.sqFt * 0.92903) / 10 : Math.round(t.sqFt)} {unitPreference === 'metric' ? ' m²' : ' square feet'}
        {t.label && ` ${t.label.toLowerCase()}`}
      </i>
    )), "plus"));
  }

  tokens.push(".");

  const roomFloors = floorplan && floorplan.floors.filter(t => t.rooms && t.rooms.length > 0);

  return (
    <div className={`${styles.intro} ${className || ''}`}>
      <section>
        <MorphBox morphSameId>
          <div className={styles.stats}>
            {tokens.map((t, i) => (
              <React.Fragment key={i}>
                {(i === 0 || t === ',' || t === '.') ? "" : " "}
                {t}
              </React.Fragment>
            ))}
          </div>
          { stats.price &&
            <div className={styles.price}>
              Offered at <i>${stats.price.toLocaleString()}</i>.
            </div>
          }
        </MorphBox>
        <hr />
        { enabledSections.includes(Section.TOUR) &&
          <ListingLink className={styles.link} appearance="arrow" to={{ section: Section.TOUR }}>Walk through and discover</ListingLink>
        }
      </section>

      { sanitizedDescription &&
        <section dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
      }

      { video &&
        <section>
            <IntersectionSensor
              render={({ ref, isIntersecting }) => (
                <ListingVideo 
                  className={styles.video}
                  externalLink={video.externalLink}
                  url={video.url} 
                  active={isIntersecting && activeSection === Section.INFO}
                  ref={ref}
                />
              )}
            />
          
        </section>
      }
      
      { roomFloors && roomFloors.length > 0 &&
        <section>
          <h3>Room dimensions <UnitPreferenceToggle size={20} className={styles.toggle} /> </h3>
          <div className={styles.sub}>
            Dimensions are approximate and should<br/>be considered illustrative only.
          </div>
          <div className={styles.floors}>
            { roomFloors.map((t, i) => (
              <div key={i} className={styles.floor}>
                <h4>{t.name}</h4>
                {t.rooms.map((r, j) => (
                  <div key={j} className={styles.room}>
                    <span className={styles.name}>{r.name.toLowerCase()}</span>
                    <span className={styles.dimensions}>{dimensionText(r.width, unitPreference === 'imperial')} &times; {dimensionText(r.length, unitPreference === 'imperial')}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          { enabledSections.includes(Section.FLOORPLAN) &&
            <ListingLink className={styles.link} appearance="arrow" to={{ section: Section.FLOORPLAN }}>Explore the floor plan</ListingLink>
          }
        </section>
      }
    </div>
  )
}