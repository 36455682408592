import React from 'react'

import { ActionLink, Avatar } from 'app/_shared'
import { useUserModal, useActiveUser } from 'app/user'

import styles from './ActionLinks.module.scss'

export default ({ className, mode }) => {
  const activeUser = useActiveUser();
  const { openSignIn } = useUserModal();
  return (
    <div className={`${styles.action} ${className} ${styles[mode]}`}>
      { mode === "page" && !!activeUser &&
        <ActionLink appearance="arrow" to="/my">Sign in</ActionLink>
      }
      { !activeUser &&
        <ActionLink appearance="arrow" onClick={openSignIn}>Sign in</ActionLink>
      }
      { (mode === "page" || !activeUser) &&
        <ActionLink className={styles.order} appearance="flashy button" to="/order">Order now</ActionLink>
      }
      { activeUser && mode === "app" &&
        <ActionLink className={styles.user} onClick={openSignIn}>
          <Avatar className={styles.avatar} user={activeUser} />
          <span className={styles.name}>
            {`${activeUser.firstName} ${activeUser.lastName}`}
          </span>
          <span className={styles.email}>
            {activeUser.email}
          </span>
        </ActionLink>
      }
    </div>
  )
}
