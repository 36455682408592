import React, { useState, useEffect, useMemo } from 'react'

import { ActionLink } from 'app/_shared'
import { useActiveUser } from 'app/user'

import List from '../List'
import RegisterMessage from './RegisterMessage'
import WelcomeMessage from './WelcomeMessage'
import Filter from './Filter'

import styles from './index.module.scss'
import { useRoute } from '../RouteContext'
import { useListingData } from '../ListingDataContext'

export default ({ className }) => {
  const { searchTerms: { filter }, layoutNav: { go: layoutGo }} = useRoute();
  const { my, cache } = useListingData();

  const activeUser = useActiveUser();
  const [showFilter, setShowFilter] = useState();

  const totalCount = my.pagination && my.pagination.totalCount;
  useEffect(() => {
    if (!showFilter && (totalCount > 15 || !!filter)) {
      setShowFilter(true);
    }
  }, [showFilter, totalCount, filter]);

  const allListings = useMemo(() => my.tourNames && my.tourNames.map(t => cache[t]), [my.tourNames, cache]);

  // NOTE: when allListings is falsy, that means it's loading, and we display a skeleton list.
  // When allListings is non-empty, we display the List.
  // When allListings is empty with a filter, we display the List (containing the toolbar) with a "no results" message.
  // When allListings is empty without a filter, we replace the List altogether with a placeholder message.
  return (!allListings || allListings.length > 0 || filter) ? (
    <List
      className={className}
      allListings={allListings}
      pagination={my.pagination}
      filterInput={showFilter && (
        <Filter filter={filter} update={filter => layoutGo({ filter, pageNumber: 1 })} />
      )}
      footer={!filter ? (
        <WelcomeMessage isFooter />
      ) : (
        <div className={styles.footer}>
          <p>
            { totalCount ? "Showing" : "No" } result{ totalCount === 1 ? '' : 's'} for search filter <strong>{filter}</strong>.
          </p>
          <ActionLink appearance="link" onClick={() => layoutGo({ filter: '', pageNumber: 1 })}>Clear search filter.</ActionLink>
        </div>
      )}
    />
  ) : (
    <div className={className}>
      {!activeUser ? (
        <RegisterMessage className={styles.placeholder} />
      ) : (
        <WelcomeMessage className={styles.placeholder} />
      )}
    </div>
  )
}
