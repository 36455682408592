import React from 'react'
import { Badge } from 'app/_shared'
import { CSSTransition } from 'react-transition-group'

import styles from './Throbber.module.scss'

export default ({ active, className }) => (
  <CSSTransition 
    in={active}
    timeout={250} 
    classNames={{ 
      enter: styles.inactive, 
      enterActive: styles.active, 
      exitActive: styles.inactive 
    }}
    unmountOnExit
    mountOnEnter
  >
    <div className={`${styles.container} ${className || ''}`}>
      <Badge className={styles.badge} throb />
    </div>
  </CSSTransition>
)