import React from 'react'

import { Impetus } from 'app/_shared'
import { getClient } from 'app/map'

import Marker from './Marker'

import styles from './Overlay.module.scss'

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.overlayRef = React.createRef();
    this.state = { overlayLoaded: false };
  }

  async componentDidMount() {
    this.googleMapClient = await getClient();
    this.overlay = new this.googleMapClient.OverlayView();
    this.overlay.remove = () => {};
    this.setState({ overlayLoaded: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.overlayLoaded && this.state.overlayLoaded) {
      const overlayDiv = this.overlayRef.current;
      this.overlay.setMap(this.props.map);

      this.overlay.draw = () => {
        if (this.overlay.getProjection()) {
          const container = this.props.map.getDiv();
          let divPosition;
          if (this.props.coordinates) {
            const position = new this.googleMapClient.LatLng(this.props.coordinates);
            divPosition = this.overlay.getProjection().fromLatLngToContainerPixel(position);
            const containerRect = container.getBoundingClientRect();
            divPosition.x *= container.offsetWidth / containerRect.width;
            divPosition.y *= container.offsetHeight / containerRect.height;
          }
          else {
            divPosition = {
              x: container.offsetWidth / 2,
              y: container.offsetHeight / 2
            }
          }

          const divLeft = Math.round(Math.max(overlayDiv.offsetWidth / 2, Math.min(container.offsetWidth - overlayDiv.offsetWidth / 2, divPosition.x + this.offsetX)));
          const divTop = Math.round(Math.max(overlayDiv.offsetHeight / 2, Math.min(container.offsetHeight - overlayDiv.offsetHeight / 2, divPosition.y + this.offsetY)));
          
          overlayDiv.style.visibility = 'visible';
          overlayDiv.style.left = divLeft + 'px';
          overlayDiv.style.top = divTop + 'px';
          
          const pointerAngle = -Math.atan2(divPosition.x - divLeft, divPosition.y - divTop);
          let pointerLeft = Math.round(Math.max(-overlayDiv.offsetWidth / 2, Math.min(overlayDiv.offsetWidth / 2, divPosition.x - divLeft)));
          let pointerTop = Math.round(Math.max(-overlayDiv.offsetHeight / 2, Math.min(overlayDiv.offsetHeight / 2, divPosition.y - divTop)));

          if (Math.abs(divPosition.y - divTop) / Math.abs(divPosition.x - divLeft) < overlayDiv.offsetHeight / overlayDiv.offsetWidth) {
            pointerTop = pointerLeft / (divPosition.x - divLeft) * (divPosition.y - divTop);
          }
          else {
            pointerLeft = pointerTop / (divPosition.y - divTop) * (divPosition.x - divLeft);
          }

          overlayDiv.style.setProperty('--pointer-left', pointerLeft + 'px');
          overlayDiv.style.setProperty('--pointer-top', pointerTop + 'px');
          overlayDiv.style.setProperty('--pointer-angle', pointerAngle + 'rad');
        }
      }
      
      this.impetus = new Impetus({
        source: overlayDiv,
        boundX: [-overlayDiv.offsetWidth / 2, overlayDiv.offsetWidth / 2],
        boundY: [-overlayDiv.offsetHeight / 2, overlayDiv.offsetHeight / 2],
        bounce: false,
        friction: 0.5,
        initialValues: [0, -overlayDiv.offsetHeight / 2],
        update: (x, y) => {
          this.offsetX = x;
          this.offsetY = y;
          this.overlay.draw();
        }
      });

      if (!this.props.children) {
        this.impetus.pause();
      }
    }

    if (this.overlay && this.props.children !== prevProps.children) {
      const overlayDiv = this.overlayRef.current;
      this.impetus.setBoundX([-overlayDiv.offsetWidth / 2, overlayDiv.offsetWidth / 2]);
      this.impetus.setBoundY([-overlayDiv.offsetHeight / 2, overlayDiv.offsetHeight / 2]);

      this.offsetX = 0;
      this.offsetY = -overlayDiv.offsetHeight / 2;
      this.impetus.setValues(0, -overlayDiv.offsetHeight / 2);

      if (!this.props.children) {
        this.impetus.pause();
      }
      else {
        this.impetus.resume();
      }
    }
  }

  componentWillUnmount() {
    if (this.overlay) {
      this.overlay.setMap(null);
      this.overlay.draw = () => {};
    }
    if (this.impetus) {
      this.impetus.destroy();
    }
  }

  recenter = () => {
    this.props.map.panTo(this.props.coordinates);
  }

  render() {
    return !this.state.overlayLoaded ? null : (
      <div className={styles.overlay} ref={this.overlayRef} style={{visibility: 'hidden'}}>
        <div className={styles.content}>
          { this.props.children }
        </div>
        <Marker className={styles.marker} onClick={this.props.coordinates && this.recenter} />
      </div>
    )
  }
}