import React from 'react'
import moment from 'moment-timezone'

import { formatTravelCost, formatDuration } from '../../service'
import { Avatar, ProductItem, OwnershipInput, WaitlistToggle } from '../_shared/'
import { BookingSteps } from '../BookingContext'
import Section from './Section'
import PhotographerInstructions from './PhotographerInstructions'
import DateBox from './DateBox'
import SummaryConfirm from './SummaryConfirm'
import styles from './Summary.module.scss'

export default ({ order }) => {
  const deliveryDate = moment(order.schedule.deliveryDeadline).local();
  const confirmed = !!order.info.confirmedDate;

  const selectedPhotographer = order.schedule.photographers.find(t => t.id === order.schedule.selectedPhotographerId);
  const assignedPhotographer = order.schedule.photographers.find(t => t.id === order.schedule.assignedPhotographerId);
  const selectedPhotographerAvailable = !selectedPhotographer || selectedPhotographer === assignedPhotographer;
  const { billingContact, specialInstructions, photographerInstructions } = order.contact;

  return (
    <>
      { typeof order.info.ownerEmail === 'string' &&
        <OwnershipInput 
          className={styles.ownership} 
          orderId={order.info.id} 
          ownerEmail={order.info.ownerEmail}
        />
      }
      
      <Section editable={!confirmed} step={BookingSteps.PRODUCT}>
        <div className={styles.productStep}>
          <ProductItem 
            product={order.product.products[0]}
            value={order.product.products[0].quantity}
          />
        </div>
      </Section>
  
      <Section editable={!confirmed} step={BookingSteps.SCHEDULE}>
        <div className={styles.scheduleStep}>
          <div className={styles.appointment}>
            <h4>Appointment</h4>
            <DateBox datetime={order.schedule.selectedTime} />
          </div>
          <div className={styles.photographer}>
            <h4>Photographer</h4>
            <Avatar 
              className={styles.avatar} 
              user={confirmed ? assignedPhotographer : selectedPhotographerAvailable ? selectedPhotographer : undefined}
            />
            { confirmed ? (
              <div className={styles.name}>
                { assignedPhotographer.name }
                { !selectedPhotographer ? (
                  <div className={styles.alt}>
                    (No Preference)
                  </div>
                ) : !selectedPhotographerAvailable ? (
                  <div className={styles.alt}>
                    Best alternative for {selectedPhotographer.name}
                  </div>
                ) : (
                  <div className={styles.alt}>
                    (Selected)
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.name}>
                { !selectedPhotographerAvailable && 
                  <div className={styles.alt}>Best alternative for</div>
                }
                { !selectedPhotographer ? (
                  "No Preference"
                ) : (
                  selectedPhotographer.name
                )}
              </div>
            )}
            { order.schedule.travelCost > 0 &&
              <div className={styles.travel}>{formatTravelCost(order.schedule.travelCost, true)} travel</div>
            }
          </div>
          <div className={styles.info}>
            <p>Your appointment will take about <strong>{formatDuration(assignedPhotographer.appointmentDuration)}</strong>.</p>
            <p>Scheduled delivery: <strong>{deliveryDate.format('dddd MMMM Do, YYYY')}</strong></p>
          </div>
        </div>
        { !confirmed &&
          <WaitlistToggle className={styles.waitlist} />
        }
      </Section>
  
      <Section editable={!confirmed} step={BookingSteps.CONTACT}>
        <div className={styles.contactStep}>
          <div className={styles.contact}>
            <div className={styles.role}>{billingContact.role} (primary contact)</div>
            <div className={styles.name}>{billingContact.firstName} {billingContact.lastName}</div>
            <div className={styles.company}>{billingContact.company}</div>
            <div className={styles.inline}>
              <div className={styles.address}>{billingContact.address}</div>
              { billingContact.unit &&
                <div className={styles.unit}>unit {billingContact.unit}</div>
              }
            </div>
            <div className={styles.email}>{billingContact.email}</div>
            <div className={styles.phone}>{billingContact.phone}</div>
          </div>
          { order.contact.additionalContacts.map((c, index) => (
            <div className={styles.contact} key={index}>
              <div className={styles.role}>{c.role}</div>
              <div className={styles.name}>{c.firstName} {c.lastName}</div>
              <div className={styles.company}>{c.company}</div>
              <div className={styles.email}>{c.email}</div>
              <div className={styles.phone}>{c.phone}</div>
            </div>
          ))}
          <div className={styles.instructions}>
            <h4>Special instructions</h4>
            { specialInstructions ? specialInstructions.split('\n').map((t, i) => (
              <p key={i}>{t}</p>
            )) : (
              <p className={styles.none}>None provided.</p> 
            )} 
          </div>
          { typeof order.info.ownerEmail === 'string' &&
            <PhotographerInstructions orderId={order.info.id} photographerInstructions={photographerInstructions} />
          }
        </div>
      </Section>
  
      <Section step={BookingSteps.CONFIRM}>
        <SummaryConfirm
          orderId={typeof order.info.ownerEmail === 'string' ? order.info.id : undefined}
          confirmData={order.confirm}
          isCancelled={!!order.info.cancelledDate}
          error={order.confirm.errors && order.confirm.errors[0]}
          cancellationPolicyText={order.schedule.cancellationPolicyDesc}
          promoCode={order.info.promoCode}
          promoCodeValid={order.info.promoCodeValid}
        />
      </Section>
    </>
  )
}