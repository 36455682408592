import React from 'react'
import { useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { ActionLink } from 'app/_shared'

import { Submit, BookingThrobber } from '../_shared'
import styles from './ButtonBar.module.scss'

export default ({ lastStep, nextStep, children }) => {
  const { isSubmitting } = useFormikContext();
  return (
    <div className={styles.buttons}>
      { lastStep &&
        <Submit targetStep={lastStep} render={form => (
          <ActionLink 
            type="button" 
            appearance="plain button" 
            disabled={form.isSubmitting} 
            tabIndex="-1" 
            className={styles.back}
            onClick={form.submitForm}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            Back
          </ActionLink>
        )} />
      }
      { nextStep &&
        <Submit targetStep={nextStep} render={form => (
          <ActionLink 
            type="submit" 
            appearance={`${form.isValid ? '' : 'plain '}button`} 
            disabled={form.isSubmitting} 
            className={styles.next} 
            data-active={form.isValid ? "" : undefined}
            onClick={e => {
              e.preventDefault();
              form.submitForm();
            }}
          >
            Next 
            <FontAwesomeIcon icon={faArrowRight} />
          </ActionLink>
        )} />
      }
      {children}
      <BookingThrobber in={isSubmitting} />
    </div>
  )
}