import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'

import { MockDevice } from 'app/_shared'
import { Section, ListingLink } from 'app/listing'

import EmailLink from './EmailLink'
import styles from './RevisionMenu.module.scss'

export default ({ className, listing }) => {
  return (
    <div className={`${styles.menu} ${className || ''}`}>
      <div className={styles.tour}>
        <ListingLink to={{ section: Section.TOUR }}>
          <h5>3D Tour</h5>
          <figure>
            <MockDevice className={styles.device} landscape>
              <div className={styles.image} style={{backgroundImage: `url(${listing.photos[0].url}?size=512x*)`}} />
              <FontAwesomeIcon className={styles.icon} icon={faExpandArrowsAlt} />
            </MockDevice>
          </figure>
        </ListingLink>
        <dl>
          <li>
            <dt>Change starting view</dt>
            <dd>free</dd>
          </li>
          <li>
            <dt>Hide parts of the property</dt>
            <dd>free</dd>
          </li>
        </dl>
      </div>
      <div className={styles.floorplan}>
        <ListingLink to={{ section: Section.FLOORPLAN }}>
          <h5>Floor Plan</h5>
          <figure>
            <img src={`${listing.floorplan.brandedPagesUrl?.[0].imperial}&width=512`} alt="floorplan" />
          </figure>
        </ListingLink>
        <dl>
          <li>
            <dt>Change room names</dt>
            <dd>free</dd>
          </li>
          <li>
            <dt>Adjust room dimensions</dt>
            <dd>free</dd>
          </li>
          <li>
            <dt>Update square footages</dt>
            <dd>free</dd>
          </li>
        </dl>
      </div>
      <div className={styles.photos}>
        <ListingLink to={{ section: Section.PHOTOS }}>
          <h5>Photos</h5>
          <figure>
            { listing.photos.filter(t => t.caption).slice(0, 15).map((t, i) => (
              <div key={i}>
                <img alt="" src={`${t.url}?size=512x*`} />
              </div>
            ))}
          </figure>
        </ListingLink>
        <dl>
          <li>
            <dt>Add new angles (please send a screenshot from the 3D tour)</dt>
            <dd>free</dd>
          </li>
          <li>
            <dt>Hide and reorder photos</dt>
            <dd>free</dd>
          </li>
          <li>
            <dt>Adjust editing</dt>
            <dd>free</dd>
          </li>
          <li>
            <dt>Virtual staging</dt>
            <dd><EmailLink>contact us for details</EmailLink></dd>
          </li>
        </dl>
      </div>
    </div>
  )
}
