import React, { useEffect, useState } from 'react'

import { IntersectionSensor } from 'app/_shared'
import { useRoute, useListingData } from 'app/layout'
import { useAddressInput } from 'app/booking'
import { Map } from 'app/map'

import { Section, useListing } from '../ListingContext'
import styles from './MapContainer.module.scss'

export default ({ className, wideLayout }) => {
  const { controller, tourName, layoutNav: { go: layoutGo }} = useRoute();
  const { my, explore, cache } = useListingData();
  const { activeSection, showSelector, listing } = useListing();
  const [visible, setVisible] = useState();
  const [loaded, setLoaded] = useState();
  const { locationFromBooking, mapInputMode } = useAddressInput();
  
  const tourNames = ({ my, explore }[controller] || {}).tourNames;
  const allListings = tourNames && tourNames.map(t => cache[t]);

  const active = locationFromBooking // map is used for booking an order
    || (listing && activeSection === Section.INFO) // map is displayed on a delivered loaded listing
    || (listing && !showSelector) // map is displayed on a undelivered loaded listing
    || (wideLayout && activeSection === Section.DASHBOARD) // sidebar map displays in wide layout with dashboard section
    || (controller && !tourName) // map is used to show a list of listings;

  useEffect(() => {
    if (visible && active) {
      const handle = setTimeout(() => setLoaded(true), 500);
      return () => clearTimeout(handle);
    }
  }, [visible, active]);

  const getMarkers = () => {
    if (!locationFromBooking && allListings) {
      return allListings.reduce((all, current) => ({
        ...all,
        [current.tourName]: {
          latLng: {
            lat: current.location.lat,
            lng: current.location.lng
          },
          onClick: () => layoutGo({ tourName: current.tourName })
        }
      }), {})
    }
    else {
      return [];
    }
  };
  
  const getOverlay = () => {
    if (locationFromBooking) {
      return {
        // NOTE: an overlay with a null coordinate will stay centered on the map as the map view changes.
        coordinates: mapInputMode ? null : locationFromBooking
      };
    }
    else if (listing) {
      return {
        tourName: listing.tourName,
        coordinates: listing.location
      }
    }
    else {
      return undefined;
    }
  }
  
  return (
    <IntersectionSensor threshold={0} onUpdate={setVisible} render={({ref}) => (
      <div className={`${styles.map} ${active ? '' : styles.hidden} ${className || ''}`} ref={ref}>
        { loaded &&
          <Map
            className={styles.inner}
            overlay={getOverlay()}
            markers={getMarkers()}
            center={locationFromBooking || (listing && listing.location)}
          />
        }
      </div>
    )} />
  )
}
