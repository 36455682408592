import React, { useState, useCallback } from 'react'
import { Modal, ActionLink } from 'app/_shared'

import AgentCard from './AgentCard'
import styles from './AgentModal.module.scss'

export default ({ render, agent }) => {
  const [modalOpen, setModalOpen] = useState();
  const close = useCallback(() => setModalOpen(false), []);
  return (
    <>
      {render(() => setModalOpen(true))}
      <Modal open={modalOpen} close={close} className={styles.card}>
        <AgentCard agent={agent} />
        <ActionLink className={styles.close} onClick={close}>
          &times;
        </ActionLink>
      </Modal>
    </>
  )
}
