import React from 'react'
import { useMatch } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas, faStar, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { ActionLink } from 'app/_shared'

import styles from './AppLinks.module.scss'

export default ({ className }) => {
  const match = useMatch(':controllerOrTourName/*');
  let rest = '';
  if (match && !['order', 'account'].includes(match.controllerOrTourName)) {
    if (match['*']) {
      rest = `/${match['*']}`;
    }
    if (!["explore", "my"].includes(match.controllerOrTourName)) {
      rest = `/${match.controllerOrTourName}` + rest;
    }
  }
  return (
    <div className={`${styles.app} ${className}`}>
      <ActionLink className={styles.wide} activeClassName={styles.active} to={`/explore${rest}`}>
        <FontAwesomeIcon icon={faGlobeAmericas} />
        <span>Explore</span>
      </ActionLink>
      <ActionLink className={styles.wide} activeClassName={styles.active} to={`/my${rest}`}>
        <FontAwesomeIcon icon={faStar} />
        <span>My listings</span>
      </ActionLink>
      <ActionLink className={styles.mobile} activeClassName={styles.active} to={`/explore`}>
        <FontAwesomeIcon icon={faGlobeAmericas} />
        <span>Explore</span>
      </ActionLink>
      <ActionLink className={styles.mobile} activeClassName={styles.active} to={`/my`}>
        <FontAwesomeIcon icon={faStar} />
        <span>My listings</span>
      </ActionLink>
      <ActionLink activeClassName={styles.active} to="/order">
        <FontAwesomeIcon icon={faPlusCircle} />
        <span>Create listing</span>
      </ActionLink>
    </div>
  )
}