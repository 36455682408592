import React from 'react'
import { Emoji, ActionLink } from 'app/_shared'
import { useRoute } from 'app/layout'
import styles from './Error.module.scss'

export default () => {
  const { tourName } = useRoute();
  return (
    <div className={styles.error}>
      <h1><Emoji symbol="😶" /> Oops!</h1>
      <h2><em>{tourName}</em> could not be loaded.</h2>
      <p>Please double check your URL, or<br/><ActionLink appearance="arrow" to="/explore">explore some nearby listings</ActionLink></p>
    </div>
  )
}