import React, { useState, useRef, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, FieldSet, FieldGroup } from 'app/_shared'
import { getClient } from 'app/map'

import { Submit, Error, BookingThrobber } from './_shared'
import { BookingSteps, useBooking } from './BookingContext'
import { useAddressInput } from './AddressInputContext'
import { Instruction } from './_shared'
import styles from './Address.module.scss'

const mapPlaceToLocation = (place) => {
  var location = {
    country: '',
    postalCode: '',
    state: '',
    city: '',
    address1: '',
    latitude: '',
    longitude: '',
    formattedAddress: ''
  };

  for (var i = 0; i < place.address_components.length; i++) {
    const component = place.address_components[i];

    if (!location.country && component.types.indexOf('country') > -1) {
      location.country = component.long_name;
    }
    else if (!location.postalCode && component.types.indexOf('postal_code') > -1) {
      location.postalCode = component.long_name;
    }
    else if (!location.state && component.types.indexOf('administrative_area_level_1') > -1) {
      location.state = component.long_name;
    }
    else if (!location.city && component.types.indexOf('locality') > -1) {
      location.city = component.long_name;
    }
    else if (component.types.indexOf('street_number') > -1) {
      location.address1 = component.long_name + (location.address1 ? ' ' + location.address1 : '');
    }
    else if (component.types.indexOf('route') > -1) {
      location.address1 = (location.address1 ? location.address1 + ' ' : '') + component.long_name;
    }
  }

  if (place.geometry !== undefined && place.geometry.location !== undefined) {
    location.latitude = place.geometry.location.lat();
    location.longitude = place.geometry.location.lng();
  }

  location.formattedAddress = place.formatted_address;
  return location;
}

const geocode = (input, geocoder) => {
  const request = typeof input === 'string' ? { address: input } : { location: {
    lat: input.lat,
    lng: input.lng
  }};

  // create request from input
  return new Promise(resolve => {
    geocoder.geocode(request, (results, status) => {
      let error, place;
      if (status === 'OK') {
        place = results[0];
        if (place.geometry.location_type !== 'ROOFTOP' || place.partial_match) {
          error = "Unable to identify exact location. Please verify the address and location on the map are correct before submitting.";
        }
      }
      else if (status === 'ZERO_RESULTS') {
        error = "Unable to find location. Please verify the address and location on the map are correct.";
      }
      else {
        error = "Unable to process location. Please contact support if this issue persists. ERROR CODE: " + status;
      }
      resolve({ ...place, error });
    });
  });
}

export default () => {
  const { isValid, isSubmitting, values, setFieldValue } = useFormikContext();
  const { activeStep, suppressError, orderState} = useBooking();
  const serverError = !suppressError && orderState.address.errors[0];
  const client = useRef();
  const inputRef = useRef();

  const { map, setLocationFromBooking } = useAddressInput();
  const [geocoder, setGeocoder] = useState();
  const [inputText, setInputText] = useState();
  const [mapIsDragging, setMapIsDragging] = useState();
  const [lastInput, setLastInput] = useState();
  const [currentPlace, setCurrentPlace] = useState();
  const [queuedSubmit, setQueuedSubmit] = useState();

  // 0. If an address input is supplied in the order but not any details
  // (as would happen if an address is specified in the address URL parameter),
  // start geocoding right away to fill in the blanks.
  useEffect(() => {
    if (orderState.address.userAddress && !orderState.address.location.formattedAddress && geocoder) {
      let abort = false;
      geocode(orderState.address.userAddress, geocoder).then(place => !abort && setCurrentPlace(place));
      return () => abort = true;
    }
  }, [geocoder, orderState.address.userAddress, orderState.address.location.formattedAddress]);

  // 1. Set up autocomplete on the input field
  useEffect(() => {
    let abort = false;
    getClient().then(c => {
      client.current = c;
      if (!abort) {
        setGeocoder(new client.current.Geocoder());
        const autocomplete = new client.current.places.Autocomplete(inputRef.current, { types: ['geocode']});
        autocomplete.addListener('place_changed', () => {
          // NOTE: if between the time an input is blurred and a place_changed event is fired from selecting an autocomplete entry,
          // a geocoding event already succeeded (should happen rarely  in practice since a manual call to geocode is delayed;
          // see note at 4.), then ignore the result of this event. Also ignore if place doesn't have a formatted_address.
          setCurrentPlace(currentPlace => {
            const place = autocomplete.getPlace();
            if (!currentPlace && place.formatted_address) {
              // NOTE: autocomplete results lack the place.geometry.location_type === 'ROOFTOP' indicator for precision.
              // Instead, there's a large list of values for place.type which can be used to indicate precision, but
              // since the list is large (https://developers.google.com/places/supported_types), we forgo it for now.
              return autocomplete.getPlace();
            }
            return currentPlace;
          });
        });
      }
    });
    inputRef.current.focus();
    return () => abort = true;
  }, []);

  // 2. When map is dragged, invalidate the current place, and update the last input in preparation for geocoding.
  useEffect(() => {
    if (map) {
      const onWheel = (e) => {
        e.preventDefault();
        const delta = Math.sign(-e.deltaY);
        const newZoom = map.getZoom() + delta
        map.setZoom(newZoom);
      };
      const container = map.getDiv();

      map.setOptions({
        scrollwheel: false,
        disableDoubleClickZoom: true
      });
      container.addEventListener('wheel', onWheel, { passive: false });

      const startListener = map.addListener('dragstart', () => setMapIsDragging(true));
      const endListener = map.addListener('dragend', () => {
        // NOTE: this handler is only called when map's lat lng or zoom changed as a result of a user interaction with the map,
        // NOT when a parent component instructed the map to move via the lat, lng, zoom and marker properties.
        setMapIsDragging(false)
        const center = map.getCenter();
        const lat = center.lat();
        const lng = center.lng();
        setCurrentPlace(undefined);
        setLastInput({ lat, lng });
      });

      return () => {
        client.current.event.removeListener(startListener);
        client.current.event.removeListener(endListener);
        map.setOptions({
          scrollwheel: true,
          disableDoubleClickZoom: false
        });
        container.removeEventListener('wheel', onWheel, { passive: false });
      }
    }
  }, [map]);

  // 3. When text input has changed, invalidate the current place, and update the last input in preparation for geocoding.
  // NOTE: inputText is used for two purposes: it's undefined when input is not in focus; it saves the value on focus so
  // on blur we know whether the value has changed.
  const onFocus = () => {
    setInputText(values.address.userAddress);
  }
  const onBlur = () => {
    if (values.address.userAddress !== inputText) {
      setCurrentPlace(undefined);
      setLastInput(values.address.userAddress);
    }
    setInputText(undefined);
  };

  // 4. Begin geocoding when geocoder is ready or lastInput changes (and is not empty), and user is not currently focused on the
  // text input or dragging the map.
  const isTyping = inputText !== undefined;
  useEffect(() => {
    if (geocoder && lastInput && !currentPlace && !isTyping && !mapIsDragging) {
      let abort = false;
      // NOTE: if the last input is from text entry, it may or may not be from selecting an autocomplete entry (no way to know here).
      // If it was, then a place_changed event will fire shortly after, so we wait 500ms before calling the geocoder to see if that happens.
      // If a place_changed event successfully sets the currentPlace (or inputText or mapIsDragging becomes true, or the last input changed),
      // then this geocoding is aborted.
      (typeof lastInput === 'string' ? new Promise(resolve => setTimeout(resolve, 500)) : Promise.resolve()).then(() => {
        !abort && geocode(lastInput, geocoder).then(place => !abort && setCurrentPlace(place));
      })
      return () => abort = true;
    }
  }, [lastInput, currentPlace, isTyping, mapIsDragging, geocoder]);

  // 5. currentPlace represents a result from geocoding or autocomplete. When it changes as a result of a successful
  // geocoding or autocomplete, update field values.
  useEffect(() => {
    if (currentPlace && !currentPlace.error) {
      const newLocation = {
        ...mapPlaceToLocation(currentPlace),
        address2: values.address.location.address2
      }
      const lat = currentPlace.geometry.location.lat();
      const lng = currentPlace.geometry.location.lng();
      setFieldValue('address.location', newLocation, true);
      setFieldValue('address.marker', { latitude: lat, longitude: lng }, true);
      setFieldValue('address.userAddress', currentPlace.formatted_address, true);

      setLocationFromBooking({ lat, lng });
    }
  }, [currentPlace, setFieldValue, setLocationFromBooking, values.address.location.address2]);

  // 6. When the submit button is pressed in the middle of an input or geocode, then
  // defer submission until after geocoding of the current input is complete. Cancel the queued submission
  // if user starts offering new input.
  const onSubmit = (e, submit) => {
    e.preventDefault();
    if (isValid && (inputText !== undefined || mapIsDragging || (!currentPlace && lastInput !== undefined))) {
      inputRef.current.blur();
      setQueuedSubmit(true);
    }
    else {
      submit();
    }
  };
  useEffect(() => {
    if (isTyping || mapIsDragging) {
      setQueuedSubmit(false);
    }
  }, [isTyping, mapIsDragging]);

  return (
    <div className={styles.address}>
      <Instruction className={styles.instruction} pointing="down" highlight={!isValid}>
        Enter your listing&apos;s address.
      </Instruction>
      <div className={styles.inline}>
        <FieldGroup collapse>
          <FieldSet
            name="address.userAddress"
            label="Listing address"
            placeholder=""
            ref={inputRef}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <FieldSet name="address.location.address2" label="Unit" style={{ flex: "0 0 5em" }} />
        </FieldGroup>
        <div className={styles.button}>
          <Submit targetStep={BookingSteps.PRODUCT} render={({ submitForm }) => {
            useEffect(() => {
              if (currentPlace && queuedSubmit) {
                if (!currentPlace.error && queuedSubmit && values.address.userAddress === currentPlace.formatted_address) {
                  // NOTE: submitForm needs a delay because setFieldValue in #5 above is asynchronous and so
                  // we need to wait for it to complete.
                  const handle = setTimeout(submitForm, 100);
                  return () => clearTimeout(handle);
                }
                else {
                  setQueuedSubmit(false);
                }
              }
            }, [submitForm]);

            return (
              <ActionLink
                type="submit"
                appearance={`${isValid ? '' : 'plain '}button`}
                disabled={isSubmitting || queuedSubmit || activeStep !== BookingSteps.ADDRESS}
                className={styles.next}
                data-active={isValid ? "" : undefined}
                onClick={(e) => onSubmit(e, submitForm)}
              >
                <span>Next</span>
                <FontAwesomeIcon icon={faArrowRight} />
              </ActionLink>
            )
          }} />
          <BookingThrobber in={isSubmitting || !!queuedSubmit} />
        </div>
      </div>
      <Error className={styles.error}>{ serverError || currentPlace?.error }</Error>
    </div>
  )
}
