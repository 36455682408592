import React, { useEffect, useState, useRef } from 'react';
import { apiKey, getClient } from '../service'
import Marker from './Marker'
import styles from './StaticMap.module.scss'

export default ({ showMarker, lat, lng, zoom, streetView, className }) => {
  const [url, setUrl] = useState();
  const ref = useRef();
  useEffect(() => {

    const { offsetWidth, offsetHeight } = ref.current;
    const width = Math.ceil(offsetWidth / 256) * 256;
    const height = Math.ceil(offsetHeight / 256) * 256;
    // TODO: Cache image on Lambda@Edge to reduce costly calls to Google server.
    const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&scale=2&key=${apiKey}&zoom=${zoom || 16}&size=${width}x${height}`;

    if (streetView) {
      getClient().then(client => {
        const location = new client.LatLng({ lat, lng });  
        new client.StreetViewService().getPanorama({ location, preference: 'best', source: 'outdoor' }, (data, status) => {
          if (status === 'OK') {
            let panoLatLng = data.location.latLng;
            let heading = client.geometry.spherical.computeHeading(panoLatLng, location);
            setUrl(`https://maps.googleapis.com/maps/api/streetview?pano=${data.location.pano}&heading=${heading}&size=600x400&key=${apiKey}`);
          }
          else {
            setUrl(mapUrl);
          }
        });
      });
    }
    else {
      setUrl(mapUrl);
    }
  }, [lat, lng, zoom, streetView])

  return (
    <div 
      ref={ref}
      className={`${styles.map} ${className || ''}`}
      style={url ? { backgroundImage: `url(${url})`} : undefined}
    >
      { showMarker &&
        <Marker className={styles.marker} />
      }
    </div>
  )
}
