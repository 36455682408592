import React, { useEffect, useRef } from 'react'
import { useAddressInput } from 'app/booking'

import { getClient } from '../service'
import Overlay from './Overlay'
import MarkerSvg from './Marker.svg'
import styles from './Map.module.scss'

const defaultLat = 41.850033;
const defaultLng = -87.6500523;
const defaultZoom = 4;

export default ({ overlay, center, markers = [], className }) => {
  const mapContainer = useRef();
  const client = useRef();
  const attachedMarkers = useRef({});
  const { map, setMap } = useAddressInput();

  // 1. Initialize the map;
  // Unset the map when this component unmounts (and the map container is destroyed)
  useEffect(() => {
    let abort = false;
    getClient().then(c => {
      client.current = c;
      !abort && setMap(new client.current.Map(mapContainer.current, {
        zoom: defaultZoom,
        streetViewControl: false,
        fullscreenControl: false,
        controlSize: 24
      }));
    });
    return () => {
      abort = true;
      setMap(undefined);
    }
  }, [setMap]);

  // 2. Update map center when the center prop changes to a valid value (even if its values are unchanged).
  useEffect(() => {
    if (map && center) {
      map.panTo(center);
      map.setZoom(16);
    }
  }, [map, center]);

  // 3. When map center is removed, show all markers, or absent markers, fall back to default view.
  useEffect(() => {
    if (map && !center) {
      const markerKeys = Object.keys(markers);
      if (markerKeys.length > 0) {
        const bounds = Object.keys(markers).reduce((bounds, tourName) => {
          bounds.extend(markers[tourName].latLng);
          return bounds;
        }, new client.current.LatLngBounds());
        map.fitBounds(bounds);
        client.current.event.addListenerOnce(map, 'bounds_changed', () => map.getZoom() > 14 && map.setZoom(14));
      }
      else {
        map.panTo({
          lat: defaultLat,
          lng: defaultLng
        });
        map.setZoom(defaultZoom);
      }
    }
  }, [map, center, markers]);

  // 4. Sync markers with the map
  const overlayTourName = overlay?.tourName;
  useEffect(() => {
    if (map) {
      const newList = Object.keys(markers).filter(t => t !== overlayTourName);
      newList.filter(t => !attachedMarkers.current[t]).forEach(t => {
        const newMarker = new client.current.Marker({
          position: markers[t].latLng,
          map,
          icon: {
            url: MarkerSvg,
            scaledSize: new client.current.Size(30,30)
          }
        });
        newMarker.addListener('click', markers[t].onClick);
        attachedMarkers.current[t] = newMarker;
      });
      Object.keys(attachedMarkers.current).filter(t => !newList.includes(t)).forEach(t => {
        attachedMarkers.current[t].setMap(null);
        delete attachedMarkers.current[t];
      });
    }
  }, [map, markers, overlayTourName]);

  return (
    <div className={`${styles.container} ${className || ''}`}>
      <div ref={mapContainer} className={styles.map}></div>
      { overlay && map &&
        <Overlay map={map} coordinates={overlay.coordinates} />
      }
    </div>
  )
}
