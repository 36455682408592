import React from 'react'

import { ActionLink, Logo, Avatar, AdminElementToggle } from 'app/_shared'

import { signOut } from '../service'
import { useActiveUser } from './UserContext'
import styles from './Menu.module.scss'

export default ({ openSignIn, close, mode }) => {
  const activeUser = useActiveUser();
  const onSignOutClick = async () => {
    await signOut();
    close();
  }

  return (
    <div className={styles.menu}>
      <ActionLink className={styles.logo} to="/" onClick={close}><Logo/></ActionLink>
      { activeUser && mode === "app" ? 
        <>
          <div className={styles.user}>
            <Avatar className={styles.avatar} user={activeUser} />
            <span className={styles.name}>
              {`${activeUser.firstName} ${activeUser.lastName}`}
            </span>
            <span className={styles.email}>
              {activeUser.email}
            </span>
            <ActionLink className={styles.signOut} appearance="arrow" onClick={onSignOutClick}>Sign out</ActionLink>
          </div> 
          <div className={styles.preferences}>
            <AdminElementToggle render={toggle => !toggle ? null : (
              <label>
                {toggle} <span>Display admin-only elements</span>
              </label>
            )} />
          </div>
        </>
        :
        <>
          <div className={styles.pages}>
            <ActionLink to="/product" onClick={close}>Product</ActionLink>
            <ActionLink to="/pricing" onClick={close}>Pricing</ActionLink>
            <ActionLink to="/showcase" onClick={close}>Showcase</ActionLink>
          </div>
          <div className={styles.actions}>
            { activeUser ?
              <ActionLink className={styles.signIn} appearance="arrow" to="/my" onClick={close}>Sign in</ActionLink>
              :
              <ActionLink className={styles.signIn} appearance="arrow" onClick={openSignIn}>Sign in</ActionLink>
            }
            <ActionLink className={styles.order} appearance="flashy button" to="/order" onClick={close}>Order now</ActionLink>
          </div>
        </>
      }
    </div>
  )
}
