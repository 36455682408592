import React from 'react'

import styles from './Badge.module.scss'

export default ({ className, throb }) => (
  <span className={`${styles.badge} ${className || ''}`} data-throb={throb ? '' : undefined}>
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
      <g fill="none">
        <g>
          <path fill="currentColor" d="M33.933994,12.7890388 C33.926591,12.7618149 33.9201433,12.7336358 33.9127403,12.7064119 C28.077994,13.3356657 24.8087403,13.9902328 23.8592478,15.4304716 L18.6489791,23.6848 C18.1582328,24.4554269 17.7372179,24.8580537 17.3520239,24.8580537 C16.9847403,24.8580537 16.5467701,24.4730985 16.0729791,23.6848 L9.38545672,12.7064119 L12.0646209,12.7064119 L17.4048,21.5835463 L22.7970388,12.7064119 L33.9127403,12.7064119 C31.8769194,5.43381493 25.1948896,0.0824119403 17.2815761,0.0824119403 C7.75751642,0.0824119403 0.00778507463,7.83094925 0.00778507463,17.355009 C0.00778507463,26.878591 7.75751642,34.6268896 17.2815761,34.6268896 C26.8049194,34.6268896 34.5532179,26.878591 34.5532179,17.355009 C34.5532179,15.8032478 34.3201433,14.2230687 33.933994,12.7890388"/>
        </g>
      </g>
    </svg>
  </span>
)
