import React, { useRef, useMemo, useEffect, useLayoutEffect, useState } from 'react'

import { useFlags, forceReflow } from 'app/_shared'

import { useListing, Section } from '../ListingContext'
import { usePhotos } from './PhotosContext'
import Carousel from './Carousel'
import Grid from './Grid'
import Toolbar from './Toolbar'
import styles from './index.module.scss'

const TRANSITION_DURATION = 250;

const transition = (from, to, skipFrom, skipTo) => {
  const fromRect = from.getBoundingClientRect();
  const toRect = to.getBoundingClientRect();
  
  const handleTransitionEnd = (node, resolve) => {
    const handler = (e) => {
      if (e.target === node) {
        node.removeEventListener('transitionend', handler);
        node.removeEventListener('transitioncancel', handler);
        node.style.transition = 'none';
        node.style.transform = null;
        node.style.zIndex = null;
        forceReflow(node);
        node.style.transition = null;
        resolve();
      }
    }
    node.addEventListener('transitionend', handler);
    node.addEventListener('transitioncancel', handler);
    return handler;
  }

  // NOTE: scaling is to be done proportionally, prefering whichever dimension has a more drastic scale
  const scaleX = toRect.width / fromRect.width;
  const scaleY = toRect.height / fromRect.height;
  const scale = Math.abs(Math.log(scaleX)) < Math.abs(Math.log(scaleY)) ? scaleX : scaleY;

  const left = (toRect.left + toRect.width / 2) - (fromRect.left + fromRect.width / 2);
  const top = (toRect.top + toRect.height / 2) - (fromRect.top + fromRect.height / 2);

  const fromPromise = skipFrom ? Promise.resolve() : new Promise(resolve => {
    from.style.transition = `transform ${TRANSITION_DURATION}ms`;
    from.style.transform = `translate(${left}px, ${top}px) scale(${scale})`;
    from.style.zIndex = 10;
    handleTransitionEnd(from, resolve);
  });

  const toPromise = skipTo ? Promise.resolve() : new Promise(resolve => {
    to.style.transition = 'none';
    to.style.transform = `translate(${-left}px, ${-top}px) scale(${1 / scale})`;
    to.style.zIndex = 10;
    forceReflow(to);
    to.style.transition = `transform ${TRANSITION_DURATION}ms`;
    to.style.transform = null;
    handleTransitionEnd(to, resolve);
  });

  return Promise.all([fromPromise, toPromise]);
}

const scrollIntoView = (item, container) => {
  const itemRect = item.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const top = itemRect.top - containerRect.top;
  if (top < 0) {
    container.scrollTop += top;
  }
  else {
    const containerPadding = parseFloat(getComputedStyle(container).getPropertyValue('padding-bottom'));
    const bottom = (itemRect.top + itemRect.height) - 
      (containerRect.top + containerRect.height - containerPadding);
    if (bottom > 0) {
      container.scrollTop += bottom;
    }
  }
}

const Layout = {
  GRID: 'grid',
  CAROUSEL: 'carousel',
  INACTIVE_FAR: 'inactiveFar',
  INACTIVE_NEAR: 'inactiveNear'
}

// This enables us to continue rendering the carousel with the last valid state 
// (i.e., when transitioning out)
const useCarouselState = (visiblePhotos, activeIndex, setActiveIndex, nextLayout) => {
  const state = useMemo(() => {
    let filteredPhotos = visiblePhotos;
    let filteredIndex = activeIndex;
    let setFilteredIndex = setActiveIndex;

    return {
      items: filteredPhotos,
      activeIndex: filteredIndex,
      setActiveIndex: setFilteredIndex
    }
  }, [visiblePhotos, activeIndex, setActiveIndex]);

  // If the latest activeIndex is invalid, then the carousel will begin to fade out, and we retain
  // the last valid state. Also, while a carousel is fading out (i.e., nextLayout not CAROUSEL), then
  // keep the carousel state stable until transition is complete.
  const shouldUpdate = state.activeIndex > -1 && nextLayout === Layout.CAROUSEL;

  const [lastState, setLastState] = useState(state);
  useEffect(() => {
    shouldUpdate && setLastState(state);
  }, [state, shouldUpdate]);
  // NOTE: prefer to render the latest state, to prevent rendering once with a stale lastState
  return shouldUpdate ? state : lastState;
}

export default function PhotosSection({ className }) {
  const { unbranded, embedded } = useFlags();
  const allowDownload = !unbranded && !embedded;

  // High level state (photos to display, which is active, and a handler to set which is active)
  // is managed in the PhotoContext and shared with e.g. the floor plan viewer, and manages routing.
  const { visiblePhotos, activeIndex, setActiveIndex } = usePhotos();
  const { activeSection } = useListing();

  const nextLayout = 
    activeSection === Section.INFO ? Layout.INACTIVE_FAR :
    activeSection !== Section.PHOTOS ? Layout.INACTIVE_NEAR :
    activeIndex > -1 ? Layout.CAROUSEL : Layout.GRID;
  const [renderedLayout, setRenderedLayout] = useState(nextLayout);

  const carouselState = useCarouselState(visiblePhotos, activeIndex, setActiveIndex, nextLayout);

  const carouselItemRef = useRef();
  const gridRef = useRef();
  
  // NOTE: we have the latest activePhotoIndex in a ref to get a ref to 
  // the active grid item. Alternatively we can re-render the grid every time the active photo changes 
  // to reassign a ref directly to the active grid item, but this visibly expensive with large photo sets.
  const activePhotoIndex = useRef();
  activePhotoIndex.current = visiblePhotos.indexOf(carouselState.items[carouselState.activeIndex]);

  // Transition from any layout except CAROUSEL to a different layout.
  useEffect(() => {
    if (nextLayout !== renderedLayout && renderedLayout !== Layout.CAROUSEL) {
      setRenderedLayout(nextLayout);
      if (nextLayout === Layout.CAROUSEL && renderedLayout === Layout.GRID) {
        const gridItem = gridRef.current.children[activePhotoIndex.current];
        const carouselItem = carouselItemRef.current;
        if (gridItem && carouselItem) {
          transition(gridItem, carouselItem);
        }
      }
    }
  }, [nextLayout, renderedLayout]);

  // Transition from CAROUSEL into a different layout.
  // NOTE: since carouse is already rendered, we utilize useLayoutEffect so it takes place before
  // the DOM is updated, so to ensure transitions can happen in sync. Updating the renderedLayout state
  // happens AFTER the transition is completed.
  useLayoutEffect(() => {
    if (nextLayout !== renderedLayout && renderedLayout === Layout.CAROUSEL) {
      if (nextLayout === Layout.GRID) {
        const gridItem = gridRef.current.children[activePhotoIndex.current];
        const carouselItem = carouselItemRef.current;
        if (gridItem && carouselItem) {
          scrollIntoView(gridItem, gridRef.current.parentNode);
          transition(carouselItem, gridItem);
        }
        // NOTE: transition on the carouselItem to INACTIVE_NEAR or INACTIVE_FAR happens with CSS.
      }
      const handler = setTimeout(() => setRenderedLayout(nextLayout), TRANSITION_DURATION);
      return () => clearTimeout(handler);
    }
  }, [nextLayout, renderedLayout]);
  
  // When the carousel is active, pressing escape will go to grid view;
  const renderingCarousel = nextLayout === Layout.CAROUSEL;
  useEffect(() => {
    if (renderingCarousel) {
      const onEscape = e => e.key === 'Escape' && setActiveIndex(undefined, true);
      document.addEventListener('keydown', onEscape);
      return () => document.removeEventListener('keydown', onEscape);
    }
  }, [renderingCarousel, setActiveIndex]);

  const carouselShouldRender = renderedLayout === Layout.CAROUSEL || nextLayout === Layout.CAROUSEL;
  const carouselTransition = [renderedLayout, nextLayout].find(t => t !== Layout.CAROUSEL);

  return (
    <div
      className={`${styles.photos} ${className || ''}`}
      data-layout={nextLayout}
    >
      <Grid 
        ref={gridRef} 
        className={styles.grid}
        items={visiblePhotos} 
      />
      {carouselShouldRender && 
        <Carousel
          activeItemRef={carouselItemRef}
          className={styles.carousel}
          {...carouselState}
          inert={nextLayout !== Layout.CAROUSEL ? 1 : undefined}
          data-transition={carouselTransition}
        />
      }
      <Toolbar 
        className={styles.tools}
        allowDownload={allowDownload}
        layout={nextLayout}
      />
    </div>
  )
}
