import React from 'react'

import { ActionLink, FieldGroup, wrapAdminElement } from 'app/_shared'

import styles from './AdminLinks.module.scss'

export default ({ tourName }) => {
  return wrapAdminElement(
    <div className={styles.build}>
      <FieldGroup collapse>
        {['info', 'capture', 'assemble', 'draw', 'draft', 'label', 'comp', 'edit', 'review'].map(t => (
          <ActionLink 
            key={t}
            appearance="admin button" 
            to={`${process.env.CONSOLE_SITE}/tour/${t}/${tourName}`}
          >
            {t}
          </ActionLink>
        ))}
      </FieldGroup>
    </div>
  )
}