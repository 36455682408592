import React from 'react'

import styles from './Instruction.module.scss'

export default ({ children, pointing, caretPosition, highlight, className }) => (
  <div className={`${styles.instruction} ${className || ''}`} data-pointing={pointing} data-highlight={highlight}>
    <i className={styles.caret} style={caretPosition || {left: '50%'}}></i>
    <div className={styles.content}>
      {children}
    </div>
  </div>
)