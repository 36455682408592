import React, { useRef, useCallback, useEffect } from 'react'
import { MorphBox, ActionLink } from 'app/_shared'

import styles from './FloorSelectorItem.module.scss'

export default ({ centerOn, active, onClick, label, children }) => {
  const ref = useRef();

  const centerIfActive = useCallback(() => active && centerOn(ref.current), [active, centerOn]);
  useEffect(() => { centerIfActive(ref.current)}, [centerIfActive]);
  return (
    <div ref={ref} className={styles.item} data-active={active}>
      <ActionLink className={styles.label} onClick={onClick}>
        {label}
      </ActionLink>
      <MorphBox 
        collapseDirection="width" 
        className={styles.tools} 
        onComplete={centerIfActive}
      >
        { active && children }
      </MorphBox>
    </div>
  )
}
