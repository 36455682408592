import React, { useRef, useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'

import { FieldSet, ActionLink, MorphBox, FieldList } from 'app/_shared'

import { Instruction, ButtonBar, RegistrationReminder } from '../_shared'
import { BookingSteps } from '../BookingContext'
import ContactEntry from './ContactEntry'
import styles from './index.module.scss'

const additionalContactTemplate = {
  firstName: '',
  lastName: '',
  company: '',
  role: '',
  email: '',
  phone: ''
};

export default () => {
  const { isSubmitting, values, errors, isValid } = useFormikContext();

  const invalidRef = useRef();

  useEffect(() => {
    !isValid && isSubmitting && invalidRef.current && invalidRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [isValid, isSubmitting]);

  const errorIndex = useMemo(() => {
    if (!errors.contact) {
      return undefined;
    }
    else if (errors.contact.billingContact) {
      return 'billing';
    }
    else if (Array.isArray(errors.contact.additionalContacts)) {
      return errors.contact.additionalContacts.findIndex(t => !!t);
    }
    else {
      return undefined;
    }
  }, [errors]);

  return (
    <div className={styles.contact}>
      <section>
        <Instruction pointing="down" highlight={errors.contact && !!errors.contact.billingContact}>
          <h3>Primary Contact</h3>
          <p>This contact will receive notifications related to billing, scheduling, and delivery for this order.</p>
          <RegistrationReminder className={styles.register} render={({ activeUser, openSignIn, openRegister }) => !activeUser && (
            <p>
              If you have an account, <ActionLink appearance="link" onClick={openSignIn}>sign in</ActionLink> to auto-fill this form. 
              Or <ActionLink appearance="link" onClick={openRegister}>create an account</ActionLink> to save your input for next time.
            </p>
          )} />
        </Instruction>
        { values.contact && 
          <div ref={errorIndex === 'billing' ? invalidRef : undefined}>
            <ContactEntry namePrefix="contact.billingContact" isPrimary />
          </div>
        }
      </section>
      <section>
        <Instruction pointing="down" highlight={errors.contact && !errors.contact.billingContact && !!errors.contact.additionalContacts}>
          <h3>Additional Contacts</h3>
          <p>Add up to 3 additional contacts to receive notifications related to scheduling and delivery for this order.</p>
        </Instruction>
        { values.contact &&
          <FieldList
            name="contact.additionalContacts"
            removeDelay={400}
            template={additionalContactTemplate}
            renderItem={({ name, key, index, exiting, remove }) => (
              <MorphBox key={key} morphOnMount>
                { !exiting &&
                  <div 
                    className={styles.additional} 
                    ref={errorIndex === index ? invalidRef : undefined}
                  >
                    <ContactEntry namePrefix={name} remove={remove} />
                  </div>
                }
              </MorphBox>
            )}
            render={({ items, renderedItems, add }) => (
              <>
                { renderedItems }
                <div className={styles.add}>
                  <ActionLink 
                    type="button" 
                    appearance="plain button" 
                    disabled={isSubmitting || items.length >= 3} 
                    onClick={isSubmitting || items.length >= 3 ? undefined : () => add()}
                  >
                    { items.length < 3 ? "Add" : "Maximum contacts added" }
                  </ActionLink>
                </div>
              </>
            )}
          />
        }
      </section>
      <section>
        <Instruction pointing="down">
          <h3>Special Instructions</h3>
          <p>
            Let us know who will meet us at the property, special access instructions, lock box code, 
            and any other applicable information that will be useful for this appointment.
          </p>
        </Instruction>
        { values.contact && 
          <FieldSet name="contact.specialInstructions" label="Special instructions" component="textarea" rows="5" />
        }
      </section>
      <ButtonBar lastStep={BookingSteps.SCHEDULE} nextStep={BookingSteps.CONFIRM} />
    </div>
  )
}
