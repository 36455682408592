import React from 'react'
import { ActionLink, Emoji } from 'app/_shared'
import { useUserModal } from 'app/user'
import styles from './Message.module.scss'

export default ({ className }) => {
  const { openSignIn, openRegister } = useUserModal();
  return (
    <div className={`${styles.container} ${className || ''}`}>
      <h1>
        <Emoji symbol="💡"/> You're not currently signed in.
      </h1>
      <p>
        <ActionLink appearance="link" onClick={openRegister}>
          Create an account
        </ActionLink> or <ActionLink appearance="link" onClick={openSignIn}>
          sign in
        </ActionLink> <span className={styles.nobreak}>to manage all your listings.</span>
      </p>
      <div className={styles.divider}>or</div>
      <p>
        <ActionLink appearance="link" to="/order">Create a new listing</ActionLink>, no registration necessary.
      </p>
    </div>
  )
}
