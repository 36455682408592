import React from 'react'

import styles from './Marker.module.scss'

export default ({ className, ...rest }) => (
  <div className={`${styles.marker} ${className || ''}`} {...rest}>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0,0,1,1.207">
      <clipPath id="clip">
        <path d="M0.5 1.207 L0.8535 0.8535 a0.5,0.5 0 1 0 -0.707 0 z"/>
      </clipPath>
      <path fill="#ED1C24" stroke="#263238" strokeWidth="0.2" d="M0.5 1.207 L0.8535 0.8535 a0.5,0.5 0 1 0 -0.707 0 z" clipPath="url(#clip)"/>
      
      <circle cx="0.5" cy="0.5" r="0.15" fill="white"/>
    </svg>
  </div>
)