import * as THREE from 'three';
import ResizeObserver from 'resize-observer-polyfill';
import { Expo } from 'gsap'

import { GestureHandler, EventHandler } from 'app/_shared'

import SceneCube from './SceneCube'
import TourCamera from './TourCamera'
import ImageLoader from './ImageLoader'
import NavArrows from './NavArrows'
import NavRings from './NavRings'

export default function (container, config, options) {
	// Initialize state variables
  const currentState = { ...config.starting };

  const events = new EventHandler();
  this.addEventListener = async (type, handler) => {
    type === 'move' && handler({ type, data: { targetScene: currentState.sceneCode }});
    type === 'rotate' && handler({ type, data: { direction: [currentState.pan, currentState.tilt]}});
    events.addEventListener(type, handler);
  };
  this.removeEventListener = events.removeEventListener;

	// Empty the dom container.
	while (container.firstChild && container.removeChild(container.firstChild));
	
	// Initialize renderer and append to the DOM.
  const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: false });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(container.offsetWidth, container.offsetHeight);
  renderer.setClearColor(0xffffff, 1);
  container.appendChild(renderer.domElement);

  // As each group is encountered (by visiting a scene in the group),
  // the group is inserted or updated as a property whose value is the
  // most recent visited setting.
  const currentGroupSettings = {};

	// TODO tour: evaluate a better place for this
  const updateNavHeight = () => {
    const bottomEdge = Math.tan(THREE.Math.degToRad(camera.fov) / 2);
    navArrows.updatePosition(currentState.pan, currentState.tilt, bottomEdge);
  }
  
  const onCameraRotate = ({ pan, tilt, source }) => {
    currentState.pan = pan;
    currentState.tilt = tilt;
    events.trigger('rotate', { 
      direction: [pan, tilt], 
      source, 
      arbitrary: !config.scenesByCode[currentState.sceneCode]?.floor,
    });
    updateNavHeight();
  };

	// Initialize camera and its update callback.
  const camera = new TourCamera(config.starting.pan, config.starting.tilt, container.offsetWidth / container.offsetHeight, renderer, onCameraRotate);

	// When container element resizes, update renderer and camera aspect ratios and related parameters.
  (new ResizeObserver(() => {
		renderer.setSize(container.offsetWidth, container.offsetHeight);
		camera.setAspect(container.offsetWidth / container.offsetHeight);
    updateNavHeight();
  })).observe(container);

	// Create the 3D elements.
  const imageLoader = new ImageLoader(config, options.imageSource);
  let activeCube = new SceneCube(options.imageSource, imageLoader);
  let inactiveCube = new SceneCube(options.imageSource, imageLoader);
  const navRings = new NavRings(config.scenesByCode);
  const navArrows = new NavArrows(config.scenesByCode, options.hideArrows);

	// Attach the 3D elements and begin animating.
  const threeScene = new THREE.Scene();
  threeScene.add(activeCube);
  threeScene.add(inactiveCube);
  threeScene.add(navRings);
  threeScene.add(navArrows);
  const animate = () => {
    window.requestAnimationFrame(animate);
    renderer.render(threeScene, camera);
  }

  const updateCurrentScene = (scene, animate = true, source) => {
    if (scene.code !== currentState.sceneCode) {
      events.trigger('move', { targetScene: scene.code, source });
      currentState.sceneCode = scene.code;
    }
    if (scene.group && scene.setting) {
      currentGroupSettings[scene.group] = scene.setting;
    }
    options.imageLoading && options.imageLoading(false);
    navArrows.load(scene.code, currentGroupSettings);
    const nearbyScenes = navRings.load(scene.code, currentGroupSettings, !animate);
    imageLoader.preload(nearbyScenes);
  }

  const getScene = (sceneCode, parameters) => {
    return config.scenesByCode[sceneCode] || {
      code: sceneCode,
      x: 0,
      y: 0,
      z: config.minZ - 130,
      yaw: 0,
      ...parameters
    }
  }

  let transitioning = false;
  let currentTransition = Promise.resolve();
  let nextAction = null;
  const moveToScene = async (sceneCode, onLoad, source) => {
    // First, define the action to run (but don't run it yet).
    const action = async () => {
      if (sceneCode === currentState.sceneCode) {
        onLoad && onLoad(true);
      }
  
      const currentScene = getScene(currentState.sceneCode);
      const targetScene = getScene(sceneCode, {
        x: currentScene.x + 1,
        y: currentScene.y,
        z: currentScene.z
      });
      const sameCluster = currentScene.cluster && currentScene.cluster === targetScene.cluster;
  
      transitioning = true;
      options.imageLoading && options.imageLoading(true);
      
      navArrows.unload();
      // navRings.hideAll(); // TODO tour: evaluate whether rings should be hidden during transition
      navRings.highlight(sceneCode);
  
      if (sameCluster) {
        const movementDirection = new THREE.Vector3(
          targetScene.x - currentScene.x,
          targetScene.y - currentScene.y,
          targetScene.z - currentScene.z,
        );
  
        [activeCube, inactiveCube] = [inactiveCube, activeCube];
        await activeCube.enter(targetScene, movementDirection);
        // At this point, activeCube's images are loaded and movement has *started*.
        updateCurrentScene(targetScene, true, source);
        onLoad && onLoad(sameCluster);
  
        await inactiveCube.exit(movementDirection)
        // At this point, inactiveCube's exit movement, and thus the whole transition, is complete.
        transitioning = false;
      }
      else {
        [activeCube, inactiveCube] = [inactiveCube, activeCube];
        // Begin fading out immediately.
        inactiveCube.exit();
        const exiting = camera.flyOut({ ease: Expo.easeIn, duration: 0.5, source });
        // Begin loading the next scene immediately, but do not animate it until the exit is complete.
        await activeCube.enter(targetScene, null, exiting);
        updateCurrentScene(targetScene, false, source);
        onLoad && onLoad(sameCluster);
        await camera.flyIn({ source });
        // At this point, the entire transition is complete.
        transitioning = false;
      }
    };
    
    nextAction = action;
    await currentTransition; // await the existing transition, if any.
    if (action === nextAction) { // proceed with the current request only if it has not been overwritten.
      currentTransition = action();
      await currentTransition;
      return true; // the requested move was completed
    }
    else {
      return false; // the requested move was cancelled because it was queued, then overwritten by another request
    }
  }

  // Set up mouse and touch events for dragging, clicking/tapping, hovering, pinching, and scrolling
  const gestureHandler = new GestureHandler(container);
  gestureHandler.addEventListener('start', () => {
    camera.killRotation();
    events.trigger('dragstart');
  });
  gestureHandler.addEventListener('drag', ({ data: { dx, dy, clickCancelled, originalEvent }}) => {
    camera.rotateByDrag(dx, dy);
    if (originalEvent.type === 'touchmove' && clickCancelled) {
      navRings.updateVisibility(camera.getTargetVector())
    }
    events.trigger('dragmove', { dx, dy });
  });
  gestureHandler.addEventListener('click', ({ data: { x, y }}) => {
    // TODO tour: highlight the ring during transition
    const vector = camera.getTargetVector(x, y);
    const targetScene = navArrows.targetExact(vector) || navRings.targetVisible(vector) || navArrows.targetNear(vector);
    navRings.highlight(targetScene);
    targetScene ? moveToScene(targetScene) : camera.bounce(vector);
    events.trigger('click', { x, y, targetScene });
  });
  gestureHandler.addEventListener('hover', ({ data: { x, y }}) => {
    const vector = camera.getTargetVector(x, y);
    navRings.updateVisibility(vector);
    const target = navArrows.targetExact(vector) || navRings.targetVisible(vector);
    navRings.highlight(target);
  });
  gestureHandler.addEventListener('pinch', ({ data: { dr }}) => camera.changeFov(dr));
  gestureHandler.addEventListener('pinchend', () => camera.resetFov());
  gestureHandler.addEventListener('wheel', ({ data: { scrollY, x, y }}) => {
    // NOTE: to debounce wheel events, event is ignored when transition is taking place.
    if (!transitioning) {
      const vector = camera.getTargetVector(x, y).multiplyScalar(-scrollY);
      // TODO: also handle scrollX as a "side step"
      const targetScene = navArrows.targetNear(vector);
      navRings.highlight(targetScene);
      targetScene ? moveToScene(targetScene) : (!transitioning && camera.bounce(vector));
      events.trigger('wheel', { x, y, scrollY, targetScene });
    }
  });

  // TODO: Set up motion sensing to change camera view.
  // setupMotion((pan, tilt) => camera.rotateBy(pan, tilt, true));

  // Utility function to find the scene closest matching the input parameters.
  const findClosestScene = ({ sceneCode, floorId, group, setting, x, y }) => {
    // NOTE: x and y are in mm. 
    // scene.x and scene.y are in cm.
    if (sceneCode) {
      return sceneCode;
    }

    let currentScene = getScene(currentState.sceneCode);
    if (typeof x !== 'number') {
      x = currentScene.x * 10;
    }
    if (typeof y !== 'number') {
      y = currentScene.y * 10;
    }
    group = group || currentScene.group;
    setting = setting || currentScene.setting;
    
    let winner = Object.values(config.scenesByCode).reduce((winner, scene) => {
      if (
        (!floorId || scene.floor === floorId) &&
        (!group || !setting || scene.group !== group || scene.setting === setting)
      ) {
        // TODO: if scene has a group and setting not matching the most recent setting on the group, then
        // accept it only if the most recent setting cannot match. Apply same logic to building arrows and rings.
        let distance = Math.pow(scene.x * 10 - x, 2) + Math.pow(scene.y * 10 - y, 2) + (floorId ? 0 : Math.pow(scene.z * 10 - currentScene.z * 10, 2));
        if (!winner || distance < winner.distance) {
          return { sceneCode: scene.code, distance }
        }
      }
      return winner;
    }, null);

    return winner && winner.sceneCode;
  }

  // Public API for imperatively navigating the tour to another scene and/or camera direction.
  // Note this function returns a promise which resolves to the found target scene code after
  // both scene transition and camera movement (both as applicable) have completed.
  this.jumpTo = async (parameters) => {
    options.onJumpTo && options.onJumpTo();    
    const targetSceneCode = findClosestScene(parameters);

    let rotate = undefined;
    const promises = [];
    if (typeof(parameters.pan) === 'number' || typeof(parameters.tilt) === 'number') {
      promises.push(new Promise(resolve => {
        rotate = async sameCluster => {
          await camera.rotateTo({ 
            pan: parameters.pan, 
            tilt: parameters.tilt, 
            duration: !sameCluster ? 0 : parameters.duration, 
            source: parameters.source 
          });
          resolve();
        }
      }));
    }

    // NOTE: if a noJump flag is set in the parameters, jump will not actually happen.
    // Regardless, the targetScene is returned so client can call jumpTo to jump directly 
    // to this scene later if desired.
		if (!parameters.noJump && targetSceneCode && targetSceneCode !== currentState.sceneCode) {
      promises.push(moveToScene(targetSceneCode, rotate, parameters.source));
    }
    else if (rotate) {
      rotate(true);
    }

    await Promise.all(promises);
    return targetSceneCode;
  }

  // Public API for entering into a tour by flying into the specified view
  this.flyIn = async (parameters) => {
    const sceneCode = parameters && parameters.sceneCode;

    if (sceneCode) {
      const { pan, tilt, source } = parameters;
      if (transitioning) {
        moveToScene(sceneCode, sameCluster => {
          camera.rotateTo({ pan, tilt, source, 
            duration: !sameCluster ? 0 : undefined,
          });
        }, source);
      }

      else {
        currentTransition = (async () => {
          transitioning = true;
          options.imageLoading && options.imageLoading(true);
          navArrows.unload();
          navRings.hideAll();
          const targetScene = getScene(sceneCode);
          updateCurrentScene(targetScene, false, source);
          await Promise.all([
            activeCube.enter(targetScene),
            camera.flyIn({ pan, tilt, source })
          ]);
          transitioning = false;
        })();
        await currentTransition;
      }
    }
    else if (!transitioning) {
      camera.flyIn({ source: parameters?.source });
    }
  }

	// Public API for imperatively flying out of the current view.
  this.flyOut = async (callback, source) => {
    // NOTE: since camera.flyOut will cancel a previous camera.flyIn, which
    // can potentially result in an existing transition never resolving (and thus no further
    // navigation possible). Therefore, whatever is happening, we reset the currentTransition
    // to resolved.
    currentTransition = Promise.resolve();
    await camera.flyOut({ source });
    callback && callback();
  }

  this.killRotation = camera.killRotation;
  
  // Public API to apply the current state to a callback.
  this.triggerCurrent = action => {
    action(currentState);
  }

  // Public function for cleaning up.
  this.destroy = () => {
    while (container.firstChild && container.removeChild(container.firstChild));
    gestureHandler.destroy();
    return undefined;
  }


  // Finally, fly into the starting scene.
  animate();
  this.flyIn(config.starting);
}
