import React, { useEffect, useCallback, useRef, useState } from 'react'
import TweenLite from 'gsap'
import ResizeObserver from 'resize-observer-polyfill'

import { GestureHandler } from '.'
import styles from './InlineScroll.module.scss'

const OVERSCROLL_RESISTANCE = 0.05;
const MAX_OVERSCROLL = 25;
const TRANSITION_DURATION = 0.3;
const MAX_SCROLL_DISTANCE = 300;

// Custom hook to set up position tracker.
// NOTE: innerDiv's width is set in CSS to be 100%, representing the full content box of the outer div
// and the scroll "window". innerDiv.scrollWidth represents the full width of its contents, (although)
// due to a rendering idiosyncracy, ignores the right margin of the last item.
const usePosition = () => {
  const innerRef = useRef();
  const [atMin, setAtMin] = useState();
  const [atMax, setAtMax] = useState();

  const position = useRef({
    get range() { return Math.max(0, innerRef.current.scrollWidth - innerRef.current.clientWidth)},
    get value() { return this._value },
    // NOTE: the scroll position is stored as a fraction of the total scroll range,
    // to accommodate graceful updating when the outer or inner containers change size.
    set value(val) {
      this._value = isNaN(val) ? 0 : val;
      innerRef.current.style.transform = val === 0 ? null : `translateX(${-this._value * this.range}px)`;
    },
    _value: 0
  });

  const setPosition = useCallback(({ x, dx = 0, animate, overscroll }) => {
    const range = position.current.range;
    const constrain = !overscroll || range <= 0; // constrain = false means range > 0
    let resistance = 1;
    let target = x ? x / (range || innerRef.current.scrollWidth) : position.current.value;

    if (animate) {
      target -= range > 0 ? dx / range : 0;
      if (constrain) {
        target = Math.min(Math.max(target, 0), 1);
      }
      else {
        target = Math.min(Math.max(target, -MAX_OVERSCROLL / range), 1 + MAX_OVERSCROLL / range);
      }
      TweenLite.to(position.current, typeof animate === 'number' ? animate : TRANSITION_DURATION, { 
        value: target,
        onComplete: !constrain ? () => setPosition({ animate }) : undefined
      });
    }
    else {
      if (!constrain) {
        resistance = Math.exp(OVERSCROLL_RESISTANCE * Math.max(0, -position.current.value, position.current.value - 1) * range);
      }
      target -= range > 0 ? dx / resistance / range : 0;
      if (constrain) {
        target = Math.min(Math.max(target, 0), 1);
      }
      position.current.value = target;
    }
    setAtMin(target <= 0);
    setAtMax(target >= 1);
    return resistance;
  }, []);

  const scroll = useCallback(direction => {
    const dx = -direction * Math.min(MAX_SCROLL_DISTANCE, innerRef.current.offsetWidth * 0.6);
    setPosition({ dx, animate: true, overscroll: true });
  }, [setPosition]);

  const killTransition = useCallback(() => TweenLite.killTweensOf(position.current), []);

  // Kill outstanding transitions when the component unmounts to prevent a stale onComplete handler.
  useEffect(() => killTransition, [killTransition]);
  return { setPosition, atMin, atMax, killTransition, scroll, innerRef };
}

// Custom hook to update the scroll position every time the inner and outer containers change size,
// including during every frame of a transition to ensure that scroll always stays in bounds.
const useUpdateOnResize = (outerRef, setPosition) => {
  const resetPosition = useCallback(() => setPosition({}), [setPosition]);

  useEffect(() => {
    const mutantObserver = new MutationObserver(resetPosition);
    const resizeObserver = new ResizeObserver(resetPosition);
    mutantObserver.observe(outerRef.current, { subtree: true, childList: true });
    resizeObserver.observe(outerRef.current);
    return () => {
      mutantObserver.disconnect()
      resizeObserver.disconnect();
    };
  }, [outerRef, resetPosition]);
  
  useEffect(() => {
    const container = outerRef.current;
    let unmounted = false;
    const onStart = (e) => {
      let timeout;
      const loop = () => {
        if (!unmounted && !!timeout) {
          resetPosition();
          window.requestAnimationFrame(loop);
        }
      }
      const end = function end(f) {
        if (!f || e.target === f.target) {
          container.removeEventListener('transitionend', end);
          container.removeEventListener('transitioncancel', end);
          timeout = clearTimeout(timeout);
        }
      };
      container.addEventListener('transitionend', end);
      container.addEventListener('transitioncancel', end);
      // NOTE: observe every frame of a transition up to 1 second only, 
      // in case the other end events never fire (for some reason).
      timeout = setTimeout(() => {
        console.log('InlineScroll transition observer hard stop');
        end();
      }, 1000); 
      window.requestAnimationFrame(loop);
    }
    container.addEventListener('transitionstart', onStart);
    return () => {
      container.removeEventListener('transitionstart', onStart);
      unmounted = true;
    }
  }, [resetPosition, outerRef]);
}

// Custom hook to handle pointer events, including drag and scroll wheel.
const useMotion = (setPosition, killTransition, scroll) => {
  const outerRef = useRef();

  const [motion, setMotion] = useState();
  useEffect(() => {
    const motion = new GestureHandler(outerRef.current);
    setMotion(motion);

    const dragHandler = ({ type, data: { dx, clickCancelled }}) => {
      if (type === 'momentum' || clickCancelled) {
        const resistance = setPosition({ dx, overscroll: true });
        motion.resistMomentum(resistance);
      }
    }
    const endHandler = () => setPosition({ animate: true });
    const wheelHandler = ({ data: { scrollX, scrollY }}) => scroll(scrollX || scrollY);
    
    motion.addEventListener('start', killTransition);
    motion.addEventListener('drag', dragHandler);
    motion.addEventListener('momentum', dragHandler);
    motion.addEventListener('momentumend', endHandler);
    motion.addEventListener('wheel', wheelHandler);

    return () => {
      motion.removeEventListener('start', killTransition);
      motion.removeEventListener('drag', dragHandler);
      motion.removeEventListener('momentum', dragHandler);
      motion.removeEventListener('momentumend', endHandler);
      motion.removeEventListener('wheel', wheelHandler);
      motion.destroy();
      setMotion(undefined);
    }
  }, [setPosition, scroll, killTransition]);
  return { outerRef, blockMotion: motion?.block };
}

export default ({ className, fixedChildren, children }) => {
  const { setPosition, atMin, atMax, killTransition, scroll, innerRef } = usePosition();
  const { outerRef, blockMotion } = useMotion(setPosition, killTransition, scroll);
  useUpdateOnResize(outerRef, setPosition);

  const centerOn = useCallback(node => {
    node && setPosition({ 
      x: node.offsetLeft - innerRef.current.clientWidth / 2 + node.offsetWidth / 2, 
      animate: 0.5 
    });
  }, [setPosition, innerRef]);

  const args = { centerOn, scroll, atMin, atMax, blockMotion };

  return (
    <div ref={outerRef} className={`${styles.outer} ${className || ''}`}>
      { typeof fixedChildren === 'function' ? fixedChildren(args) : fixedChildren }
      <div ref={innerRef} className={styles.inner}>
        { typeof children === 'function' ? children(args) : children }
      </div>
    </div>
  )
}