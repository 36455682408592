import React, { useRef, useCallback, useEffect } from 'react'
import { wrapAdminElement, MorphBox, ActionLink } from 'app/_shared'
import styles from './SelectorItem.module.scss'

import { ListingLink } from '../ListingContext'

export default ({ active, admin, to, onClick, label, centerOn, children }) => {
  const ref = useRef();
  const Link = to ? ListingLink : ActionLink;

  const centerIfActive = useCallback(() => active && centerOn(ref.current), [active, centerOn]);
  useEffect(() => { centerIfActive(ref.current)}, [centerIfActive]);

  return wrapAdminElement((
    <div className={`${styles.item} ${admin ? styles.admin : ''} ${active ? styles.active : ''}`} ref={ref}>
      <Link to={to} onClick={onClick} className={styles.link}>
        {label}
      </Link>
      <MorphBox
        collapseDirection="width" 
        className={styles.tools} 
        onComplete={centerIfActive}
      >
        { active && children }
      </MorphBox>
    </div>
  ), !admin);
}
