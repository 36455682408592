import React from 'react'
import { TweenLite } from 'gsap'
import ResizeObserver from 'resize-observer-polyfill'

import { GestureHandler } from 'app/_shared'

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleRef = React.createRef();
  }

  componentDidMount() {
    // NOTE: we assume this component's top element is the drag handle for its immediate parent,
    // and the parent's range of motion is within its immediate parent (this component's grandparent)
    const node = this.props.targetRef.current;
    const container = node.parentNode;
    const padding = 10;
    let transform = { x: 0, y: 0 };
    let originalPosition = null;

    const applyBounds = () => {
      let marginTop = parseFloat(getComputedStyle(node).marginTop);
      let dx = container.offsetWidth - node.offsetWidth;
      let dy = container.offsetHeight - node.offsetHeight;
      return {
        right: Math.min(dx - padding, Math.max(padding, dx - node.offsetLeft - transform.x)),
        bottom: Math.min(dy - padding - marginTop, Math.max(padding + this.handleRef.current.offsetHeight, dy - node.offsetTop - transform.y))
      };
    }

    const onUpdate = () => {
      node.style.transform = `translate(${transform.x}px, ${transform.y}px)`;
    }

    const reset = () => {
      if (originalPosition) {
        node.style.setProperty('--floating-floor-plan-right', (originalPosition.right - transform.x) + 'px');
        node.style.setProperty('--floating-floor-plan-bottom', (originalPosition.bottom - transform.y) + 'px'); 
      }        
      originalPosition = null;
      transform.x = 0;
      transform.y = 0;
      node.style.transform = null;
      // eslint-disable-next-line
      let w = node.offsetWidth; // force a reflow
      node.style.transition = null;
    }

    // NOTE: there is no handling of this.handleRef becoming detached. Therefore whatever renders in the props.render function and
    // is referenced by this.handleRef should remain mounted for as long as this DragHandle is mounted.
    this.motion = new GestureHandler(this.handleRef.current);
    this.motion.addEventListener('start', () => {
      this.tween && this.tween.kill();
      reset();
      originalPosition = {
        right: container.offsetWidth - node.offsetLeft - node.offsetWidth,
        bottom: container.offsetHeight - node.offsetTop - node.offsetHeight
      };
      node.style.transition = 'none';
    });
    this.motion.addEventListener('drag', ({ data: { dx, dy }}) => {
      transform.x += dx;
      transform.y += dy;
      onUpdate();
    });
    this.motion.addEventListener('end', () => {
      let newPosition = applyBounds();
      let target = {
        x: originalPosition.right - newPosition.right,
        y: originalPosition.bottom - newPosition.bottom
      };
      if (target.x !== transform.x || target.y !== transform.y) {
        this.tween = TweenLite.to(transform, 0.25, { ...target, onUpdate, onComplete: reset });
      }
      else {
        reset();
      }
    });
    
    this.resizeObserver = new ResizeObserver(() => {
      if (this.props.draggable) {
        this.tween && this.tween.kill();
        reset();
        if (node.style.getPropertyValue('--floating-floor-plan-right') && node.style.getPropertyValue('--floating-floor-plan-bottom')) {
          let newPosition = applyBounds();
          node.style.setProperty('--floating-floor-plan-right', newPosition.right + 'px');
          node.style.setProperty('--floating-floor-plan-bottom', newPosition.bottom + 'px');
        }
      }
      else {
        node.style.removeProperty('--floating-floor-plan-right');
        node.style.removeProperty('--floating-floor-plan-bottom');
      }
    });
    this.resizeObserver.observe(container);
  }

  componentWillUnmount() {
    this.motion = this.motion.destroy();
    this.resizeObserver.disconnect();
  }

  render() {
    return this.props.render(this.handleRef)
  }
}