import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import { ActionLink, Popup } from 'app/_shared'

import mockup from './LinkedIn.jpg'

import styles from './LinkedIn.module.scss'
import tabStyles from '../index.module.scss'

const data = {
  id: 'linkedin',
  icon: faLinkedinIn,
  background: 'linear-gradient(to top left, #0077B5, #049fed)'
}

const LinkedIn = ({ tourUrl, divProps }) => {
  return (
    <div {...divProps}>
      <h3>Create a LinkedIn Post</h3>
      <div className={tabStyles.content}>
        <p>
          Post this Realvision tour to LinkedIn to share with your professional network. You'll have a chance to enter your own message.
        </p>
        <Popup
          width={575} 
          height={700} 
          url={`http://www.linkedin.com/shareArticle?url=${encodeURIComponent(tourUrl)}&mini=true`} 
          render={open => (
            <ActionLink 
              type="button"
              appearance="button"
              onClick={open}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} /> Post to LinkedIn
            </ActionLink>
          )}
        />
      </div>
    </div>
  )
}

LinkedIn.Preview = ({ title, description, image }) => {
  return (
    <div className={`${styles.stage} ${tabStyles.mockup}`} style={{ backgroundImage: `url(${mockup})`}}>
      <img src={image} alt="" />
      <div className={styles.metadata}>
        <div className={styles.title}>{title}</div>
        <div className={styles.site}>real.vision</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  )
}

LinkedIn.data = data;
export default LinkedIn;