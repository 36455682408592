import React from 'react'
import Calendar from 'react-calendar/dist/entry.nostyle'
import moment from 'moment-timezone'

import styles from './DateSelect.module.scss'

export default ({ onDateChange, selectedDate }) => {
  const minDate = new Date();
  let maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 2);

  return (
    <Calendar className={styles.calendar} 
      calendarType="US"
      prev2Label={null}
      next2Label={null}
      minDetail="month"
      value={selectedDate && new Date(moment(selectedDate, "YYYY-MM-DD").format("l"))}
      maxDate={maxDate}
      minDate={minDate}
      onClickDay={date => onDateChange(date.toISOString().substr(0, 10))}
    />
  )
}