import React, { useEffect, useState } from 'react'
import { ActionLink, MorphBox, Emoji } from '.';
import styles from './UpdateDetector.module.scss'

export default () => {
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    const launchUrl = window.location.href;
    if (typeof window !== 'undefined') {
      // When page loads, immediately fetch the head again to get the last modified date from the server (CloudFront in production)
      fetch(launchUrl, { method: 'HEAD' }).then(res => {
        const lastModified = res.headers.get('Last-Modified');
        const handle = setInterval(() => {
          // Periodically check to see if the last modified date is different. Note, we can't use an If-Modified-Since header here
          // because the TTL for all HTML files is set to 0 in production and so the response will never be 304.
          fetch(launchUrl, { method: 'HEAD' }).then(res => {
            if (lastModified !== res.headers.get('Last-Modified')) {
              setUpdated(true);
              clearInterval(handle);
            }
          })
        }, 60000);
      });
    }
  }, []);

  return (
    <MorphBox className={styles.toast}>
      { updated && (
        <div>
          <p><Emoji className={styles.icon} symbol="💡" /> A newer version of Realvision is available.</p>
          <div className={styles.buttons}>
            <ActionLink className={styles.later} appearance="link" onClick={() => setUpdated(false)}>Later</ActionLink>
            <ActionLink className={styles.refresh} appearance="button" onClick={() => window.location.reload()}>Refresh to update</ActionLink>
          </div>
        </div>
      )}
    </MorphBox>
  )
}