import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import { PhotoGrid, useForwardedRef } from 'app/_shared'

import { Section, ListingLink } from '../ListingContext'
import Photo from './Photo'
import styles from './Grid.module.scss'

const maxRowHeight = container => Math.min(275, container.clientHeight * 0.6);

// NOTE: this component is expensive to render for a larger number of items and visibly 
// affects performance if rerendered unnecessarily. Therefore it is memoized on its props
// here. Avoid changing props (including ref and className) as much as possible!!
export default React.memo(React.forwardRef(({ items, className }, ref) => {
  const gridRef = useForwardedRef(ref);

  // when the filter changes, if none of the visible items is fully visible, scroll to the nearest one.
  useEffect(() => {
    const container = gridRef.current.parentNode;
    const containerPadding = parseFloat(getComputedStyle(container).getPropertyValue('padding-bottom'));
    const containerRect = container.getBoundingClientRect();
    const visibleItems = Array.from(gridRef.current.children).filter(t => !t.classList.contains(styles.hidden));

    if (visibleItems.length > 0) {
      const allItems = [];
      // Loop through all the visible photos. If any are fully within the container viewport, then do nothing.
      // Otherwise, scroll to make the closest photo visible.
      for (let item of visibleItems) {
        const itemRect = item.getBoundingClientRect();
        const top = itemRect.top - containerRect.top;
        const bottom = (itemRect.top + itemRect.height) - (containerRect.top + containerRect.height - containerPadding);
        const center = (itemRect.top + itemRect.height / 2) - (containerRect.top + (containerRect.height - containerPadding) / 2)

        if (top >= 0 && bottom <= 0) {
          return;
        }
        allItems.push({ center, top, bottom });
      }
      
      // find the photo closest to the viewport.
      const closestItem = allItems.sort((a, b) => Math.min(Math.abs(a.top), Math.abs(a.bottom)) - Math.min(Math.abs(b.top), Math.abs(b.bottom)))[0];
      container.scroll({ 
        top: container.scrollTop + closestItem.center, 
        behavior: 'smooth'
      });
    }
  }, [gridRef]);

  return (
    <PhotoGrid
      photos={items}
      className={`${styles.grid} ${className || ''}`}
      maxRowHeight={maxRowHeight}
      render={layout => layout && (
        <div className={styles.inner} ref={gridRef}>
          { layout.map(t => (
            <div
              key={t.code}
              style={{...t.grid, '--transition-delay': Math.random() * 0.25 + 's' }}
              className={styles.item}
            >
              <ListingLink className={styles.inner} to={{ section: Section.PHOTOS, subSection: t.code }}>
                <Photo
                  photoId={t.code}
                  className={styles.inner}
                  url={t.url}
                  aspect={t.aspect || 1.5}
                  cover
                />
                { t.externalLink &&
                  <div className={styles.play}>
                    <FontAwesomeIcon icon={faPlay} />
                  </div>
                }
              </ListingLink>
            </div>
          ))}
        </div>
      )}
    />
  )
}))