import React from 'react'
import { Form, useFormikContext } from 'formik'
import { FieldSet, ActionLink } from 'app/_shared'
import Error from '../Error'

export default () => {
  const { isSubmitting } = useFormikContext();
  return (
    <Form>
      <p>Enter your email address, and we'll send you a verification code for resetting your password.</p>
      <FieldSet type="email" name="email" label="Email address" />
      <Error />
      <ActionLink type="submit" appearance="button" disabled={isSubmitting}>Send code</ActionLink>
    </Form>
  )
}
