import React from 'react'

import { ActionLink } from 'app/_shared'

import styles from './ModalHeader.module.scss'

export default ({ children, close, className }) => (
  <div className={`${styles.header} ${className || ''}`}>
    {children}
    <ActionLink className={styles.close} onClick={close}>
      &times;
    </ActionLink>
  </div>
)