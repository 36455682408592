import React, { useCallback, useState } from 'react'

import { FieldGroup, FieldSet, ActionLink } from 'app/_shared'

import InnerForm from './InnerForm'
import * as service from '../../service'
import styles from './PromoCodeInput.module.scss'
import { useBooking } from '../BookingContext'

export default ({ promoCode, promoCodeValid, className }) => {
  const [showInput, setShowInput] = useState(!promoCode || !promoCodeValid);
  const { orderState } = useBooking();

  const reset = useCallback(async submitForm => {
    // NOTE: we don't actually save over the promo code if it's invalid.
    promoCodeValid && await submitForm();
    setShowInput(true);
  }, [promoCodeValid]);

  if (orderState) { // NOTE: promo code is only editable when in a booking flow
    return (
      <div className={`${styles.promo} ${className}`}>
        <InnerForm
          initialValues={{ info: { id: orderState.info.id, promoCode: '' }}}
          submitEndpoint={service.submitPromoCode}
          onSubmit={(_, { setFieldValue }) => {
            setFieldValue('info.promoCode', '');
            setShowInput(false);
          }}
        >
          {({ values, isSubmitting, isInnerFormSubmitting, submitOnEnter, submitForm }) => showInput ? (
            <FieldGroup className={styles.input} collapse>
              <FieldSet 
                label="Promo code" 
                name="info.promoCode"
                disabled={isSubmitting}
                onKeyPress={submitOnEnter}
              />
              <ActionLink 
                appearance="plain button" 
                disabled={isSubmitting || !values.info.promoCode } 
                onClick={submitForm}
              >
                Apply
              </ActionLink>
            </FieldGroup> 
          ) : (
            <div className={`${styles.message} ${promoCodeValid ? styles.valid : ''}`}>
              { isInnerFormSubmitting ? (
                <span>Updating...</span> 
              ) : (
                <>
                  <span>Promo code <em>{promoCode}</em> { promoCodeValid ? 'applied' : 'is not valid' }.</span>
                  <ActionLink
                    appearance="link"
                    disabled={isSubmitting} 
                    onClick={() => reset(submitForm)}
                  >
                    { promoCodeValid ? 'remove' : 'reset' }
                  </ActionLink>
                </>
              )}
            </div>
          )}
        </InnerForm>
      </div>
    )
  }
  else if (promoCode && promoCodeValid) {
    return (
      <div className={`${styles.promo} ${className}`}>
        <div className={`${styles.message} ${styles.valid}`}>
          <span>Promo code <em>{promoCode}</em> applied.</span>
        </div>
      </div>
    )
  }
  else {
    return null;
  }
}
