import React from 'react'

import { ActionLink } from 'app/_shared'

import AdjustmentRemove from './AdjustmentRemove'
import styles from './SummaryFinanceLine.module.scss'

function formatCents(cents) {
  return `${cents < 0 ? '-' : ''}$${(Math.abs(cents) / 100).toFixed(2)}`
}

export default ({ orderId, adjustmentId, label, amount, newAmount, voided, cancellation, className, final }) => (
  <div className={`${styles.line} ${final ? styles.final : ''} ${className || ''}`} data-voided={voided && typeof newAmount !== 'number'}>
    <span className={styles.title}>{label}</span>
    <span className={styles.amount} data-voided={voided && typeof newAmount === 'number'}>{typeof amount === 'number' ? formatCents(amount) : ''}</span>
    { newAmount !== undefined &&
      <span className={styles.amount}>{typeof newAmount === 'number' ? formatCents(newAmount) : ''}</span>
    }
    { orderId && 
      <AdjustmentRemove className={styles.button} orderId={orderId} adjustmentId={adjustmentId} />
    }
    { cancellation &&
      <ActionLink className={styles.button} onClick={cancellation.toggle} type="button" appearance="admin button" disabled={!cancellation.toggle}>
        { cancellation.waived ? "Reset" : "Waive"}
      </ActionLink>
    }
  </div>
)