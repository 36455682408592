import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { ActionLink } from 'app/_shared'

import styles from './Modal.module.scss'

export default ({ open, close, className = '', children }) => {
  const [shouldRender, setShouldRender] = useState(open);
  const [childrenState, setChildrenState] = useState(children);

  useEffect(() => {
    if (open) {
      setChildrenState(children);
    }
  }, [open, children]);

  useEffect(() => {
    let handler = undefined;
    if (!open) {
      handler = setTimeout(() => setShouldRender(false), 250);
      return () => clearTimeout(handler);
    }
    else {
      setShouldRender(true);
    }
  }, [open]);

  useEffect(() => {
    if (close && open) {
      const onEscape = e => e.key === 'Escape' && close();
      document.addEventListener('keydown', onEscape);
      return () => document.removeEventListener('keydown', onEscape);
    }
  }, [open, close]);

  return !shouldRender ? null : ReactDOM.createPortal((
    <div className={styles.modal} inert={open ? undefined : ''}>
      <ActionLink className={styles.overlay} onClick={close}></ActionLink>
      <div className={`${styles.content} ${className}`}>
        {childrenState}
      </div>
    </div>
  ), document.body);
}
