import React, { useEffect, useReducer, useCallback, useContext, useMemo } from 'react'

import { useFlags } from 'app/_shared'
import { useActiveUser } from 'app/user'

import { useRoute } from './RouteContext'
import * as service from '../service'

const findMismatch = (params, stateParams) => Object.keys(params).filter(t => !stateParams || stateParams[t] !== params[t]);

const updateCache = (cache, newListings) => {
  return newListings.reduce((map, listing) => {
    map[listing.tourName] = listing;
    return map;
  }, cache)
}

const reducer = (state, { type, tourName, listing, photos, newFloors, result, controller, params }) => {
  switch (type) {
    case 'updateListing':
      return {
        ...state,
        cache: {
          ...state.cache,
          [tourName]: listing
        }
      };
    case 'updateListingPhotos':
      return {
        ...state,
        cache: {
          ...state.cache,
          [tourName]: {
            ...state.cache[tourName],
            photos
          }
        }
      };
    case 'addListingFloors':
      return {
        ...state,
        cache: {
          ...state.cache,
          [tourName]: {
            ...state.cache[tourName],
            floorplan: {
              ...state.cache[tourName].floorplan,
              floors: [
                ...(state.cache[tourName].floorplan?.floors ?? []),
                ...newFloors
              ]
            }
          }
        }
      };
    case 'clear':
      // NOTE: params indicate the "signature" of the API call to the server
      // to retrieve listings for the current controller.
      const mismatch = findMismatch(params, state[controller].params);
      return mismatch.length === 0 ? state : {
        ...state,
        [controller]: {
          tourNames: null,
          params,
          // If the only change is page number, then retain the pagination state so
          // the pagination controls continue to show.
          pagination: mismatch.length === 1 && mismatch[0] === 'pageNumber' ? {
            ...state[controller].pagination,
            pageNumber: params.pageNumber
          } : undefined
        }
      };
    case 'load':
      // NOTE: the reducer's state argument is the *latest* state. Here we proceed only if the
      // params "signature" of the dispatch still matches what's in the latest state.
      return findMismatch(params, state[controller].params).length > 0 ? state : {
        ...state,
        [controller]: {
          tourNames: result.listings.map(t => t.tourName),
          params,
          // NOTE: pageNumber and pageSize are subject to range checking inside the API,
          // and may be different in the result compared to what was requested.
          pagination: {
            totalCount: result.totalCount,
            pageNumber: result.pageNumber,
            pageSize: result.pageSize
          }
        },
        cache: updateCache(state.cache, result.listings)
      }
    default: return state;
  }
}

const ListingDataContext = React.createContext();

export default function ListingDataContextProvider({ children }) {
  // NOTE: searchTerms is memoized based on the URL query string
  const { controller, tourName, searchTerms } = useRoute();

  const activeUserEmail = useActiveUser()?.email;
  const [state, dispatch] = useReducer(reducer, { explore: {}, my: {}, cache: {}});
  const { debugMode } = useFlags();

  const refreshActiveListing = useCallback(() => {
    let abort = false;
    if (tourName) {
      service.getListing(tourName, debugMode).then(listing => {
        !abort && dispatch({ type: 'updateListing', tourName, listing });
      }, () => {
        !abort && dispatch({ type: 'updateListing', tourName, listing: 'NOT_FOUND' });
      });
    }
  }, [tourName, debugMode]);

  const updateListingPhotos = useCallback((tourName, photos) => {
    dispatch({ type: 'updateListingPhotos', tourName, photos });
  }, []);

  const addFloors = useCallback((tourName, newFloors) => {
    dispatch({ type: 'addListingFloors', tourName, newFloors });
  }, []);

  // When the active listing or user attributes change, explicitly refresh the active listing from the server.
  useEffect(refreshActiveListing, [refreshActiveListing, activeUserEmail]);

  useEffect(() => {
    if (['my', 'explore'].includes(controller)) {
      // TODO: if state[controller].params and state[controller].pagination differ in pageNumber and pageSize (i.e., API enforced bounds),
      // then update the URL but without triggering a refetch
      const params = { activeUserEmail, ...searchTerms };
      dispatch({ type: 'clear', controller, params });

      let abort = false;
      const retrieve = controller === 'explore' ? service.getFeaturedListings : activeUserEmail ? service.getMyListings : () => Promise.resolve({ listings: [] });
      retrieve(searchTerms).then(result => !abort && dispatch({ type: 'load', result, controller, params }));
      return () => abort = true;
    }
  }, [controller, activeUserEmail, searchTerms]);

  const contextValue = useMemo(() => ({
    ...state,
    refreshActiveListing,
    updateListingPhotos,
    addFloors
  }), [state, refreshActiveListing, updateListingPhotos, addFloors])

  return (
    <ListingDataContext.Provider value={contextValue}>
      { children }
    </ListingDataContext.Provider>
  )
}

export const useListingData = () => useContext(ListingDataContext);
