import React, { useCallback, useEffect, useState } from 'react'

import { Modal, ModalHeader, AddressBlock } from 'app/_shared'
import { OrderChange } from 'app/booking'
import { useListing } from 'app/listing'
import { useRoute } from 'app/layout'

import { useDashboard } from './DashboardContext'
import styles from './ChangeModal.module.scss'

export default ({ onSuccess }) => {
  const { modal, layoutNav: { go }} = useRoute();
  const { listing } = useListing();
  const { orders } = useDashboard();

  // Modal only opens if the order ID in the url matches one of the orders loaded
  // in the dashboard.
  // NOTE: orderId is stored in state so that the modal remains open when the dashboard
  // order list reloads after a successful cancellation or reschedule.
  const [op, orderIdStr, date] = modal?.split('_') ?? [];
  const [orderId, setOrderId] = useState();
  useEffect(() => {
    if (!orderIdStr || (op !== 'reschedule' && op !== 'cancel')) {
      setOrderId(undefined);
    }
    else if (orders) {
      const currentOrder = orders.find(t => t.info.id === parseInt(orderIdStr));
      currentOrder && setOrderId(currentOrder.info.id);
    }
  }, [orderIdStr, op, orders]);

  const close = useCallback(() => go({ modal: '' }), [go]);

  return (
    <Modal
      open={!!orderId} 
      close={close} 
      className={styles.modal}
    >
      <ModalHeader close={close}>
        <h2>Change appointment for</h2>
        <AddressBlock className={styles.address} address={listing.address} />
      </ModalHeader>

      <OrderChange
        orderId={orderId}
        initialDate={date}
        op={op}
        className={styles.content} 
        close={close}
        onSuccess={onSuccess}
      />
    </Modal>
  );
}
