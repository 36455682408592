import React from 'react'
import { Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

import { ActionLink, FieldSet, Popup } from 'app/_shared'

import { useListing } from '../../ListingContext'
import mockup from './Twitter.jpg'
import styles from './Twitter.module.scss'
import tabStyles from '../index.module.scss'

const data =   {
  id: 'twitter',
  icon: faTwitter,
  background: 'linear-gradient(to bottom right, #38A1F3, #3eabff)'
};

const Twitter = ({ tourUrl, divProps }) => {
  const { listing } = useListing();
  const unit = listing?.address?.unit;
  const street = listing?.address?.street;
  const text = (unit ? `Unit ${unit}, ` : '') + street;

  return (
    <div {...divProps}>
      <h3>Share a tweet</h3>
      <div className={tabStyles.content}>
        <Formik
          initialValues={{ tweet: `Visit a 24/7 open house of ${text} in Realvision:` }}
        >
          { formik => (
            <>
              <p>
                Enter your tweet (we've included a link back to this Realvision listing):
                <FieldSet 
                  className={styles.input} 
                  name="tweet" 
                  component="textarea" 
                  autoResize 
                  maxLength={280 - 24}
                  rows={1}
                  after={(
                    <>
                      <span className={styles.link}>
                        { tourUrl }
                      </span>
                      <span className={styles.count}>
                        {formik.values.tweet.length + 24} of 280
                      </span>
                    </>
                  )}
                />
              </p>
              <Popup
                width={550} 
                height={420} 
                url={`http://twitter.com/intent/tweet?text=${encodeURIComponent(formik.values.tweet + '\n' + tourUrl)}`} 
                render={open => (
                  <ActionLink 
                    type="button"
                    appearance="button"
                    onClick={open}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} /> Tweet
                  </ActionLink>
                )}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  )
}

Twitter.Preview = ({ title, description, image }) => {
  return (
    <div className={`${styles.stage} ${tabStyles.mockup}`} style={{ backgroundImage: `url(${mockup})`}}>
      <img src={image} alt=""/>
      <div className={styles.metadata}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.site}>real.vision</div>
      </div>
    </div>
  )
}

Twitter.data = data;
export default Twitter;