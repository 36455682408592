import React, { useEffect, useState, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { useForwardedRef, SizeSensor } from 'app/_shared'
import styles from './Photo.module.scss'

// This is the width of image returned from the server when there is no size suffix,
// which is used as the preview background image.
const DEFAULT_IMAGE_WIDTH = 768;
const loadImage = (src) => new Promise(resolve => {
  const img = new Image();
  img.onload = resolve;
  img.src = src;
});

export default React.forwardRef(({ photoId, aspect, cover, dynamic, url, active, style, className = '' }, forwardedRef) => {
  const ref = useForwardedRef(forwardedRef);
  const [width, setWidth] = useState(DEFAULT_IMAGE_WIDTH);
  const [loadedUrl, setLoadedUrl] = useState();

  const updateWidth = useCallback(({ reset }) => {
    setWidth(oldWidth => {
      const { clientWidth, clientHeight } = ref.current;
      let width = aspect > 0 ? Math[cover ? 'max' : 'min'](clientWidth, clientHeight * aspect) : clientWidth;
      width = Math.ceil(width * window.devicePixelRatio / 256) * 256;
      return Math.max(width, (reset ? DEFAULT_IMAGE_WIDTH : oldWidth));
    })
  }, [aspect, cover, ref]);

  const fullUrl = width > DEFAULT_IMAGE_WIDTH ? `${url}&size=${width}x*` : undefined;

  // Whenever the version URL changes, re-evaluate the required image width
  useEffect(() => {
    updateWidth({ reset: true });
  }, [url, updateWidth]);

  // Unload the image immediately when the photo changes. Otherwise keep old version displayed.
  useEffect(() => {
    setLoadedUrl(undefined);
  }, [photoId]);

  // Whenever the version URL changes, start loading the new preview image
  useEffect(() => {
    let abort = false;
    loadImage(url).then(() => !abort && setLoadedUrl(url));
    return () => abort = true;
  }, [url]);

  // Load the full image if needed, but only if already showing the same photo 
  // (i.e., no earlier than after preview has completed loading)
  useEffect(() => {
    if (fullUrl && loadedUrl?.startsWith(url)) {
      let abort = false;
      loadImage(fullUrl).then(() => !abort && setLoadedUrl(fullUrl));
      return () => abort = true;
    }
  }, [url, loadedUrl, fullUrl]);

  return (
    <SizeSensor
      ref={ref}
      onResize={dynamic && updateWidth}
      className={`${styles.container} ${cover ? styles.cover : undefined} ${className}`}
      style={{ backgroundImage: `url(${loadedUrl})`, ...style }}
    >
      { active && loadedUrl && !loadedUrl.startsWith(url) && (
        // Show a throbber if photo is showing a previous version while the current version loads
        <span className={styles.loading}>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </span>
      )}
    </SizeSensor>
  )
});
