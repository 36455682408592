import React from 'react'
import { faCode, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ClickToCopy } from 'app/_shared'

import styles from './Embed.module.scss'
import tabStyles from '../index.module.scss'

const data = {
  id: 'embed',
  icon: faCode,
  background: 'linear-gradient(to top left, var(--gray-700), var(--gray-600))'
}

const Link = ({ tourUrl, divProps }) => {
  return (
    <div {...divProps}>
      <h3>Embed on your website</h3>
      <div className={tabStyles.content}>
        <p>
          <strong>Click below</strong> to copy the HTML code for embedding this Realvision tour in another website:
        </p>
        <ClickToCopy className={styles.copy} value={`<iframe src="${tourUrl}" width="100%" height="720px" frameborder="0" allowfullscreen></iframe>`} />
        <p className={tabStyles.tip}>
          <FontAwesomeIcon icon={faInfoCircle} />
          Depending on your website's specific layout, you may need to tweak the width and height attributes for 
          optimal results. Your website developer or administrator may be able to help.
        </p>
      </div>
    </div>
  )
}

Link.data = data;
export default Link;