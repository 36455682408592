import React from 'react'

import { FieldGroup, FieldSet, ActionLink, wrapAdminElement } from 'app/_shared'
import InnerForm from './InnerForm'
import * as service from '../../service'

import styles from './OwnershipInput.module.scss'

export default ({ className, orderId, ownerEmail }) => wrapAdminElement(
  <InnerForm
    initialValues={{ orderId, ownerEmail }}
    submitEndpoint={service.updateOwner}
    onSubmit={(order, { setFieldValue }) => {
      // If the submitted value is updated to a different value by the server than the one submitted,
      // (i.e., email does not match a user), then set the field to what the server saved the value as.
      setFieldValue('ownerEmail', order.info.ownerEmail);
    }}
  >
    {({ isSubmitting, submitForm, submitOnEnter }) => (
      <div className={`${styles.owner} ${className || ''}`}>
        <FieldGroup collapse>
          <FieldSet 
            admin
            label="Owner email"
            name="ownerEmail"
            disabled={isSubmitting}
            onKeyPress={submitOnEnter}
          />
          <ActionLink 
            appearance="admin button" 
            disabled={isSubmitting} 
            onClick={submitForm}
          >
            Assign
          </ActionLink>
        </FieldGroup> 
      </div>
    )}
  </InnerForm>
)
