import React, { useEffect, useRef } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { TabBox, Throbber, recordEvent } from 'app/_shared'
import { UserChangeListener, useCookieAccess } from 'app/user'

import { Submit } from './_shared'
import BookingContextProvider, { useBooking, BookingSteps } from './BookingContext'
import { useAddressInput } from './AddressInputContext'
import Progress from './Progress'
import Address from './Address'
import Product from './Product'
import Schedule from './Schedule'
import Contact from './Contact'
import Confirm from './Confirm'
import Complete from './Complete'
import IframeRedirect from './IframeRedirect'

import styles from './index.module.scss'

const contactEntryShape = {
  firstName:Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  role: Yup.string().required('Required'),
  email: Yup.string().email('Invalid').required('Required'),
  phone: Yup.string().when('role', {
    is: 'Listing Agent',
    then: Yup.string().required('Required')
  }),
  company: Yup.string().when('role', {
    is: 'Listing Agent',
    then: Yup.string().required('Required')
  })
};

const validationSchema = {
  address: Yup.object().shape({
    userAddress: Yup.string().required('Required'),
    location: Yup.object().shape({
      formattedAddress: Yup.string().required('Required'),
      latitude: Yup.string().required('Required'),
      longitude: Yup.string().required('Required')
    }),
    marker: Yup.object().shape({
      latitude: Yup.string().required('Required'),
      longitude: Yup.string().required('Required')
    })
  }),
  product: Yup.object().shape({
    // NOTE: We are requiring that all products have a quanity.
    // Since we are not offering multiple products,
    // this validation logic will work for now
    products: Yup.array().of(Yup.object().shape({
      quantity: Yup.number().typeError('Invalid')
      .positive('Invalid')
      .max(10000, 'Invalid')
      .required('Required'),
    })).min(1).required()
  }),
  schedule: Yup.object().shape({
    // NOTE: A selected photographer is not required
    selectedTime: Yup.string().required('Required'),
  }),
  contact: Yup.object().shape({
    billingContact: Yup.object().shape({
      ...contactEntryShape,
      phone: Yup.string().required('Required')
    }),
    additionalContacts: Yup.array().of(
      Yup.object().shape(contactEntryShape)
    )
  }),
  confirm: Yup.object()
};

function mapOrderStateToForm(order) {
  function getEmptyIfNull(value) {
    return value === undefined || value === null ? "" : value;
  }

  // This function maps the order state as obtained from the API, into a form which matches
  // what each step's submission API endpoints expect as input.
  return {
    info: order.info && { // Note: info is submitted when submitting any step.
      id: order.info.id,
      promoCode: getEmptyIfNull(order.info.promoCode),
      ownerEmail: getEmptyIfNull(order.info.ownerEmail),
      isAdmin: order.info.isAdmin
    },

    address: order.address && {
      userAddress: getEmptyIfNull(order.address.userAddress),
      location: order.address.location && {
        formattedAddress: order.address.location.formattedAddress,
        latitude: order.address.location.latitude,
        longitude: order.address.location.longitude,
        address1: order.address.location.address1,
        address2: getEmptyIfNull(order.address.location.address2),
        city: order.address.location.city,
        state: order.address.location.state,
        postalCode: order.address.location.postalCode,
        country: order.address.location.country
      },
      marker: order.address.marker && {
        latitude: order.address.marker.latitude,
        longitude: order.address.marker.longitude
      }
    },

    product: order.product && {
      products: order.product.products && order.product.products.map(t => ({
        id: t.id,
        quantity: t.quantity || ''
      }))
    },

    schedule: order.schedule && {
      selectedPhotographerId: order.schedule.selectedPhotographerId,
      selectedTime: order.schedule.selectedTime,
      travelCost: order.schedule.travelCost,
      cancellationPolicyId: order.schedule.cancellationPolicyId,
      waitlistEnabled: order.schedule.waitlistEnabled,
      ignoreAvailability: order.schedule.ignoreAvailability,
      unavailableTimeSelected: order.schedule.unavailableTimeSelected
    },

    contact: order.contact && {
      billingContact: order.contact.billingContact && {
        firstName: getEmptyIfNull(order.contact.billingContact.firstName),
        lastName: getEmptyIfNull(order.contact.billingContact.lastName),
        company: getEmptyIfNull(order.contact.billingContact.company),
        role: getEmptyIfNull(order.contact.billingContact.role),
        address: getEmptyIfNull(order.contact.billingContact.address),
        unit: getEmptyIfNull(order.contact.billingContact.unit),
        email: getEmptyIfNull(order.contact.billingContact.email),
        phone: getEmptyIfNull(order.contact.billingContact.phone)
      },
      additionalContacts: order.contact.additionalContacts.map(t => ({
        firstName: getEmptyIfNull(t.firstName),
        lastName: getEmptyIfNull(t.lastName),
        company: getEmptyIfNull(t.company),
        role: getEmptyIfNull(t.role),
        email: getEmptyIfNull(t.email),
        phone: getEmptyIfNull(t.phone)
      })),
      specialInstructions: getEmptyIfNull(order.contact.specialInstructions)
    },

    confirm: order.confirm && {
      productTotal: order.confirm.productTotal,
      discountTotal: order.confirm.discountTotal,
      travelTotal: order.confirm.travelTotal,
      priceAdjustmentsTotal: order.confirm.priceAdjustmentsTotal,
      taxTotal: order.confirm.taxTotal
    }
  }
}

const Booking = ({ className }) => {
  const containerRef = useRef();
  const { activeStep, orderState } = useBooking();
  const formValues = mapOrderStateToForm(orderState);
  const { setLocationFromBooking, setBookingStep } = useAddressInput();

  const marker = formValues.address && formValues.address.marker;
  const lat = marker && marker.latitude;
  const lng = marker && marker.longitude;

  useEffect(() => {
    if (activeStep && !isNaN(lat) && !isNaN(lng)) {
      setLocationFromBooking({ lat, lng });
    }
  }, [lat, lng, activeStep, setLocationFromBooking]);

  useEffect(() => {
    setBookingStep(activeStep);
    containerRef.current.scroll({ top: 0, behavior: 'smooth' });
    return () => setBookingStep(undefined);
  }, [activeStep, setBookingStep]);

  const orderId = orderState?.info?.id;
  useEffect(() => {
    activeStep && orderId && recordEvent('orderProgress', {activeStep, orderId})
  }, [activeStep, orderId]);

  return (
    <div className={`${styles.container} ${className}`} ref={containerRef}>
      <Throbber active={!activeStep} />
      { activeStep &&
        // NOTE: the entire booking flow is one single Formik, with the validation schema dynamically changing depending on what step we're on.
        // This is to enable the use of Formik data (isSubmitting, isValid, as well as form values) in real time in components like the Progress component
        // indicating the current step, and to be able to reuse components like Submit.
        <Formik
          initialValues={formValues}
          enableReinitialize
          validateOnMount
          onSubmit={undefined} // NOTE: to allow submission bypassing validation, we're not using Formik's default submission. See _shared/Submit.js
          validationSchema={Yup.object().shape({[activeStep]: validationSchema[activeStep]})}
        >
          <Form className={styles.window}>
            { activeStep !== BookingSteps.COMPLETE && (
              <Progress activeStep={activeStep} />
            )}
            <TabBox className={styles.steps} activeTab={activeStep} center>
              <Address tabkey={BookingSteps.ADDRESS} />
              <Product tabkey={BookingSteps.PRODUCT} />
              <Schedule tabkey={BookingSteps.SCHEDULE} />
              <Contact tabkey={BookingSteps.CONTACT} />
              <Confirm tabkey={BookingSteps.CONFIRM} />
              <Complete tabkey={BookingSteps.COMPLETE} />
            </TabBox>
            <Submit render={({ submitForm }) => (
              <UserChangeListener onUserChange={submitForm} />
            )} />
          </Form>
        </Formik>
      }
    </div>
  )
}

export default ({ className }) => {
  const { hasCookieAccess } = useCookieAccess();

  useEffect(() => {
    console.log({ hasCookieAccess });
  }, [hasCookieAccess]);

  return hasCookieAccess ? (
    <BookingContextProvider>
      <Booking className={className} />
    </BookingContextProvider>
  ) : (
    <IframeRedirect />
  )
}
