import React, { useEffect } from 'react'
import { useForwardedRef } from '.'
import ResizeObserver from 'resize-observer-polyfill'

export default React.forwardRef(({ children, onResize, ...rest }, forwardedRef) => {
  const ref = useForwardedRef(forwardedRef);
  useEffect(() => {
    if (typeof onResize === 'function') {
      const ro = new ResizeObserver(() => onResize(ref.current));
      ro.observe(ref.current);
      return () => ro.disconnect();
    }
  }, [onResize, ref]);

  return (
    <div ref={ref} {...rest}>
      { children }
    </div>
  )
});
