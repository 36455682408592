import React, { useCallback } from 'react'

import { usePhotos } from '../PhotosContext'
import { useListing } from '../../ListingContext'
import Button from './Button'
import { downloadZip } from '../../../service'

export default function DownloadButton({ size, icon, children }) {  
  const { listing: { tourName } } = useListing();
  const { visiblePhotos, activeIndex } = usePhotos();
  const activePhoto = visiblePhotos[activeIndex];
  const baseUrl = activePhoto?.url;

  const getPhoto = useCallback(async () => {
    window.location.href = `${baseUrl}&size=${size}&filename=${size}_${activePhoto.name}`;
    await new Promise(resolve => setTimeout(resolve, 2000));
  }, [activePhoto, size, baseUrl]);

  const getZip = useCallback(async () => {
    await downloadZip(tourName, size);
  }, [tourName, size]);

  return (
    <Button 
      id={`${tourName}/${activePhoto?.code}`} 
      icon={icon} 
      onClick={activePhoto ? getPhoto : getZip} 
      singleUse
    >
      { children }
    </Button>
  )
}