import React from 'react'
import moment from 'moment-timezone'

import styles from './Status.module.scss'

export default ({ events, className }) => {
  if (!events || !events.length) {
    return null;
  }

  // Get earliest event ending later than now; if none, then latest event ended prior to now.
  // NOTE: appointments are evaluated by end date here, rather than start date, so that
  // a currently occuring appointment will count as a future event. All other events have the same start and end time.
  let primaryEvent = events.filter(t => !t.ended)[0] || events.filter(t => t.ended).reverse()[0];

  // NOTE: time zone is supplied by the API only for appointment times and is displayed in local time. 
  // All other times are supplied without a timeZone property and is therefore displayed in the user's device's time zone.
  let date = primaryEvent.timeZone ? moment.tz(primaryEvent.start, primaryEvent.timeZone) : moment(primaryEvent.start);

  return (
    <div className={`${styles.status} ${styles[primaryEvent.type]} ${className || ''}`}>
      <span>
        <span className={styles.type}>{ 
          primaryEvent.type 
        }</span> on <strong>{
          date.format('ll')
        }</strong>{ primaryEvent.type !== 'delivery' && 
          <>
            {' at '} <strong>{date.format('h:mm a')}</strong>
          </>
        }
      </span>
    </div>
  )
}