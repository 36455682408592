import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCloudDownloadAlt, faPlay } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, useFlags, useUnitPreference } from 'app/_shared'

import { useListing, Section, ListingLink } from '../ListingContext'
import Photo from '../PhotosSection/Photo'

import styles from './Media.module.scss'

export default ({ className }) => {
  const { listing, enabledSections } = useListing();
  const tourPreview = listing.photos && (listing.photos.find(t => !t.hidden) || listing.photos[0]); // NOTE: if there are no photos, tour preview will be blank.
  const { unbranded } = useFlags();
  const { unitPreference } = useUnitPreference();
  const floorPlanPreviewUrl = listing.floorplan?.[`${unbranded ? 'un' : ''}brandedPagesUrl`]?.[0][unitPreference];
  const floorPlanPdfUrl = listing.floorplan?.[`${unbranded ? 'un' : ''}brandedPdfUrl`]?.[unitPreference];

  return (
    <div className={`${styles.media} ${className || ''}`}>
      { enabledSections.includes(Section.TOUR) &&
        <div className={styles.tour}>
          <h3>3D Tour</h3>
          <ListingLink to={{ section: Section.TOUR }} className={styles.image}>
            { tourPreview && 
              <Photo key={tourPreview.url} url={tourPreview.url} cover />
            }
            <div className={styles.overlay}>
              <FontAwesomeIcon icon={faPlay} />
            </div>
          </ListingLink>
        </div>
      }
      { enabledSections.includes(Section.FLOORPLAN) && 
        <div className={styles.floorplan}>
          <h3>Floor plan</h3>
          <ListingLink to={{ section: Section.FLOORPLAN }} className={styles.image}>
            <Photo url={floorPlanPreviewUrl} />
          </ListingLink>
          <div className={styles.buttons}>
            <ListingLink to={{ section: Section.FLOORPLAN }}>
              <FontAwesomeIcon icon={faSearch} /> Explore
            </ListingLink>
            <ActionLink to={floorPlanPdfUrl}>
              <FontAwesomeIcon icon={faCloudDownloadAlt} /> Download PDF
            </ActionLink>
          </div>
        </div>
      }
    </div>
  )
}