import React from 'react'
import { useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { ActionLink } from 'app/_shared'

import ScheduleForm from '../Schedule/ScheduleForm'
import styles from './SelectionStep.module.scss'

export default ({ orderId, orderData, newSelectionValid, close, next, showError }) => {
  const { isSubmitting } = useFormikContext();
  const { schedule, originalSchedule } = orderData;

  return (
    <div className={styles.selection}>
      <ScheduleForm 
        active
        orderId={orderId}
        photographers={schedule.photographers}
        originalSelectedTime={originalSchedule && originalSchedule.selectedTime}
        originalTravelCost={originalSchedule && originalSchedule.travelCost}
        errors={showError && schedule.errors}
      />
      <div className={styles.buttons}>
        { close &&
          <ActionLink 
            type="button" 
            appearance="plain button"
            disabled={isSubmitting} 
            className={styles.back} 
            onClick={close}
          >
            Close 
          </ActionLink>
        }
        <ActionLink 
          type="button" 
          appearance={`${newSelectionValid ? '' : 'plain '}button`} 
          disabled={isSubmitting || !newSelectionValid} 
          className={styles.next} 
          onClick={next}
        >
          Next <FontAwesomeIcon icon={faArrowRight} />
        </ActionLink>
      </div>
    </div>
  )
}
