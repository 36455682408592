import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useFormikContext } from 'formik'

import { AdminElement } from 'app/_shared'

import { getAppointmentTimes, formatDuration } from '../../service'
import UnavailableBlocks from './UnavailableBlocks'
import TimeSlots from './TimeSlots'
import styles from './TimeSelect.module.scss'

const getMajorityTimeZone = timeSlots => {
  return timeSlots && timeSlots.filter(t => !t.type).map(t => t.startTime.timeZoneName).reduce((acc, current) => {
    acc.counts[current] = (acc.counts[current] || 0) + 1;
    if (!acc.winner || acc.counts[acc.winner] < acc.counts[current]) {
      acc.winner = current;
    }
    return acc;
  }, { winner: null, counts: {} }).winner;
}

export default function TimeSelect({ orderId, active, selectedDate, photographers, selectedPhotographer, originalSelectedTime, originalTravelCost, divRef }) {
  const { values: { schedule, info }, isSubmitting, setFieldValue } = useFormikContext();
  const selectedTime = schedule && schedule.selectedTime;
  const selectedTimeUtc = selectedTime && selectedTime.utc;
  const scrollBehavior = useRef('auto');
  const [scheduleData, setScheduleData] = useState();

  const isAvailable = useCallback((slot) => {
    if (!selectedPhotographer) {
      return !slot.noAvailability;
    }

    const slotZones =  Object.keys(slot.zones);
    const photogZones = selectedPhotographer?.zones.map(z => z.id + "") ?? [];
    const overlapZones = slotZones.filter(a => photogZones.includes(a));
    return overlapZones.some(o => slot.zones[o] === true);
  }, [selectedPhotographer]);

  const selectSlot = useCallback((startTime, travelCost, smooth, unavailableTimeSelected) => {
    if (active && !isSubmitting) {
      scrollBehavior.current = smooth ? 'smooth' : 'auto';
      setFieldValue("schedule.selectedTime", startTime, true);
      setFieldValue("schedule.travelCost", travelCost, true);
      setFieldValue("schedule.unavailableTimeSelected", unavailableTimeSelected, true);
    }
  }, [active, isSubmitting, setFieldValue]);

  // Get appointment times when the user chooses a date.
  useEffect(() => {
    setScheduleData({});
    if (selectedDate && orderId) {
      let abort = false;
      getAppointmentTimes(orderId, selectedDate).then((result) => {
        if (!abort) {
          setScheduleData((({timeSlots, unavailableBlocks, searchRange}) => ({timeSlots, unavailableBlocks, searchRange}))(result));
        }
      });
      return () => abort = true;
    }
  }, [orderId, selectedDate]);

  const duration = (() => {
    if (!scheduleData?.timeSlots) {
      return undefined;
    }
    // if photographer is selected and is available at the selected time, or is available for all displayed times,
    // or ignoreAvailability options is selected then display the selected photographer's duration.
    const selectedTimeSlot = scheduleData.timeSlots.find(x => x.startTime.utc === selectedTimeUtc);
    if (selectedPhotographer && (schedule?.ignoreAvailability
        || scheduleData.timeSlots.every(s => isAvailable(s))
        || (selectedTimeSlot && isAvailable(selectedTimeSlot)))) {
      return selectedPhotographer.appointmentDuration;
    } else {
      const zoneIds = scheduleData.timeSlots.reduce((all, slot) => [
        ...all,
        ...((!selectedTimeUtc || slot.startTime.utc === selectedTimeUtc) ? Object.keys(slot.zones).filter(z => slot.zones[z] === true) : [])
      ], []);
      // Display the maximum duration of all the photographers represented in the above zones.
      const applicablePhotographers = photographers.filter(t => t.zones.find(s => zoneIds.includes(s.id + "")));
      return applicablePhotographers.length > 0 ? Math.max(...applicablePhotographers.map(t => t.appointmentDuration)) : undefined;
    }
  })();

  return (
    <div className={styles.time} ref={divRef}>
      <div className={styles.duration}>
        { duration && (
          <>
            Your appointment will take up to <span>{formatDuration(duration)}</span>.
          </>
        )}
      </div>
      <div className={styles.scrollWrapper}>
        <div className={styles.slotWrapper}>
        <TimeSlots
          className={styles.slots}
          isDateSelected={!!selectedDate}
          selectedTime={selectedTime}
          selectSlot={selectSlot}
          timeSlots={scheduleData?.timeSlots}
          photographers={photographers}
          selectedPhotographer={selectedPhotographer}
          isAvailable={isAvailable}
          scrollBehavior={scrollBehavior}
          originalSelectedTime={originalSelectedTime}
          originalTravelCost={originalTravelCost} />
        { info.isAdmin && selectedPhotographer && scheduleData?.unavailableBlocks &&
          <AdminElement>
            <UnavailableBlocks
              className={styles.unavailableBlocks}
              unavailableBlocks={scheduleData?.unavailableBlocks[selectedPhotographer.id]}
              searchRange={scheduleData?.searchRange} />
          </AdminElement>
        }
        </div>
      </div>

      <div className={styles.timezone}>
        { selectedTime ? selectedTime.timeZoneName : getMajorityTimeZone(scheduleData?.timeSlots) }
      </div>
    </div>
  )
}
