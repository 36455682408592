import { useEffect, useRef } from 'react'
import { useActiveUser } from './UserContext'

// TODO: This class is a bit of a hack. Try to refactor its only user.
export default ({ onUserChange }) => {
  const activeUserString = JSON.stringify(useActiveUser());
  const isInitial = useRef(true);
  const action = useRef();
  action.current = onUserChange;

  useEffect(() => {
    !isInitial.current && action.current();
    isInitial.current = false;
  }, [activeUserString]);

  return null;
}
