import React from 'react'
import { MorphBox } from 'app/_shared'
import styles from './Error.module.scss'

export default ({ children, className }) => (
  <MorphBox className={styles.outer}>
    { children && 
      <div className={`${styles.error} ${className}`}>{children}</div> 
    }
  </MorphBox>
)