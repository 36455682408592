import React from 'react'
import { useFormikContext } from 'formik'

import { MorphBox } from 'app/_shared'

import styles from './UserContext.module.scss'

export default () => {
  const { status } = useFormikContext();
  return (
    <MorphBox className={styles.error} collapseDirection="height">
      { status && status.error && 
        <p>{status.error}</p> 
      }
    </MorphBox>
  )
}
