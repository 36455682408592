export const apiKey = process.env.GOOGLE_MAPS_KEY;

let clientPromise = undefined;
export const getClient = () => {
  if (!clientPromise) {
    clientPromise = new Promise(resolve => {
      if (typeof window !== 'undefined') {
        const loadGoogleMapsApi = require('load-google-maps-api');
        loadGoogleMapsApi({ key: apiKey, libraries: ['places', 'geometry']}).then(api => resolve(api));
      }
      else {
        resolve({});
      }
    });
  }
  return clientPromise;
}
