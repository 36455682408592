import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ActionLink } from 'app/_shared'

import Tabs from './Tabs'
import styles from './TabSelector.module.scss'

export default ({ activeTab, setActiveTab, className }) => {
  return (
    <div className={`${styles.header} ${className || ''}`}>
      { Tabs.map(t => (
        <ActionLink
          key={t.data.id}
          className={`${styles.tab} ${activeTab === t.data.id ? styles.active : ''}`} 
          onClick={() => setActiveTab(t.data.id)}
          style={{'--background': t.data.background}}
        >
          <FontAwesomeIcon icon={t.data.icon} />
        </ActionLink>
      ))}
    </div>
  )
}