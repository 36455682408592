import React from 'react'
import clsx from 'clsx';
import { DateTime }  from 'luxon';

import styles from './UnavailableBlocks.module.scss'

export default function UnavailableBlocks({ className, unavailableBlocks, searchRange }) {
  const searchStart = DateTime.fromISO(searchRange.start.utc);
  const searchEnd = DateTime.fromISO(searchRange.end.utc);

  return (
    <div className={clsx(styles.list, className)}>
      {!!unavailableBlocks && unavailableBlocks.filter(u => searchEnd > DateTime.fromISO(u.startTime.utc)).sort((a, b) => a.startTime.utc.localeCompare(b.startTime.utc)).map((block, i) => {
        const blockStart = DateTime.fromISO(block.startTime.utc);
        const blockEnd = DateTime.fromISO(block.endTime.utc);
        const elementHeight = (DateTime.min(blockEnd, searchEnd) - blockStart) / (searchEnd - searchStart) * 100 + '%';
        const elementTop = (blockStart - searchStart) / (searchEnd - searchStart) * 100 + '%';
        const description = `${block.description} ${DateTime.fromFormat(block.startTime.localTime, "H:m").toFormat('h:mm a')} - ${DateTime.fromFormat(block.endTime.localTime, "H:m").toFormat('h:mm a')}`;

        return (
          <div className={clsx(styles.unavailable)} key={i} style={{height: elementHeight, top: elementTop}} title={description}>
            <div className={clsx(styles.badge, styles[block.type])} />
          </div>
        );
      })}
    </div>
  )
}
