import React, { useEffect } from 'react'
import { Hint, useFlags, useHint } from 'app/_shared'
import { useTour } from './TourContext'
import styles from './TourHints.module.scss'
import clsx from 'clsx'

export default ({ wideLayout, isSidebar, hasThumbnails, floatingFloorPlanActive }) => {
  const { disableAutoplay } = useFlags();
  const { tour, tourLoaded } = useTour();
  const { dismiss, queuedHints } = useHint();

  // Set up listener for dismissing the tourDrag hint. Remove the listener
  // when it's no longer needed (since it fires very frequently)
  const tourDragQueued = queuedHints.includes('tourDrag');
  useEffect(() => {
    if (tour && tourLoaded && dismiss && tourDragQueued) {
      const handler = ({ data }) => {
        if (Math.abs(data.dx) > 1 || Math.abs(data.dy) > 1) {
          dismiss('tourDrag');
        }
      }
      tour.addEventListener('dragmove', handler);
      return () => tour.removeEventListener('dragmove', handler);
    }
  }, [tour, tourLoaded, dismiss, tourDragQueued]);

  // Set up listener for dismissing the tourMove hint; remove when not needed.
  const tourMoveQueued = queuedHints.includes('tourMove');
  useEffect(() => {
    if (tour && tourLoaded && dismiss && tourMoveQueued) {
      const clickHandler = ({ data }) => data.targetScene && dismiss('tourMove');
      const wheelHandler = ({ data }) => data.targetScene && dismiss('tourMove');

      tour.addEventListener('click', clickHandler);
      tour.addEventListener('wheel', wheelHandler);

      return () => {
        tour.removeEventListener('click', clickHandler);
        tour.removeEventListener('wheel', wheelHandler);
      }
    }
  }, [tour, tourLoaded, dismiss, tourMoveQueued]);

  return (
    <>
      <Hint name="tourDrag" priority={2} className={`${styles.drag} ${isSidebar ? styles.sidebar : ''}`} position={isSidebar ? "right" : "top"}>
        Drag the image to look around.
      </Hint>

      <Hint name="tourMove" priority={3} className={`${styles.move} ${isSidebar ? styles.sidebar : ''}`} position={isSidebar ? "right" : "top"}>
        Click on arrows, rings, or a general direction you'd like to move.
      </Hint>

      { hasThumbnails && 
        <Hint name="tourThumbnail" priority={4} className={styles.thumbnail} position="top">
          Click on a shortcut to jump to that location.
        </Hint>
      }

      { !wideLayout && 
        <Hint name="tourOpenFloatingFloorPlan" priority={6} inactive={floatingFloorPlanActive} className={clsx(styles.floorplan, { [styles.nudge]: !disableAutoplay })} position={ floatingFloorPlanActive ? undefined : "top" }>
          Open the interactive floor plan here.
        </Hint>
      }

      { !disableAutoplay && 
        <Hint name="tourAutoplay" priority={7} className={styles.autoplay} position="top">
          Click here to start and stop automatic tour playback (like a video).
        </Hint>
      }

      <Hint name="tourRestart" final className={styles.restart} position="top">
        Click here any time to see the tips again.
      </Hint>
    </>
  );
}