import React from 'react'

import { ActionLink } from 'app/_shared'

import ChangeSummary from './ChangeSummary'
import styles from './CompleteStep.module.scss'

export default ({ originalTime, newTime, email, close }) => (
  <div className={styles.complete}>
    <ChangeSummary originalTime={originalTime} newTime={newTime} />
    <h2>{newTime ? 'Reschedule' : 'Cancellation'} successful.</h2>
    <p>Confirmation sent to <strong>{email}</strong>.</p>
    <ActionLink appearance="plain button" type="button" onClick={close}>
      Close
    </ActionLink>
  </div>
)