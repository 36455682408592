import React, { useRef, useMemo, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import { useListing, Section, ListingLink } from '../ListingContext'
import { useTour } from './TourContext'
import TourViewer from './TourViewer'
import ThumbnailStrip from './ThumbnailStrip'
import styles from './index.module.scss'

const Layout = {
  FULL: 'full',
  SIDEBAR: 'sidebar',
  INACTIVE: 'inactive'
};

const blink = (node, tour) => {
  return new Promise(resolve => {
    let transitionEnd = () => {
      node.removeEventListener('transitionend', transitionEnd);
      node.style.opacity = null;
      node.style.transition = null;
      tour.flyIn();
      resolve();
    }
    node.addEventListener('transitionend', transitionEnd);
    node.style.transition = 'opacity 0.15s';
    node.style.opacity = 0;
    tour.flyOut();
  });
}

export default ({ wideLayout, enableFloatingFloorPlan, className }) => {
  const ref = useRef();

  const { listing: { photos }, activeSection, targetPhoto } = useListing();
  const { tour, tourConfig } = useTour();

  const nextLayout = activeSection === Section.TOUR ? Layout.FULL
    : activeSection === Section.FLOORPLAN && wideLayout ? Layout.SIDEBAR
    : Layout.INACTIVE;
  const [renderedLayout, setRenderedLayout] = useState(nextLayout);

  const targetSceneCode = targetPhoto?.sceneCode;
  const targetPitch = targetPhoto?.pitch;
  const targetYaw = targetPhoto?.yaw;
  useEffect(() => {
    if (renderedLayout !== nextLayout) {
      let abort = false;
      // NOTE: the next desired layout is sometimes applied after desired transitions (i.e., flyOut) completes.
      // If target layout has changed during the transition then do not continue setting the Layout.
      new Promise(resolve => {
        if (!tour) {
          resolve();
        } 
        else if (nextLayout === Layout.INACTIVE) {
          tour.flyOut(resolve);
        }
        else if ((renderedLayout === Layout.SIDEBAR && nextLayout === Layout.FULL) || (renderedLayout === Layout.FULL && nextLayout === Layout.SIDEBAR)) {
          blink(ref.current, tour).then(resolve);
        }
        else {
          let parameters = targetSceneCode ? {
            pan: (tourConfig.scenesByCode[targetSceneCode]?.yaw ?? 0) - targetYaw,
            tilt: targetPitch,
            sceneCode: targetSceneCode
          } : undefined;
          tour.flyIn(parameters);
          resolve();
        }
      }).then(() => {
        !abort && setRenderedLayout(nextLayout);
      });
      return () => abort = true;
    }
  }, [renderedLayout, nextLayout, targetSceneCode, targetPitch, targetYaw, tour, tourConfig]);

  const thumbnails = useMemo(() => {
    return photos && photos.filter(t => t.caption && t.sceneCode);
  }, [photos]);

  return (
    <div 
      ref={ref} 
      className={`${styles.tour} ${className || ''}`}
      data-layout={renderedLayout}
      data-hidden={nextLayout === Layout.INACTIVE}
    >
      <TourViewer 
        className={styles.viewer}
        thumbnails={thumbnails}
        wideLayout={wideLayout}
        isSidebar={renderedLayout === Layout.SIDEBAR}
        enableFloatingFloorPlan={enableFloatingFloorPlan}
      />
      { thumbnails && thumbnails.length > 0 &&
        <ThumbnailStrip
          className={styles.thumbs}
          photos={thumbnails}
        />
      }
      { renderedLayout === Layout.SIDEBAR &&
        <ListingLink 
          appearance="white pill" 
          className={styles.expand} 
          to={{ section: Section.TOUR }}
        >
          3D Tour <FontAwesomeIcon icon={faExternalLinkAlt} />
        </ListingLink>
      }
    </div>
  )
}
