export default ({ width, height, url, render }) => {
  return render(() => {
    // We attempt to place the popup centered on the current browser window.
    const left = window.screenX + window.outerWidth / 2 - width / 2;
    const top = window.screenY + window.outerHeight / 2 - height / 2;

    // NOTE: the left and top parameters are relative to the top left corner of the *primary monitor*, and thus, if the window 
    // is in a secondary monitor which is above or left of the primary monitor (determined by the screenX and screenY properties,
    // which is the position of the brower window relative to the upper left corner of the *primary monitor*), 
    // the left and top parameter should be appropriately negative to center the popup. However, negative values are invalid in 
    // the window.open options (having negative values result in disregarding all parameters), and therefore we fall back to "moveTo"
    // for the new window, which unavoidably results sometimes in a flash before moveTo takes effect.
    const options = `${left > 0 && top > 0 ? `left=${left},top=${top},` : ''}width=${width},height=${height},toolbar=0,resizable=0`;
    window.open(url, '', options).moveTo(left, top);
  })
}