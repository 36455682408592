import React from 'react'

import { FieldSet } from 'app/_shared'

import Instruction from './Instruction'
import styles from './ProductItem.module.scss'

const getPriceForQuantity = (tiers, quantity) => {
  var tier = tiers.find(t => (t.min == null || t.min <= quantity) && (t.max == null || t.max > quantity));
  return tier ? tier.valueAtZero + quantity * tier.valuePerUnit : null;
}

export default ({
  product,
  instruction,
  name,
  value,
  highlight
}) => {
  let priceFinal = getPriceForQuantity(product.pricing.pricingTiers, value || 0);
  let priceOriginal = product.pricing.secondaryPricingTiers && getPriceForQuantity(product.pricing.secondaryPricingTiers, value || 0);

  return (
    <div className={styles.product}>
      { instruction &&
        // TODO: use instruction copy text from the product data
        <Instruction className={styles.quantityInstruction} pointing="down" caretPosition={{ left: "4.5em" }} highlight={highlight} >
          Enter your property&apos;s approximate gross area (including basement).
        </Instruction>
      }
      <div className={styles.inline}>
        <div className={styles.quantity}>
          { name ?
            // TODO: Use product.unitDescription for label once backend provides shorter name
            <FieldSet type="number" name={name} label="Gross Area" min={0} onChange={e => {
              const sanitized = e.target.value.replace(/\D/g,''); // remove all non-number characters
              e.target.value = sanitized && parseFloat(sanitized); // formats the number, i.e., strips leading zeros
            }} /> :
            <>
              <span className={styles.label}>Gross Area:</span>
              <span className={styles.value}>{value}</span>
            </>
          }
          <span className={styles.unit}>{product.unit}</span>
        </div>
        <div className={styles.arrow}></div>
        <div className={styles.price}>
          { typeof priceOriginal === 'number' && typeof priceFinal === 'number' && (priceOriginal > priceFinal) &&
            <span className={styles.original}>${priceOriginal.toFixed(2)}</span>
          }
          { priceFinal !== 0 ? (
            <span className={styles.final}>${priceFinal.toFixed(2)}</span>
          ) : (
            <span className={styles.free}>Exclusive</span>
          )}
        </div>
      </div>
      <Instruction className={styles.priceInstruction} pointing="up" caretPosition={{ right: "2.5em" }}>
        { product.description &&
          <p>{product.description}</p>
        }
        { product.pricing.description && product.pricing.description.split('\\n').map((t, index) =>
          <div key={index}>{t}</div>
        )}
      </Instruction>
    </div>
  )
}
