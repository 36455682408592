import React from 'react'
import { Link } from 'gatsby'

import styles from './ActionLink.module.scss'

export default React.forwardRef(({ to, target, type, onClick, appearance, className, disabled, children, ...rest }, ref) => {
  const appearances = (appearance || '').split(' ').filter(t => t.length > 0);

  let props = {
    className: [
      className,
      ...appearances.map(t => styles[t]),
      styles.actionLink
    ].join(' '),
    disabled: disabled || undefined,
    onClick: typeof onClick === 'function' ? onClick : undefined,
    ref,
    ...rest
  };

  let Component;

  if (to) {
    if (target || !to.startsWith('/')) {
      Component = 'a';
      props = { ...props, href: to, target };
      // NOTE: type are ignored
    }
    else {
      Component = Link;
      props = { ...props, to };
      // NOTE: type, target are ignored
    }
  }
  else if (type || (appearances.includes('button') && props.onClick)) {
    Component = 'button';
    props = { ...props, type };
    // NOTE: to, target are ignored
  }
  else {
    Component = "span";
    props = { ...props, onKeyPress: props.onClick, role: props.onClick ? "button" : undefined };
  }

  return (
    <Component {...props}>
      { appearances.includes('arrow') && 
        <svg aria-hidden="true" data-prefix="fal" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
          <path fill="currentColor" d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z">
          </path>
        </svg>
      }
      { children }
    </Component>
  )
})
