import * as THREE from 'three';
import { TweenLite, Expo, TweenMax } from 'gsap'

const MAX_HORIZONTAL_FOV = 110; // degrees
const MAX_VERTICAL_FOV = 90; // degrees

function getDefaultFov(aspect) {
  return Math.min(MAX_VERTICAL_FOV, THREE.Math.radToDeg(Math.atan(1 / aspect * Math.tan(THREE.Math.degToRad(MAX_HORIZONTAL_FOV) / 2)) * 2));
}

export default class extends THREE.PerspectiveCamera {
  constructor(startingPan, startingTilt, aspect, renderer, onUpdate) {
    // NOTE: aspect may be NaN, 0 or Infinity if rendering container is not visible 
    // (if width or height is 0). Default to an aspect of 1 if this happens.
    var fov = getDefaultFov(aspect || 1);
    super(fov, aspect || 1);
    this.rotation.order = "ZXY";
    this.rotation.z = THREE.Math.degToRad(startingPan - 90);
    this.rotation.x = THREE.Math.degToRad(startingTilt + 90);

    this._renderer = renderer;
    this._onUpdate = () => onUpdate({
      pan: THREE.Math.radToDeg(this.rotation.z) + 90, 
      tilt: THREE.Math.radToDeg(this.rotation.x) - 90,
      source: this.source
    });
    this._target = {
      x: this.rotation.x,
      z: this.rotation.z
    };
  }

  // Immediately stop all rotations
  killRotation = () => {
    TweenLite.killTweensOf(this.rotation);
    this._target.x = this.rotation.x;
    this._target.z = this.rotation.z;
    this._onUpdate();
  }

  // Set the target rotation absolutely
  // NOTE: a whole multiple of 360 degrees is added or subtracted from the pan in order to achieve the smallest rotation motion
  rotateTo = ({ pan, tilt, duration = 1.2, source }) => {
    this.source = source;
    this.killRotation();
    return new Promise(resolve => {
      this._target.z = THREE.Math.degToRad(THREE.Math.radToDeg(this._target.z) + ((pan - 90 - THREE.Math.radToDeg(this._target.z)) % 360 + 540) % 360 - 180);
      this._target.x = THREE.Math.degToRad(tilt + 90);
      TweenLite.to(this.rotation, duration, { ...this._target, onUpdate: this._onUpdate, onComplete: resolve });
    });
  }

  // Set the target rotation incrementally based on a screen drag.
  rotateByDrag = (dx, dy) => {
    let pan = dx / this._renderer.domElement.offsetHeight * this.fov * 1.5;
    let tilt = dy / this._renderer.domElement.offsetHeight * this.fov * 1.5;
    return this.rotateBy(pan, tilt);
  }

  // Set the target rotation incrementally by the specifed pan and tilt degrees
  rotateBy = (pan, tilt) => {
    this.source = undefined;
    return new Promise(resolve => {
      this._target.z += THREE.Math.degToRad(pan);
      this._target.x = Math.max(0, Math.min(Math.PI, this._target.x + THREE.Math.degToRad(tilt)));
      TweenMax.to(this.rotation, 1, { ...this._target, ease: Expo.easeOut, onUpdate: this._onUpdate, onComplete: resolve });
    });
  }

  // Immediately point the camera in the specified direction and fly it into the center
  flyIn = async ({ pan, tilt, source }) => {
    this.source = source;
    TweenLite.killTweensOf(this.position);
    return new Promise(resolve => {
      if (typeof(pan) === 'number' && typeof(tilt) === 'number') {
        this.rotation.z = THREE.Math.degToRad(pan - 90);
        this.rotation.x = THREE.Math.degToRad(tilt + 90);
        this._target.z = this.rotation.z;
        this._target.x = this.rotation.x;
        this._onUpdate();
      }
      
      window.requestAnimationFrame(() => {
        this.position.copy(this.getTargetVector().multiplyScalar(-256));
        TweenMax.to(this.position, 1, { x: 0, y: 0, z: 0, ease: Expo.easeOut, onUpdate: this._onUpdate, onComplete: resolve });
      });
    });
  }

  // Fly the camera away from center
  flyOut = ({ ease = Expo.easeOut, duration = 1, source }) => {
    this.source = source;
    TweenLite.killTweensOf(this.position);
    return new Promise(resolve => {
      //this._target.x = Math.PI / 2;
      //TweenMax.to(this.rotation, duration ?? 1, { ...this._target, ease: Expo.easeOut, onUpdate: this._onUpdate });
      TweenMax.to(this.position, duration, { ...this.getTargetVector().multiplyScalar(-256), ease, onUpdate: this._onUpdate, onComplete: () => {
        this.position.copy(new THREE.Vector3());
        resolve();
      }});
    });
  }
  
  // Get the vector being projected by the camera on the specified 2D coordinates.
  // NOTE: coordinates are given in screen pixels from center of the canvas; positive y is down.
  // If the page coordinates are not specified, then return the vector the camera is facing.
  getTargetVector = (x, y) => {
    const vector = new THREE.Vector3(0, 0, 1);
    
    if (typeof(x) === 'number' && typeof(y) === 'number') {
      vector.set(x / this._renderer.domElement.offsetWidth * 2, -y / this._renderer.domElement.offsetHeight * 2, 1);
    }

    return vector.unproject(this).normalize();
  };
  
  // Update the aspect ratio, typically when the renderer's container changes size.
  setAspect = aspect => {
    this.aspect = aspect || 1;
    this.fov = getDefaultFov(aspect);
    this.updateProjectionMatrix();
  }
  
  // Animate the FOV back to the default value as calculated from the current aspect ratio.
  resetFov = () => {
    const target = getDefaultFov(this.aspect);
    TweenLite.to(this, 0.5, { fov: target, onUpdate: () => this.updateProjectionMatrix() });
  }

  // Modify the FOV incrementally by scaling the projected image by a factor
  changeFov = factor => {
    // TODO tour: enforce max and min fov
    TweenLite.killTweensOf(this);
    this.fov = THREE.Math.radToDeg(Math.atan(Math.tan(THREE.Math.degToRad(this.fov / 2)) / factor) * 2);
    this.updateProjectionMatrix();
  }

  // Move the camera in a bouncing motion along the specified vector,
  // typically to indicate motion is not possible in that direction.
  bounce = vector => {
    TweenLite.to(this.position, 0.3, { 
      x: vector.x * 64, y: vector.y * 64, z: vector.z * 64,
      onComplete: () =>{
        TweenLite.to(this.position, 0.3, { 
          x: 0, y: 0, z: 0,
        });
      }
    });
  }
}