import React from 'react'
import clsx from 'clsx'
import { DateTime } from 'luxon'

import { pricing } from './index'

import styles from './Terms.module.scss'

export default ({ className, annual, plan }) => {
  const monthly = pricing[plan][annual][3];

  const startDate = DateTime.now();
  const endDate = startDate.plus({ months: annual ? 12 : 3 }).minus({ day: 1 });
  const creditExpiry = endDate.plus({ days: 90 });

  return (
    <div className={clsx(styles.container, className)}>
      <dl>
        <dt>Initial Term:</dt>
        <dd>{startDate.toLocaleString(DateTime.DATE_MED)} through {endDate.toLocaleString(DateTime.DATE_MED)} ({ annual ? '1 year' : '3 months' })</dd>

        <dt>Renewal:</dt>
        { annual ? (
          <dd>Automatically into additional annual Terms unless notified 30 days prior to renewal date</dd>
        ) : (
          <dd>Month to month after Initial Term; Termination upon 30 days notice</dd>
        )}

        <dt>Initial Charge:</dt>
        <dd>{ annual ? (
          <>
            ${monthly.toLocaleString()} &times; 12 = $<u>{(monthly * 12).toLocaleString()}</u>
          </>
        ) : (
          <>
            $<u>{monthly.toLocaleString()}</u>
          </>
        )}, redeemable for Services until {creditExpiry.toLocaleString(DateTime.DATE_MED)} (90 days after Initial Term)</dd>

        <dt>Recurring Charges:</dt>
        <dd>{ annual ? (
          <>
            $<u>{(monthly * 12).toLocaleString()} on renewal</u>
          </>
        ) : (
          <>
            $<u>{monthly.toLocaleString()} monthly</u> (3 total payments during Initial Term)
          </>
        )}, redeemable for Services until 90 days after corresponding Term</dd>
      </dl>
    </div>
  )
}
