import React, { useRef, useState, useEffect, useCallback } from 'react'
import { FieldArray, getIn, useFormikContext } from 'formik'

const createKeys = contacts => Array.isArray(contacts) ? contacts.map((_, i) => ({ key: i, visible: true })) : [];

export default ({ name, template, removeDelay, renderItem, render }) => {
  const { values } = useFormikContext();
  const items = getIn(values, name);
  const keys = useRef(createKeys(items));
  const forceRender = useState()[1];

  useEffect(() => {
    if (!items) {
      keys.current = [];
    }
    else if (keys.current.length !== items.length) {
      keys.current = createKeys(items);
    }
  }, [items]);

  const add = useCallback((push) => {
    keys.current = [...keys.current, { key: keys.current.length > 0 ? Math.max(...keys.current.map(t => t.key)) + 1 : 0, visible: true }];
    push(template);
  }, [template]);

  const startRemove = useCallback((index, remove) => {
    const newKeys = [...keys.current];
    newKeys[index].visible = false;
    keys.current = newKeys;
    forceRender({});

    setTimeout(() => {
      const newKeys = [...keys.current];
      newKeys.splice(index, 1);
      keys.current = newKeys;
      remove(index);
    }, removeDelay)
  }, [forceRender, removeDelay]);

  return (
    <FieldArray
      name={name}
      render={({ push, remove }) => render({
        items,
        renderedItems: items.map((_, index) => renderItem({
          name: `${name}[${index}]`,
          key: keys.current[index].key,
          index,
          exiting: !keys.current[index].visible,
          remove: () => startRemove(index, remove)
        })),
        add: () => add(push)
      })}
    />
  )
}