import React, { useContext, useCallback, useRef, useMemo } from 'react'
import { useLocation, navigate } from "@reach/router"
import query from 'query-string'
import Helmet from 'react-helmet'

const RouteContext = React.createContext();

const parsePath = (path) => {
  let controller, tourName, section, subSection;
  const parts = path.split('/').filter(t => t.length > 0);
  if (['my', 'explore', 'order', 'account'].includes(parts[0])) {
    [controller, tourName, section = '', subSection = ''] = parts;
  }
  else {
    [tourName, section = '', subSection = ''] = parts;
  }

  return [controller, tourName, section, subSection];
}

export default ({ children }) => {
  const location = useLocation();
  const [controller, tourName, section, subSection] = parsePath(location.pathname);

  const search = location.search;
  const parsedQuery = useMemo(() => query.parse(search), [search]);

  const path = useCallback((to) => {
    const stringOrCurrent = (test, current) => typeof test === 'string' ? test : current;

    const newController = stringOrCurrent(to.controller, controller);
    const newTourName = stringOrCurrent(to.tourName, tourName);
    const newSection = newTourName && stringOrCurrent(to.section, section);
    const newSubSection = newTourName && stringOrCurrent(to.subSection, subSection);

    const newPath = '/' + [newController, newTourName, newSection, newSubSection].filter(t => !!t).join('/');

    let newQuery = {...parsedQuery};
    if (to.pageNumber === 1) {
      delete newQuery.page;
    }
    else if (to.pageNumber) {
      newQuery.page = to.pageNumber;
    }

    if (to.filter) {
      newQuery.filter = to.filter;
    }
    else if (to.filter === '') {
      delete newQuery.filter;
    }

    if (to.d1 === '') {
      delete newQuery.d1;
    }

    if (to.modal) {
      newQuery.modal = to.modal;
    }
    else if (to.modal === '') {
      delete newQuery.modal;
    }

    const newSearch = query.stringify(newQuery);
    return newPath + (newSearch && ('?' + newSearch));
  }, [controller, tourName, section, subSection, parsedQuery]);

  // NOTE: reference to layoutNav.go never changes to prevent unnecessary rerenders.
  // It always has access to the latest values of the props in the mutant ref.
  const mutant = useRef();
  mutant.current = { path, fullPath: location.pathname + location.search };
  const go = useCallback(to => {
    const newPath = mutant.current.path(to);
    if (mutant.current.fullPath !== newPath || to.state) {
      // Imperative navigation risks infinite loops when logic is buggy.
      // This output will make that apparent.
      console.log('Navigating', newPath);
      navigate(newPath, { state: to.state, replace: to.replace });
    }
  }, []);

  const searchTerms = useMemo(() => ({
    contactEmail: parsedQuery.contact,
    geohash: parsedQuery.geohash,
    market: parsedQuery.market,
    brokerage: parsedQuery.brokerage,
    office: parsedQuery.office,
    team: parsedQuery.team,
    filter: parsedQuery.filter,
    pageNumber: parseInt(parsedQuery.page) || 1,
    pageSize: 60, //parseInt(parsedQuery.pageSize) || 60
  }), [parsedQuery])

  return (
    <RouteContext.Provider value={{
      layoutNav: { go, path },

      controller,
      tourName,
      section,
      subSection,
      locationState: location.state,
      fullPath: location.pathname + location.search,

      startingView: parsedQuery.d1,
      searchTerms,
      modal: parsedQuery.modal
    }}>
      { controller && !tourName &&
        <Helmet
          title={`${
            controller === 'explore' ? 'Explore' :
            controller === 'my' ? 'My Listings' :
            controller === 'order' ? 'Create Listing' :
            controller === 'account' ? 'Account Settings' :
            ''} | Realvision`}
          link={[{
            rel: "canonical",
            href: `https://real.vision/${controller}`
          }]}
        />
      }
      { children }
    </RouteContext.Provider>
  )
}

export const useRoute = () => useContext(RouteContext);
