import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTh, faStreetView, faLaptopHouse, faFileDownload, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import { useListing, Section, ListingLink } from '../../ListingContext'
import { usePhotos } from '../PhotosContext'
import Button from './Button'
import DownloadButton from './DownloadButton'
import styles from './index.module.scss'


export default function Toolbar({ className, allowDownload, layout }) {
  const { listing: { tourReady }, listingNav } = useListing();
  const { visiblePhotos, activeIndex } = usePhotos();
  const activePhoto = visiblePhotos[activeIndex];

  return (
    <div className={`${styles.tools} ${styles[layout]} ${className || ''}`}>
      { activePhoto && (
        <>
          <div className={styles.name}>
            {!activePhoto.externalLink && activePhoto.name} 
            {activePhoto.hidden && (
              <span className={styles.hidden}>
                <FontAwesomeIcon icon={faEyeSlash} /> Hidden by User
              </span>
            )}
        </div>
          <Button to={{ section: Section.PHOTOS }} Component={ListingLink} icon={faTh}>
            TO GRID
          </Button>
          { tourReady &&
            <Button
              onClick={() => listingNav({ section: Section.TOUR, state: { targetPhoto: activePhoto }}, true)}
              disabled={!activePhoto.sceneCode}
              icon={faStreetView}
            >
              TO 3D
            </Button>
          }
        </>
      )}

      { allowDownload && (
        <div className={styles.group}>
          <span className={styles.label}>DOWNLOAD {activePhoto ? '' : 'ALL'}</span>
          <DownloadButton icon={faLaptopHouse} size="mls">
            FOR MLS
          </DownloadButton>
          <DownloadButton icon={faFileDownload} size="full">
            HI-RES
          </DownloadButton>
        </div>
      )}
    </div>
  )
}