import * as THREE from 'three'
import { TweenLite } from 'gsap'
import NavArrowImg from './NavArrow.png'

const positionA = 370;
const positionB = 250;
    
const arrowIdleOpacity = 0.3;
const arrowHoverOpacity = 1;

const angleThreshold = 0.8;

export default class extends THREE.Object3D {
  constructor(scenesByCode, hideArrows) {
    super();

    this._sceneMap = scenesByCode;
        
    const arrowTexture = new THREE.TextureLoader().load(NavArrowImg);

    this._createArrow = (srcScene, dstScene) => {
      const material = new THREE.MeshBasicMaterial({
        map: arrowTexture,
        transparent: true,
        side: THREE.DoubleSide,
        depthTest: false,
        opacity: 0
      });

      const geometry = new THREE.PlaneBufferGeometry(80, 80);
      const arrow = new THREE.Mesh(geometry, material);
      arrow.visible = !hideArrows;
      arrow.renderOrder = 10000;
      arrow.sceneCode = dstScene.code;
      arrow.position.copy(new THREE.Vector3(
        dstScene.x - srcScene.x,
        dstScene.y - srcScene.y,
        dstScene.z - srcScene.z
      ).setLength(140));
      arrow.rotateZ(Math.atan2(arrow.position.y, arrow.position.x) - Math.PI / 2);
      const tilt = Math.atan2(arrow.position.z, Math.sqrt(Math.pow(arrow.position.x, 2) + Math.pow(arrow.position.y, 2)));
      arrow.rotateX(0.6 * tilt);

      setTimeout(() => {
        this.add(arrow);
        TweenLite.to(arrow.material, 0.3, { opacity: arrowIdleOpacity });
      }, 500);
    }

    this._removeArrow = arrow => {
      TweenLite.to(arrow.material, 0.3, { opacity: 0 });
      setTimeout(() => {
        this.remove(arrow);
      }, 300);
    }
  }

  // Update the nav's position based on the camera direction to create the illusion of parallax.
  updatePosition = (pan, tilt, bottomEdge) => {
    const sinTilt = Math.sin(THREE.Math.degToRad(tilt));
    const cosTilt = Math.cos(THREE.Math.degToRad(tilt));
    const sinPan = Math.sin(THREE.Math.degToRad(pan));
    const cosPan = Math.cos(THREE.Math.degToRad(pan));
    const b = positionB * bottomEdge;

    this.position.z = b * (0.5 * sinTilt - 1);
    this.position.y = positionA * cosTilt * sinPan;
    this.position.x = positionA * cosTilt * cosPan;
  }
  
  // Create arrows from the specified scene to its links.
  load = (sceneCode, currentGroupSettings) => {
    const scene = this._sceneMap[sceneCode];
    scene && scene.linkedScenes
      .filter(t => !scene.group || this._sceneMap[t].group !== scene.group || this._sceneMap[t].setting === scene.setting)
      .forEach(t => this._createArrow(scene, this._sceneMap[t]));
  }

  // Remove all arrows (typically, when a transition to another scene starts).
  unload = () => {
    this.children.forEach(t => this._removeArrow(t));
  };

  // Find the arrow directly hovering under the vector parameter.
  targetExact = (vector) => {
    const raycaster = new THREE.Raycaster();
    raycaster.set(new THREE.Vector3(), vector.normalize());
    var intersects = raycaster.intersectObject(this, true);
    
    const lastActiveArrow = this._activeArrow;

    if (intersects.length > 0) {
      this._activeArrow = intersects[0].object;
    }
    else {
      this._activeArrow = null;
    }
    
    if (lastActiveArrow !== this._activeArrow) {
      if (lastActiveArrow) {
        TweenLite.to(lastActiveArrow.material, 0.3, { opacity: arrowIdleOpacity });
      }
      if (this._activeArrow) {
        TweenLite.to(this._activeArrow.material, 0.3, { opacity: arrowHoverOpacity });
      }
    }

    return this._activeArrow && this._activeArrow.sceneCode;
  }

  // Find the arrow representing the linked scene nearest but no greater than a threshold from the vector parameter.
  targetNear = (vector) => {
    const winner = this.children.reduce((winner, arrow) => {
      // NOTE: arrow.position represents the vector from this scene to the target scene. It is NOT the actual
      // world coordinates of the arrow since this NavArrows container has its own position offset.
      const angle = vector.angleTo(arrow.position);
      return angle < winner.angle ? { sceneCode: arrow.sceneCode, angle } : winner;
    }, { sceneCode: null, angle: angleThreshold });

    return winner && winner.sceneCode;
  }
}