import React from 'react'
import { ButtonBar } from '../_shared'

import ScheduleForm from './ScheduleForm'
import { useBooking, BookingSteps } from '../BookingContext'
import styles from './index.module.scss'

export default () => {
  const { activeStep, orderState, suppressError } = useBooking();
  return (
    // NOTE: ScheduleForm is also used in Change/SelectionStep with slightly different props
    // and NOT within a BookingContext, therefore props are passed to it here and not accessed
    // from context inside ScheduleForm.
    <div className={styles.schedule}>
      <ScheduleForm 
        active={activeStep === BookingSteps.SCHEDULE}
        orderId={orderState.info.id}
        photographers={orderState.schedule.photographers}
        errors={suppressError ? [] : orderState.schedule.errors}
      />
      <ButtonBar lastStep={BookingSteps.PRODUCT} nextStep={BookingSteps.CONTACT} />
    </div>
  );
}