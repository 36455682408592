import React, { useContext, useEffect, useState, useCallback } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import safeLocalStorage from 'app/_shared/safeLocalStorage'

import { ActionLink } from '.'

import styles from './Hint.module.scss'

const HintContext = React.createContext({});

// The context provider keeps track of a list of hints to show one at a time, and has
// a list of functions to control the flow of what hints to show.
export const HintContextProvider = ({ children }) => {
  const [active, setActive] = useState(null);
  const [shake, setShake] = useState();
  const [history, setHistory] = useState(() => {
    try {
      return JSON.parse(safeLocalStorage.getItem('hintHistory')) ?? {};
    }
    catch {
      return {}
    }
  });
  const [allHints, setAllHints] = useState({});

  useEffect(() => {
    safeLocalStorage.setItem('hintHistory', JSON.stringify(history));
  }, [history]);

  const register = useCallback((name, priority, final) => {
    setAllHints(allHints => ({
      ...allHints,
      [name]: { priority, final }
    }));
  }, []);

  const deregister = useCallback((name) => {
    setAllHints(allHints => {
      delete allHints[name];
      return allHints;
    })
  }, [])

  const dismiss = useCallback((name, immediate) => {
    setHistory(history => ({
        ...history,
        [name]: immediate ? 'dismissed' : 'completed'
      }
    ))
  }, []);

  const dismissAll = useCallback(() => {
    setHistory(history => Object.keys(allHints).filter(t => !history[t] && !allHints[t].final).reduce((a, b) => ({ ...a, [b]: 'dismissed'}), history));
  }, [allHints]);

  const queuedHints = Object.keys(allHints).filter(t => !Object.keys(history).includes(t)).sort((a, b) => 
    !allHints[a].final === !allHints[b].final ? allHints[a].priority - allHints[b].priority : !!allHints[a].final - !!allHints[b].final
  );

  const restart = useCallback(() => {
    if (!active) {
      setHistory({});
    }
    else if (allHints[active].final) {
      setHistory({});
      setActive(null);
    }
    else {
      setShake(true);
    }
  }, [active, allHints]);

  useEffect(() => {
    if (shake) {
      const handle = setTimeout(() => setShake(false), 500);
      return () => clearTimeout(handle);
    }
  }, [shake])

  useEffect(() => {
    // If the active hint is no longer queued, then display the next queued hint.
    // If the active hint was "completed", then delay by 2 seconds to give user indication of the completion;
    // otherwise update immediately.
    if (!queuedHints.includes(active)) {
      const handle = setTimeout(() => setActive(queuedHints[0]), history[active] === 'completed' ? 2000 : 0);
      return () => clearTimeout(handle);
    }
  }, [queuedHints, active, allHints, history]);

  return (
    <HintContext.Provider value={{ register, deregister, dismiss, dismissAll, restart, active, history, queuedHints, shake }}>
      { children }
    </HintContext.Provider>
  )
}

export const useHint = () => useContext(HintContext);

// This is the public component for rendering a hint.
// NOTE: completing a hint should not cause it to cease being rendered, since the hint stays for
// a few seconds after completion, or fades out after being skipped. Set the inactive prop to true
// if the hint should not currently be displayed, but should not abruptly disappear if it completes.
// Example: tourOpenFloatingFloorPlan
export default ({ name, priority, final, className, position, inactive, children }) => {
  const { active, history, dismiss, dismissAll, register, deregister, shake } = useHint();
  const isActive = active === name;
  const [shouldRender, setShouldRender] = useState(isActive);
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    if (!inactive) {
      register(name, priority, final);
      return () => deregister(name);
    }
  }, [name, final, priority, inactive, register, deregister]); // IMPORTANT: register and deregister from the context state must stay constant

  useEffect(() => {
    let abort = false;
    if (!isActive) {
      setTimeout(() => !abort && setShouldRender(false), 250);
    }
    else {
      setShouldRender(true);
    }
    return () => abort = true;
  }, [isActive]);

  useEffect(() => {
    shake && setInitial(false);
  }, [shake]);

  useEffect(() => {
    isActive && setInitial(true);
  }, [isActive]);

  const complete = history[name] === 'completed';

  return !shouldRender ? null : (
    <div 
      className={clsx(styles.hint, styles[history[name]], className, {
        [styles.initial]: initial,
        [styles.inactive]: !isActive,
        [styles.shake]: shake
      })}
      data-position={position}
    >
      <div className={styles.content}>
        { children }
      </div>
      <div className={styles.toolbar}>
        { !final &&
          <ActionLink appearance="link" className={clsx(styles.skip, { [styles.hidden]: complete })} onClick={complete ? undefined : dismissAll}>
            Skip all tips
          </ActionLink>
        }
        <ActionLink appearance="button" className={styles.dismiss} onClick={() => dismiss(name, true)}>
          { final ? (
            <>
              <svg className={styles.timer} viewBox="-0.6, -0.6, 1.2 1.2">
                <circle r="0.5" strokeWidth="0.1" className={styles.bg}></circle>
                <circle
                  r="0.5" 
                  strokeWidth="0.12" 
                  className={styles.handle} 
                  strokeDasharray={`0 ${Math.PI.toFixed(10)} ${Math.PI.toFixed(10)}`}
                  onAnimationEnd={() => dismiss(name, true)}
                ></circle>
              </svg>
              got it!
            </>
          ) : "skip" }
        </ActionLink>
        { complete && (
          <FontAwesomeIcon className={styles.check} icon={faCheck} />
        )}
      </div>
    </div>
  )
}