import * as THREE from 'three'
import { TweenLite, Power3 } from 'gsap'
import NavRingInner from './NavRingInner.png'
import NavRingInnerHidden from './NavRingInnerHidden.png'
import NavRingOuter from './NavRingOuter.png'

const scale = 3;
const minDistanceSquare = 250000;
const near = (scene, neighbor) => {
  const x = neighbor.x - scene.x;
  const y = neighbor.y - scene.y;
  const z = neighbor.z - scene.z;
  const d = x * x + y * y + z * z;
  return d < minDistanceSquare && z > -scene.height && z < scene.height;
};

const targetAngleThreshold = 0.14;
const visibleAngleThreshold = 0.64;

const ringTexture = new THREE.TextureLoader().load(NavRingInner);
const highlightTexture = new THREE.TextureLoader().load(NavRingOuter);
const hiddenRingTexture = new THREE.TextureLoader().load(NavRingInnerHidden);

class Ring extends THREE.Object3D {
  constructor(scene) {
    super();
    const geometry = new THREE.PlaneBufferGeometry(100, 100);
    
    this._inner = new THREE.Mesh(
      geometry, 
      new THREE.MeshBasicMaterial({
        map: scene.hidden ? hiddenRingTexture : ringTexture,
        transparent: true,
        side: THREE.DoubleSide,
        depthTest: false,
        opacity: 0
      })
    );
    this._inner.renderOrder = 1000;
    
    this._outer = new THREE.Mesh(
      geometry,
      new THREE.MeshBasicMaterial({
        map: highlightTexture,
        transparent: true,
        side: THREE.DoubleSide,
        depthTest: false,
        opacity: 0
      })
    );
    this._outer.renderOrder = 1000;

    this.add(this._inner);
    this.add(this._outer);
    this.sceneCode = scene.code;

    this.position.fromArray([
      scene.x * scale, 
      scene.y * scale,
      (scene.z - scene.height) * scale
    ]);
  }

  hide = (onComplete) => {
    if (this.active) {
      this.hidden = true;
      TweenLite.to(this._outer.material, 0.5, { opacity: 0 });
      TweenLite.to(this._inner.material, 0.5, { opacity: 0, onComplete });
    }
  }

  show = () => {
    if (this.active) {
      this.hidden = false;
      TweenLite.to(this._inner.material, 0.5, { opacity: 0.3 });
      TweenLite.to(this._outer.material, 0.5, { opacity: 0 });
    }
  }

  highlight = () => {
    if (this.active) {
      this.hidden = false;
      TweenLite.to(this._inner.material, 0.5, { opacity: 0.99 });
      TweenLite.to(this._outer.material, 0.5, { opacity: 0.99 });
    }
  }
}

export default class extends THREE.Object3D {
  constructor(scenesByCode) {
    super();
    const allRings = {};

    this._activeRing = null;
    this._sceneMap = scenesByCode;

    this._addRing = (sceneCode) => {
      const ring = allRings[sceneCode] || (allRings[sceneCode] = new Ring(scenesByCode[sceneCode]));
      this.add(ring);
      ring.active = true;
    }
    
    this._removeRing = ring => {
      ring.hide(() => !ring.active && this.remove(ring));
      // NOTE: ring.active keeps track of whether a ring is logically removed after a scene transition
      // but has not yet been removed from the container since it's still fading out.
      // This prevents an inactive ring from becoming active again by ring.show, ring.hide or ring.highlight
      // cancelling the current hide animation.
      ring.active = false;
    }
  }
  
  // Load rings representing scenes in the vincinity of the indicated scene.
  // This typically happens on moving to a new scene.
  load = (sceneCode, currentGroupSettings, noAnimate) => {
    const scene = this._sceneMap[sceneCode];

    const nearbyScenes = !scene ? [] : Object.values(this._sceneMap)
      .filter(t => t.code !== sceneCode &&
        (!scene.group || t.group !== scene.group || t.setting === scene.setting) &&
        (scene.linkedScenes.includes(t.code) || near(scene, t)))
      .map(t => t.code);

    const ringsToAdd = nearbyScenes.filter(t => !this.children.some(ring => ring.active && ring.sceneCode === t));
    const ringsToRemove = this.children.filter(t => t.active && !nearbyScenes.includes(t.sceneCode));

    ringsToAdd.forEach(sceneCode => this._addRing(sceneCode));
    ringsToRemove.forEach(ring => this._removeRing(ring));
    
    scene && TweenLite.to(this.position, noAnimate ? 0 : 0.8, {
      x: -scene.x * scale, y: -scene.y * scale, z: -scene.z * scale, ease: Power3.easeInOut,
    });

    return nearbyScenes;
  }

  // Make only loaded rings within a threshold from the indicated vector visible.
  // NOTE: rings disappear after a period of inactivity.
  updateVisibility = vector => {
    clearTimeout(this._timeout);
    this._timeout = setTimeout(this.hideAll, 2000);

    this.children.filter(ring => ring !== this._activeRing).forEach(ring => {
      const angle = vector.angleTo(this.localToWorld(ring.position.clone()));
      angle > visibleAngleThreshold ? ring.hide() : ring.show();
    });
  }
  
  // Find the ring closest to and within a tight threshold to the indicated vector.
  targetVisible = (vector) => {
    const winner = this.children.filter(ring => !ring.hidden).reduce((winner, ring) => {
      const angle = vector.angleTo(this.localToWorld(ring.position.clone()));
      return angle < winner.angle ? { sceneCode: ring.sceneCode, angle } : winner;
    }, { sceneCode: null, angle: targetAngleThreshold });
    return winner && winner.sceneCode;
  }

  // Highlight a scene. Scene stays highlighted until another scene is highlighted,
  // or this function is called with a null parameter.
  highlight = (sceneCode) => {
    if ((this._activeRing && this._activeRing.sceneCode) !== sceneCode) {
      this._activeRing && this._activeRing.show();
      this._activeRing = this.children.find(t => t.sceneCode === sceneCode);
      this._activeRing && this._activeRing.highlight();
    }
  }
  
  // Hide all rings. Typically at the start of a scene transition.
  hideAll = () => {
    this.children
      .filter(ring => ring !== this._activeRing)
      .forEach(ring => ring.hide());
  }
}
