import { authFetch } from 'app/_shared'

const backendEndpoint = process.env.BACKEND_API_ENDPOINT;

export async function getListing(tourName, debugMode) {
  let url = `${backendEndpoint}/listing/get`;
  let response = await authFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ tourName, debugMode })
  }, 3);

  let listing = await response.json();
  if (listing.passcode && listing.passcode !== prompt("Enter passcode")) {
    throw new Error("incorrect passcode");
  }
  return listing;
}

export async function getMyListings(searchTerms) {
  let url = `${backendEndpoint}/listing/my`;
  let response = await authFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(searchTerms)
  }, 3);
  return await response.json();
}

export async function getFeaturedListings(searchTerms) {
  let url = `${backendEndpoint}/listing/explore`;
  let response = await authFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(searchTerms)
  }, 3);
  return await response.json();
}
