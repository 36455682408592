export default class AutoPlay {
  constructor ({ tour, tourConfig, thumbnails }) {  
    let sceneVisits = {}
    let isPlaying = false;

    tourConfig.scenes.forEach(scene => sceneVisits[scene.code] = 0);
    
    const arcAreaBetween = (currentScene, targetScene, currentYaw, currentPitch) => {
      let v1 = { x: targetScene.x - currentScene.x, y: targetScene.y - currentScene.y, z: targetScene.z - currentScene.z };
      let d = Math.sqrt(v1.x * v1.x + v1.y * v1.y + v1.z * v1.z);
      let yawRadian = currentYaw / 180 * Math.PI;
      let pitchRadian = currentPitch / 180 * Math.PI;
      let v2 = { x: Math.cos(yawRadian) * Math.cos(pitchRadian), y: Math.sin(yawRadian) * Math.cos(pitchRadian), z: Math.sin(pitchRadian) };
      return Math.acos((v1.x * v2.x + v1.y * v2.y + v1.z * v2.z) / d) * d;
    }

    const getDelta = (currentScene, targetScene, currentYaw) => {
      let yawRadian = currentYaw / 180 * Math.PI;
      let v1 = { x: targetScene.x - currentScene.x, y: targetScene.y - currentScene.y };
      let v2 = { x: Math.cos(yawRadian), y: Math.sin(yawRadian) };
      return -Math.atan2(v1.x * v2.y - v1.y * v2.x, v1.x * v2.x + v1.y * v2.y) / Math.PI * 180;
    }

    const expandLinks = (sceneCode, foundScenes) => {
      if (!foundScenes) {
        foundScenes = [];
      }
      foundScenes.push(sceneCode);
      let sceneConfig = tourConfig.scenesByCode[sceneCode];
      sceneConfig.linkedScenes.forEach(linkedScene => {
        if (!foundScenes.includes(linkedScene)) {
          expandLinks(linkedScene, foundScenes);
        }
      });
      return foundScenes;
    }

    const startPlayback = () => {
      // rotation durations, in seconds
      const galleryRotationTime = 8;
      const minimumRotationTime = 3;
      const maximumRotationTime = 9;
      const rotationMultiplier = 0.1;

      tour.triggerCurrent(async current => {
        let currentScene = tourConfig.scenesByCode[current.sceneCode];

        var allLinks = expandLinks(currentScene.code, null);
        var allTraversed = allLinks.every(function (scene) {
          return sceneVisits[scene] >= 1;
        });

        var nextScene = null;
        // If we have traversed all scenes or there are no linked scenes
        // go to the next gallery image not inside the same group
        // and reset scene visits
        if (allTraversed || currentScene.linkedScenes.length === 0) {
          var currentPhoto = thumbnails.find(thumb => allLinks.includes(thumb.scene));
          let offset = thumbnails.indexOf(currentPhoto);
          let destPhoto = null;
          for (var i = offset + 1; i < thumbnails.length; i++) {
            let newPhoto = thumbnails[i];
            if (!allLinks.includes(newPhoto.scene)) {
              destPhoto = newPhoto;
              nextScene = newPhoto.scene;
              allLinks.forEach(scene => sceneVisits[scene] = 0);
              break;
            }
          }

          await tour.jumpTo({ 
            pan: current.pan + 20, 
            tilt: current.tilt, 
            duration: galleryRotationTime,
            source: this
          });
          
          if (!isPlaying) return;
          await tour.jumpTo({
            sceneCode: destPhoto.scene,
            pan: (!tourConfig.scenesByCode[destPhoto.scene] ? 0 : tourConfig.scenesByCode[destPhoto.scene].yaw) - destPhoto.yaw - 10,
            tilt: destPhoto.pitch,
            source: this
          });
          sceneVisits[nextScene] += 1;

          isPlaying && startPlayback();
        }

        else {
          nextScene = currentScene.linkedScenes.reduce((winner, scene) => {
            const cost = (1 + sceneVisits[scene]) * arcAreaBetween(currentScene, tourConfig.scenesByCode[scene], current.pan, current.tilt);
            return !winner || cost < winner.cost ? { scene, cost } : winner;
          }, null).scene;
          var targetScene = tourConfig.scenesByCode[nextScene];

          let delta = getDelta(currentScene, targetScene, current.pan);
          let pan = current.pan + delta;
          let duration = Math.min(maximumRotationTime, Math.max(minimumRotationTime, delta * rotationMultiplier));

          await tour.jumpTo({ 
            pan, 
            tilt: 0,
            duration,
            source: this
          });

          if (!isPlaying) return;
          await tour.jumpTo({
            sceneCode: nextScene,
            source: this
          });
          sceneVisits[nextScene] += 1;

          isPlaying && startPlayback();
        }
      });
    }

    // Public facing properties/functions to control playback
    this.enable = () => {
      if (!isPlaying) {
        isPlaying = true;
        startPlayback();
      }
    }
    
    this.disable = () => {
      if (isPlaying) {             
        isPlaying = false;
        tour.killRotation();
      }
    }

    this.destroy = () => {
      this.disable();
      return undefined;
    }
  }
}