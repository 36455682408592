import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { ActionLink } from 'app/_shared'
import styles from './ClickToCopy.module.scss'

export default ({ className, value }) => {
  const [copied, setCopied] = useState();

  const copy = () => {
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  }

  useEffect(() => {
    setCopied(false);
  }, [value]);

  useEffect(() => {
    let handle;
    if (copied) {
      handle = setTimeout(() => setCopied(false), 5000);
    }
    return () => clearTimeout(handle);
  }, [copied]);

  return (
    <ActionLink
      className={`${styles.link} ${className || ''}`} 
      data-copied={copied}
      onClick={copy}
    >
      <div>
        <span className={styles.value}>{value}</span>
      </div>
      <span className={styles.copied}><FontAwesomeIcon icon={faCheck} /> copied</span>
    </ActionLink>
  )
}