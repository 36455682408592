import React, { useEffect, useRef, useReducer } from 'react'
import { useListing } from '../ListingContext'
import styles from './HeroBackground.module.scss'

const getDimension = (raw) => {
  return Math.max(512, (Math.min(2048, Math.ceil(raw * window.devicePixelRatio / 256) * 256)));
}

export default ({ className, visible }) => {
  const { listing: { tourName, photos }} = useListing();
  const ref = useRef();
  let visiblePhotos = photos && photos.filter(t => !t.hidden && !t.externalLink);
  if (visiblePhotos && visiblePhotos.length === 0) {
    visiblePhotos = photos; // fall back to hidden photos if there are no visible photos. Might still be empty.
  }

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'restart':
        return { currentIndex: visiblePhotos?.length > 0 ? 0 : undefined };
      case 'next':
        return visiblePhotos.length <= 1 ? state : {
          prevUrl: state.currentLoadedUrl,
          currentIndex: (state.currentIndex + 1) % visiblePhotos.length
        };
      case 'loaded':
        return { ...state, currentLoadedUrl: action.url };
      default:
        return state;
    }
  }, {});

  useEffect(() => {
    dispatch({ type: 'restart' });
  }, [tourName]);

  const currentBaseUrl = visiblePhotos?.[state.currentIndex]?.url;
  useEffect(() => {
    if (state.currentIndex === undefined) {
      dispatch({ type: 'restart' });
    }
    else if (!state.currentLoadedUrl) {
      let abort = false;
      new Promise(resolve => {
        const src = `${currentBaseUrl}&size=${getDimension(ref.current.offsetWidth)}x${getDimension(ref.current.offsetHeight)}`;
        const image = new Image();
        image.onload = () => resolve(src);
        image.src = src;
      }).then(url => !abort && dispatch({ type: 'loaded', url }));
      return () => abort = true;
    }
    else if (visible) {
      const timeout = setTimeout(() => dispatch({ type: 'next' }), 4000);
      return () => clearTimeout(timeout);
    }
  }, [visible, currentBaseUrl, state.currentLoadedUrl]);

  return (
    <div ref={ref} className={`${styles.outer} ${className || ''}`}>
      { state.prevUrl &&
        <div
          key={state.prevUrl}
          className={styles.inner}
          style={{backgroundImage: `url(${state.prevUrl})`}}
        />
      }
      { state.currentLoadedUrl &&
        <div
          key={state.currentLoadedUrl}
          className={styles.inner}
          style={{backgroundImage: `url(${state.currentLoadedUrl})`}}
        />
      }
    </div>
  )
}
