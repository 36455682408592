import React from 'react'
import styles from './Emoji.module.scss'

export default ({ label, className, symbol }) => (
  <span
    className={`${styles.emoji} ${className || ''}`}
    role="img"
    aria-label={label ? label : ""}
    aria-hidden={label ? "false" : "true"}
  >
    {symbol}
  </span>
);