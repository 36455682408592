import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { navigate } from 'gatsby'

import { FieldSet, ActionLink } from 'app/_shared'

import Error from './Error'
import { signIn } from '../service'
import styles from './UserContext.module.scss'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid').required('Required'),
  password: Yup.string().required('Required')
});

export default ({ openRegister, openReset, finish, mode }) => {
  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <h1>Sign in</h1>
        <div className={styles.subtext}>
          <p>Don't have an account?</p>
          <ActionLink appearance="arrow" onClick={openRegister}>Register</ActionLink>
        </div>
      </div>
      <Formik 
        initialValues={{ email: '', password: '' }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            actions.setStatus({ error: undefined });
            await signIn(values);
            finish();
            if (mode !== 'app') {
              navigate('/my');
            }
          }
          catch({ fieldErrors, statusError, message }) {
            actions.setSubmitting(false);
            fieldErrors && actions.setErrors(fieldErrors);
            (statusError || message) && actions.setStatus({ error: statusError || message });
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FieldSet type="email" name="email" label="Email address" />
            <FieldSet type="password" name="password" label="Password" />
            <ActionLink appearance="arrow" onClick={openReset}>Forgot your password?</ActionLink>
            <Error />
            <ActionLink type="submit" appearance="button" disabled={isSubmitting}>Submit</ActionLink>
          </Form>
        )}
      </Formik>
    </div>
  )
}
