import React from 'react'
import { useFormikContext } from 'formik'

import { MorphBox } from 'app/_shared'

import SummaryFinanceLine from './SummaryFinanceLine'
import AdjustmentCreate from './AdjustmentCreate'
import styles from './SummaryFinance.module.scss'

function getCancellationPercent(confirmData) {
  let total = confirmData.productTotal + confirmData.travelTotal + confirmData.priceAdjustmentsTotal;
  return total > 0 ? ` (${Math.round(confirmData.cancellationCharge / total * 1000) / 10}%)` : '';
}

function getSubtotal(confirmData, isCancelled, waiveCancellation) {
  return !isCancelled ? 
    (confirmData.productTotal + confirmData.travelTotal + confirmData.priceAdjustmentsTotal)  :
    ((waiveCancellation ? 0 : confirmData.cancellationCharge) + confirmData.cancellationChargeAdjustment);
}

function getCancellation(formik, confirmData) {
  if (!formik ||
    !formik.values ||
    formik.values.waiveCancellation === undefined ||
    !formik.values.info ||
    typeof formik.values.info.ownerEmail !== 'string' ||
    !confirmData.cancellationCharge
  ) {
    return undefined;
  }
  else {
    return {
      waived: formik.values.waiveCancellation,
      toggle: formik.isSubmitting ? undefined : () =>  formik.setFieldValue('waiveCancellation', !formik.values.waiveCancellation)
    }
  }
}

export default ({ orderId, confirmData, newConfirmData, isCancelled, className }) => {
  const formik = useFormikContext();
  // formik here is intended to reference the Formik component defined in Change/index, which has a field
  // indicating whether the user intends to waive the cancellation fee.
  // formik can also resolve to the form of the regular booking flow, in which case values.waiveCancellation would be undefined.
  const cancellation = getCancellation(formik, confirmData);
  const subtotal = getSubtotal(confirmData, isCancelled, cancellation && cancellation.waived);
  const tax = cancellation && cancellation.waived ? subtotal * confirmData.taxRate : confirmData.taxTotal;
  const newSubtotal = newConfirmData && getSubtotal(newConfirmData);
  const newTax = newConfirmData && newConfirmData.taxTotal; // NOTE: tax is calcluated in Change/index accounting for possible changes in travel charge

  let newAdjustments = newConfirmData ? [...newConfirmData.priceAdjustments] : [];
  let getMatchingAdjustmentAmount = (originalAdjustment) => {
    let newAdjustment = newAdjustments.find(t => t.description === originalAdjustment.description && t.amount === originalAdjustment.amount);
    if (!newAdjustment) {
      newAdjustment = newAdjustments.find(t => t.description === originalAdjustment.description);
    }
    if (newAdjustment) {
      newAdjustments.splice(newAdjustments.indexOf(newAdjustment), 1);
      return newAdjustment.amount;
    }
    else {
      return null;
    }
  }

  return (
    <div className={`${styles.breakdown} ${className || ''}`}>
      { newConfirmData &&
        <div className={styles.heading}>
          <span>Original order</span>
          <span>Updated order</span>
        </div>
      }
      <SummaryFinanceLine 
        label="Products total" 
        amount={confirmData.productTotal + confirmData.discountTotal} 
        voided={isCancelled} 
        newAmount={newConfirmData && (newConfirmData.productTotal + newConfirmData.discountTotal)}
      />

      { (!!confirmData.discountTotal || (newConfirmData && !!newConfirmData.discountTotal)) && 
        <SummaryFinanceLine 
          label="Discount total" 
          amount={!!confirmData.discountTotal && -confirmData.discountTotal} 
          voided={isCancelled} 
          newAmount={newConfirmData && !!newConfirmData.discountTotal && -newConfirmData.discountTotal}
        />
      }

      <SummaryFinanceLine 
        label="Travel charge" 
        amount={confirmData.travelTotal}
        voided={isCancelled} 
        newAmount={newConfirmData && newConfirmData.travelTotal}
      />

      { confirmData.priceAdjustments.map(t => (
        <SummaryFinanceLine 
          key={t.id} 
          label={`Adjustment${t.description ? ` (${t.description})` : ''}`} 
          amount={t.amount} 
          newAmount={newConfirmData && getMatchingAdjustmentAmount(t)} 
          voided={isCancelled} 
          orderId={!isCancelled && orderId}
          adjustmentId={t.id}
        />
      ))}

      { newAdjustments.map(t => (
        <SummaryFinanceLine 
          key={t.id} 
          label={`Adjustment${t.description ? ` (${t.description})` : ''}`} 
          amount={null} 
          newAmount={t.amount} 
          voided={isCancelled} 
        />
      ))}

      { (confirmData.cancellationCharge || isCancelled) &&
        <SummaryFinanceLine 
          label={`Cancellation charge${getCancellationPercent(confirmData)}`} 
          amount={confirmData.cancellationCharge}
          // NOTE: assuming newConfirmData is never cancelled
          newAmount={newConfirmData && null}
          cancellation={cancellation}
        />
      }
      <MorphBox>
        { cancellation && cancellation.waived &&
          <SummaryFinanceLine
            className={styles.waived}
            label={`Adjustment (cancellation waived)`} 
            amount={-confirmData.cancellationCharge}
            newAmount={newConfirmData && null}
          />
        }
      </MorphBox>

      { confirmData.cancellationAdjustments.map(t => (
        <SummaryFinanceLine 
          key={t.id} 
          label={`Adjustment${t.description ? ` (${t.description})` : ''}`} 
          amount={t.amount}
          // NOTE: assuming newConfirmData is never cancelled
          newAmount={newConfirmData && null}
          orderId={orderId}
          adjustmentId={t.id}
        />
      ))}

      { !!orderId &&
        <AdjustmentCreate orderId={orderId} />
      }

      <hr />

      <SummaryFinanceLine 
        label="Subtotal" 
        amount={subtotal} 
        newAmount={newSubtotal}
      />

      <SummaryFinanceLine 
        label="Tax" 
        amount={tax} 
        newAmount={newTax}
        // NOTE: tax totals are verified in the back end prior to confirmation, since the amount changes
        // depending on a change in travel charge, or whether a cancellation fee is waived.
      />

      <hr />

      <SummaryFinanceLine 
        final
        label={!orderId ? "New total" : "Order total"}
        amount={subtotal + tax}
        newAmount={newConfirmData && (newSubtotal + newTax)}
      />
    </div>
  )
}