import { useFormikContext } from 'formik'
import { useBooking, BookingSteps } from '../BookingContext'

const activeBeforeTarget = (targetStep, activeStep) => {
  for (let key in BookingSteps) {
    if (BookingSteps[key] === (targetStep || activeStep)) {
      return false;
    }
    if (BookingSteps[key] === activeStep) {
      return true;
    }
  }
  return false; // This should not be reached.
}

// This is copied from the Formik source code, to create an object where every field,
// including those in nested objects and arrays, have a value of true.
const setNestedObjectValues = (object, value, visited = new WeakMap(), response = {}) => {
  for (let k of Object.keys(object)) {
    const val = object[k];
    if (val !== null && typeof val === 'object') {
      if (!visited.get(val)) {
        visited.set(val, true);
        response[k] = Array.isArray(val) ? [] : {};
        setNestedObjectValues(val, value, visited, response[k]);
      }
    } else {
      response[k] = value;
    }
  }
  return response;
}

export default ({ targetStep, render }) => {
  const formik = useFormikContext();
  const { activeStep, actions: { submit }} = useBooking();

  const submitForm = async () => {
    if (formik.isSubmitting) {
      return;
    }

    formik.setStatus({ error: null });
    formik.setSubmitting(true);

    if (activeBeforeTarget(targetStep, activeStep)) {
      formik.setTouched(setNestedObjectValues(formik.values, true));
      await formik.validateForm();
      if (!formik.isValid) {
        formik.setSubmitting(false);
        return;
      }
    }
    
    try {
      await submit(formik.values, targetStep || activeStep);
      formik.setTouched({});
    }
    catch({ fieldErrors, statusError, message }) {
      // NOTE: normally back end validation errors are included in the order state within a 200 response,
      // and therefore should be handled and rendered by the try block above.
      // This catch block only applies to network errors, or an error response from the API, in which case
      // the thrown object is expected to indicate any applicable fieldErrors, or an overall statusError.
      fieldErrors && formik.setErrors(fieldErrors);
      (statusError || message) && formik.setStatus({ error: statusError || message });
    }
    finally {
      formik.setSubmitting(false);
    }
  }
  
  // We're giving the consumer a custom submit handler which is capable of conditionally bypassing validation,
  // which overrides the normal formik.submitForm.
  return render({ ...formik, submitForm });
}
