import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Formik, Form } from 'formik'

import { Throbber, TabBox } from 'app/_shared'

import SelectionStep from './SelectionStep'
import ConfirmStep from './ConfirmStep'
import CompleteStep from './CompleteStep'
import * as service from '../../service'
import styles from './index.module.scss'

const mapToForm = (orderData, initialDate) => ({
  info: {
    id: orderData.info.id,
    ownerEmail: orderData.info.ownerEmail,
    isAdmin: orderData.info.isAdmin
  },
  schedule: {
    selectedPhotographerId: orderData.schedule.selectedPhotographerId,
    selectedTime: !initialDate ? orderData.schedule.selectedTime : { localDate: initialDate },
    travelCost: orderData.schedule.travelCost,
    cancellationPolicyId: orderData.schedule.cancellationPolicyId,
    waitlistEnabled: orderData.schedule.waitlistEnabled,
    ignoreAvailability: orderData.schedule.ignoreAvailability,
    unavailableTimeSelected: orderData.schedule.unavailableTimeSelected
  },
  originalConfirm: orderData.originalConfirm && {
    cancellationCharge: orderData.originalConfirm.cancellationCharge
    // NOTE: tax related to the original order is not verified on the server, since the
    // front end decides whether cancellation is waived (via the SummaryFinance component)
  },
  // NOTE: !orderData.confirm indicates this order cannot be rescheduled
  // presumably because the address or product steps have become invalid
  confirm: orderData.confirm && {
    productTotal: orderData.confirm.productTotal,
    travelTotal: orderData.confirm.travelTotal,
    priceAdjustmentsTotal: orderData.confirm.priceAdjustmentsTotal,
    taxTotal: orderData.confirm.taxTotal
  },
  waiveCancellation: false
});

// NOTE: newSelectionValid is true if there is a new time selected, AND
// it's different from the original appointment time.
const newSelectionValid = (newSchedule, originalSchedule) => {
  const newTime = newSchedule.selectedTime && newSchedule.selectedTime.utc;
  const originalTime = originalSchedule && originalSchedule.selectedTime.utc;

  const newPhotographer = newSchedule.selectedPhotographerId;
  const originalPhotographer = originalSchedule && originalSchedule.selectedPhotographerId;

  const ignoreAvailability = newSchedule.ignoreAvailability;
  const originalIgnoreAvailability = originalSchedule && originalSchedule.ignoreAvailability;

  return newTime && (newTime !== originalTime || newPhotographer !== originalPhotographer
    || ignoreAvailability !== originalIgnoreAvailability);
}

export default ({ className, close, orderId, initialDate, op, onSuccess }) => {
  const [orderData, setOrderData] = useState();
  const [activeStep, setActiveStep] = useState();
  const [successData, setSuccessData] = useState();
  const [showError, setShowError] = useState();

  useEffect(() => {
    let abort = false;
    setOrderData(undefined);
    orderId && service.startChange(orderId).then(data => !abort && setOrderData(data));
    return () => abort = true;
  }, [orderId]);

  useEffect(() => {
    if (op) {
      setActiveStep(op === 'reschedule' ? 'selection' : 'confirm');
    }
  }, [op]);

  const formValues = useMemo(() => orderData && mapToForm(orderData, initialDate), [orderData, initialDate]);

  const onSubmit = useCallback(async values => {
    // NOTE: as long as the new selection is not valid (valid meaning a time different from the
    // existing time selected), a submission is treated as a cancellation request.
    // Note that form submission is not possible on the selection step, since the "next" button
    // is blocked as long as selection is not valid.
    const isCancel = !newSelectionValid(values.schedule, orderData.originalSchedule);
    if (isCancel) {
      values = { ...values, confirm: null };
    }
    let result = await service.changeOrder(values);
    if (result.isComplete) {
      setSuccessData(result);
      setActiveStep('complete');
      onSuccess && onSuccess();
    }
    else {
      setShowError(true);
      setOrderData(result.orderModel);
      setActiveStep(result.orderModel.schedule.isValid || isCancel ? "confirm" : "selection");
    }
  }, [onSuccess, orderData]);

  return (
    <div className={`${styles.reschedule} ${className || ''}`} >
      { orderData && (
        <Formik
          initialValues={formValues}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => {
            const valid = newSelectionValid(values.schedule, orderData.originalSchedule)
            return (
              <Form>
                <TabBox className={styles.tabs} activeTab={activeStep} center>
                  <SelectionStep
                    tabkey="selection"
                    orderId={orderId}
                    orderData={orderData}
                    newSelectionValid={valid}
                    showError={showError}
                    next={() => {
                      // Manually calculate and update the travelTotal and taxTotal for back end validation
                      const travelTotal = values.schedule.travelCost || 0;
                      const taxTotal = Math.round(
                        (orderData.confirm.productTotal + travelTotal + orderData.confirm.priceAdjustmentsTotal) * orderData.confirm.taxRate);
                      setFieldValue("confirm.travelTotal", travelTotal);
                      setFieldValue("confirm.taxTotal", taxTotal);
                      setActiveStep('confirm');
                    }}
                    close={close}
                  />
                  <ConfirmStep
                    tabkey="confirm"
                    orderData={orderData}
                    isCancel={!valid}
                    showError={showError}
                    back={() => setActiveStep('selection')}
                  />
                  <CompleteStep
                    tabkey="complete"
                    originalTime={orderData.originalSchedule && orderData.originalSchedule.selectedTime}
                    newTime={valid && values.schedule.selectedTime}
                    email={successData?.billingContactEmail}
                    close={close}
                  />
                </TabBox>
              </Form>
            )
          }}
        </Formik>
      )}
      <Throbber active={!orderData} />
    </div>
  )
}
