// This class keeps a cache of Promises that resolve to images for all images that it's been
// requested in the past to load.
export default class {
  constructor(config, imageSource) {
    this._queue = [];
    this._config = config;
    this._cache = {};

    this._loadNext = async () => {
      if (this._queue.length > 0) {
        const scene = this._queue.pop();
        await this.load(imageSource + scene.code + '/d.jpg?v=' + scene.version);
        this._loadNext();
      }
    }
  }

  load = url => {
    if (!this._cache[url]) {
      this._cache[url] = new Promise(resolve => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = () => resolve(img);
        img.src = url;
      });
    }
    return this._cache[url];
  }

  hasInCache = url => {
    return !!this._cache[url];
  }
  
  preload = (sceneCodes) => {
    this._queue = sceneCodes.map(t => this._config.scenesByCode[t]);
    this._loadNext();
  };
}