import React from 'react'

import { ActionLink, Emoji } from 'app/_shared'

import { Error } from './_shared'
import styles from './IframeRedirect.module.scss'

export default () => {
  return (
    <div className={styles.container}>
      <p>
        To proceed with your Realvision order, please continue in a new tab:
      </p>
      <ActionLink appearance="button" className={styles.button} to={window.location.href} target="_blank">real.vision/order</ActionLink>
      <p>
        Alternatively, email us at <ActionLink appearance="link" to="mailto:help@real.vision">help@real.vision</ActionLink> and we'll lend you a hand!
      </p>
      <Error className={styles.notice}>
        <Emoji symbol={"👀"} /> Your browser's privacy settings currently prevents Realvision from keeping track of your order progress when embedded in another site. 
        Simply open <ActionLink to={window.location.href} target="_blank">real.vision/order</ActionLink> in a new tab to continue.
      </Error>
    </div>
  )
}
