import React from 'react'
import { ActionLink } from 'app/_shared'

export default ({ openSignIn }) => {
  return (
    <div>
      <p>Your password has been successfully reset.</p>
      <ActionLink appearance="arrow" onClick={openSignIn}>
        Back to sign in
      </ActionLink>
    </div>
  )
}