import React from 'react'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import { ActionLink } from 'app/_shared'
import { useListing } from 'app/listing'
import { useRoute } from 'app/layout'

import AdminLinks from './AdminLinks'
import Appointment from './Appointment'
import Delivered from './Delivered'
import styles from './index.module.scss'

const dateFormats = {
  appointment: {
    sameDay: '[today] (ll) [at] h:mm a z',
    nextDay: '[tomorrow] (ll) [at] h:mm a z',
    nextWeek: '[on] dddd, ll [at] h:mm a z',
    lastDay: '[yesterday] (ll) [at] h:mm a z',
    lastWeek: '[on] dddd, ll [at] h:mm a z',
    sameElse: '[on] dddd, ll [at] h:mm a z'
  },
  delivery: {
    sameDay: '[today] (ll)',
    nextDay: '[tomorrow] (ll)',
    nextWeek: '[on] dddd, ll',
    lastDay: '[on] dddd, ll',
    lastWeek: '[on] dddd, ll',
    sameElse: '[on] dddd, ll'
  },
  default: {
    sameDay: '[today] (ll)',
    nextDay: '[tomorrow] (ll)',
    nextWeek: '[on] dddd, ll',
    lastDay: '[yesterday] (ll)',
    lastWeek: '[on] dddd, ll',
    sameElse: '[on] dddd, ll'
  }
};

const createMoment = (time, timeZone) => {
  return timeZone ? moment.tz(time, timeZone) : moment(time);
}

export default ({ className }) => {
  const { listing: { tourName, status, permissions, photos, tourReady, floorplan }} = useListing();
  const { layoutNav: { path }} = useRoute();
  const orderEvents = status.orderEvents;
  
  // The next, or failing that, the latest event. This is displayed as the listing's latest status, similar to the listing header and tile.
  const statusEvent = orderEvents.filter(t => !t.ended)[0] || orderEvents.filter(t => t.ended).reverse()[0];

  // The event which is expanded with more options displayed. Only applicable currently to appointment and delivered events.
  // NOTE: this is not necessarily the same as the status event above. For example, after an appointment and before a delivery, the upcoming delivery
  // is the active event but the completed appointment is the expanded event (which asks the users for a rating).
  const expandedEvent = orderEvents.filter(t => t.type === 'appointment' && !t.started)[0] ||
    orderEvents.filter(t => (t.type === 'appointment' && t.started) || t.type === 'delivered').reverse()[0];

  return (
    <div className={`${styles.timeline} ${className || ''}`}>
      { permissions.includes('global') && 
        <AdminLinks tourName={tourName} />
      }
      { orderEvents.map((t, i) => {
        const now = createMoment();
        const start = createMoment(t.start, t.timeZone);
        const end = t.end && createMoment(t.end, t.timeZone);
        const nextStart = orderEvents[i + 1] && createMoment(orderEvents[i + 1].start, orderEvents[i + 1].timeZone);

        return (
          <div className={`${styles.item} ${t === statusEvent ? styles.active : ''} ${t === expandedEvent ? styles.expanded : ''}`} key={i}>
            <div className={`${styles.badge} ${styles[t.type] || ''} ${t.started ? styles.started : ''}`}>
              { t.ended &&
                <FontAwesomeIcon icon={t.type === 'cancelled' ? faTimes : faCheck} />
              }
            </div>
            { (nextStart || t.type === 'delivered') && 
              <div className={styles.progress}>
                { nextStart && 
                  <div className={styles.bar} style={{height: `${Math.max(0, Math.min(1, now.diff(end || start) / nextStart.diff(end || start))) * 100}%`}} />
                }
              </div>
            }
            <div className={styles.title}>
              <span className={styles.type}>
                {t.type}
              </span> <span className={styles.time}>
                {start.calendar(null, dateFormats[t.type] || dateFormats.default)}.
              </span>
            </div>
            { t.type === 'appointment' &&
              <Appointment
                event={t}
                expand={t === expandedEvent}
                globalPermission={permissions.includes('global')}
                className={styles.details}
              />
            }
            { t === expandedEvent && t.type === 'delivered' && 
              photos?.length && tourReady && floorplan &&
              <Delivered className={styles.details} />
            }
            { t.type === 'cancelled' &&
              <ActionLink className={styles.reschedule} appearance="button" to={path({ modal: `reschedule_${t.orderId}` })}>
                Reschedule
              </ActionLink>
            }
          </div>
        )
      })}
    </div>
  )
}