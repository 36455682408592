import * as THREE from 'three';
import { TweenLite, Power3 } from 'gsap';

const CUBE_SIZE = 1024;
const DAMP_FACTOR = 100;
const MAX_MOVEMENT_FACTOR = 0.4;
const _90 = Math.PI / 2;
const _180 = Math.PI;

function getMovementTarget(direction) {
  let h = direction.length();
  let k = h / (h + DAMP_FACTOR) * MAX_MOVEMENT_FACTOR * CUBE_SIZE;
  return direction.clone().setLength(k);
}

export default class extends THREE.Object3D {
  constructor(imageSource, loader) {
    super();

    const faceTransforms = [[_90, -_90, 0], [-_90, 0, _180], [_90, _90, 0], [_90, 0, 0 ], [_180, 0, -_90], [0, 0, -_90]];
    faceTransforms.forEach(transform => {
      var texture = new THREE.Texture(undefined);
      var material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        depthTest: false // this tells three.js to NOT determine render order based on distance from camera, and use the manually specified .renderOrder instead.
      });
      var geometry = new THREE.PlaneBufferGeometry(CUBE_SIZE, CUBE_SIZE);
      var face = new THREE.Mesh(geometry, material);
      face.rotation.fromArray(transform);
      face.translateZ(-CUBE_SIZE / 2);
      this.add(face);
    });

    this._loader = loader;
    this._imageSource = imageSource;

    this._loadPreview = async () => {
      const loadingScene = this._activeScene;
      const img = await loader.load(imageSource + loadingScene.code + '/d.jpg?v=' + loadingScene.version);

      if (loadingScene === this._activeScene) {
        this.children.forEach(face => {
          const texture = face.material.map;
          const index = this.children.indexOf(face);
          texture.offset.fromArray([index % 3 / 3, (1 - Math.floor(index / 3)) / 2]);
          texture.repeat.fromArray([1 / 3, 1 / 2]);
          texture.image = img;
          texture.needsUpdate = true;
        });
      }
    }

    this._loadFullFaces = async () => {
      const loadingScene = this._activeScene;

      await Promise.all(this.children.map(async face => {
        const img = await loader.load(imageSource + loadingScene.code + '/c' + this.children.indexOf(face) + '.jpg?v=' + loadingScene.version);

        if (loadingScene === this._activeScene) {
          const texture = face.material.map;
          texture.repeat.fromArray([1, 1]);
          texture.offset.fromArray([0, 0]);
          texture.image = img;
          texture.needsUpdate = true;
        }
      }));
    };
  }
  
  // Activate this cube by loading scene images, then moving the scene along the indicated direction to the origin
  // while fading in the the scene images. If direction is not indicated then simply load the new scene and fade in.
  // NOTE: this async function resolves when image loading completes and the transition *starts*, not when it ends.
  enter = async (scene, direction, waitUntil) => {
    this._activeScene = scene;

    this.children.forEach(face => {
      face.material.opacity = 0;
      face.renderOrder = 10;
    });

    const fullFacesInCache = [0, 1, 2, 3, 4, 5].every(i => 
      this._loader.hasInCache(this._imageSource + scene.code + '/c' + i + '.jpg?v=' + scene.version)
    );
    if (fullFacesInCache) {
      await this._loadFullFaces();
    }
    else {
      await this._loadPreview();
    }
    // NOTE: after the image is loaded, we optionally wait until another promise is fulfilled
    // before going on to animate the cube transition.
    waitUntil && await waitUntil;

    if (scene === this._activeScene) {
      this.rotation.z = THREE.Math.degToRad(scene.yaw);
      const onComplete = fullFacesInCache ? undefined : this._loadFullFaces;
      this.children.forEach(face => TweenLite.to(face.material, 0.8, { opacity: 1, ease: Power3.easeInOut }));
      
      if (direction) {
        this.position.copy(getMovementTarget(direction));
      }
      TweenLite.to(this.position, 0.8, { x: 0, y: 0, z: 0, ease: Power3.easeInOut, onComplete });
    }
  };

  // Deactivate this cube by moving the scene away along the indicated direction, and decreasing its render order.
  // NOTE: this function resolves when the transition *ends*.
  exit = async direction => {
    this._activeScene = null;
    this.children.forEach(t => t.renderOrder = 1);
    // NOTE: if there is no direction specified, the cube simply fades out in place.
    const target = direction ? getMovementTarget(direction).negate() : { x: 0, y: 0, z: 0 };
    !direction && this.children.forEach(face => TweenLite.to(face.material, 0.5, { opacity: 0, ease: Power3.easeInOut }));

    await new Promise(resolve => {
      TweenLite.to(this.position, 0.8, {
        x: target.x,
        y: target.y,
        z: target.z,
        ease: Power3.easeInOut,
        onComplete: () => {
          this.position.copy(new THREE.Vector3());
          resolve();
        } 
      });
    });
    
    this.children.forEach(face => face.material.opacity = 0);
  }
}