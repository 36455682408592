import React, { useRef, useEffect, useState } from 'react'
import { useFlags, useUnitPreference } from 'app/_shared'
import { useTour } from '../TourSection/TourContext';
import styles from './Viewer.module.scss'

// Custom hook to create debug data for drawing dots and lines on the floor plan.
const useDebug = (floorId) => {
  const { debugMode } = useFlags();
  const { tourConfig } = useTour();
  const [debugData, setDebugData] = useState();
  useEffect(() => {
    if (tourConfig && debugMode) {
      const scenes = tourConfig.scenes.filter(t => t.floor === floorId);
      const connections = scenes.reduce((connections, scene) => {
        const newConnections = [];
        scene.linkedScenes.forEach(t => {
          const to = tourConfig.scenesByCode[t];
          const existing = connections.find(t => t.to === scene && t.from === to);
          if (existing) {
            existing.bothWays = true;
          }
          else {
            newConnections.push({ from: scene, to });
          }
        });
        return [...connections, ...newConnections];
      }, []);
      setDebugData({ scenes, connections });
    }
    else {
      setDebugData();
    }
  }, [tourConfig, floorId, debugMode]);
  return debugData;
}

export default ({ markerSize, scale, selected, tourPosition, offset, interactive, floor, onLoad }) => {
  const floorRef = useRef();
  const markerRef = useRef();
  const { unitPreference } = useUnitPreference();
  const [loaded, setLoaded] = useState();
  const { tour } = useTour();
  const debugData = useDebug(floor.id);

  const floorSvgUrl = floor.svgUrl[unitPreference];
  const transform = `scale(1 -1) translate(${floor.transform.tx} ${floor.transform.ty}) rotate(${floor.transform.rz / Math.PI * 180})`;
  useEffect(() => {
    let abort = false;
    setLoaded(false);
    fetch(floorSvgUrl).then(response => response.text()).then(svgText => {
      if (!abort) {
        floorRef.current.lastChild && floorRef.current.removeChild(floorRef.current.lastChild);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(svgText,"image/svg+xml");
        const floorNode = xmlDoc.getElementById(floor.id);
        floorNode.setAttribute('transform', transform);
        floorRef.current.appendChild(floorNode);
        onLoad(floor.id, floorRef.current.getBBox());
        setLoaded(true);
      }
    });
    return () => {
      abort = true;
    }
  }, [floorSvgUrl, floor.id, transform, onLoad]);

  // NOTE: only allow the parent to unload this floor's bounding box when the floor id changes, not when
  // the floorSvgUrl changes (e.g., when transitioning between imperial and metric).
  useEffect(() => () => onLoad(floor.id, undefined), [floor.id, onLoad]);

  useEffect(() => {
    if (tourPosition?.floorId) {
      markerRef.current.style.transform = `translate(${tourPosition.x}px, ${-tourPosition.y}px)`;
    }
  }, [tourPosition]);

  const photoDriven = tourPosition?.photoDriven;
  const photoYaw = (tourPosition?.floorId && tourPosition.yaw) ?? undefined;
  useEffect(() => {
    if (tour) {
      if (!photoDriven) {
        const rotateHandler = ({ data }) => {
          if (!data.arbitrary) {
            markerRef.current.firstChild.setAttribute('transform', `rotate(${-data.direction[0] || 0} 0 0)`);
          }
        }
        tour.addEventListener('rotate', rotateHandler);
        return () => tour.removeEventListener('rotate', rotateHandler);
      }
      else if (photoYaw !== undefined) {
        let targetYaw = -photoYaw;
        const currentTransform = markerRef.current.firstChild.getAttribute('transform');
        const currentYaw = parseFloat(currentTransform?.match(/^rotate\((?<yaw>-?\d+(\.\d+)?)/)?.groups.yaw);
        if (!isNaN(currentYaw)) {
          targetYaw = currentYaw + ((targetYaw - currentYaw) % 360 + 540) % 360 - 180;
        }
        markerRef.current.firstChild.setAttribute('transform', `rotate(${targetYaw} 0 0)`);
      }
    }
  }, [tour, photoDriven, photoYaw]);

  const markerScale = markerSize / scale;
  return (
    <g className={styles.floor} data-active={selected} style={{transform: `translate(${offset}px, 0)`}}>
      <g ref={floorRef}></g>
      { debugData && 
        <g className={styles.debug} transform='scale(10 -10)'>
          { debugData.connections.map((t, i) => (
            <line key={i} x1={t.from.x} y1={t.from.y} x2={t.to.x} y2={t.to.y} strokeWidth={0.005 * markerScale} style={{ stroke: t.bothWays ? 'var(--blue-300)' : 'var(--blue-100)' }} />
          ))}
          { debugData.scenes.map(t => (
            <g key={t.code} transform={`translate(${t.x} ${t.y})`} >
              <circle r="0.015" strokeWidth="0.005" stroke="white" transform={`scale(${markerScale})`} style={{fill: t.hidden ? 'var(--realvision-red)' : 'var(--blue-500)'}} />
            </g>
          ))}
        </g>
      }
      <g className={styles.marker} ref={markerRef} data-active={interactive && loaded && tourPosition?.floorId === floor.id}>
        <g className={photoDriven ? styles.photo : ''}>
          <path d="M0.707 0 L0.3535 0.3535 a0.5,0.5 0 1 1 0 -0.707 z" transform={`scale(${markerScale})`} style={{ fill: 'var(--realvision-red' }} />
          <circle r="0.15" fill="white" transform={`scale(${markerScale})`}/>
        </g>
      </g>
    </g>
  )
}
