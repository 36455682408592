import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

import { ActionLink, TabBox, ClickToCopy, Popup } from 'app/_shared'

import { useListing } from '../../ListingContext'
import SubTabSelector from '../SubTabSelector'
import pageMockup from './Facebook.jpg'
import photoMockup from './Facebook360.jpg'

import styles from './Facebook.module.scss'
import tabStyles from '../index.module.scss'

const data = {
  id: 'facebook',
  icon: faFacebookF,
  background: 'linear-gradient(to top right, #4267b2, #517dd4)',
  color: '#4267b2',
  subTabs: ['Facebook Page Share', '360 Photo']
}

const Facebook = ({ activeSubTab, setActiveSubTab, tourUrl, startingScene, divProps }) => {
  const { listing } = useListing();
  return (
    <div {...divProps}>
      <SubTabSelector subTabs={data.subTabs} activeSubTab={activeSubTab} setActiveSubTab={setActiveSubTab} />
      <TabBox className={styles.subtabs} activeTab={activeSubTab} center>
        <div className={tabStyles.content} tabkey="Facebook Page Share">
          <p>
            Share this Realvision tour on Facebook for all to see! You'll have a chance to enter a custom message.
          </p>
          <Popup
            width={575} 
            height={815} 
            url={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(tourUrl)}`} 
            render={open => (
              <ActionLink 
                appearance="button"
                onClick={open}
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} /> Post to Facebook
              </ActionLink>
            )}
          />
        </div>
        { startingScene && listing &&
          <div className={tabStyles.content} tabkey="360 Photo">
            <p>
              Post an <strong>interactive 360 photo</strong> to Facebook as a preview of the full Realvision experience.
            </p>
            <ol>
              <li>
                <ActionLink
                  className={tabStyles.action}
                  appearance="button"
                  to={`${process.env.SCENE_DISTRIBUTION}${startingScene.code}/adjusted-pano.jpg?v=${startingScene.version}&filename=${listing.tourName}_360.jpg`}
                  disabled={!startingScene}
                >
                  <FontAwesomeIcon icon={faCloudDownloadAlt} /> Download 360 photo
                </ActionLink>
              </li>
              <li>
                Click to copy the Realvision link:
                <ClickToCopy className={styles.copy} value={tourUrl} />
              </li>
              <li>
                <ActionLink 
                  className={styles.open}
                  to="https://facebook.com"
                  target="_blank"
                >
                  Open Facebook
                </ActionLink> to post the 360 photo. Write a caption and <strong>paste the Realvision link</strong>.
              </li>
            </ol>
          </div>
        }
      </TabBox>
    </div>
  )
}

Facebook.Preview = [
  ({ title, description, image }) => {
    return (
      <div className={`${styles.page} ${tabStyles.mockup}`} style={{ backgroundImage: `url(${pageMockup})`}}>
        <img className={styles.image} src={image} alt="" />
        <div className={styles.metadata}>
          <div className={styles.site}>REAL.VISION</div>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    )
  },
  ({ startingScene }) => {
    return (
      <div className={`${styles.photo} ${tabStyles.mockup}`} style={{ backgroundImage: `url(${photoMockup})`}}>
        <div className={styles.stage}>
          <div className={styles.cube} style={startingScene && { '--yaw': startingScene.yaw + 'deg' }}>
            {startingScene && [0, 1, 2, 3, 4, 5].map(i => (
              <div key={i} 
                className={styles.face}
                style={{ backgroundImage: `url(${process.env.SCENE_DISTRIBUTION}${startingScene.code}/d.jpg?v=${startingScene.version})`}} />
            ))}
          </div>
        </div>
      </div>
    )
  }
]

Facebook.data = data;
export default Facebook;