import React, { useState, useRef, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { MorphBox } from 'app/_shared'

import { Instruction, Error, UnavailableMessage, WaitlistToggle } from '../_shared'

import { useBooking } from '../BookingContext'
import PhotographerSelect from './PhotographerSelect'
import DateSelect from './DateSelect'
import TimeSelect from './TimeSelect'
import styles from './ScheduleForm.module.scss'

export default function ScheduleForm({ active, orderId, photographers, errors, originalSelectedTime, originalTravelCost }) {
  const timeSelectRef = useRef();
  const { orderState } = useBooking();
  const { values: { schedule }, isSubmitting, isValid } = useFormikContext();
  const [selectedDate, setSelectedDate] = useState();
  const selectedTime = schedule && schedule.selectedTime;

  // for the purposes of scheduling, if there only a single photographer, he is treated as selected.
  const selectedPhotographer = photographers.find(t => t.id === schedule?.selectedPhotographerId || photographers.length === 1);

  useEffect(() => { selectedTime && setSelectedDate(selectedTime.localDate)}, [selectedTime]);

  const onDateSelection = (date) => {
    if (active && !isSubmitting) {
      setSelectedDate(date);
      timeSelectRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return photographers.length === 0 ? (
    <UnavailableMessage address={orderState && orderState.address.userAddress} />
  ) : (
    <div className={styles.form}>
      <Error className={styles.error}>{errors && errors.length > 0 ? errors[0] : null}</Error>
      <Instruction
        className={styles.instruction}
        pointing="down"
        highlight={!isValid}
        caretPosition={{ left: isValid ? "50%" : selectedDate ? "80%" : "20%" }}
      >
        Choose a date and time for your appointment.
      </Instruction>
      <div className={styles.datetime}>
        <DateSelect
          onDateChange={onDateSelection}
          selectedDate={selectedDate}
        />
        <TimeSelect
          orderId={orderId}
          active={active}
          selectedDate={selectedDate}
          photographers={photographers}
          selectedPhotographer={selectedPhotographer}
          originalSelectedTime={originalSelectedTime}
          originalTravelCost={originalTravelCost}
          divRef={timeSelectRef}
        />
      </div>
      <MorphBox className={styles.options}>
        <PhotographerSelect
          className={styles.photographer}
          active={active}
          photographers={photographers}
          selectedPhotographer={selectedPhotographer}
        />
        { selectedTime &&
          <WaitlistToggle className={styles.waitlist} />
        }
      </MorphBox>
    </div>
  );
}
