import React, { useContext, useEffect, useCallback } from 'react'

import { useFlags, useAdminElement, ActionLink } from 'app/_shared'
import { useRoute, useListingData } from 'app/layout'

const ListingContext = React.createContext();

export default ({ children }) => {
  const { layoutNav: { path: layoutPath, go: layoutGo }, tourName, section, subSection, locationState } = useRoute();
  const { cache } = useListingData();
  const listing = cache[tourName];
  
  const { unbranded, embedded, minimalNav, tourOnly } = useFlags();
  const adminElementEnabled = useAdminElement();
  
  let thisListing = typeof listing === 'object' ? listing : undefined;
  let error = typeof listing === 'object' ? undefined : listing;

  const photosGalleryAdmin = thisListing?.permissions?.includes('gallery');
  const enabledSections = [];
  !tourOnly && (!embedded || !minimalNav) && enabledSections.push(Section.INFO);
  thisListing?.tourReady && enabledSections.push(Section.TOUR);
  !tourOnly && thisListing?.floorplan?.floors?.[0] && enabledSections.push(Section.FLOORPLAN);
  !tourOnly && thisListing?.photos && (thisListing.photos.some(t => !t.hidden) || (photosGalleryAdmin && adminElementEnabled !== false)) && enabledSections.push(Section.PHOTOS);
  !tourOnly && thisListing?.permissions.includes('order') && !unbranded && !(minimalNav && embedded) && enabledSections.push(Section.DASHBOARD);

  // TODO: populate startingFloor from the server, or from the tour config once it has loaded 
  // (but note that floor plans are usually displayed before tour config is loaded)
  const startingFloor = enabledSections.includes(Section.FLOORPLAN) && (thisListing.floorplan.floors.find(t => t.name.toLowerCase() === 'main') || thisListing.floorplan.floors[0]);

  if (thisListing && enabledSections.length === 0) {
    thisListing = undefined;
    error = "Listing cannot be viewed.";
  }

  // Show the selector only if at least one of TOUR, FLOORPLAN and PHOTOS is enabled AND more than one section is enabled.
  const showSelector = enabledSections.length > 1 && !!enabledSections.find(v => [Section.TOUR, Section.FLOORPLAN, Section.PHOTOS].includes(v));
  const dashboardActive = enabledSections.includes(Section.DASHBOARD) && (section === Section.DASHBOARD || !showSelector);

  const listingNav = useCallback((to, go) => {
    const stringOrCurrent = (test, current) => typeof test === 'string' ? test : current;

    // NOTE: subSection is erased if section is specified and subSection is not specified.
    let targetSection = stringOrCurrent(to.section, section);
    const targetSubSection = stringOrCurrent(to.subSection, (typeof to.section === 'string' ? '' : subSection));

    // undelivered listings defaults to the status subsection of dashboard.
    if (!showSelector && targetSection === Section.DASHBOARD && targetSubSection === '') {
      targetSection = Section.NONE;
    }

    // TODO: update internal state when layoutNav is undefined, indicating tour navigation
    // should not be path-based.
    return (go ? layoutGo : layoutPath)({ section: targetSection, subSection: targetSubSection, state: to.state, replace: to.replace });
  }, [section, subSection, showSelector, layoutGo, layoutPath]);

  useEffect(() => {
    if (thisListing && enabledSections.length > 0 && !enabledSections.includes(section)) {
      listingNav({ section: enabledSections[0], replace: true }, true);
    }
    // TODO: for tours without content, also redirect /dashboard to /, but ensure there is no risk of an infinite loop.
  });

  const data = {
    // general purpose props used by numerous components
    activeSection: section,
    activeSubSection: subSection,
    listing: thisListing,
    error,
    enabledSections,
    photosGalleryAdmin,
    startingFloor,
    showSelector,
    dashboardActive,
    listingNav,

    // used in TourSection to set the target view
    targetPhoto: locationState?.targetPhoto
  };

  return (
    <ListingContext.Provider value={data}>
      {children}
    </ListingContext.Provider>
  );
}

export const useListing = () => useContext(ListingContext);

// TODO: move this to the ListingLayout and use this for the links in the List components as well, and
// consolidate the pagination controls.
export const ListingLink = ({ to, ...rest }) => {
  const { listingNav } = useListing();

  // TODO: replace the ActionLink's to prop with an onClick if listing state is not nav-based.
  return (
    <ActionLink {...rest} to={listingNav(to)} />
  )
}

export const Section = {
  NONE: '',
  INFO: '',
  TOUR: 'tour',
  PHOTOS: 'photos',
  FLOORPLAN: 'floorplan',
  DASHBOARD: 'dashboard'
}