import React, { useMemo } from 'react'
import { Logo, useFlags, ActionLink } from 'app/_shared'

import styles from './LogoLink.module.scss'

export default ({ className }) => {
  const { embedded, fullPath } = useFlags();
  return useMemo(() => (
    <ActionLink 
      className={`${styles.logo} ${className || ''}`} 
      to={embedded ? fullPath : '/'}
      target={embedded ? '_blank' : undefined}
    >
      <Logo />
    </ActionLink>
  ), [embedded, fullPath, className])
}