import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import DateBox from '../Confirm/DateBox'
import styles from './ChangeSummary.module.scss'

export default ({ originalTime, newTime }) => (
  <div className={styles.times}>
    { originalTime && 
      <div className={styles.original}>
        <h4>Original time</h4>
        <DateBox datetime={originalTime} />
      </div>
    }
    { newTime &&
      <>
        { originalTime &&
          <FontAwesomeIcon icon={faArrowRight} />
        }
        <div>
          <h4>New time</h4>
          <DateBox datetime={newTime} />
        </div>
      </>
    }
  </div>
)