import React, { useState } from 'react'
import { Formik, Form } from 'formik'

import { ActionLink, TabBox, FieldSet, Emoji } from 'app/_shared'
import { submitRating, submitComment } from '../../../service'

import styles from './Survey.module.scss'

export const reactions = ["😠", "😟", "😕", "🙂", "😄"];

export default ({ orderId, photographerName, submission, className }) => {
  const [tab, setTab] = useState((!submission || !submission.rating) ? 'rating' : !submission.comment ? 'comment' : 'thank');

  return (
    <div className={`${styles.survey} ${className || ''}`}>
      <div className={styles.inner}>
        <TabBox activeTab={tab}>
          <div className={styles.rating} tabkey="rating">
            <h3>How did {photographerName || 'we'} do at this appointment?</h3>
            <div>
              {reactions.map((t, i) => (
                <ActionLink type="button" key={i} onClick={() => {
                  submitRating(orderId, i + 1)
                  setTab('comment');
                }}>
                  <Emoji symbol={t} />
                </ActionLink>
              ))}
            </div>
          </div>
          <div className={styles.comments} tabkey="comment">
            <h3>Do you have any specific feedback about this appointment?</h3>
            <Formik
              onSubmit={(values) => { 
                submitComment(orderId, values.comment);
                setTab('thank');
              }}
              initialValues={{ comment: '' }}
            >
              {formik => (
                <Form>
                  <FieldSet name="comment" placeholder="Your comments" component="textarea" rows="5" />
                  <ActionLink appearance="button" onClick={formik.handleSubmit}>Submit</ActionLink>
                </Form>
              )}
            </Formik>
          </div>
          <div className={styles.thank} tabkey="thank">
            Thanks for giving us your feedback!
          </div>
        </TabBox>
      </div>
    </div>
  )
}