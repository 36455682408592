import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { ActionLink, Throbber } from 'app/_shared'

import styles from './Payment.module.scss'

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const PaymentForm = ({ amount, currency, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setSubmitting(true);
    const { error } = await stripe.confirmPayment({
      elements,
      // TODO: build redirect recapture mechanism
      redirect: 'if_required'
    });
    setSubmitting(false);

    if (error) {
      setErrorMessage(error.message);
    }
    else {
      onSuccess();
    }
  }

  return stripe && elements && (
    <form onSubmit={handleSubmit} className={styles.form}>
      <PaymentElement />
      <ActionLink disabled={submitting} type="submit" className={styles.button} appearance="button">
        Pay ${(amount).toLocaleString()} {currency.toUpperCase()}
      </ActionLink>
      { errorMessage && (
        <div>
          { errorMessage }
        </div>
      )}
    </form>
  )
}

export default ({ amount, currency, show, className, onSuccess }) => {
  const [stripeIntentSecret, setStripeIntentSecret] = useState(null);
  
  useEffect(() => {
    setStripeIntentSecret(null);
    if (show) {
      let abort = false;
      // TODO: amount and currency calculated server-side
      fetch(`${process.env.FINANCE_API_ENDPOINT}/subscription/charge/secret?amount=${amount * 100}&currency=${currency}`)
        .then(result => result.json())
        .then(({ secret }) =>!abort && setStripeIntentSecret(secret));
      return () => abort = true;
    }
  }, [show, amount, currency]);

  return (
    <div className={clsx(styles.container, className)}>
      { stripeIntentSecret ? (
        <Elements stripe={stripePromise} options={{ clientSecret: stripeIntentSecret }}>
          <PaymentForm amount={amount} currency={currency} onSuccess={onSuccess} />
        </Elements>
      ) : (
        <Throbber active />
      )}
    </div>
  )
}