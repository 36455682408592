import React from 'react'
import IntersectionSensor from './IntersectionSensor'

// A video that automatically plays when a portion of it is showing on screen 
// (defined by threshold having a value from 0 to 1) and paused otherwise.
export default ({ threshold, src, className }) => (
  <IntersectionSensor
    threshold={threshold}
    render={({ ref, isIntersecting }) => {
      ref.current && ref.current[ isIntersecting ? 'play' : 'pause' ]();
      return (
        <video muted loop ref={ref} className={className}>
          <source src={src} type="video/mp4" />
        </video>
      )
    }}
  />
)