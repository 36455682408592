import React from 'react'

import { ActionLink } from 'app/_shared'

import styles from './SubTabSelector.module.scss'

export default ({ subTabs, activeSubTab, setActiveSubTab }) => {
  return (
    <div className={styles.selector}>
      {subTabs.map(t => (
        <ActionLink
          key={t}
          className={`${styles.item} ${activeSubTab === t ? styles.active : ''}`} 
          onClick={() => setActiveSubTab(t)}
        >
          {t}
        </ActionLink>
      ))}
    </div>
  )
}