import React from 'react'
import { ElementQueries } from 'css-element-queries'
import smoothscroll from 'smoothscroll-polyfill'
import App from 'app'

import 'app/global.scss'

export const wrapPageElement = ({ element, props }) => {
  return (
    <App {...props} >
      {element}
    </App>
  );
}

export const onInitialClientRender = () => {
  require('intersection-observer');
  ElementQueries.init();
  smoothscroll.polyfill();
  

  // When a text input element (input or textarea) has focus and the window resizes 
  // (typically due to a virtual keyboard appearing on mobile devices), recenter the focused input.
  window.addEventListener('resize', () => 
    document.activeElement && 
    document.activeElement.selectionStart !== undefined && 
    document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  );
}

export const onServiceWorkerUpdateReady = () => {
  console.log(new Date().toISOString() + ": app update available.")
}