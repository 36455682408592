import React, { useEffect, useState, useMemo, useContext } from 'react'

import { useListing } from 'app/listing'
import { getConfirmedOrders } from 'app/booking'

const DashboardContext = React.createContext({});

export default ({ children }) => {
  const { listing: { tourName, status } } = useListing();
  const hasOrders = status.orderEvents.some(t => t.type === 'appointment' || t.type === 'cancelled');

  const [orderData, setOrderData] = useState();
  useEffect(() => {
    if (hasOrders && (!orderData || orderData.tourName !== tourName)) {
      let abort = false;
      setOrderData(undefined);
      getConfirmedOrders(tourName).then(orders => !abort && setOrderData({ tourName, orders: orders.sort((a, b) => a.info.confirmedDate < b.info.confirmedDate ? 1 : -1) }));
      return () => abort = true;
    }
  }, [tourName, hasOrders, orderData]);

  const orders = orderData ? orderData.orders : hasOrders ? [] : undefined;
  const state = useMemo(() => ({
    updateOrder: order => {
      setOrderData(prevOrderData => {
        if (prevOrderData) {
          const orders = [...prevOrderData.orders];
          const index = orders.findIndex(t => t.info.id === order.info.id);
          if (index !== -1) {
            orders[index] = order;
            return { ...prevOrderData, orders };
          }
        }
        return prevOrderData;
      })
    },
    refetchOrders: () => setOrderData(undefined),
    orders
  }), [orders]);

  return (
    <DashboardContext.Provider value={state}>
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboard = () => useContext(DashboardContext);