import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

import { ActionLink } from 'app/_shared'
import { useRoute } from 'app/layout'

import styles from './ControllerButton.module.scss'

export default ({ className, mobile }) => {
  const { controller, layoutNav: { path }} = useRoute();
  const [lastController, setLastController] = useState(controller || 'explore');

  useEffect(() => {
    if (controller && controller !== lastController) {
      setLastController(controller);
    }
  }, [controller, lastController]);

  // NOTE: this button has two modes. When both the listing and the listing list are visible at the same time (screen is wide enough),
  // the button alternates between opening and closes the listing list. Otherwise (screen is narrow), the button only ever
  // opens the list and closes the listing.
  return (
    <ActionLink 
      appearance="pill"
      className={`${styles.button} ${className || ''}`}
      to={path(mobile ? { controller: lastController, tourName: '' } : { controller: controller ? '' : lastController })}
    >
      <FontAwesomeIcon icon={controller && !mobile ? faAngleDoubleLeft : faAngleDoubleRight} />
    </ActionLink>
  );
}