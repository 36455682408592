import React, { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import Switch from 'react-switch'

import * as service from '../../service'
import styles from './WaitlistToggle.module.scss'

// If given an order and updateOrder, component will keep its own state and 
// directly call the back end and update the order.
// Otherwise, component will be driven by an enclosing formik.
export default ({ order, updateOrder, className }) => {
  const [enabled, setEnabled] = useState(order?.schedule.waitlistEnabled);
  const formik = useFormikContext();

  useEffect(() => {
    if (order && enabled !== order.schedule.waitlistEnabled) {
      let abort = false;
      service.updateWaitlistEnabled(order.info.id, enabled).then(newOrder => !abort && updateOrder(newOrder));
      return () => abort = true;
    }
  }, [enabled, order, updateOrder]);

  return (order || formik) && (
    // eslint-disable-next-line
    <label className={`${styles.waitlist} ${className || ''}`}>
      <Switch
        className={styles.switch}
        checked={!!(order ? enabled : formik.values.schedule?.waitlistEnabled)}
        onChange={checked => order ? setEnabled(checked) : formik?.setFieldValue('schedule.waitlistEnabled', checked)} 
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        width={22 * 2}
        height={22}
        handleDiameter={22 - 4}
      />
      <span>Notify me if earlier time slots become available.</span>
    </label>
  )
}
