import React, { useState, useRef, useCallback } from 'react'

import { SizeSensor, Throbber, useFlags } from 'app/_shared'
import { useRoute } from 'app/layout'
import { Dashboard } from 'app/dashboard'

import MetadataContextProvider from './_shared/MetadataContext'
import ListingContextProvider, { useListing, Section } from './ListingContext'
import ShareContextProvider from './Share'
import TourContextProvider from './TourSection/TourContext'
import PhotosContextProvider from './PhotosSection/PhotosContext'

import Header from './Header'
import Selector from './Selector'
import InfoSection from './InfoSection'
import TourSection from './TourSection'
import PhotosSection from './PhotosSection'
import FloorPlanSection from './FloorPlanSection'
import Error from './Error'
import PostMessageApi from './PostMessageApi'

import styles from './index.module.scss'

const Listing = ({ className }) => {
  const [wideLayout, setWideLayout] = useState();
  const [floatingFloorPlanActive, setFloatingFloorPlanActive] = useState(window.innerWidth > 640);
  const containerRef = useRef();
  const { tourName } = useRoute();
  const { listing, error, showSelector, activeSection, activeSubSection, enabledSections } = useListing();
  const { unbranded, tourOnly, embedded, enableJsApi } = useFlags();
  
  const onHeaderResize = useCallback(sensor => {
    containerRef.current.style.setProperty('--header-blank-height', sensor.offsetHeight + 'px');
  }, []);

  const onResize = useCallback(sensor => {
    setWideLayout(sensor.offsetWidth >= 1000);
  }, []);

  return (
    <SizeSensor 
      className={`${styles.listing} ${wideLayout ? styles.wide : ''} ${className || ''}`}
      ref={containerRef}
      onResize={onResize}
    >
      { embedded && enableJsApi &&
        <PostMessageApi />
      }
      <div className={`${styles.content} ${showSelector || !tourName || error || tourOnly ? '' : styles.scroll}`}>
        <Throbber className={styles.throbber} active={tourName && !listing && !error} />
        { showSelector &&
          <Selector 
            className={[
              styles.selector,
              activeSection === Section.INFO || activeSection === Section.TOUR || (activeSection === Section.PHOTOS && activeSubSection) ? styles.invert : '',
              wideLayout && unbranded && activeSection === Section.INFO ? styles.wide : ''
            ].join(' ')}
          />
        }
        { enabledSections.includes(Section.INFO) &&
          <InfoSection 
            className={`${styles.section} ${styles.info}`}
            wideLayout={wideLayout}
          />
        }
        { enabledSections.includes(Section.TOUR) &&
          <TourSection 
            className={`${styles.section} ${styles.tour}`}
            wideLayout={wideLayout}
            enableFloatingFloorPlan={floatingFloorPlanActive ? undefined : () => setFloatingFloorPlanActive(true)}
          />
        }
        { enabledSections.includes(Section.FLOORPLAN) &&
          <FloorPlanSection
            className={`${styles.section} ${styles.floorplan}`}
            wideLayout={wideLayout}
            disableFloatingFloorPlan={floatingFloorPlanActive ? () => setFloatingFloorPlanActive(false) : undefined}
          />
        }
        { enabledSections.includes(Section.PHOTOS) &&
          <PhotosSection className={styles.section}/>
        }
        { enabledSections.includes(Section.DASHBOARD) &&
          <Dashboard
            key={listing.tourName} // force a rerender when changing listings to avoid awkward transitions especially between delivered and undelivered listings.
            className={`${styles.section} ${styles.dashboard} ${activeSection === Section.DASHBOARD || !showSelector ? '' : styles.hidden}`}
          />
        }
        { error && (
          <Error />
        )}
      </div>
      { tourName && !(unbranded && activeSection === Section.INFO && !listing) && !tourOnly &&
        // NOTE: the styles.content div does not have a z-index, so that its children (selector and sections) and 
        // its siblings (Header, in both sidebar and top bar forms) are on the same stack context. 
        // This way, in wide layout, Header's drop shadow appears over content, content's child (TourSection or 
        // FloorPlanSection) appears over Header on the sidebar, and header's descendent Share appears over content's child.
        // Therefore ordering is important and Header is at the end over everything here in order to be on top of its siblings
        // without needing an explicit z-index.
        <Header 
          className={styles.header}
          onResize={onHeaderResize}
          wideLayout={wideLayout}
        />
      }
    </SizeSensor>
  )
}

export default ({ className }) => (
  <ListingContextProvider>
    <TourContextProvider>
      <PhotosContextProvider>
        <MetadataContextProvider>
          <ShareContextProvider>
            <Listing className={className} />
          </ShareContextProvider>
        </MetadataContextProvider>
      </PhotosContextProvider>
    </TourContextProvider>
  </ListingContextProvider> 
)
