import React, { useRef, useEffect, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, LinearProgress, SizeSensor } from 'app/_shared'
import { Tile } from 'app/listing'

import { useRoute } from './RouteContext'
import styles from './List.module.scss'

export default ({ allListings, pagination, filterInput, header, footer, className }) => {
  const scrollRef = useRef();
  const activeRef = useRef();
  const scrollBehavior = useRef('auto');
  const { tourName, layoutNav: { go: layoutGo, path }} = useRoute();

  const hasListings = !!allListings;
  const { totalCount, pageNumber, pageSize } = pagination || {};
  
  const centerActiveRef = useCallback(() => {
    if (activeRef.current) {
      let item = activeRef.current.parentNode;
      let scrollTop = item.offsetTop + item.offsetHeight / 2 - scrollRef.current.offsetHeight / 2;
      scrollRef.current.scroll({ top: scrollTop, behavior: scrollBehavior.current });
    }
  }, []);

  useEffect(() => {
    if (!hasListings) {
      scrollRef.current.scroll({ top: 0 });
    }
  }, [hasListings]);
  useEffect(centerActiveRef, [tourName, hasListings]);
  useEffect(() => {
    scrollBehavior.current = hasListings ? 'smooth' : 'auto';
  }, [hasListings]);

  const pageCount = Math.ceil(totalCount / pageSize);
  const total = totalCount || (allListings && allListings.length);

  return (
    <SizeSensor className={`${styles.container} ${className || ''}`} onResize={centerActiveRef}>
      <div className={styles.toolbar}>
        { filterInput }
        <div className={styles.count}>
          { allListings ? `${total} listing${total === 1 ? '' : 's'}` : 'Loading...' }
        </div>
        { !allListings && (
          <LinearProgress className={styles.progress} />
        )}
      </div>
      <div className={styles.listings} ref={scrollRef}>
        { header &&
          <div className={styles.header}>
            { header }
          </div>
        }
        <div className={styles.list}>
          { allListings ? allListings.map(t =>
            <ActionLink to={path({ tourName: t.tourName })} key={t.tourName} className={t.tourName === tourName ? styles.active : undefined} >
              <Tile divRef={t.tourName === tourName ? activeRef : undefined} listing={t} />
            </ActionLink>
          ) : Array.from(Array(9)).map((_, i) =>
            <Tile key={i}/>
          )}
        </div>
        { footer &&
          <div className={styles.footer}>
            { footer }
          </div>
        }
      </div>
      { pageCount > 1 &&
        // NOTE: internally, page numbers in ReactPaginate is zero-indexed, except for the buttons displayed
        // to users, which are one-indexed. Outside this component, we choose to be consistently one-indexed.
        <ReactPaginate 
          containerClassName={styles.paginate}
          pageLinkClassName={styles.page}
          activeClassName={styles.active}
          forcePage={pageNumber - 1}
          pageCount={pageCount}
          onPageChange={({ selected }) => layoutGo({ pageNumber: selected + 1 })}
          nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
          previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
          previousLinkClassName={styles.arrow}
          nextLinkClassName={styles.arrow}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
        />
      }
    </SizeSensor>
  )
}