import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faCheck } from '@fortawesome/free-solid-svg-icons'
import { ActionLink, MorphBox } from 'app/_shared'
import styles from './Button.module.scss'

export default ({ id, icon, singleUse, hidden, Component = ActionLink, children, className, onClick, hotkey, ...rest }) => {
  const [pendingAction, setPendingAction] = useState();
  const [complete, setComplete] = useState();

  useEffect(() => {
    setPendingAction(undefined);
    setComplete(false);
  }, [id]);

  const hotkeyActive = hotkey && !pendingAction && !complete && onClick;
  useEffect(() => {
    if (hotkeyActive) {
      const onPress = e => e.key.toLowerCase() === hotkey.toLowerCase() && setPendingAction(onClick());
      document.addEventListener('keypress', onPress);
      return () => document.removeEventListener('keypress', onPress);
    }
  }, [hotkeyActive, hotkey, onClick]);
  
  useEffect(() => {
    if (pendingAction) {
      let abort = false;
      const finish = () => {
        if (!abort) {
          setPendingAction(undefined);
          if (singleUse) {
            setComplete(true);
          }
        }
      }
      if (pendingAction.then) {
        pendingAction.then(finish);
      }
      else {
        finish();
      }
      return () => abort = true;
    }
  }, [pendingAction, singleUse]);

  useEffect(() => {
    if (complete && hidden) {
      const hideTimeout = setTimeout(() => setComplete(false), 1000);
      return () => clearTimeout(hideTimeout);
    }
  }, [complete, hidden]);

  if (typeof children === 'string' && hotkeyActive) {
    const index = children.toLowerCase().indexOf(hotkey.toLowerCase());
    if (index > -1) {
      children = <>
        {children.slice(0, index)}
        <i>{hotkey}</i>
        {children.slice(index + 1)}
      </>
    }
  }

  const button = (
    <Component
      className={`${styles.button} ${(onClick && !pendingAction && !complete) || rest.to ? '' : styles.inert} ${className || ''}`}
      onClick={(onClick && !pendingAction && !complete) ? () => setPendingAction(onClick()) : undefined}
      {...rest}
    >
      { icon?.icon ? (
        <FontAwesomeIcon 
          icon={complete ? faCheck : (pendingAction && pendingAction.then) ? faCircleNotch : icon}
          spin={(pendingAction && pendingAction.then) && !complete}
        />
      ) : icon}
      <span>{children}</span>
    </Component>
  );

  const hide = !complete && hidden;

  return hidden !== undefined ? (
    <MorphBox collapseDirection="width" className={`${styles.morph} ${hide ? styles.hidden : ''}`}>
      { !hide && button }
    </MorphBox>
  ) : button;
}