import React from 'react'

import { InlineScroll } from 'app/_shared'
import { useListing, ListingLink, Section } from 'app/listing'

import styles from './Selector.module.scss'

const titles = {
  '': 'status',
  'orders': 'Orders',
  'photos': 'Manage Photos'
}

export default ({ enabledSubSections }) => {
  const { activeSubSection, dashboardActive } = useListing();
  return enabledSubSections.length > 1 ? (
    <InlineScroll className={styles.selector}>
      { ({ centerOn }) => enabledSubSections.map(t =>
        <ListingLink 
          key={t}
          className={`${styles.tab} ${dashboardActive && activeSubSection === t ? styles.active : ''}`} 
          to={{section: Section.DASHBOARD, subSection: t}}
          onClick={e => centerOn(e.target)}
        >
          {titles[t] || t}
        </ListingLink>
      )}
    </InlineScroll>
  ) : null;
}