import React, { useState, useEffect } from 'react'

import { InlineScroll, ActionLink, MorphBox } from 'app/_shared'

import { useTour } from './TourContext'
import styles from './SettingSelector.module.scss'

export default ({ className }) => {
  const { tour, tourConfig } = useTour();

  const [currentGroup, setCurrentGroup] = useState();
  useEffect(() => {
    if (tour) {
      const moveHandler = ({ data }) => {
        setCurrentGroup(currentGroup => {
          const scene = tourConfig.scenesByCode[data.targetScene];
          const oldGroupName = currentGroup && currentGroup.name;
          const newGroupName = scene && scene.group;
          if (!newGroupName) {
            return undefined;
          }
          else if (newGroupName !== oldGroupName) {
            return {
              name: newGroupName,
              settings: Array.from(new Set(Object.values(tourConfig.scenesByCode).filter(t => t.group === newGroupName).map(t => t.setting))),
              activeSetting: scene.setting
            };
          }
          else if (scene.setting !== currentGroup.activeSetting) {
            return {
              ...currentGroup,
              activeSetting: scene.setting
            }
          }
          else {
            return currentGroup;
          }
        });
      }
      tour.addEventListener('move', moveHandler);
      return () => {
        setCurrentGroup(undefined);
        tour.removeEventListener('move', moveHandler);
      }
    }
  }, [tour, tourConfig]);

  return (
    <MorphBox className={`${styles.box} ${!currentGroup ? styles.inactive : ''} ${className || ''}`} morphSameId>
      { currentGroup &&
        <InlineScroll className={styles.scroller}>
          {({ centerOn }) => (
            <div className={styles.selector}>
              {currentGroup.settings.map(setting => (
                <ActionLink 
                  key={setting} 
                  className={`${styles.item} ${currentGroup.activeSetting === setting ? styles.active : ''}`} 
                  onClick={e => {
                    centerOn(e.currentTarget);
                    tour.jumpTo({ group: currentGroup.name, setting });
                  }}
                >
                  { setting }
                </ActionLink>
              ))}
            </div>
          )}
        </InlineScroll>
      }
    </MorphBox>
  );
}
