import { useEffect } from 'react'
import { useTour } from './TourSection/TourContext'

export default () => {
  const { tourConfig, tour } = useTour();
    
  // Use the PostMessage API to communicate with a parent window.
  useEffect(() => {
    if (tour) {
      const moveHandler = ({ data }) => {
        const scene = tourConfig.scenesByCode[data.targetScene];
        window.parent.postMessage({
          function: 'move',
          scene: data.targetScene,
          sceneYaw: scene?.yaw ?? 0,
          x: scene?.x ?? 0,
          y: scene?.y ?? 0,
          z: scene?.z ?? 0,
        }, "*");
      }

      const rotateHandler = ({ data }) => {
        window.parent.postMessage({
          function: 'rotate',
          direction: data.direction
        }, "*");
      }

      const messageHandler = e => {
        const message = e.data;
        switch (message.function) {
          case 'jump':
            tour.jumpTo({
              sceneCode: message.scene,
              pan: message.direction[0],
              tilt: message.direction[1]
            });
            break;
          case 'move':
            tour.jumpTo({
              sceneCode: message.scene,
            });
            break;
          case 'rotate':
            tour.jumpTo({
              pan: message.direction[0],
              tilt: message.direction[1]
            });
            break;
          default: // nothing
        }
      }

      tour.addEventListener('move', moveHandler);
      tour.addEventListener('rotate', rotateHandler);
      window.addEventListener('message', messageHandler);

      return () => {
        tour.removeEventListener('move', moveHandler);
        tour.removeEventListener('rotate', rotateHandler);
        window.removeEventListener('message', messageHandler)
      }
    }
  }, [tour, tourConfig]);

  return null;
}