import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { FieldSet, ActionLink, Throbber } from 'app/_shared'
import { useActiveUser } from 'app/user'

import Error from './Error'
import * as service from '../service'
import styles from './UserContext.module.scss'

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Required')
});

export default ({ close }) => {
  const activeUser = useActiveUser();
  const [sendStatus, setSendStatus] = useState('SENDING');

  useEffect(() => {
    let abort = false;
    service.sendVerifyCode().then(() => {
      !abort && setSendStatus('SENT');
    }, () => {
      !abort && setSendStatus('ERROR');
    });
    return () => abort = true;
  }, [])

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <h1>Verify your email</h1>
        <div className={styles.subtext}>
        <ActionLink appearance="arrow" onClick={close}>
          Skip
        </ActionLink>
        </div>
      </div>
      <Formik 
        initialValues={{code: ''}}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          try {
            actions.setStatus({ error: undefined });
            await service.verify(values);
            close();
          }
          catch({ fieldErrors, statusError, message }) {
            actions.setSubmitting(false);
            fieldErrors && actions.setErrors(fieldErrors);
            (statusError || message) && actions.setStatus({ error: statusError || message });
          }
        }}
      >
        {({ isSubmitting, status }) => (
          <Form>
            { sendStatus === 'ERROR' ? (
              <>
                <p>Failed to send a verification code.</p>
                <p>
                  <ActionLink appearance="link" onClick={close}>
                    Skip verification for now.
                  </ActionLink>
                </p>
              </>
            ) : (
              <>
                <p>
                  We've sent a code to <em>{activeUser?.email}</em> to verify you own this email address. 
                </p>
                <p>
                  Please enter it below to ensure you always receive the best pricing customized for you, and to access additional account features.
                </p>
                <FieldSet name="code" label="Verification code" />
                <Error />
                <ActionLink type="submit" appearance="button" disabled={isSubmitting}>Verify</ActionLink>
              </>
            )}
            <Throbber active={sendStatus === 'SENDING'} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
