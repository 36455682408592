import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStreetView, faQuestion } from '@fortawesome/free-solid-svg-icons'

import { Throbber, ActionLink, useFlags, useHint } from 'app/_shared'

import { useTour } from './TourContext'
import SettingSelector from './SettingSelector'
import TourHints from './TourHints'
import AutoPlayButton from './AutoPlayButton'
import styles from './TourViewer.module.scss'

export default ({ isSidebar, wideLayout, thumbnails, enableFloatingFloorPlan, className }) => {
  const tourContainer = useRef();

  const { disableAutoplay, tourOnly } = useFlags();
  const { dismiss, restart } = useHint();
  const { tour, tourConfig, setTour, tourLoaded, setTourLoaded } = useTour();

  const [imageLoading, setImageLoading] = useState(true);

  // Create a 3D tour in the DOM; destroy it when the tourConfig changes.
  useEffect(() => {
    setImageLoading(true);
    if (tourConfig && typeof window !== 'undefined') {
      let loadingTimeout;
      const container = tourContainer.current;

      const options = {
        imageSource: process.env.SCENE_DISTRIBUTION,
        hideArrows: false,
        imageLoading: loading => {
          if (loading) {
            loadingTimeout = setTimeout(() => setImageLoading(true), 500);
          }
          else {
            clearTimeout(loadingTimeout);
            setImageLoading(false);
          }
        }
      };

      const RealvisionTour = require('./core/Tour.js').default;
      const tour = new RealvisionTour(container, tourConfig, options);
      setTour(tour);
    
      return () => {
        setTour(undefined);
        tour.destroy();
      };
    }
  }, [tourConfig, setTour]);
  
  // update the listing context state when the tour finishes loading its first scene
  useEffect(() => {
    !tourLoaded && !imageLoading && setTourLoaded(true);
  }, [tourLoaded, imageLoading, setTourLoaded]);

  return (
    <div className={`${styles.viewer} ${isSidebar ? styles.sidebar : ''} ${className || ''}`}>
      { tourLoaded && !tourOnly &&
        <TourHints 
          wideLayout={wideLayout} 
          isSidebar={isSidebar}
          hasThumbnails={thumbnails && thumbnails.length > 0}
          floatingFloorPlanActive={!enableFloatingFloorPlan}
        />
      }
      <div className={styles.inner} ref={tourContainer}></div>
      <Throbber className={styles.throbber} active={imageLoading} />
      { tour && 
        <>
          { !tourOnly && 
            <div className={styles.toolbar}>
              <ActionLink appearance="white pill" onClick={restart} >
                <FontAwesomeIcon icon={faQuestion} fixedWidth/>
              </ActionLink>
              { !disableAutoplay &&
                <AutoPlayButton thumbnails={thumbnails}/>
              }
              { !wideLayout && 
                <ActionLink 
                  appearance="white pill" 
                  onClick={() => {
                    if (enableFloatingFloorPlan) {
                      enableFloatingFloorPlan();
                      dismiss("tourOpenFloatingFloorPlan");
                    };
                  }} 
                  className={enableFloatingFloorPlan ? '' : styles.inactive}>
                  <FontAwesomeIcon icon={ faStreetView } fixedWidth />
                </ActionLink>
              }
            </div>
          }
          <SettingSelector className={styles.settings} />
        </>
      }
    </div>
  );
}
