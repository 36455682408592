import React from 'react'

import { ActionLink, wrapAdminElement } from 'app/_shared'
import { useRoute } from 'app/layout'
import { WaitlistToggle } from 'app/booking'

import { useDashboard } from '../../DashboardContext'
import styles from './Reschedule.module.scss'

export default ({ admin, orderId, className }) => {
  const { layoutNav: { path }} = useRoute();
  const { orders, updateOrder } = useDashboard();
  const currentOrder = orders.find(t => t.info.id === orderId);

  return wrapAdminElement((
    <div className={`${styles.reschedule} ${admin ? styles.admin : ''} ${className || ''}`}>
      { currentOrder &&
        <WaitlistToggle className={styles.waitlist} order={currentOrder} updateOrder={updateOrder} />
      }
      <ActionLink appearance="link" to={path({ modal: `reschedule_${orderId}` })}>
        Reschedule
      </ActionLink>
      <ActionLink appearance="link" to={path({ modal: `cancel_${orderId}` })}>
        Cancel
      </ActionLink>
    </div>
  ), !admin);
}

