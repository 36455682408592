import React, { useState, useEffect } from 'react'

import { ActionLink } from 'app/_shared'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt, faCircleNotch, faCheck } from '@fortawesome/free-solid-svg-icons'

import { downloadZip } from 'app/listing'

import styles from './DownloadPhotos.module.scss'

export default ({ tourName, size, className }) => {
  const [loading, setLoading] = useState(false);
  const [started, setStarted] = useState(false);

  const onClick = async () => {
    setLoading(true);
    await downloadZip(tourName, size);
    setStarted(true);
  };

  useEffect(() => {
    setLoading(false);
    setStarted(false);
  }, [tourName]);

  return (
    <ActionLink 
      className={`${styles.download} ${className || ''}`}
      appearance={"pill"}
      disabled={loading || started} 
      onClick={loading || started ? undefined : onClick}
    >
      { started ? (
        <>
          <FontAwesomeIcon icon={faCheck} className={styles.check} fixedWidth /> Download started
        </>
      ) : loading ? (
        <>
          <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth /> Preparing zip file...
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faCloudDownloadAlt} /> Download {size} size photos
        </>
      )}
    </ActionLink>
  )
}