import React from 'react'
import { Emoji } from 'app/_shared'
import styles from './Message.module.scss'

export default () => (
  <div className={styles.container}>
    <h1>
      <Emoji symbol="🌎" /> Get inspired!
    </h1>
    <p>
      Check out some Realvision listings by some of the industry's most successful real estate agents.
    </p>
  </div>
)