import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faAngleDoubleRight, faPlay, faStreetView } from '@fortawesome/free-solid-svg-icons'
import VimeoPlayer from '@vimeo/player'

import { ActionLink, Throbber } from 'app/_shared'

import { ListingLink, Section } from '../ListingContext'
import Photo from '../PhotosSection/Photo'
import styles from './ListingVideo.module.scss'

let loadYouTubeApi = undefined;
const getYT = () => {
  if (!loadYouTubeApi) {
    loadYouTubeApi = new Promise(resolve => {
      if (typeof window !== 'undefined') {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        window.onYouTubeIframeAPIReady = () => resolve(window.YT);
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
      else {
        resolve();
      }
    });
  }
  return loadYouTubeApi;
}

const PlayerState = {
  IDLE: -3, // iframe is not showing; overlay shows preview
  INITIALIZING: -2, // iframe is showing, player is initializing; overlay shows preview plus throbber

  // the following correspond to the player state from the YouTube API.
  UNSTARTED: -1, // no overlay
  ENDED: 0, // whole overlay with links to restart (click to play) and to other sections, no click
  PLAYING: 1, // no overlay
  PAUSED: 2, // partial overlay, with links to other sections, no click
  BUFFERING: 3, // no overlay
}

export default React.forwardRef(({ className, externalLink, url, active, style, onMore }, ref) => {
  const iframeRef = useRef();
  const [iframeSrc, setIframeSrc] = useState(active ? externalLink : undefined);

  const [playerState, setPlayerState] = useState();
  const player = useRef();

  useEffect(() => {
    if (active && externalLink !== iframeSrc) {
      setIframeSrc(externalLink);
    }
    if (!active && iframeSrc && externalLink !== iframeSrc) {
      setIframeSrc(undefined);
    }
  }, [externalLink, active, iframeSrc]);

  useEffect(() => {
    if (!active && player.current && (playerState === PlayerState.PLAYING || playerState === PlayerState.BUFFERING)) {
      player.current.pauseVideo();
    }
  }, [playerState, active]);

  useEffect(() => {
    let abort = false;
    if (iframeSrc) {
      if (iframeSrc.startsWith('https://www.youtube.com/embed/')) {
        getYT().then(YT => {
          YT && !abort && new YT.Player(iframeRef.current, {
            events: {
              'onReady': ({ target }) => {
                if (!abort) {
                  player.current = {
                    playVideo: () => target.playVideo(),
                    pauseVideo: () => target.pauseVideo(),
                    seekTo: t => target.seekTo(t)
                  };
                  setPlayerState(PlayerState.UNSTARTED);
                  target.addEventListener('onStateChange', ({ data }) => {
                    setPlayerState(data)
                  });
                }
              }
            }
          });
        });
      }
      else if (iframeSrc.startsWith('https://player.vimeo.com/video/')) {
        const vimeoPlayer = new VimeoPlayer(iframeRef.current);
        setPlayerState(PlayerState.UNSTARTED);
        player.current = {
          playVideo: () => vimeoPlayer.play(),
          pauseVideo: () => vimeoPlayer.pause(),
          seekTo: t => vimeoPlayer.setCurrentTime(t)
        };
        vimeoPlayer.on('play', () => setPlayerState(PlayerState.PLAYING));
        vimeoPlayer.on('bufferend', () => setPlayerState(PlayerState.PLAYING));
        vimeoPlayer.on('ended', () => setPlayerState(PlayerState.ENDED));
        vimeoPlayer.on('pause', () => setPlayerState(PlayerState.PAUSED));
        vimeoPlayer.on('bufferstart', () => setPlayerState(PlayerState.BUFFERING));
      }
      else {
        setPlayerState(PlayerState.UNSTARTED);
      }
    }
    return () => {
      abort = true;
      player.current = undefined;
      setPlayerState(undefined);
    }
  }, [iframeSrc]);

  const overlayState = playerState !== undefined ? playerState : (iframeSrc ? PlayerState.INITIALIZING : PlayerState.IDLE);

  return (
    <div ref={ref} style={style} className={`${styles.container} ${className || ''}`}>
      { iframeSrc &&
        <iframe title="video" ref={iframeRef} frameBorder="0" src={`${iframeSrc}?enablejsapi=1&rel=0`} allowFullScreen />
      }
      <div 
        className={styles.overlay} 
        data-state={overlayState}
      >
        { (overlayState === PlayerState.IDLE || overlayState === PlayerState.INITIALIZING || overlayState === PlayerState.ENDED) && 
          <Photo url={url} className={styles.background} />
        }
        <Throbber className={styles.throbber} active={overlayState === PlayerState.INITIALIZING} />
        <ActionLink className={styles.icon} onClick={() => {
          if (player.current) {
            player.current.seekTo(0);
            player.current.playVideo();
          }
        }}>
          <FontAwesomeIcon icon={faUndo} />
          <span>Replay</span>
        </ActionLink>
        <ListingLink className={styles.icon} to={{section: Section.TOUR}}>
          <FontAwesomeIcon icon={faStreetView} />
          <span>Explore</span>
        </ListingLink>
        { playerState !== PlayerState.ENDED ? (
          <ActionLink className={styles.icon} onClick={() => {
            if (player.current) {
              player.current.playVideo();
            }
          }}>
            <FontAwesomeIcon icon={faPlay} />
            <span>Resume</span>
          </ActionLink>
        ) : onMore ? (
          <ActionLink className={styles.icon} onClick={onMore}>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
            <span>More</span>
          </ActionLink>
        ) : (
          <ListingLink className={styles.icon} to={{section: Section.PHOTOS}}>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
            <span>Photos</span>
          </ListingLink>
        )}
      </div>
      { !active && // a second overlay when active is false, to prevent mouse events reaching below the parent element.
        <div className={styles.overlay} />
      }
    </div>
  )
})