import { authFetch } from 'app/_shared'

const backendEndpoint = process.env.BACKEND_API_ENDPOINT;

export async function downloadGuide(orderId) {
  let url = `${backendEndpoint}/listing/appointment-guide`;
  await authFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ orderId })
  });
}

export async function submitRating(orderId, appointmentRating) {
  let url = `${backendEndpoint}/listing/feedback-rating`;
  await authFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ orderId, appointmentRating })
  });
}

export async function submitComment(orderId, appointmentComments) {
  let url = `${backendEndpoint}/listing/feedback-comment`;
  await authFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ orderId, appointmentComments })
  });
}

export async function updatePhotos(tourName, photos) {
  let url = `${backendEndpoint}/listing/updatephotos`;
  let response = await authFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ tourName, photos })
  });
  let updatedPhotos = await response.json();
  return updatedPhotos;
}
