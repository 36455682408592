import React from 'react'
import { Throbber } from 'app/_shared'

export default class extends React.Component {
  constructor(props) {
    super(props);

    // NOTE: due to some timing idiosyncrachies around Formik, throbber is never activated at initial mount,
    // even if this.props.in is initially true.
    this.state = { in: false }; 
  }

  componentDidUpdate(prevProps) {
    if (prevProps.in !== this.props.in && this.props.in !== this.state.in) {
      this.setState({ in: this.props.in });
    }
  }

  render() {
    return (
      <Throbber className={this.props.className} active={this.state.in} />
    )
  }
}