import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

import { Selector } from 'app/listing'

import styles from './MockSelector.module.scss'

export default ({ className }) => (
  <div className={`${styles.mock} ${className || ''}`}>
    <div className={styles.zig} />
    <Selector className={styles.selector}>
      <FontAwesomeIcon className={styles.pointer} icon={faArrowUp} />
      <FontAwesomeIcon className={styles.pointer} icon={faArrowUp} />
      <FontAwesomeIcon className={styles.pointer} icon={faArrowUp} />
    </Selector>
    <div className={styles.zig} />
  </div>
)