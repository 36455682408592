import query from 'query-string'
import { authFetch } from 'app/_shared'
import { DateTime } from 'luxon';

const apiEndpoint = process.env.BACKEND_API_ENDPOINT;

const sendRequest = async ({ path, data, method, headers }) => {
  const response = await authFetch(`${apiEndpoint}${path}`, {
    method: method || 'POST',
    headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        ...headers
    },
    body: JSON.stringify(data)
  }, 3);

  return await response.json();
}

export const formatTravelCost = (cents, includeSign) => cents === 0 ? null : `${includeSign ? (cents > 0 ? '+' : '-') : ''}$${(Math.abs(cents) / 100).toFixed(2)}`;
export const formatDuration = seconds => `${parseInt(seconds / 3600) } h${Math.ceil(seconds / 60 % 60) !== 0 ? ` ${Math.ceil(seconds / 60 % 60)} m` : ""}`;

export async function initializeOrder() {
  // NOTE: the server will receive four pieces of information: the current user ID (anon or registered) via the auth token,
  // the promo code to attach to the order, a referal slug, and a property address.
  // Any of these could be null or undefined. From these values the server will pass back a new order, or a previously started order.
  const parsedQuery = query.parse(window.location.search);
  return await sendRequest({
    path: '/booking/start',
    data: {
      promoCode: parsedQuery.promo,
      referenceSlug: parsedQuery.r,
      address: parsedQuery.address,
      addressUnit: parsedQuery.addressUnit,
      utmSource: parsedQuery.utm_source,
      utmMedium: parsedQuery.utm_medium,
      utmCampaign: parsedQuery.utm_campaign,
      utmTerm: parsedQuery.utmTerm,
      utmContent: parsedQuery.utmContent
    }
  });
}

export async function getAppointmentTimes(orderId, dateString) {
  const result = await sendRequest({
    path: '/booking/getappointmenttimes',
    data: {
      orderId,
      day: parseInt(dateString.substr(8, 2)),
      month: parseInt(dateString.substr(5, 2)) - 1,
      year: parseInt(dateString.substr(0, 4))
    }
  });

  const timeSlots = result.photographers.map(t => t.startTimes).flat().reduce((allSlots, t) => {
    let match = allSlots.find(s => s.startTime.utc === t.time.utc);
    if (!match) {
      match = { startTime: t.time, zones: {}, notAvailable: t.notAvailable };
      allSlots.push(match);
      match.noAvailability = true; // Flips to false for first available slot
    }

    match.noAvailability = match.noAvailability && !t.isAvailable;
    if (t.zoneId !== -1) {
      match.zones[t.zoneId] = t.isAvailable;
    }

    return allSlots;
  }, []);

  timeSlots.sort((a, b) => DateTime.fromISO(a.startTime.utc) - DateTime.fromISO(b.startTime.utc));

  const unavailableBlocks = result.photographers.reduce((all, t) => ({ ...all, [t.photographerId]: t.unavailableBlocks }), {});

  return { timeSlots, unavailableBlocks, searchRange: result.searchRange };
}

export async function submit(info, model, step, targetStep) {
  return await sendRequest({
    path: `/booking/submit${step}`,
    data: { info, model, targetStep }
  });
}

export async function submitPromoCode({ info }, targetStep) {
  return await sendRequest({
    path: `/booking/submitconfirm`,
    data: { info, targetStep }
  });
}

export async function updateOwner({ orderId, ownerEmail }, targetStep) {
  return await sendRequest({
    path: '/booking/update-owner',
    data: { orderId, ownerEmail, targetStep }
  });
}

export async function updatePhotographerInstructions({ orderId, photographerInstructions }, targetStep) {
  return await sendRequest({
    path: '/booking/photographer-instructions',
    data: { orderId, photographerInstructions, targetStep }
  });
}

export async function updateWaitlistEnabled(orderId, enabled) {
  return await sendRequest({
    path: '/booking/enable-waitlist',
    data: { orderId, enabled }
  });
}

export async function createAdjustment({ orderId, description, amount }, targetStep) {
  return await sendRequest({
    path: '/booking/create-adjustment',
    data: { orderId, description, amount, targetStep }
  });
}

export async function deleteAdjustment({ orderId, adjustmentId }, targetStep) {
  return await sendRequest({
    path: '/booking/delete-adjustment',
    data: { orderId, adjustmentId, targetStep }
  });
}

export async function confirmBooking(orderModel) {
  return await sendRequest({
    path: '/booking/confirmbooking',
    data: { orderModel }
  });
}

export async function get(tourName) {
  return await sendRequest({
    path: '/booking/getforlisting',
    data: { tourName }
  });
}

export async function startChange(orderId) {
  return await sendRequest({
    path: '/booking/startchange',
    data: { orderId }
  });
}

export async function changeOrder(orderModel) {
  return await sendRequest({
    path: '/booking/changeorder',
    data: orderModel
  });
}
