import React, { useState, useRef, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { TweenLite } from 'gsap'

import { ActionLink, GestureHandler } from 'app/_shared'

import Photo from './Photo'
import styles from './Carousel.module.scss'
import ListingVideo from '../_shared/ListingVideo'

const MAX_OVERSCROLL = 50;
const OVERSCROLL_RESISTANCE = 0.03;
const GAP = 5; // gap width between items in percentage of container width

// Carousel does not render all items. Most times it renders the active item and
// its immediate neighbors. During a transition, it also renders the item which was centered
// at the start of the transition, and its immediate neighbors.
const getRenderedItems = ({ targetIndex, renderedItems, currentPosition }) => {
  // 1. Find the item which is currently closest to center. This is the "anchor"" item.
  let [anchorIndex, anchorOffset] = [targetIndex, 0];
  // NOTE: having an falsy or empty renderedItems means we want to render only
  // the target item at 0 offset, plus its immediate neighbors.
  if (renderedItems?.length > 0) {
    const centeredItem = renderedItems.reduce((closest, item) => {
      const distance = Math.abs(currentPosition - item.offset);
      return !closest || distance < closest.distance ? { distance, item } : closest;
    }, null).item;
    anchorIndex = centeredItem.index;
    anchorOffset = centeredItem.offset;
  }

  // 2. Anchor item, target item, and their immediate neighbors, will be rendered.
  // One or both of the anchor item or the target item must be defined.
  // Put each rendered item's index all into a list, remove duplicates, and sort.
  const renderedIndexes = [...new Set([
    ...(anchorIndex === undefined ? [] : [anchorIndex, anchorIndex - 1, anchorIndex + 1]),
    ...(targetIndex === undefined ? [] : [targetIndex, targetIndex - 1, targetIndex + 1])
  ])].sort((a, b) => a - b);

  // 3. Check if the output rendered list is equivalent to the input.
  let newRenderedItems = renderedItems;
  const outputEquivalent = renderedItems?.length === renderedIndexes.length &&
    renderedIndexes.every((t, i) => renderedItems[i].index === t);
  if (!outputEquivalent) {
    // 3a. Create new output: map the list of arrays into data objects, preserving the previous offset of
    // the anchor item (which defaulted to 0 if didn't previously exist).
    const j = renderedIndexes.indexOf(anchorIndex ?? targetIndex);
    newRenderedItems = renderedIndexes.map((t, i) => ({
      index: t,
      offset: i - j + anchorOffset
    }));
  }

  // 4. Identify the new target item, which is either the input target item if specified, or
  // the found anchor item otherwise.
  const newTarget = newRenderedItems.find(t => t.index === (targetIndex ?? anchorIndex));
  return { newRenderedItems, newTarget };
};

// NOTE: PhotosSection/index ensures that this Carousel component is only rendered when the
// activeIndex is in bounds. There is no range checking in here.
export default function Carousel({ items, activeIndex, setActiveIndex, activeItemRef, className = '', ...rest }) {
  const outerRef = useRef();
  const innerRef = useRef();
  const maxIndex = items.length - 1;

  const [renderedItems, setRenderedItems] = useState(() => {
    const { newRenderedItems } = getRenderedItems({ targetIndex: activeIndex })
    return newRenderedItems;
  });

  // Mutable ref to keep track of the position of the carousel.
  // NOTE: the position ref is a non-extensible object and cannot be tweened directly with TweenLite 
  // (throws an error), therefore position.current is our mutable position tracker. It needs to always 
  // be the same object reference so that starting a new tween will cancel existing ones in progress. 
  // A setter is applied so that setting position.current.value automatically applies a transform to 
  // the inner div.
  const position = useRef({
    get value() { return this._value },
    set value(val) {
      this._value = val;
      innerRef.current.style.transform = val === 0 ? null : `translate3d(${-position.current.value * (100 + GAP)}%, 0, 0)`;
    },
    _value: 0,
    target: 0,
    onComplete: undefined
  });

  // Set up motion/gesture handler for the entire duration of the this component's lifecycle.
  const [motion, setMotion] = useState();
  useEffect(() => {
    const motion = new GestureHandler(outerRef.current);
    setMotion(motion);
    const positionCurrent = position.current;
    return () => {
      motion.destroy();
      setMotion(undefined);
      TweenLite.killTweensOf(positionCurrent);
    }
  }, []);
  
  // Function to transition to the current active item, whose position is recorded in position.current.target.
  // Optionally specify an overscroll direction (1 or -1). Upon overscroll completion, another transition 
  // will go to the actual target position.
  const animatePosition = useCallback(overscroll => {
    // NOTE: killing outstanding tweens is supposed to be automatic when a new tween starts, preventing a 
    // stale onComplete from running. However, we've observed that in certain race conditions caused by high
    // CPU usage, a stale onComplete might still run, resulting in an infinite loop. 
    // Killing the previous tween manually prevents this.
    TweenLite.killTweensOf(position.current);
    TweenLite.to(position.current, 0.3, {
      value: position.current.target + (overscroll ?? 0) * MAX_OVERSCROLL / outerRef.current.offsetWidth,
      onComplete: overscroll ? animatePosition : position.current.onComplete
    });
  }, []);

  // When the activeIndex changes, update rendered items, update the position target, 
  // and start a transition.
  useEffect(() => {
    setRenderedItems(renderedItems => {
      const targetIndex = activeIndex;
      const { newRenderedItems, newTarget } = getRenderedItems({ 
        targetIndex, renderedItems, currentPosition: position.current.value 
      });
      position.current.target = newTarget.offset;
      position.current.onComplete = () => {
        // Upon a transition completion, recalculate the rendered items and reset their position.
        // Also call back setActiveIndex with stable = true, to signify a stable final position.
        position.current.value = 0;
        position.current.target = 0;
        const { newRenderedItems } = getRenderedItems({ targetIndex });
        setRenderedItems(newRenderedItems);
        setActiveIndex(activeIndex, true);
        position.current.onComplete = undefined;
      };
      
      // Begin the transition if not currently dragging; otherwise defer until dragging ends.
      if (motion && !motion.isDragging && renderedItems.length > 0) {
        motion.killMomentum();
        animatePosition();
      }
      return newRenderedItems;
    });
  }, [motion, activeIndex, setActiveIndex, animatePosition]);

  // Do not perform state update and trigger navigation after transition complete,
  // if carousel has become inactive and is fading out.
  useEffect(() => {
    if (rest.inert) {
      TweenLite.killTweensOf(position.current);
    }
  }, [rest.inert, activeIndex]);

  // Callback to go to adjacent images, used by wheel and keyboard handlers, and the arrow buttons.
  const goToNeighbor = useCallback(direction => {
    const newIndex = activeIndex + Math.sign(direction);
    if (newIndex < 0 || newIndex > maxIndex) {
      animatePosition(direction);
    }
    else {
      setActiveIndex(newIndex, false);
    }
  }, [maxIndex, activeIndex, setActiveIndex, animatePosition]);

  useEffect(() => {
    const onPress = e => {
      if (e.key.toLowerCase() === 'd') {
        goToNeighbor(-1);
      }
      else if (e.key.toLowerCase() === 'f') {
        goToNeighbor(1);
      }
    }
    document.addEventListener('keypress', onPress);
    return () => document.removeEventListener('keypress', onPress);
  }, [goToNeighbor]);

  // Attach events to handle keyboard and scroll wheel events to go to adjacent items.
  useEffect(() => {
    if (motion) {
      const wheelHandler = ({ data: { scrollX, scrollY }}) => {
        goToNeighbor(scrollX || scrollY);
      };

      const keyboardHandler = (e) => {
        if (!e.altKey && !e.ctrlKey) {
          const direction = e.key === 'ArrowLeft' ? -1 : e.key === 'ArrowRight' ? 1 : 0;
          if (direction !== 0) {
            goToNeighbor(direction);
          }
        }
      }
      
      motion.addEventListener('wheel', wheelHandler);
      document.addEventListener('keydown', keyboardHandler);
      return () => {
        motion.removeEventListener('wheel', wheelHandler);
        document.removeEventListener('keydown', keyboardHandler);
      }
    }
  }, [motion, goToNeighbor]);

  // Attach events to handle mouse and touch dragging.
  useEffect(() => {
    if (motion) {
      let containerWidth = outerRef.current.offsetWidth;

      // Upon every drag frame, find and render the currently centered item and its immediate neighbors,
      // and callback setActiveIndex with transient = true. Upon release, manually initiate a transition 
      // to the active item.
      const updateRenderedItems = () => {
        let minPosition = -Infinity;
        let maxPosition = Infinity;
        setRenderedItems(renderedItems => {
          // Get the current centered scene and its neighbors.
          const { newRenderedItems, newTarget } = getRenderedItems({ 
            renderedItems, currentPosition: position.current.value
          });
          setActiveIndex(newTarget.index, false);
          // Apply boundaries if the currently centered item is the first or last in the list.
          if (newTarget.index === 0) {
            minPosition = newTarget.offset;
          }
          else if (newTarget.index === maxIndex) {
            maxPosition = newTarget.offset;
          }
          return newRenderedItems;
        });
        return { minPosition, maxPosition };
      };

      const startHandler = () => {
        containerWidth = outerRef.current.offsetWidth;
        TweenLite.killTweensOf(position.current);
        updateRenderedItems();
      }

      const dragHandler = ({ data: { dx }}) => {
        const { minPosition, maxPosition } = updateRenderedItems();
        // Apply a resistance to both the movement and the momentum if position is beyond boundaries.
        const resistance = Math.exp(OVERSCROLL_RESISTANCE * containerWidth * Math.max(
          0, minPosition - position.current.value, position.current.value - maxPosition
        ));
        position.current.value -= dx / resistance / containerWidth;
        motion.resistMomentum(resistance);
      }

      const endHandler = ({ data }) => {
        !data?.killed && animatePosition();
      }

      motion.addEventListener('start', startHandler);
      motion.addEventListener('drag', dragHandler);
      motion.addEventListener('momentum', dragHandler);
      motion.addEventListener('momentumend', endHandler);

      return () => {
        motion.removeEventListener('start', startHandler);
        motion.removeEventListener('drag', dragHandler);
        motion.removeEventListener('momentum', dragHandler);
        motion.removeEventListener('momentumend', endHandler);
      }
    }
  }, [motion, maxIndex, setActiveIndex, animatePosition]);

  return (
    <div ref={outerRef} className={`${styles.outer} ${className}`} {...rest}>
      <ActionLink 
        appearance={`white pill ${activeIndex === 0 ? 'disabled' : ''}`}
        className={`${styles.arrow} ${styles.left}`}
        onClick={() => goToNeighbor(-1)}
        ref={motion?.block}
      >
        <FontAwesomeIcon icon={faAngleLeft} fixedWidth />
      </ActionLink>
      <div ref={innerRef} className={styles.inner}>
        { renderedItems.filter(t => t.index >= 0 && t.index <= maxIndex).map(t => {
          const active = t.index === activeIndex;
          const { code, aspect, externalLink } = items[t.index];
          const Item = externalLink ? ListingVideo : Photo;
          return (
            <div 
              key={code}
              className={`${styles.item} ${active ? styles.active : ''}`}
              style={{ left: t.offset * (100 + GAP) + '%' }}
            >
              <Item
                photoId={code}
                ref={active ? activeItemRef : undefined}
                className={styles.photo}
                active={active}
                aspect={aspect || (externalLink ? 1.78 : 1.5)}
                url={items[t.index].url}
                externalLink={externalLink}
                onMore={() => goToNeighbor(1)}
                dynamic
              />
            </div>
          )
        })}
      </div>
      <ActionLink 
        appearance={`white pill ${activeIndex === items.length - 1 ? 'disabled' : ''}`}
        className={`${styles.arrow} ${styles.right}`} 
        onClick={() => goToNeighbor(1)}
        ref={motion?.block}
      >
        <FontAwesomeIcon icon={faAngleRight} fixedWidth />
      </ActionLink>
    </div>
  )
} 
