import React, { useRef, useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt, faExpand, faTimes, faArrowsAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, InlineScroll, Hint, MorphBox, useUnitPreference, forceReflow } from 'app/_shared'
import { useListing, Section, ListingLink } from '../ListingContext'
import { useTour } from '../TourSection/TourContext'

import DragHandle from './DragHandle'
import Viewer from './Viewer'
import FloorSelectorItem from './FloorSelectorItem'
import styles from './index.module.scss'

const Layout = {
  FULL: 'full',
  SIDEBAR: 'sidebar',
  FLOATING: 'floating',
  COLLAPSED: 'collapsed',
  INACTIVE: 'inactive'
}

const getAreaText = (area, unitPreference) => area
    .map(a => `${unitPreference === 'metric' ? Math.round(a.sqFt * 0.92903) / 10 : Math.round(a.sqFt)} ${unitPreference === 'metric' ? 'm²' : 'SF'} ${area.length > 1 ? a.label : ''}`)
    .join(' + ');

export default ({ className, wideLayout, disableFloatingFloorPlan }) => {
  const { listing: { floorplan: { floors, area }}, activeSection, startingFloor } = useListing();
  const { tourLoaded } = useTour();
  const { unitPreference } = useUnitPreference();

  const containerRef = useRef();
  
  const overrideTransition = useCallback(() => {
    const container = containerRef.current;
    return new Promise(resolve => {
      const handler = () => {
        container.removeEventListener('transitionend', handler);
        container.style.transition = 'none';
        forceReflow(container);
        container.style.transition = null;
        resolve();
      }
      container.style.transition = 'opacity 0.4s, visibility 0.4s, transform 0.4s';
      container.addEventListener('transitionend', handler);
    });
  }, []);
      
  const nextLayout = activeSection === Section.FLOORPLAN ? Layout.FULL 
    : activeSection === Section.TOUR ? (wideLayout ? Layout.SIDEBAR : disableFloatingFloorPlan ? Layout.FLOATING : Layout.COLLAPSED)
    : activeSection === Section.PHOTOS && wideLayout ? Layout.SIDEBAR 
    : Layout.INACTIVE;
  const [renderedLayout, setRenderedLayout] = useState(nextLayout);
  useEffect(() => {
    let abort = false;
    if (renderedLayout !== nextLayout) {
      (async () => {
        if (renderedLayout === Layout.INACTIVE || (renderedLayout === Layout.COLLAPSED && nextLayout === Layout.FULL)) {
          overrideTransition(); // NOTE: no await; applies nextLayout immediately afterwards.
          // TODO: trigger viewer to fly in or out, depending on nextLayout
        }
  
        else if (nextLayout === Layout.INACTIVE || (nextLayout === Layout.COLLAPSED && renderedLayout === Layout.FULL)) {
          await overrideTransition(); // NOTE: awaits transition and applies nextLayout after transition completes.
          // TODO: trigger viewer to fly in or out, depending on renderedLayout
        }
  
        // NOTE: If layout has changed while awaiting a transition then do not do not update the layout here.
        !abort && setRenderedLayout(nextLayout);
      })();
    }
    return () => abort = true;
  }, [renderedLayout, nextLayout, overrideTransition]);

  const startingFloorId = startingFloor.id;
  const [selectedFloorId, setSelectedFloorId] = useState(startingFloorId);
  useEffect(() => setSelectedFloorId(startingFloorId), [startingFloorId]);

  return (
    <DragHandle draggable={renderedLayout === Layout.FLOATING} targetRef={containerRef} render={handleRef => (
      <div
        ref={containerRef}
        className={`${styles.floorplan} ${className || ''}`} 
        // NOTE: use nextLayout instead of renderedLayout here to immediately begin fading out,
        // since renderedLayout doesn't get updated until the transition is complete.
        data-hidden={nextLayout === Layout.INACTIVE || nextLayout === Layout.COLLAPSED}
        data-layout={renderedLayout}
      >
        { (renderedLayout === Layout.FLOATING || renderedLayout === Layout.COLLAPSED) && 
          <div className={styles.toolbar}>
            <ListingLink 
              appearance="white pill" 
              className={styles.button}
              to={{ section: Section.FLOORPLAN }}
            >
              <FontAwesomeIcon fixedWidth icon={faExpand} />
            </ListingLink>
            <ActionLink 
              appearance="white pill" 
              className={styles.button} 
              onClick={disableFloatingFloorPlan}
            >
              <FontAwesomeIcon fixedWidth icon={faTimes} />
            </ActionLink>
          </div>
        }
        <div className={styles.handle} ref={handleRef}>
          <FontAwesomeIcon fixedWidth icon={faArrowsAlt} />
        </div>
        <Viewer
          className={styles.viewer}
          floors={floors}
          selectedFloorId={selectedFloorId}
          setSelectedFloorId={setSelectedFloorId}
          stacked={renderedLayout !== Layout.FULL}
          floating={renderedLayout === Layout.FLOATING}
          interactive={renderedLayout !== Layout.INACTIVE && (wideLayout || renderedLayout !== Layout.FULL)}
        />
        { tourLoaded && ((activeSection === Section.FLOORPLAN && wideLayout) ||
          (activeSection === Section.TOUR && (wideLayout || !!disableFloatingFloorPlan))) &&
          // TODO: reposition this hint to fit in the viewport, when the floor plan is dragged around.
          <Hint 
            name="tourFloorPlanClick" 
            priority={5} 
            className={styles.hint} 
            position={renderedLayout === Layout.FLOATING ? 'left' : renderedLayout === Layout.SIDEBAR ? 'right' : 'top'}
          >
            Click somewhere on the floor plan to jump there.
          </Hint>
        }
        { (renderedLayout === Layout.FULL || floors.length > 1) &&
          <InlineScroll className={styles.selector}>
            {({ centerOn }) => (
              <div className={styles.floors}>
                { floors.map(t => (
                  <FloorSelectorItem
                    key={t.id} 
                    active={selectedFloorId === t.id}
                    centerOn={centerOn}
                    onClick={() => setSelectedFloorId(t.id)}
                    label={t.name}
                  >
                    { renderedLayout === Layout.FULL && t.area && t.area.length > 0 &&
                      <div>
                        { getAreaText(t.area, unitPreference) }
                      </div>
                    }
                    { renderedLayout === Layout.FULL &&
                      <ActionLink to={t.imageUrl[unitPreference]} onClick={e => e.stopPropagation()}>
                        <FontAwesomeIcon icon={faCloudDownloadAlt} /> JPG
                      </ActionLink>
                    }
                  </FloorSelectorItem>
                ))}
              </div>
            )}
          </InlineScroll>
        }
        <MorphBox className={styles.area}>
          { area && area.length > 0 && renderedLayout === Layout.FULL && floors.length > 1 &&
            <div className={styles.inner}>
              Total: { getAreaText(area, unitPreference) }
            </div>
          }
        </MorphBox>
        { renderedLayout === Layout.SIDEBAR &&
          <ListingLink 
            appearance="pill" 
            className={styles.expand} 
            to={{ section: Section.FLOORPLAN }}
          >
            Floor plan <FontAwesomeIcon icon={faExternalLinkAlt} />
          </ListingLink>
        }
      </div>
    )} />
  )
}
