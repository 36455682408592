import React, { useState } from 'react'
import { faLink, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FieldSet, ClickToCopy, useFlags } from 'app/_shared'

import styles from './Link.module.scss'
import tabStyles from '../index.module.scss'

const data = {
  id: 'link',
  icon: faLink,
  background: 'linear-gradient(to top right, var(--gray-500), var(--gray-400))'
}

const Link = ({ tourUrl, divProps }) => {
  const [unbranded, setUnbranded] = useState();
  const { touchInput, isMac } = useFlags();
  const instruction = !touchInput ? (isMac ? 'ctr+click' : 'right click') : 'tap & hold';

  return (
    <div {...divProps}>
      <h3>Copy link</h3>
      <div className={tabStyles.content}>
        <p>
          <strong>Click below</strong> to copy the link to this Realvision tour:
        </p>
        <ClickToCopy className={styles.copy} value={`${tourUrl}${unbranded ? '?o=u' : ''}`} />
        <FieldSet className={styles.check} type="checkbox" label="Unbranded (check only for posting to MLS)" checked={unbranded} onChange={() => setUnbranded(t => !t)} />
        <p className={tabStyles.tip}>
          <FontAwesomeIcon icon={faInfoCircle} />
          After copying, <strong>{instruction}</strong> on any text input in another site or app, and select <strong>paste</strong>.
        </p>
      </div>
    </div>
  )
}

Link.data = data;
export default Link;