import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import Photo from '../PhotosSection/Photo'
import { useListing, Section, ListingLink } from '../ListingContext'
import styles from './Photos.module.scss'

export default ({ className }) => {
  const { listing } = useListing();
  let photos = listing.photos.filter(t => t.caption && !t.hidden);
  if (photos.length < 6) {
    photos = listing.photos.filter(t => !t.hidden);
  }
  const singleRow = photos.length < 4;

  return (
    <div className={`${styles.photos} ${className || ''}`}>
      <h3>
        {listing.photos.filter(t => !t.hidden).length} Photos
      </h3>
      <div>
        { (singleRow ? [0] : [0, 1]).map(a => (
          <div key={a} className={styles.row}>
            { photos.filter((_, i) => singleRow || i % 2 === a).map(t => (
              <ListingLink key={t.code} to={{ section: Section.PHOTOS, subSection: t.code }}>
                <Photo
                  url={t.url}
                  aspect={t.aspect}
                  className={styles.photo}
                  style={{'--photo-aspect': t.aspect}}
                />
              </ListingLink>
            ))}
          </div>
        ))}
        <ListingLink className={styles.more} appearance="white pill" to={{ section: Section.PHOTOS }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </ListingLink>
      </div>
    </div>
  )
}