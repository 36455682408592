import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { FieldSet, FieldGroup, ActionLink } from 'app/_shared'

export default ({ namePrefix, isPrimary, remove }) => (
  <>
    <FieldGroup>
      <FieldSet name={`${namePrefix}.firstName`} label="First name" />
      <FieldSet name={`${namePrefix}.lastName`} label="Last name" />
      { !isPrimary && 
        <ActionLink type="button" appearance="plain button" onClick={remove} disabled={!remove}><FontAwesomeIcon icon={faTimes} /></ActionLink>
      }
    </FieldGroup>
    <FieldGroup>
      <FieldSet name={`${namePrefix}.company`} label="Company or brokerage" />
      <FieldSet name={`${namePrefix}.role`} label="Role" component="select" style={{maxWidth: '14em'}}>
        <option value=""></option>
        <option value="Listing Agent">Agent</option>
        <option value="Listing Administrator">Listing administrator</option>
        <option value="Home Owner">Homeowner</option>
        <option value="Other">Other</option>
      </FieldSet>
    </FieldGroup>
    { isPrimary && 
      <FieldGroup collapse>
        <FieldSet name={`${namePrefix}.address`} label="Billing address" />
        <FieldSet name={`${namePrefix}.unit`} label="Unit" style={{flex: "0 0 6em"}} />
      </FieldGroup>
    }
    <FieldGroup>
      <FieldSet type="email" name={`${namePrefix}.email`} label="Email address" />
      <FieldSet type="tel" name={`${namePrefix}.phone`} label="Phone number" />
    </FieldGroup>
  </>
)