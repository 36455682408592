import {authFetch} from 'app/_shared'

const endpoint = process.env.AUTH_ENDPOINT;
const postJsonToAuth = async (path, body) => {
  const response = await authFetch(endpoint + path, {
    method: 'POST',
    headers: body ? {'Content-Type': 'application/json'} : undefined,
    body: body ? JSON.stringify(body) : undefined
  });

  let json = null;
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    // This try catch silently deals with a response body that isn't valid json (including being empty)
    try {
      json = await response.json();
    }
    catch (error) { }
  }

  if (response.ok) {
    return json;
  }
  else {
    throw json;
  }
}

export const signIn = ({email, password}) => postJsonToAuth('/user/sign-in', {email, password});
export const signOut = () => postJsonToAuth('/user/sign-out');

export const register = ({firstName, lastName, email, phone, password}) => postJsonToAuth('/user/register', {firstName, lastName, email, phone, password});
export const sendVerifyCode = () => postJsonToAuth('/user/register/code');
export const verify = ({code}) => postJsonToAuth('/user/register/verify', {code});

export const reset = ({email, password, code}) => postJsonToAuth(`/user/password/${password ? 'submit' : code ? 'verify' : 'code'}`, {email, password, code});
export const verifySsoToken = (token, origin) => postJsonToAuth('/sso/verify', {token, origin});
