import React from 'react'
import { Formik, Form } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons'

import { FieldSet, ActionLink } from 'app/_shared'
import styles from './Filter.module.scss'

export default ({ filter, update }) => {
  return (
    <Formik
      enableReinitialize
      onSubmit={(values, actions) => {
        if (values.filter !== (filter || '')) {
          update(values.filter);
        }
        actions.setSubmitting(false);
      }}
      initialValues={{ filter: filter || '' }}
    >
      {({ values, submitForm }) => {
        const submitEnabled = !filter || (filter !== values.filter);
        return (
          <Form className={styles.filter}>
            <FieldSet
              name="filter"
              placeholder="Search"
            />
            <ActionLink type="button" appearance="plain button" onClick={submitEnabled ? submitForm : () => update('')}>
              <FontAwesomeIcon fixedWidth icon={submitEnabled ? faSearch : faTimes} />
            </ActionLink>
          </Form>
        )
      }}
    </Formik>
  )
}