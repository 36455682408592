import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStreetView, faArrowDown } from '@fortawesome/free-solid-svg-icons'

import { ActionLink } from 'app/_shared'

import { useListing, Section, ListingLink } from '../ListingContext'
import styles from './LinkBar.module.scss'

export default ({ className, topRef }) => {
  const ref = useRef();
  const { listing, enabledSections, startingFloor, listingNav } = useListing();
  const visiblePhotos = listing.photos && listing.photos.filter(t => !t.hidden && !t.externalLink);
  const videos = listing.photos && listing.photos.filter(t => !t.hidden && t.externalLink);

  const scroll = topRef ? 
    () => topRef.current.scrollIntoView({ behavior: 'smooth' }) : 
    () => ref.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <div className={`${styles.links} ${className || ''}`} ref={ref}>
      { enabledSections.includes(Section.TOUR) &&
        <ListingLink className={styles.tour} to={{ section: Section.TOUR }}>
          <div className={styles.image} style={{backgroundImage: `url(${process.env.SCENE_DISTRIBUTION}${listing.startingSceneId}/t.jpg)`}} />
          <div className={styles.label}>
            <FontAwesomeIcon icon={faStreetView} /> <span>3D Tour</span>
          </div>
        </ListingLink>
      }

      { enabledSections.includes(Section.PHOTOS) && videos.length > 0 &&
        <ActionLink type="button" className={styles.videos} onClick={() => listingNav({ section: Section.PHOTOS, subSection: videos[0].code }, true)}>
          <div className={styles.image} style={{backgroundImage: `url(${videos[0].url})`}} />
          <div className={styles.label}>
            video
          </div>
        </ActionLink>
      }

      { enabledSections.includes(Section.PHOTOS) && visiblePhotos.length > 0 &&
        <ListingLink className={styles.photos} to={{ section: Section.PHOTOS }}>
          <div className={styles.image} style={{backgroundImage: `url(${visiblePhotos[0].url})`}} />
          <div className={styles.label}>
            { visiblePhotos.length } photos
          </div>
        </ListingLink>
      }
      
      { enabledSections.includes(Section.FLOORPLAN) &&
        <ListingLink className={styles.floorplan} to={{ section: Section.FLOORPLAN }}>
          <div className={styles.image} style={{backgroundImage: `url(${startingFloor.imageUrl.imperial})`}} />
          <div className={styles.label}>
            Floor plan
          </div>
        </ListingLink>
      }
      <ActionLink type="button" className={`${styles.arrow} ${topRef ? styles.top : ''}`} onClick={scroll}>
        <div className={styles.image}>
          <FontAwesomeIcon icon={faArrowDown} />
          <FontAwesomeIcon icon={faArrowDown} />
        </div>
        <div className={styles.label}>
          { topRef ? "Top" : "Info" }
        </div>
      </ActionLink>
    </div>
  )
}