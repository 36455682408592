import { TweenLite } from 'gsap'
import ResizeObserver from 'resize-observer-polyfill'

import { GestureHandler, EventHandler } from 'app/_shared'

const BOUNDARY_RESISTANCE = 0.005;
const WHEEL_ZOOM_FACTOR = 1.5;
const DEFAULT_TRANSITION_DURATION = 0.25;

export default class {
  constructor(svg) {
    const events = new EventHandler();
    this.addEventListener = events.addEventListener;
    this.removeEventListener = events.removeEventListener;
    this.isDragging = () => false; // this gets updated in initialize()

    let currentView = undefined;
    let bounds = undefined;
    let scale = null;

    const constrainBounds = ({ x, y }) => [
      Math.min(Math.max(x, bounds.x - bounds.w / 2), bounds.x + bounds.w / 2),
      Math.min(Math.max(y, bounds.y - bounds.h / 2), bounds.y + bounds.h / 2)
    ];

    const getBoundDiff = ({ x, y }) => {
      const [cx, cy] = constrainBounds({ x, y });
      return [x - cx, y - cy];
    };

    const updateScale = () => {
      // NOTE: scale is screen pixels per SVG unit (real life millimeters)
      // NOTE: since we're calling the root SVG, getCTM and getScreenCTM should give us the same scale in the transform matrix.
      // In Chrome, getScreenCTM will take css transform of the parent into account, which becomes problematic when the
      // floor plan is floating and hidden (having a CSS transform scale). In Safari, getCTM gives us null, while getScreenCTM
      // gives ignores parents' CSS transform. Therefore the following code gives us the desired behavior in both cases.
      let matrix = svg.getCTM() || svg.getScreenCTM();
      scale = Math.sqrt(matrix.a * matrix.a + matrix.b * matrix.b);
      events.trigger('scale', { scale });
    }
    const sizeSensor = new ResizeObserver(updateScale);
    // NOTE: observing the svg directly has proven unreliable. Therefore
    // observation is made on the svg's parent, with the assumption that all
    // resizing of the svg must be accompanied by resizing of its parent.
    sizeSensor.observe(svg.parentNode);

    const updateView = (targetView, duration) => {
      if (Object.values(targetView).some(t => isNaN(t))) {
        return; // This empirically sometimes happens... haven't determined why.
      }
      const setViewBox = ({ x, y, w, h }) => svg.setAttribute('viewBox', `${x - w / 2} ${y - h / 2} ${w} ${h}`);
      const fullTarget = { ...currentView, ...targetView };
      const sizeChanged = fullTarget.w !== currentView?.w || fullTarget.h !== currentView?.h;
      if (duration) {
        // NOTE: all transitioning updates are subject to bound constraints...
        const [x, y] = constrainBounds(fullTarget);
        this.tween && this.tween.kill();
        this.tween = TweenLite.to(currentView, duration ?? DEFAULT_TRANSITION_DURATION, { 
          onUpdate: sizeChanged ?
            () => { setViewBox(currentView); updateScale(); } :
            () => setViewBox(currentView),
          ...fullTarget, x, y
        });
      }
      else {
        // ...whereas non-transitioning updates are implicitly from direct manual manipulation
        // and therefore not subject to bound contraints; manual input also triggers the "move" event.
        currentView = fullTarget;
        setViewBox(currentView);
        events.trigger('move', currentView);
        sizeChanged && updateScale();
      }
    };

    let handler;
    const initialize = () => {
      updateView(bounds);
      handler = new GestureHandler(svg);
      this.isDragging = () => handler.isDragging;

      handler.addEventListener('start', () => {
        this.tween && this.tween.kill();
        events.trigger('start');
        updateScale();
      });

      handler.addEventListener('click', ({ data: { x, y }}) => {
        events.trigger('click', {
          x: x / scale + currentView.x,
          y: y / scale + currentView.y
        })
      });

      const zoomHandler = ({ type, data: { scrollY, dr, x, y }}) => {
        const zoom = type === 'pinch' ? 1 / dr : Math.pow(WHEEL_ZOOM_FACTOR, scrollY);
        const viewX = currentView.x - x * (zoom - 1) / scale;
        const viewY = currentView.y - y * (zoom - 1) / scale;
        updateView({
          x: viewX, 
          y: viewY,
          w: currentView.w * zoom,
          h: currentView.h * zoom,
        }, type === 'pinch' ? undefined : DEFAULT_TRANSITION_DURATION);
      }
      handler.addEventListener('wheel', zoomHandler);
      handler.addEventListener('pinch', zoomHandler);

      const moveHandler = ({ type, data: { dx, dy }}) => {
        const factor = diff => Math.exp(-Math.abs(diff) * scale * BOUNDARY_RESISTANCE);
        const [diffX, diffY] = getBoundDiff(currentView);
        updateView({
          x: currentView.x - dx * factor(diffX) / scale,
          y: currentView.y - dy * factor(diffY) / scale
        });
        type === 'momentum' && handler.resistMomentum(1 / factor(Math.sqrt(diffX ** 2 + diffY ** 2)));
      };
      handler.addEventListener('drag', moveHandler);
      handler.addEventListener('momentum', moveHandler);

      handler.addEventListener('momentumend', () => {
        updateView({}, DEFAULT_TRANSITION_DURATION);
        events.trigger('end');
      });
    }

    this.setBounds = async (newBounds) => {
      bounds = newBounds;
      handler && handler.killMomentum();
      !currentView && initialize();
    }

    this.transitionToBounds = (duration) => {
      bounds && updateView(bounds, duration || DEFAULT_TRANSITION_DURATION);
    }

    this.destroy = () => {
      this.tween && this.tween.kill();
      handler && handler.destroy();
      sizeSensor.disconnect();
      return null;
    };
  }
}