import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import Switch from 'react-switch'
import clsx from 'clsx';

import { Avatar, ActionLink, Modal, useAdminElement, wrapAdminElement } from 'app/_shared'

import styles from './PhotographerSelect.module.scss'

export default function PhotographerSelect({ active, photographers, selectedPhotographer, className }) {
  const { values: { info, schedule }, isSubmitting, setFieldValue } = useFormikContext();
  const adminEnabled = useAdminElement();
  const selectedTime = schedule?.selectedTime;
  const renderForAdminOnly = photographers.length < 2 || !selectedTime;

  const [modalOpen, setModalOpen] = useState();
  const select = photographerId => {
    if (active && !isSubmitting) {
      setFieldValue("schedule.selectedPhotographerId", photographerId, true);
      setModalOpen(false);
    }
  }

  // render this component if either it should be shown to only admins and admin elements are not hidden,
  // or if it should be shown to non-admins too.
  return (!renderForAdminOnly || (info.isAdmin && adminEnabled)) && wrapAdminElement((
    <div className={clsx(styles.summary, renderForAdminOnly && styles.admin, className)}>
      { selectedPhotographer ? (
        <>
          <Avatar className={styles.avatar} user={selectedPhotographer} />
          { photographers.length === 1 ? (
            <span>
              {photographers[0].name} is the only available photographer for this location.
            </span>
          ) : (
            <span>
              {selectedPhotographer.name} is your preferred photographer. <ActionLink appearance="link" onClick={() => select(null)}>Remove</ActionLink>
            </span>
          )}
          { info.isAdmin && !!selectedPhotographer && wrapAdminElement((
            <label className={styles.force}>
              <Switch
                className={styles.switch}
                checked={schedule?.ignoreAvailability ?? false}
                onChange={checked => setFieldValue('schedule.ignoreAvailability', checked)}
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                width={22 * 2}
                height={22}
                handleDiameter={22 - 4}
              />
              <span>Force select time and photographer</span>
            </label>
          ), renderForAdminOnly)}
        </>
      ) : (
        <span>
          The most suitable photographer for your
          schedule will be assigned. <ActionLink appearance="link" onClick={() => setModalOpen(true)}>Change</ActionLink>
        </span>
      )}
      <Modal open={modalOpen} close={() => setModalOpen(false)} className={styles.modal}>
        <p>
          Select a preferred photographer, or
          select <ActionLink appearance="link" onClick={() => select(null)}>No preference</ActionLink> to
          see the most availability.
        </p>
        <div className={styles.photographers}>
          <div className={styles.inner}>
            <ActionLink
              className={styles.item}
              onClick={() => select(null)}
            >
              <Avatar className={styles.avatar} />
              No preference
            </ActionLink>
            { photographers.map(t => (
              <ActionLink
                key={t.id}
                className={styles.item}
                onClick={() => select(t.id)}
              >
                <Avatar className={styles.avatar} user={t} />
                <span>{t.name}</span>
              </ActionLink>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  ), !renderForAdminOnly);
}
