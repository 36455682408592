import React, { useMemo } from 'react'
import moment from 'moment-timezone'
import { OrderSummary } from 'app/booking'

import styles from './Order.module.scss'

export default ({ status, order }) => {
  const events = useMemo(() => {
    const events = [];

    const confirmedDate = moment(order.info.confirmedDate);
    events.push({
      utc: order.info.confirmedDate,
      component: (
        <div className={styles.event} data-type="confirmed">
          Confirmed on <strong>{
            confirmedDate.format('ll')
          }</strong> at <strong>{
            confirmedDate.format('h:mm a')
          }</strong> by <strong>{order.info.confirmedUser}</strong>.
        </div>
      )
    });

    if (order.info.cancelledDate) {
      const cancelledDate =  moment(order.info.cancelledDate);
      events.push({
        utc: order.info.cancelledDate,
        component: (
          <div className={styles.event} data-type="cancelled">
            Cancelled on <strong>{
              cancelledDate.format('ll')
            }</strong> at <strong>{
              cancelledDate.format('h:mm a')
            }</strong> by <strong>{
              order.info.cancelledUser
            }</strong>.
          </div>
        )
      });
    }
    else if (order.info.deliveredDate) {
      const deliveredDate =  moment(order.info.deliveredDate);
      events.push({
        utc: order.info.deliveredDate,
        component: (
          <div className={styles.event} data-type="delivered">
            Delivered on <strong>{
              deliveredDate.format('ll')
            }</strong> at <strong>{
              deliveredDate.format('h:mm a')
            }</strong>.
          </div>
        )
      });
    }
    else {
      const deliveryDate = moment(order.schedule.deliveryDeadline);
      events.push({
        utc: order.schedule.deliveryDeadline,
        component: (
          <div className={styles.event} data-type="delivery">
            Scheduled delivery on <strong>{deliveryDate.format('ll')}</strong>.
          </div>
        )
      });
    }

    return events.sort((a, b) => a.utc === b.utc ? 0 : a.utc > b.utc ? 1 : -1);
  }, [order]);

  return (
    <div className={styles.order}>
      <div className={styles.events} data-status={status}>
        { events.map((t, i) => (
          <React.Fragment key={i}>{t.component}</React.Fragment>
        ))}
      </div>
      <div className={styles.summary}>
        <div className={styles.title}>Order summary</div>
        <OrderSummary order={order} />
      </div>
    </div>
  )
}
