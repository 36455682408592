import React, { useState, useEffect, useContext } from 'react'
import Helmet from 'react-helmet'

import { useRoute } from 'app/layout'
import { getMetadata } from '../../service'

const MetadataContext = React.createContext();

export default ({ children }) => {
  const { tourName } = useRoute();
  const [tags, setTags] = useState();

  useEffect(() => {
    setTags(undefined);
    if (tourName) {
      let abort = false;
      getMetadata(tourName).then(tags => !abort && setTags(tags));
      return () => abort = true;
    }
  }, [tourName]);

  return (
    <MetadataContext.Provider value={tags}>
      { tourName &&
        <Helmet>
          { tags ? tags.map((t, i) => (
            <t.tag key={i} {...t.attributes}>{t.innerText}</t.tag>
          )) : (
            <title>Loading {tourName}... | Realvision</title>
          )}
        </Helmet>
      }
      { children }
    </MetadataContext.Provider>
  )
}

export const useMetadata = () => useContext(MetadataContext);