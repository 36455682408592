import React, { useState, useCallback } from 'react'
import { SizeSensor } from 'app/_shared';

const DEFAULT_ASPECT = 1.5;
const GAP = 5;

export default ({ photos, maxRowHeight, className, render }) => {
  const [layout, setLayout] = useState([]);

  // The goal is to create rows of images, of equal height *within* each row, and in which the images are fully
  // shown (not cropped), with the height of each row as close to a specified maxRowHeight as practical without exceeding it.
  const updateLayout = useCallback(container => {
    const clientWidth = container.clientWidth;
    const maxRowHeightValue = typeof maxRowHeight === 'function' ? maxRowHeight(container) : maxRowHeight;
    const remainingPhotos = [...photos];
    const output = [];
    let lastRowHeight;

    while (remainingPhotos.length > 0) {
      let rowHeight, rowPhotoCount = 0, aspectSum = 0;
      // Add one photo at a time into the row until the photo height is less than maxRowHeight,
      // or we've run out of photos.
      do {
        rowPhotoCount++;
        aspectSum += remainingPhotos[rowPhotoCount - 1].aspect || DEFAULT_ASPECT;
        rowHeight = (clientWidth - (rowPhotoCount + 1) * GAP) / aspectSum;
      } while (rowHeight > maxRowHeightValue && rowPhotoCount < remainingPhotos.length);

      if (rowPhotoCount < remainingPhotos.length) {
        lastRowHeight = rowHeight;
      }
      // The final row is constrained to no taller than the preceding row height
      else {
        rowHeight = Math.min(rowHeight, lastRowHeight || maxRowHeightValue);
      }

      for (let i = 0; i < rowPhotoCount; i++) {
        let photo = remainingPhotos.shift();
        let grid = {
          marginLeft: GAP + 'px',
          marginTop: GAP + 'px',
          width: rowHeight * (photo.aspect || DEFAULT_ASPECT),
          height: rowHeight
        }
        output.push({ ...photo, grid });
      }
    }
    setLayout(output);
  }, [maxRowHeight, photos]);
  
  return (
    <SizeSensor className={className || ''} onResize={updateLayout}>
      { render(layout) }
    </SizeSensor>
  )
};