import React, { useState } from 'react'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { ActionLink, Emoji } from 'app/_shared'

import { downloadGuide } from '../../../service'
import guideUrl from './Preparation.pdf'
import styles from './Preparation.module.scss'

export default ({ lastGuideDownload, orderId, className }) => {
  const [lastDownloadMoment, setLastDownloadMoment] = useState(lastGuideDownload && moment(lastGuideDownload));
  const [downloadPending, setDownloadPending] = useState(false);

  const onDownloadClick = async () => {
    setDownloadPending(true);
    await downloadGuide(orderId);
    var link = document.createElement('a');
    link.href = guideUrl;
    link.download = 'Preparing for your Realvision appointment.pdf';
    link.dispatchEvent(new MouseEvent('click'));
    setDownloadPending(false);
    setLastDownloadMoment(moment());
  };

  return (
    <div className={`${styles.prepare} ${className || ''}`}>
      <h3>Prepare for your appointment just like an open house.</h3>
      <section>
        <figure>
          <img src="https://photos.real.vision/SniwmHgCUER.jpg?v=1716659648&size=768x*" alt=""/>
          <Emoji className={styles.emoji} symbol="🙂" />
        </figure>
        <div>
          <h3>1. Stage the property to your liking.</h3>
          <p>
            Does your client want an impeccably neat or a "lived in" look? We don't want to assume and move things around,
            so please stage the home to satisfaction <strong>before</strong> your appointment.
          </p>
        </div>
      </section>
      <section>
        <figure>
          <img src="https://photos.real.vision/6aJWfctX5KL.jpg?v=-758605308&size=768x*" alt=""/>
          <Emoji className={styles.emoji} symbol="😟" />
        </figure>
        <div>
          <h3>2. Hide unwanted clutter behind closed doors.</h3>
          <p>
            Our cameras shoot in 360 degrees and see in all directions at once.
            Viewers of your 3D tour will also be able to turn and look in all directions, so clutter should be completely out of sight.
          </p>
        </div>
      </section>
      <section>
        <figure>
          <img src="https://photos.real.vision/YkgEZRqFa3e.jpg?v=-1821702246&size=768x*" alt=""/>
          <Emoji className={styles.emoji} symbol="😟" />
        </figure>
        <div>
          <h3>3. Take care of the finishing touches.</h3>
          <p>
            Remove all cars from the driveway and signs from the lawn, including For Sale signs. Hide all personal items for privacy concerns. Contain or remove all pets.
          </p>
        </div>
      </section>
      <p className={styles.summary}>
        Your Realvision package will be your online open house that's open around the clock. Make sure you prepare the property to show it off at its finest, to hundreds of viewers.
      </p>
      { lastDownloadMoment ? (
        <div className={styles.downloaded}>
          <FontAwesomeIcon icon={faCheckCircle} className={styles.check} /> 
          <div className={styles.thanks}>
            Thanks for downloading the preparation guide!
          </div>
          <div className={styles.date}>
            { lastDownloadMoment.fromNow()}
            { lastDownloadMoment.diff(moment(), 'hours') > 36 && ` on ${lastDownloadMoment.format('ll')}`}
          </div>
          <ActionLink appearance="link" onClick={onDownloadClick} disabled={downloadPending}>
            Download again
          </ActionLink>
        </div>
      ) : (
        <ActionLink appearance="button" onClick={onDownloadClick} disabled={downloadPending}>
          Download the full preparation guide
        </ActionLink>
      )}
    </div>
  )
}