import React from 'react'

import { useUserModal, useActiveUser } from 'app/user'

import styles from './RegistrationReminder.module.scss'

export default ({ className, render }) => {
  const { openSignIn, openRegister, openVerify } = useUserModal();
  const activeUser = useActiveUser();
  const content = render({ activeUser, openSignIn, openVerify, openRegister });
  return content && (
    <div className={`${styles.register} ${className || ''}`}>
      { content }
    </div>
  )
}
