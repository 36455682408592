import React from 'react'
import clsx from 'clsx'
import { pricing } from './index'

import styles from './Price.module.scss'

export default ({ className, plan, annual = false }) => {
  const [tier1, tier2, extra, monthly, savings] = pricing[plan][annual];
  const redeemCount = Math.floor(monthly / tier1);

  return (
    <div className={clsx(styles.price, className)}>
      <h3>Per listing:</h3>
      <b>${tier1}</b> <span>for up to 2000 sq ft</span> <br/>
      <b>${tier2}</b> <span>for up to 4000 sq ft</span> <br/>
      <b>+{extra}¢</b> <span>per sq ft over 4000</span>

      { savings && (
        <div className={styles.badge} key={annual}>
          Save <div>{savings}%</div>
        </div>
      )}

      { monthly && (
        <>
          <h3>Per month:</h3>
          <div className={styles.monthly}>${monthly.toLocaleString()}</div>
          { redeemCount > 0 && (
            <div className={styles.redeem}>
              redeemable for up to {redeemCount} listing{redeemCount > 1 ? 's' : ''}
            </div>
          )}
        </>
      )}

    </div>
  )
}
